import styles from './file-header.module.sass'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CustomBreadcrumbs from '../breadcrumbs/CustomBreadcrumbs'
import { useRef } from 'react'
import { useUploady } from '@rpldy/uploady'
import { decksSelector } from '../../store/reducers/decks'
import CreateShareLinkPopup from '../popups/create-share-link'
import { useTranslation } from 'react-i18next'

const FileHeader = ({ deckFlow, navigate, file }) => {
    const { t } = useTranslation()
    let { id, deckId } = useParams()
    const fileInputRef = useRef(null)
    const { currentDeck } = useSelector(decksSelector)
    const { setOptions, upload } = useUploady()
    const token = localStorage.getItem('token')

    const handleFileChange = (event) => {
        setOptions({
            destination: {
                url: `${process.env.REACT_APP_BASE_URL}/api/projects/files/upload/${id}`,
                headers: { Authorization: `Bearer ${token}` },
            },
        })
        upload(event.target.files)
    }
    return (
        <>
            <div className={styles.header}>
                <div className={styles.flexCenter}>
                    <input
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        id="upload"
                        type="file"
                        onChange={handleFileChange}
                        multiple
                    />
                    <svg
                        onClick={() => navigate(-1)}
                        width="12"
                        height="22"
                        style={{ marginRight: '10px' }}
                        viewBox="0 0 12 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </svg>
                    {deckFlow ? (
                        <div className={styles.deckHeader}>
                            <DeckSvg />
                            {currentDeck?.name}
                            <Separator />
                        </div>
                    ) : (
                        <ThreeLines />
                    )}

                    <Link to={`/`}>{t('all_projects')}</Link>
                    <Separator />
                    <CustomBreadcrumbs deckFlow={deckFlow} deckId={deckId} file={file} />
                </div>
                {!deckFlow && (
                    <div className={styles.buttonGroup}>
                        <button>
                            <a href={file.source} download>
                                {t('download')}
                            </a>
                        </button>
                        <CreateShareLinkPopup id={id} file={true} />
                    </div>
                )}
            </div>
        </>
    )
}
export default FileHeader
const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}

const DeckSvg = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.975433 0.582245C1.03276 0.245079 1.30738 0 1.62786 0H11.3721C11.6926 0 11.9672 0.245079 12.0246 0.582245L12.9891 6.25497C13.0631 6.69024 12.7504 7.09091 12.3367 7.09091H0.663316C0.24959 7.09091 -0.0631222 6.69024 0.0108874 6.25497L0.975433 0.582245Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.18129 1.41818L1.45788 5.67273H11.5421L10.8187 1.41818H2.18129ZM1.62786 0C1.30738 0 1.03276 0.245079 0.975433 0.582245L0.0108874 6.25497C-0.0631222 6.69024 0.24959 7.09091 0.663316 7.09091H12.3367C12.7504 7.09091 13.0631 6.69024 12.9891 6.25497L12.0246 0.582245C11.9672 0.245079 11.6926 0 11.3721 0H1.62786Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.909 13L5.909 5.90906L7.09082 5.90906L7.09082 13L5.909 13Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5459 11.8181L9.45499 11.8181V12.9999H3.5459V11.8181Z"
                fill="black"
            />
        </svg>
    )
}
const ThreeLines = () => {
    return (
        <svg
            width="16"
            height="16"
            style={{ marginRight: '7px' }}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="3" y1="3" x2="13" y2="3" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="8" x2="13" y2="8" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="13" x2="13" y2="13" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}
