import { createAction, handleActions } from 'redux-actions'
import { all } from 'redux-saga/effects'

const namespace = 'notifications'

const initialState = {
    showNotificationsPopup: false,
    notifications: [],
}

const SET_SHOW_POPUP = `${namespace}/SET_SHOW_POPUP`
const SET_NOTIFICATIONS = `${namespace}/SET_NOTIFICATION`

export const setShowNotifications = createAction(SET_SHOW_POPUP)
export const setNotifications = createAction(SET_NOTIFICATIONS)

// export const resetComments = createAction(RESET_COMMENTS)

export default handleActions(
    {
        [SET_SHOW_POPUP]: (state, { payload }) => ({ ...state, showNotificationsPopup: payload }),
        [SET_NOTIFICATIONS]: (state, { payload }) => ({ ...state, notifications: payload }),
        // [RESET_COMMENTS]: (state) => ({ ...state, comments: [] }),
    },
    initialState
)

export const notificationsSelector = (state) => state[namespace]

export function* sagas() {
    yield all([])
}
