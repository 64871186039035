import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import styles from './shared-links.module.sass'
import { useDispatch } from 'react-redux'
import { resource } from '../../../api/http'
import { SettingMini } from '../../../assets/img/svg/svgs'
import { format } from 'date-fns'
import { isPast } from 'date-fns'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

const SharedLinksPopup = ({ item }) => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false)

    const [links, setLinks] = React.useState([])

    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    React.useEffect(() => {
        // GET https://api.lovemedia.pp.ua/api/file/share-links/{fileId}
        const urlParam = item.type === 'directory' ? 'project' : 'file'
        resource
            .get(`${process.env.REACT_APP_BASE_URL}/api/${urlParam}/share-links/${item?.id}`, {})
            .then(({ data: { data, success, message } }) => {
                if (success) {
                    setLinks(data)
                }
            })
    }, [item])

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div className={styles.wrapper}>
            <SettingMini onClick={() => setOpen(true)} />
            <Dialog
                fullScreen={false}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={styles.wrapper}
                sx={{ with: 820 }}
            >
                <DialogTitle id="responsive-dialog-title">{t('shared_links')}</DialogTitle>

                <DialogContent>
                    <div className={styles.header}>
                        <div>{t('object')}</div>
                        <div>{t('link_address')}</div>
                        <div>{t('creation_date')}</div>
                        <div>{t('valid_until')}</div>
                        <div>{t('status')}</div>
                    </div>
                    {links?.map((element, idx) => {
                        const status = isPast(new Date(element.time_to))
                        return (
                            <div key={element.id} className={styles.item}>
                                <div>
                                    <img src={item.leftSidebarPreview} alt="leftSidebarPreview" />
                                    <div>{item.name}</div>
                                </div>

                                <div>
                                    <a href={element.link} target="_blank">
                                        {element.link.slice(0, 22) + '...'}
                                    </a>
                                </div>
                                <div>
                                    <span>{format(new Date(element.created_at), 'd MMMM yyyy')}</span>
                                </div>
                                <div>
                                    <span>{format(new Date(element.time_to), 'd MMMM yyyy')}</span>
                                </div>

                                <div>
                                    {status ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <svg
                                                style={{ marginRight: 5 }}
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="5" cy="5" r="5" fill="#FF5454" />
                                            </svg>
                                            <div>Inactive</div>
                                        </div>
                                    ) : (
                                        <div>
                                            <svg
                                                style={{ marginRight: 5 }}
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="5" cy="5" r="5" fill="#14AE5C" />
                                            </svg>
                                            Active
                                        </div>
                                    )}

                                    <FileMenu elem={element} setLinks={setLinks} links={links} />
                                </div>
                            </div>
                        )
                    })}
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default SharedLinksPopup

const FileMenu = ({ dispatch, elem, setLinks, links }) => {
    const { t } = useTranslation()
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const removeLink = () => {
        //api.lovemedia.pp.ua/api/remove-share-link/{linkId}
        resource
            .get(`${process.env.REACT_APP_BASE_URL}/api/remove-share-link/${elem?.id}`, {})
            .then(({ data: { data, success, message } }) => {
                if (success) {
                    setLinks(links.filter((e) => e.id !== elem.id))
                }
            })
    }
    return (
        <>
            <svg
                onClick={handleOpenUserMenu}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity="0.5">
                    <circle cx="15" cy="21" r="2" transform="rotate(-90 15 21)" fill="black" />
                    <circle cx="15" cy="15" r="2" transform="rotate(-90 15 15)" fill="black" />
                    <circle cx="15" cy="9" r="2" transform="rotate(-90 15 9)" fill="black" />
                </g>
            </svg>
            <Menu
                sx={{
                    mt: '45px',
                    // top: -25,
                    // left: 10,
                    ul: {
                        width: 210,
                        background: '#1F1F1F',
                        color: '#fff',
                        // borderRadius: '5px',
                        padding: 0,
                    },
                    borderRadius: 2,
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {[t('Delete')].map((setting) => (
                    <MenuItem
                        key={setting}
                        onClick={() => {
                            // dispatch(removeFile(elem.id))
                            removeLink()
                        }}
                        sx={{
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            borderRadius: '5px',
                            div: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: 40,
                                opacity: 0.5,
                                '&:hover': { opacity: 1, background: '#292929' },
                            },
                        }}
                    >
                        <span>{setting}</span>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
