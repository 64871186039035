import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../casting-quiz.module.sass'
import { resource } from '../../../api/http'
import { getAllCastings } from '../../../store/reducers/castings'
import { useTranslation } from 'react-i18next'

const General = ({ formik }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [currentQuiz, setCurrentQuiz] = useState([])
    const { questions } = currentQuiz
    const generalIds = [3, 5, 6, 7] //[1, 2, 3, 4, 5,6, 7]

    useEffect(() => {
        if (!currentQuiz) dispatch(getAllCastings())
        try {
            resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/castings/${id}`)
                .then(({ data: { data, success, message } }) => {
                    if (success) setCurrentQuiz(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])
    const dispatch = useDispatch()

    const inclusesQuestion = (id) => {
        var found = questions?.find((e) => e.id === id)
        return found !== -1 ? found : false
    }

    return (
        <div>
            <div>
                <div className={styles.subTitle}>
                    <div>{t('general_casting')}</div>
                </div>

                <div>
                    <div className={styles.question}>
                        <span>{t('first_name')}</span>

                        <input type="text" name={'1'} value={formik.values[`1`]} onChange={formik.handleChange} />
                    </div>
                    <div className={styles.question}>
                        <span>{t('last_name')}</span>

                        <input type="text" name={'2'} value={formik.values[`2`]} onChange={formik.handleChange} />
                    </div>
                    {generalIds?.map((e) => {
                        const findItem = inclusesQuestion(e)
                        
                        return findItem ? (
                            <div className={styles.question} key={findItem?.id}>
                                <span>{t(findItem?.name)}</span>

                                <input
                                    type="text"
                                    name={`${findItem?.id}`}
                                    value={formik.values[`${findItem?.id}`]}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        ) : null
                    })}

                    {inclusesQuestion(4) && (
                        <div>
                            <span>{t('character')}</span>
                            {[inclusesQuestion(4)].map((item) => {
                                return (
                                    <div className={styles.checker}>
                                        <div className={styles.checker__item}>
                                            {formik.values['4'] === t('man') ? (
                                                <Checked />
                                            ) : (
                                                <Unchecked onClick={() => formik.setFieldValue('4', t('man'))} />
                                            )}

                                            <div>{t('man')}</div>
                                        </div>
                                        <div className={styles.checker__item}>
                                            {formik.values['4'] === t('woman') ? (
                                                <Checked />
                                            ) : (
                                                <Unchecked onClick={() => formik.setFieldValue('4', t('woman'))} />
                                            )}
                                            <div>{t('woman')}</div>
                                        </div>
                                        <div className={styles.checker__item}>
                                            {formik.values['4'] === t('kid') ? (
                                                <Checked />
                                            ) : (
                                                <Unchecked onClick={() => formik.setFieldValue('4', t('kid'))} />
                                            )}
                                            <div>{t('kid')}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default General

const Unchecked = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13.332" cy="13.333" r="12.5" stroke="#3376A3" />
        </svg>
    )
}
const Checked = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13.332" cy="13.333" r="12.5" fill="#D7E7FF" stroke="#3376A3" />
            <path d="M7.33203 12.0552L11.8838 16.333L19.332 9.33301" stroke="#3376A3" strokeWidth="2" />
        </svg>
    )
}
