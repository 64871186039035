import React, { useEffect, useMemo, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Avatar } from '@mui/material'
import styles from '../settings.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { updateUser, userSelector } from '../../../store/reducers/user'
import ChangePasswordPopup from '../../../components/popups/change-password'
import { useTranslation } from 'react-i18next'

const General = ({ navigate }) => {
    // const loading = useSelector(authLoading)
    // const onHandleSubmitForm = () => {}
    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const { t } = useTranslation()
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            firstName: '',
            lastName: '',
            organization: '',
            avatar: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(2, 'First name must be at least 2 characters')
                .max(50, 'First name must be at most 50 characters')
                .required('Please enter a valid first name'),
            email: Yup.string().email('Invalid email address').required(t('req_email')),
            // firstName: Yup.string()
            //     .min(2, 'First name must be at least 2 characters')
            //     .max(50, 'First name must be at most 50 characters')
            //     .required('Please enter a valid first name'),
            // lastName: Yup.string()
            //     .min(2, 'First name must be at least 2 characters')
            //     .max(50, 'First name must be at most 50 characters')
            //     .required('Please enter a valid first name'),
            // organization: Yup.string()
            //     .min(2, 'First name must be at least 2 characters')
            //     .max(50, 'First name must be at most 50 characters')
            //     .required('Please enter a valid first name'),
        }),
        onSubmit: (values) => {
            let fd = new FormData()
            if (values.name) fd.append('name', values.name)
            if (values.email) fd.append('email', values.email)
            if (values.firstName) fd.append('firstName', values.firstName)
            if (values.lastName) fd.append('lastName', values.lastName)
            if (values.organization) fd.append('organization', values.organization)
            // if (typeof values.avatar === 'object') {
            //     fd.append('avatar', values.avatar)
            // }
            dispatch(updateUser(fd))
        },
    })

    useEffect(() => {
        formik.setValues(user) // sets values from server
    }, [user])

    const handleClick = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = (event) => {
        if (event.target.files[0])
            formik.setFieldValue('avatar', event.target.files[0]).then((e) => {
                const fd = new FormData()
                fd.append('avatar', event.target.files[0])
                dispatch(updateUser(fd))
            })
    }

    const memoAvatar = useMemo(() => {
        const type = typeof formik.values['avatar']
        if (type === 'object') return URL.createObjectURL(formik.values['avatar'])
        else return formik.values['avatar']
    }, [formik.values['avatar']])

    const fileInputRef = useRef(null)

    return (
        <form onSubmit={formik.onSubmit}>
            <div className={styles.general}>
                <span className={styles.general__title}>{`${t('profile')} avatar`}</span>
                <span className={styles.general__subtitle}>Min. 200x200. JPEG / PNG</span>

                <div className={styles.avatar}>
                    <Avatar sx={{ bgcolor: 'green', height: 80, width: 80 }} src={memoAvatar}>
                        N
                    </Avatar>
                    <button
                        onClick={(e) => {
                            e.preventDefault()
                            handleClick()
                        }}
                    >
                        {t('upload_photo')}
                    </button>
                    <input
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        id="upload"
                        type="file"
                        onChange={handleFileChange}
                        multiple
                    />
                </div>
                <div className={styles.fields}>
                    <span>{t('username')}</span>
                    <input type="text" name="name" onChange={formik.handleChange} value={formik.values['name']} />
                    <span>Email</span>
                    <input type="text" name="email" onChange={formik.handleChange} value={formik.values['email']} />
                    <span>{t('first_name')}</span>
                    <input
                        type="text"
                        name="firstName"
                        onChange={formik.handleChange}
                        value={formik.values['firstName']}
                    />
                    <span>{t('last_name')}</span>
                    <input
                        type="text"
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values['lastName']}
                    />
                    <span>{t('organization')}</span>
                    <input
                        type="text"
                        name="organization"
                        onChange={formik.handleChange}
                        value={formik.values['organization']}
                    />
                    <span>{t('password')}</span>
                    <ChangePasswordPopup />
                </div>

                <div className={styles.general__btns}>
                    <button
                        className={styles.general__save}
                        type="submit"
                        onClick={formik.handleSubmit}
                        disabled={!formik.isValid && formik.dirty}
                    >
                        {t('save_changes')}
                    </button>
                    <button className={styles.general__cancel}>{t('cancel')}</button>
                </div>
                {/* <div className={styles.general__delete}>
                    <span>Delete account</span>
                </div> */}
            </div>
        </form>
    )
}

export default General
