import { useFormik } from 'formik'
import React, { useEffect, useMemo, useRef } from 'react'
import styles from './create-deca.module.sass'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { createDeck, decksSelector, getAllDecks } from '../../store/reducers/decks'
import { Card, CardContent, CardMedia } from '@mui/material'
import template1 from '../../assets/img/template1.png'
import template2 from '../../assets/img/template2.png'
import CustomTextField from '../../components/custom-text-field/CustomTextField'
import { useTranslation } from 'react-i18next'

const CreateDeck = ({ navigate }) => {
    const { t } = useTranslation()
    const { decks } = useSelector(decksSelector)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!decks.length) dispatch(getAllDecks())
    }, [])
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            thumb: '',
            // files: [],
            type: 1,
            // user_id: 0,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().min(1).required('Name is a required field'),
            description: Yup.string().required('Description is a required field'),
            thumb: Yup.mixed().required(),
            type: Yup.number().required(),
        }),
        onSubmit: (values) => {
            let fd = new FormData()
            fd.append('name', values.name)
            fd.append('description', values.description)
            fd.append('type', values.type) //TO DO selected type
            fd.append('thumb', values.thumb)

            dispatch(createDeck({ values: fd, navigate }))
        },
        validateOnMount: true,
    })

    const memoLogo = useMemo(() => {
        if (formik.values['thumb']) return URL.createObjectURL(formik.values['thumb'])
        else return
    }, [formik.values['thumb']])

    const frameworks = [
        { id: 1, name: `${t('template')} 1`, img: template1 },
        { id: 2, name: `${t('template')} 2`, img: template2 },
    ]
    const fileInputRef = useRef(null)
    const handleClick = () => {
        fileInputRef.current.click()
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <svg
                    onClick={() => navigate(-1)}
                    width="12"
                    height="22"
                    style={{ marginRight: 20 }}
                    viewBox="0 0 12 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g opacity="0.5">
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </g>
                </svg>
                <div>{t('new_deck')}</div>
            </div>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
                <div className={styles.flexRow}>
                    <div></div>
                    <div className={styles.leftSide}>
                        <img src={formik.values['type'] === 1 ? template1 : template2} alt="template" />
                        <div>
                            <div className={styles.leftSide__logo}>
                                <div className={styles.leftSide__logo__img}>
                                    {formik.values['thumb'] ? <img src={memoLogo} alt="prewiev" /> : null}
                                </div>
                                <div className={styles.leftSide__logo__rightSideWrapper}>
                                    <div>
                                        <div className={styles.title}>{t('add_your_logo')}</div>
                                        <div className={styles.description}>{t('100x100')}</div>
                                    </div>
                                    {/* <button>Upload</button> */}
                                    <input
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        id="thumb"
                                        name="thumb"
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => {
                                            if (event.currentTarget.files[0])
                                                formik.setFieldValue('thumb', event.currentTarget.files[0])
                                        }}
                                        className="form-control"
                                    />
                                    <button type="button" onClick={handleClick}>
                                        {t('upload_deck')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.padding}>
                            <div className={styles.title}>{t('deck_title')}</div>
                            <CustomTextField name="name" formik={formik} small={true} />

                            <div className={styles.title}>{t('short_description')}</div>
                            <CustomTextField name="description" formik={formik} small={true} />

                            <button
                                onClick={formik.onSubmit}
                                autoFocus
                                type="submit"
                                disabled={!formik.isValid}
                                className={styles.createBtn}
                            >
                                {t('create')}
                            </button>
                        </div>
                    </div>
                    <div className={styles.rightSide}>
                        <span>{t('templates')}</span>
                        {frameworks.map((item) => {
                            return (
                                <Card
                                    key={item.id}
                                    onClick={() => {
                                        formik.setFieldTouched('type')
                                        formik.setFieldValue('type', item.id)
                                    }}
                                    sx={{
                                        width: '208px',
                                        margin: '15px 0px 5px',

                                        backgroundColor: formik.values['type'] === item.id ? '#D7E7FF' : 'transparent',
                                        border:
                                            formik.values['type'] === item.id ? '2px solid #3376A3;' : 'transparent',
                                        borderRadius: 3,
                                        borderTopLeftRadius: 12,
                                        borderTopRightRadius: 12,
                                        fontFamily: 'Zeitung Micro Pro',
                                        fontSize: 12,
                                        fontWeight: 400,
                                    }}
                                >
                                    <CardMedia
                                        sx={{
                                            width: 208,
                                            minHeight: 132,
                                            backgroundSize: 208,
                                        }}
                                        image={item.img}
                                        // title={item.name}
                                    />

                                    <CardContent
                                        sx={{
                                            color: '#000',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'end',
                                            padding: '7px 7px 10px 10px',
                                            paddingBottom: '10px !important',
                                        }}
                                    >
                                        <div className={styles.flexBetween}>
                                            <span>{item.name}</span>
                                        </div>
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CreateDeck
