import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take } from 'redux-saga/effects'

const namespace = 'uploads'

const initialState = {
    uploadsItems: [],
    showUploadings: false,
}

const SET_UPLOADS = `${namespace}/SET_UPLOADS`
const ADD_ITEM = `${namespace}/ADD_ITEM`
const SET_ITEM = `${namespace}/SET_ITEM`
const UPDATE_ITEM = `${namespace}/UPDATE_ITEM`
const SET_SHOW_POPUP = `${namespace}/SET_SHOW_POPUP`

export const addUploadItem = createAction(ADD_ITEM)
export const setItem = createAction(SET_ITEM)
export const updateItem = createAction(UPDATE_ITEM)
export const setUploadPopup = createAction(SET_SHOW_POPUP)

export default handleActions(
    {
        [SET_UPLOADS]: (state, { payload }) => ({ ...state, uploadsItems: payload }),
        [SET_ITEM]: (state, { payload }) => ({ ...state, uploadsItems: [...state.uploadsItems, payload] }),
        [UPDATE_ITEM]: (state, { payload: { id, completed, loaded, itemState } }) => ({
            ...state,
            uploadsItems: state.uploadsItems.map((item) => {
                if (item.id === id) {
                    item.completed = completed
                    item.loaded = loaded
                    item.state = itemState
                }
                return item
            }),
        }),
        [SET_SHOW_POPUP]: (state, { payload }) => ({ ...state, showUploadings: payload }),
    },
    initialState
)

export const uploadsSelector = (state) => state[namespace]
export const itemsColection = (state) => state[namespace].uploadsItems
export const showUploadings = (state) => state[namespace].showUploadings

function* addItem() {
    while (true) {
        const { payload } = yield take(ADD_ITEM)
        try {
            const items = yield select(itemsColection)
            const hasId = items.filter((element, idx) => {
                return element.id === payload.id
            })

            if (!hasId.length) {
                yield put(setItem(payload))
            } else {
                const { completed, loaded, state, id } = payload
                yield put(updateItem({ id: id, completed: completed, loaded: loaded, itemState: state })) // Updates only fields wich need update
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export function* sagas() {
    yield all([addItem()])
}
