// import styles from './deck-carusel-shared.module.sass'
// import 'react-alice-carousel/lib/scss/alice-carousel.scss'
import { decksSelector } from '../../store/reducers/decks'
import { useSelector } from 'react-redux'

import FirstDeckType from './deck-types/First'
import SecondDeckType from './deck-types/Second'

const DeckCaruselShared = (props) => {
    const {
        sharedDeck: { type },
    } = useSelector(decksSelector)

    switch (type) {
        case 1:
            return <FirstDeckType />
            break
        case 2:
            return <SecondDeckType />
            break

        default:
            return null
    }
}
export default DeckCaruselShared
