import React from 'react'
import styles from '../location-bar.module.sass'
import { useTranslation } from 'react-i18next'

const AnswersSection = ({ currentAnsvers }) => {
    const { t } = useTranslation()
    const { answers } = currentAnsvers
    console.log(123, currentAnsvers)
    return (
        <div className={styles.info__wrapper}>
            <div className={styles.info__header}>
                <div>
                    <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6.5L3.5 3.5L0.5 0.5" stroke="black" />
                    </svg>
                    <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6.5L3.5 3.5L0.5 0.5" stroke="black" />
                    </svg>
                </div>

                <span>{t('info')}</span>
            </div>
            {answers?.map((e) => {
                return (
                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t(e.question)}</span>
                        {e.question === 'Google location pin' ? (
                            <a target='_blank' href={e.answer}> {e.answer.slice(8, 25) + '...'}</a>
                        ) : (
                            <div className={styles.info__item}>{e.answer}</div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}
export default AnswersSection
