import React, { useState } from 'react'
import styles from '../deck-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { decksSelector, setCurrentDeck, updateDeck, updateFiles } from '../../../store/reducers/decks'
import { useFormik } from 'formik'
// import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import AddDeckPagePopup from '../../popups/add-deck-page/AddDeckFolder'
import { resource } from '../../../api/http'

const Pages = (props) => {
    // const { selectedIndex, changeIndex } = props // slider curent index index and setter
    const {
        currentDeck: { files, description, name, thumb, directories },
        currentDeck,
    } = useSelector(decksSelector)

    const dispatch = useDispatch()
    const [newTitle, setNewTitle] = useState(name)
    const [newDescription, setNewDescription] = useState(description)
    const [logo, setLogo] = useState(false)
    const { id } = useParams()

    const formik = useFormik({
        initialValues: {
            // decaType: null,
        },
        // validationSchema: Yup.object().shape({
        //     decaType: Yup.object()
        //         .shape({
        //             id: Yup.number().required('id is req'),
        //         })
        //         .required('This field is required.'),
        // }),
        onSubmit: (values) => {
            if (newTitle !== name || newDescription !== description || logo) {
                let fd = new FormData()
                fd.append('name', newTitle)
                fd.append('description', newDescription)
                if (logo) fd.append('thumb', logo)
                dispatch(updateDeck({ id: id, fd }))
            }

            files.forEach(function (item, i, arr) {
                const inputValue = document.getElementById(`title_${item.id}`).value

                if (inputValue !== item.title) {
                    console.log(inputValue)
                    dispatch(updateFiles({ id: item.id, values: { deck_id: item.deck_id, title: '66666' } }))
                }
            })
        },
        validateOnMount: true,
    })

    return (
        <div className={styles.edit}>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
                <div className={styles.edit__header}>
                    <span>Pages</span>
                    <AddDeckPagePopup />
                </div>
                <div className={styles.directories}>
                    {directories?.map((e) => (
                        <div className={styles.folder}>
                            <div className={styles.directories__item}>
                                <div>
                                    <PageLogo />
                                    {e.name}
                                </div>

                                <Close
                                    onClick={() => {
                                        resource
                                            .delete(`${process.env.REACT_APP_BASE_URL}/api/deck-directories/${e.id}`)
                                            .then(({ data: { data, message, success } }) => {
                                                if (success) {
                                                    currentDeck.directories = currentDeck.directories.filter(
                                                        (elem) => elem.id !== e.id
                                                    )
                                                    dispatch(setCurrentDeck(currentDeck))
                                                }
                                            })
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>

                {/* <div className={styles.edit__footer}>
                    <button type="submit">{t("save_changes")}</button>
                    <button>{t("cancel")}</button>
                </div> */}
            </form>
        </div>
    )
}
export default Pages

const PageLogo = () => {
    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 2C0 0.89543 0.895431 0 2 0H18C19.1045 0 20 0.895431 20 2V7L12.6316 14H2C0.895432 14 0 13.1046 0 12V2Z"
                fill="#3376A3"
            />
            <path d="M19.9998 7H14.6313C13.5268 7 12.6313 7.89543 12.6313 9V14L19.9998 7Z" fill="#99BAD1" />
        </svg>
    )
}
const Close = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1.46143 1L12.9999 13" stroke="black" />
            <path d="M1 13L12.5385 1" stroke="black" />
        </svg>
    )
}
