import { Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import styles from './projects.module.sass'
import { useDispatch } from 'react-redux'
import { deleteProjectById } from '../../store/reducers/projects'
import { formatDistance } from 'date-fns'
import CreateProjectPopup from '../../components/popups/create-project'
import EditProjectPopup from '../../components/popups/edit-project'
import SettingsAvatar from '../../components/avatar/SettingsAvatar'
import { useTranslation } from 'react-i18next'

import { pl } from 'date-fns/locale'

const ProjectsMap = ({ projects, deckFlow, deckId, navigate }) => {
    const [id, setId] = useState()
    const [editPopup, setEditPopup] = useState(false)

    // if (!projects.length) return <CreateProjectPopup emptyProjects={true} />

    return projects?.map((item, idx) => {
        const to = deckFlow ? `/deck-builder/${deckId}/project/${item.id}` : `/project/${item.id}`
        const selected = id === item.id

        return (
            <section key={item.id}>
                <Item
                    item={item}
                    to={to}
                    selected={selected}
                    navigate={navigate}
                    setId={setId}
                    deckFlow={deckFlow}
                    setEditPopup={setEditPopup}
                    editPopup={editPopup}
                />
            </section>
        )
    })
}

const Item = ({ item, to, selected, navigate, setId, deckFlow, setEditPopup, editPopup }) => {
    const { t } = useTranslation()
    return (
        <>
            <div
                onDoubleClick={() => {
                    navigate(to)
                }}
                onClick={(e) => setId(item.id)}
                key={item.id}
                className={styles.projectItem}
            >
                <div
                    style={{
                        width: 307,
                        backgroundColor: selected ? '#D7E7FF' : '#F8F8F8',
                        borderRadius: 10,
                        boxShadow: 'none',
                        border: selected ? '2px solid #3375A3' : '2px solid rgba(0, 0, 0, 0.2)',
                        img: { display: 'none' },
                    }}
                >
                    <div>
                        <img src={item.thumb} className={selected ? styles.thumb__selected : styles.thumb} />
                    </div>
                    <div className={styles.CardContent}>
                        <div className={styles.row}>
                            <div>
                                <div className={styles.name}>{item.name}</div>
                                <div className={styles.editTime}>
                                    <div>
                                        <div className={styles.subName}>{item.description}</div>
                                        <div className={styles.subName}>
                                            {`${t('edited')}
                                        ${formatDistance(new Date(), new Date(item.updated_at), {
                                            includeSeconds: true,
                                            locale: pl,
                                        })}
                                        ${t('ago')}`}
                                        </div>
                                    </div>
                                    {!deckFlow && selected && (
                                        <Dots id={item.id} item={item} setEditPopup={setEditPopup} />
                                    )}
                                </div>
                            </div>
                            <div>
                                {!deckFlow && !selected && (
                                    <div className={styles.members__wrap}>
                                        <div className={styles.members}>
                                            {item?.teamMembers?.map((e, idx) => {
                                                const margin = idx * 15 + 'px'
                                                return <SettingsAvatar key={e.id} name={e.name} margin={margin} />
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selected && <EditProjectPopup openState={editPopup} item={item} close={() => setEditPopup(false)} />}
            {/* ^^^^^ for render only one popup */}
        </>
    )
}
export default ProjectsMap

const Dots = ({ id, item, setEditPopup }) => {
    const { t } = useTranslation()
    const [anchorElUser, setAnchorElUser] = useState(false)
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = (event) => {
        setAnchorElUser(null)
    }
    const dispatch = useDispatch()

    return (
        <>
            <Menu
                sx={{
                    mt: '45px',
                    top: -32,
                    left: -42,
                    ul: {
                        width: 122,
                        background: '#1F1F1F',
                        color: '#fff',
                        borderRadius: '0',
                        padding: 0,
                    },
                    borderRadius: '5px',
                }}
                id="basic-menu"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {[t('Edit'), t('Delete')].map((setting, idx) => (
                    <MenuItem
                        sx={{
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            borderRadius: 5,
                            div: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: 40,
                                opacity: 0.5,
                                '&:hover': { opacity: 1, background: '#292929' },
                            },
                        }}
                        key={setting}
                        onClick={() => {
                            if (idx === 1) dispatch(deleteProjectById(id))
                            if (idx === 0) {
                                setEditPopup(true)
                            }
                            handleCloseUserMenu()
                        }}
                    >
                        <div>{setting}</div>
                    </MenuItem>
                ))}
            </Menu>
            <svg
                width="15"
                onClick={(e) => {
                    handleOpenUserMenu(e)
                }}
                height="15"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="3" cy="9" r="2" fill="#3376A3" />
                <circle cx="9" cy="9" r="2" fill="#3376A3" />
                <circle cx="15" cy="9" r="2" fill="#3376A3" />
            </svg>
        </>
    )
}
