import styles from '../deck-carusel-shared.module.sass'
import { decksSelector } from '../../../store/reducers/decks'
import { useSelector } from 'react-redux'
import { useRef, useState } from 'react'
import { FacebookLink, InstLink, LinkidInkLink, DeckLogo, TwitterLink, VimeoLink } from '../assets/SvgImages'
import MansoryView from '../../mansory-view'
import { useBeforeRender } from '../../../hooks/useBeforeRender'
import DeckListView from '../../list-view-deck-files'
import FullScreenPopup from '../components/FullScreenPopup'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const SecondDeckType = (props) => {
    useBeforeRender(() => {
        //Fix mui render issue  https://stackoverflow.com/questions/75774800/how-to-stop-resizeobserver-loop-limit-exceeded-error-from-appearing-in-react-a
        window.addEventListener('error', (e) => {
            if (e) {
                const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
                const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
                if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer'
                if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer'
            }
        })
    }, [])

    const {
        sharedDeck: {
            id,
            files,
            name,
            thumb,
            description,
            type,
            directories,
            facebook_link,
            linkedin_link,
            twitter_link,
            font_color,
            instagram_link,
            vimeo_link,
            font,
        },
    } = useSelector(decksSelector)
    const { t } = useTranslation()

    const [currentFolderId, setCurrentFilderId] = useState(0)
    const currentFolderIndex = directories.findIndex((e) => e.id === currentFolderId)
    const [currentFilter, setCurrentFilter] = useState(1)
    const [selectedFileIndex, changeSelectedFileIndex] = useState(0)

    let allFiles = []

    const x = directories.map((e) => {
        allFiles = [...allFiles, ...e.files]
        return null
    })

    const handleOnWheel = (e) => {
        // e.preventDefault()
        var container = document.getElementById('scroll-container')
        var containerScrollPosition = document.getElementById('scroll-container').scrollLeft
        container.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY,
            // behaviour: 'smooth', //if you want smooth scrolling
        })
    }

    const handleDownload = () => {
        const url = currentFolderId
            ? `${process.env.REACT_APP_BASE_URL}/api/decks/directory/download/${currentFolderId}`
            : `${process.env.REACT_APP_BASE_URL}/api/decks/download/${id}`
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'archive.zip')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
    }
    const [isHovered, setIsHovered] = useState(false)
    const handleMouseEnter = () => {
        setIsHovered(true)
    }
    const handleMouseLeave = () => {
        setIsHovered(false)
    }
    const buttonStyle = {
        background: 'transparent',
        color: font_color ?? '#fff',
        border: `2px solid ${font_color ?? '#fff'}`,
        borderRadius: 15,
        padding: 5,
        fontFamily: font ?? 'Zeitung Micro Pro',
        margin: '0 30px 0',
    }
    const buttonHoverStyle = {
        cursor: 'pointer',
    }
    const [fullScreen, setfullScreen] = useState(false)

    const [autoplay, setAutoplay] = useState(false)

    var timerID

    const videoRef = useRef(null)

    useEffect(() => {
        StartVideoBlur()

        return () => clearInterval(timerID)
    }, [videoRef, selectedFileIndex, currentFolderId])

    const StartVideoBlur = () => {
        const canvas = document.getElementById('myCanvas')
        const ctx = canvas?.getContext('2d')

        timerID = window.setInterval(function () {
            ctx?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height) //draw blur
        }, 30)
    }
    useEffect(() => {
        if (currentFilter === 2) {
            //Only for deck carusel
            if (currentFolderId && currentFolderIndex !== -1) {
                if (directories[currentFolderIndex]?.files[selectedFileIndex]?.type === 'video') {
                    videoRef.current.addEventListener('ended', () => {
                        changeSelectedFileIndex((prevIndex) =>
                            prevIndex === directories[currentFolderIndex]?.files.length - 1 ? 0 : prevIndex + 1
                        )
                    })

                    if (autoplay) videoRef.current.play()
                } else {
                    if (autoplay)
                        // For images, show image for 3 seconds before moving to the next item
                        setTimeout(() => {
                            changeSelectedFileIndex((prevIndex) =>
                                prevIndex === directories[currentFolderIndex].files.length - 1 ? 0 : prevIndex + 1
                            )
                        }, 3000)
                }
            } else {
                if (allFiles[selectedFileIndex].type === 'video') {
                    videoRef.current.addEventListener('ended', () => {
                        changeSelectedFileIndex((prevIndex) => (prevIndex === allFiles.length - 1 ? 0 : prevIndex + 1))
                    })
                    if (autoplay) videoRef.current.play()
                } else {
                    if (autoplay) {
                        setTimeout(() => {
                            changeSelectedFileIndex((prevIndex) =>
                                prevIndex === allFiles.length - 1 ? 0 : prevIndex + 1
                            )
                        }, 3000)
                    }
                }
            }
        }

        // Clean up event listener on unmount or when the current media changes
        return () => {
            // videoRef.current.removeEventListener('ended', () =>
            //     changeSelectedFileIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1))
            // )
        }
    }, [selectedFileIndex, currentFolderIndex])

    const handleMediaEnd = () => {
        // changeIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1))
    }
    const currentItem =
        currentFolderId && currentFolderId !== -1 ? (
            directories[currentFolderIndex]?.files[selectedFileIndex]?.type === 'video' ? (
                <div className={styles.itemVideo} key={directories[currentFolderIndex].files[selectedFileIndex].id}>
                    <div>
                        <video
                            autoplay={autoplay}
                            // muted
                            ref={videoRef}
                            // src={directories[currentFolderIndex].files[selectedFileIndex].source}
                            controls
                            height={'100%'}
                            width={'100%'}
                            id="video"
                            onEnded={handleMediaEnd}
                            onPlay={() => setAutoplay(true)}
                        >
                            <source src={directories[currentFolderIndex].files[selectedFileIndex].source} />
                        </video>
                        <canvas id="myCanvas"></canvas>
                        <section>
                            <ItemButtons
                                item={directories[currentFolderIndex]?.files[selectedFileIndex]}
                                fullScreen={fullScreen}
                                setfullScreen={setfullScreen}
                                video={true}
                            />
                        </section>
                    </div>
                </div>
            ) : (
                <div className={styles.itemImage} key={files[selectedFileIndex]?.id}>
                    {directories[currentFolderIndex]?.files.length ? (
                        <div>
                            <img
                                src={directories[currentFolderIndex]?.files[selectedFileIndex]?.source}
                                className={styles.media}
                                alt="1"
                                ref={videoRef}
                            />
                            <canvas id="myCanvas"></canvas>
                            <ItemButtons
                                item={directories[currentFolderIndex].files[selectedFileIndex]}
                                fullScreen={fullScreen}
                                setfullScreen={setfullScreen}
                            />
                        </div>
                    ) : null}
                </div>
            )
        ) : allFiles[selectedFileIndex].type === 'video' ? (
            <div className={styles.itemVideo}>
                <div>
                    <video
                        autoplay={autoplay}
                        // muted
                        ref={videoRef}
                        // src={files[selectedIndex].source}
                        controls
                        height={'100%'}
                        width={'100%'}
                        id="video"
                        onEnded={handleMediaEnd}
                        onPlay={() => setAutoplay(true)}
                    >
                        <source src={allFiles[selectedFileIndex]?.source} />
                    </video>
                    <canvas id="myCanvas"></canvas>
                    <section>
                        <ItemButtons
                            item={allFiles[selectedFileIndex]}
                            fullScreen={fullScreen}
                            setfullScreen={setfullScreen}
                            video={true}
                        />
                    </section>
                </div>
            </div>
        ) : (
            <div className={styles.itemImage} key={files[selectedFileIndex]?.id}>
                <div>
                    <img src={allFiles[selectedFileIndex]?.source} className={styles.media} alt="1" ref={videoRef} />
                    <canvas id="myCanvas"></canvas>
                    <ItemButtons
                        item={allFiles[selectedFileIndex]}
                        fullScreen={fullScreen}
                        setfullScreen={setfullScreen}
                    />
                </div>
            </div>
        )

    const items2 =
        currentFolderId && currentFolderId !== -1
            ? directories[currentFolderIndex]?.files?.map((item, idx) => {
                  return (
                      <div
                          className={
                              selectedFileIndex === idx ? styles.downItems__selectedItem : styles.downItems__item
                          }
                          onClick={() => {
                              setAutoplay(false)
                              changeSelectedFileIndex(idx)
                          }}
                          key={item.id}
                          data-value={idx + 1}
                      >
                          <img src={item?.preview} className={styles.media} alt="1" />
                      </div>
                  )
              })
            : allFiles?.map((item, idx) => {
                  return (
                      <div
                          className={
                              selectedFileIndex === idx ? styles.downItems__selectedItem : styles.downItems__item
                          }
                          onClick={() => {
                              setAutoplay(false)
                              changeSelectedFileIndex(idx)
                          }}
                          key={item.id}
                          data-value={idx + 1}
                      >
                          <img src={item?.preview} className={styles.media} alt="1" />
                      </div>
                  )
              })

    return (
        <div className={styles.secondType} class="carusel">
            <div className={styles.secondType__leftSide}>
                <div>
                    <div>{name}</div>
                    <div>{description}</div>
                    <div className={styles.secondType__directories}>
                        {[{ id: 0, name: t('all') }, ...directories]?.map((e) => (
                            <div
                                className={currentFolderId === e.id ? styles.secondType__directories__selected : null}
                                onClick={() => {
                                    setAutoplay(false)
                                    setCurrentFilderId(e.id)
                                    changeSelectedFileIndex(0)
                                }}
                            >
                                {e.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.footer__links}>
                    {facebook_link ? (
                        <a href={facebook_link} target="_blank" rel="noopener noreferrer">
                            <FacebookLink />
                        </a>
                    ) : null}

                    {twitter_link ? (
                        <a href={twitter_link} target="_blank" rel="noopener noreferrer">
                            <TwitterLink />
                        </a>
                    ) : null}

                    {linkedin_link ? (
                        <a href={linkedin_link} target="_blank" rel="noopener noreferrer">
                            <LinkidInkLink />
                        </a>
                    ) : null}
                    {instagram_link ? (
                        <a href={instagram_link} target="_blank" rel="noopener noreferrer">
                            <InstLink />
                        </a>
                    ) : null}
                    {vimeo_link ? (
                        <a href={vimeo_link} target="_blank" rel="noopener noreferrer">
                            <VimeoLink />
                        </a>
                    ) : null}
                </div>
            </div>
            <div className={styles.secondType__container}>
                <div className={styles.header}>
                    <div>
                        <Filters setCurrentFilter={setCurrentFilter} currentFilter={currentFilter} />{' '}
                        <button
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            style={{ ...buttonStyle, ...(isHovered && buttonHoverStyle) }}
                            // eslint-disable-next-line no-undef
                            onClick={() => handleDownload()}
                        >
                            {t("Download")} {!currentFolderId ? 'all' : directories[currentFolderIndex]?.name}
                        </button>
                    </div>
                    <img src={thumb} alt="src" />
                </div>
                {currentFilter === 0 && (
                    <DeckListView
                        files={directories[currentFolderIndex]?.files ?? directories.flatMap((e) => e.files)}
                        directories={directories}
                    />
                )}
                {currentFilter === 1 && (
                    <MansoryView
                        files={directories[currentFolderIndex]?.files ?? directories.flatMap((e) => e.files)}
                    />
                )}
                {currentFilter === 2 && (
                    <>
                        <div className={styles.currentItem}>{currentItem ?? null}</div>
                        <div className={styles.fileName}>
                            {currentFolderId && currentFolderId !== -1
                                ? directories[currentFolderIndex]?.files[selectedFileIndex]?.title
                                : allFiles[selectedFileIndex].title}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className={styles.downItems} onWheel={handleOnWheel} id="scroll-container">
                                {items2}
                            </div>
                        </div>
                    </>
                )}

                <div className={styles.footer}>
                    <div />
                    <div className={styles.footer__rightSide}>
                        <div>Created with</div>
                        <DeckLogo />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SecondDeckType
const Filters = ({ setCurrentFilter, currentFilter }) => {
    // console.log('currentFilter', currentFilter)
    return (
        <div>
            {/* // 0 */}
            <svg
                width="30"
                height="30"
                onClick={() => setCurrentFilter(0)}
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity={currentFilter === 0 ? '1' : '0.4'}>
                    <line x1="11.5" y1="6.5" x2="24.5" y2="6.5" stroke="white" strokeWidth="5" strokeLinecap="round" />
                    <circle cx="5.5" cy="6.5" r="2.5" fill="white" />
                    <line
                        x1="11.5"
                        y1="14.5"
                        x2="24.5"
                        y2="14.5"
                        stroke="white"
                        strokeWidth="5"
                        strokeLinecap="round"
                    />
                    <circle cx="5.5" cy="14.5" r="2.5" fill="white" />
                    <line
                        x1="11.5"
                        y1="22.5"
                        x2="24.5"
                        y2="22.5"
                        stroke="white"
                        strokeWidth="5"
                        strokeLinecap="round"
                    />
                    <circle cx="5.5" cy="22.5" r="2.5" fill="white" />
                </g>
            </svg>
            {/* // 1 */}
            <svg
                width="30"
                height="30"
                onClick={() => setCurrentFilter(1)}
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity={currentFilter === 1 ? '1' : '0.4'}>
                    <rect x="4.5" y="4.5" width="9.267" height="9.267" rx="0.5" fill="white" stroke="white" />
                    <rect x="4.5" y="16.2329" width="9.267" height="9.267" rx="0.5" fill="white" stroke="white" />
                    <rect x="16.2324" y="4.5" width="9.267" height="9.267" rx="0.5" fill="white" stroke="white" />
                    <rect x="16.2324" y="16.2329" width="9.267" height="9.267" rx="0.5" fill="white" stroke="white" />
                </g>
            </svg>
            {/* // 2 */}
            <svg
                onClick={() => setCurrentFilter(2)}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                opacity={currentFilter === 2 ? '1' : '0.4'}
            >
                <rect x="4" y="4" width="22" height="14" rx="1" fill="white" />
                <rect x="4" y="20" width="6" height="6" rx="1" fill="white" />
                <rect x="12" y="20" width="6" height="6" rx="1" fill="white" />
                <rect x="20" y="20" width="6" height="6" rx="1" fill="white" />
            </svg>
            {/* <button>download houses</button> */}
        </div>
    )
}
const DownloadFileSvg = ({ onClick }) => {
    return (
        <svg
            opacity={0.5}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path d="M15 6L15 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path
                d="M11.1111 13.7142L15 18.4285L18.8889 13.7142M7 14.9999V21.9999H23V14.9999"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export const ItemButtons = ({ item, fullScreen, setfullScreen, video }) => {
    return (
        <div className={styles.btnGroup}>
            <a href={item?.source} download style={{ height: 30 }}>
                <DownloadFileSvg />
            </a>

            {video ? (
                ''
            ) : (
                <FullScreenSvg
                    onClick={() => {
                        setfullScreen(true)
                    }}
                />
            )}
            {fullScreen && <FullScreenPopup onClick item={item} setfullScreen={setfullScreen} />}
        </div>
    )
}
const FullScreenSvg = ({ onClick }) => {
    return (
        <svg
            opacity={0.5}
            onClick={onClick}
            style={{ marginLeft: 8 }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.66667 2.49988H4.16667C3.72464 2.49988 3.30072 2.67547 2.98816 2.98803C2.67559 3.30059 2.5 3.72452 2.5 4.16654V6.66655M17.5 6.66655V4.16654C17.5 3.72452 17.3244 3.30059 17.0118 2.98803C16.6993 2.67547 16.2754 2.49988 15.8333 2.49988H13.3333M13.3333 17.4999H15.8333C16.2754 17.4999 16.6993 17.3243 17.0118 17.0117C17.3244 16.6992 17.5 16.2752 17.5 15.8332V13.3332M2.5 13.3332V15.8332C2.5 16.2752 2.67559 16.6992 2.98816 17.0117C3.30072 17.3243 3.72464 17.4999 4.16667 17.4999H6.66667"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
