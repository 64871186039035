import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take } from 'redux-saga/effects'
import { resource } from '../../api/http'
import { setLoading, setSuccess } from './popups'

const namespace = 'decks'

const RESET = `${namespace}/RESET`
const CREATE = `${namespace}/CREATE`
const DELETE = `${namespace}/DELETE`
const SET_ALL = `${namespace}/SET_ALL`
const GET_ALL = `${namespace}/GET_ALL`
const ADD_DECK = `${namespace}/ADD_DECK`
const ADD_FILES = `${namespace}/ADD_FILES`
const REMOVE_FILE = `${namespace}/REMOVE_FILE`
const UPDATE_FILES = `${namespace}/UPDATE_FILES`
const UPDATE_DECK = `${namespace}/UPDATE_DECK`

const GET_BY_ID = `${namespace}/GET_BY_ID`

const SET_CURRENT_DECK = `${namespace}/SET_CURRENT_DECK`
const ADD_RPOJECT_FILE = `${namespace}/ADD_RPOJECT_FILE`

const SET_SHARED_DECK = `${namespace}/SET_SHARED_DECK`

const ADD_OPENED_PAGES = `${namespace}/ADD_OPENED_PAGES`
const REMOVE_OPENED_PAGES = `${namespace}/REMOVE_OPENED_PAGES`

export const resetDeck = createAction(RESET)
export const createDeck = createAction(CREATE)
export const deleteDeck = createAction(DELETE)
export const getAllDecks = createAction(GET_ALL)
export const setAllDecks = createAction(SET_ALL)
export const addDeck = createAction(ADD_DECK)
export const removeFile = createAction(REMOVE_FILE)
export const getDeckById = createAction(GET_BY_ID)
export const setCurrentDeck = createAction(SET_CURRENT_DECK)
export const addFiles = createAction(ADD_FILES)
export const updateFiles = createAction(UPDATE_FILES)
export const updateDeck = createAction(UPDATE_DECK)
export const addProjectFile = createAction(ADD_RPOJECT_FILE)
export const setSharedDeck = createAction(SET_SHARED_DECK)
export const addOpenedPages = createAction(ADD_OPENED_PAGES)
export const removeOpenedPages = createAction(REMOVE_OPENED_PAGES)

const initialState = {
    load: false,
    decks: [],
    currentDeck: [],
    updatedFiles: [],
    sharedDeck: '',
    openedPages: [],
}

export default handleActions(
    {
        [RESET]: () => initialState,
        [SET_ALL]: (state, { payload }) => ({ ...state, decks: payload }),
        [ADD_DECK]: (state, { payload }) => ({ ...state, decks: [payload, ...state.decks] }),

        [SET_CURRENT_DECK]: (state, { payload }) => ({
            ...state,
            currentDeck: payload,
        }),

        [ADD_OPENED_PAGES]: (state, { payload }) => ({
            ...state,
            openedPages: [...state.openedPages, payload],
        }),
        [REMOVE_OPENED_PAGES]: (state, { payload }) => ({
            ...state,
            openedPages: state.openedPages.filter((e) => e !== payload),
        }),

        [SET_SHARED_DECK]: (state, { payload }) => ({
            ...state,
            sharedDeck: payload,
        }),
    },
    initialState
)

export const decksSelector = (state) => state[namespace]

export function* createRequest() {
    while (true) {
        const {
            payload: { values, navigate },
        } = yield take(CREATE)
        try {
            const {
                data: { data, success },
            } = yield call(resource.post, 'api/decks', values)

            console.log('data', data)

            if (success) {
                yield put(addDeck(data))
                navigate('/decks')
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* getAllRequest() {
    while (true) {
        yield take(GET_ALL)
        try {
            const {
                data: { data },
            } = yield call(resource.get, 'api/decks')
            if (data) {
                yield put(setAllDecks(data))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* getByIdRequest() {
    while (true) {
        const { payload } = yield take(GET_BY_ID)
        console.log('payload', payload)
        try {
            const {
                data: { data },
            } = yield call(resource.get, `/api/decks/${payload}`)

            if (data) yield put(setCurrentDeck(data))
        } catch (err) {
            console.log(err)
        }
    }
}
export function* addFilesRequest() {
    while (true) {
        const { payload } = yield take(ADD_FILES)
        const { currentDeck } = yield select(decksSelector)
        console.log('payload', payload)
        try {
            const { files } = currentDeck
            currentDeck.files = [...files, payload]

            yield put(setCurrentDeck(currentDeck))
        } catch (err) {
            console.log(err)
        }
    }
}
export function* updateFilesRequest() {
    while (true) {
        const {
            payload: { id, values },
        } = yield take(UPDATE_FILES)

        try {
            const {
                data: { data, success },
            } = yield call(resource.put, `api/deck-files/${id}`, values)

            if (success) {
                const { currentDeck } = yield select(decksSelector)
                const objIndex = currentDeck.files.findIndex((obj) => obj.id == 1)

                // yield put(setCurrentDeck(data))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* updateDeckRequest() {
    while (true) {
        const {
            payload: { id, fd },
        } = yield take(UPDATE_DECK)
        try {
            yield put(setLoading(true))
            const {
                data: { data, success, message },
            } = yield call(resource.post, `api/decks/${id}`, fd)

            if (success) {
                yield put(setSuccess({ visible: true, text: message ?? 'Success' }))
                yield put(setCurrentDeck(data))
            }
        } catch (err) {
            console.log(err)
        } finally {
            yield put(setLoading(false))
        }
    }
}
export function* addFileFromProjectsRequest() {
    while (true) {
        const { payload } = yield take(ADD_RPOJECT_FILE)

        try {
            yield put(setLoading(true))
            const {
                data: { data, success },
            } = yield call(resource.post, `/api/project/copy-files-from-project-to-deck`, payload)

            if (success) {
                yield put(setCurrentDeck(data))
            }
        } catch (err) {
            console.log(err)
        } finally {
            yield put(setLoading(false))
        }
    }
}
export function* deleteRequest() {
    while (true) {
        const { payload } = yield take(DELETE)

        try {
            const {
                data: { data, success },
            } = yield call(resource.delete, `/api/decks/${payload}`)

            if (success) {
                console.log(data)
                const { decks } = yield select(decksSelector)
                let filtredDecks = decks.filter((e) => e.id !== +payload)
                yield put(setAllDecks(filtredDecks))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* removeFilesRequest() {
    while (true) {
        const { payload } = yield take(REMOVE_FILE)
        const { currentDeck } = yield select(decksSelector)

        try {
            const {
                data: { success },
            } = yield call(resource.delete, `api/deck-files/${payload}`)

            if (success) {
                const removeById = (arr, targetId) =>
                    arr.reduce(
                        (acc, obj) =>
                            obj.id === targetId
                                ? acc
                                : [
                                      ...acc,
                                      {
                                          ...obj,
                                          ...(obj.files && { files: removeById(obj.files, targetId) }),
                                      },
                                  ],
                        []
                    )

                currentDeck.directories = removeById(currentDeck.directories, payload)
                currentDeck.files = removeById(currentDeck.files, payload)
                yield put(setCurrentDeck(currentDeck))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* sagas() {
    yield all([
        createRequest(),
        getAllRequest(),
        getByIdRequest(),
        addFilesRequest(),
        updateFilesRequest(),
        updateDeckRequest(),
        addFileFromProjectsRequest(),
        deleteRequest(),
        removeFilesRequest(),
    ])
}
