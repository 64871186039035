import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'
import { resource } from '../../api/http'

const namespace = 'drag'

const SET_HIGHLIGHT_DECK_CONTENT = `${namespace}/SET_HIGHLIGHT_DECK_CONTENT`

export const hightlightDeckContent = createAction(SET_HIGHLIGHT_DECK_CONTENT)



const initialState = {
    highlightDeckContent: false,
}
export const dragSelector = (state) => state[namespace]

export default handleActions(
    {
        [SET_HIGHLIGHT_DECK_CONTENT]: (state, { payload }) => ({ ...state, highlightDeckContent: payload }),
    },
    initialState
)



function* init() {
    yield put(hightlightDeckContent(false))
}

export function* sagas() {
    yield all([init()])
}
