import React, { useEffect, useRef, useState } from 'react'
import styles from '../right-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { projectsSelector, setSelectedFile, updateFileById, updateProjectById } from '../../../store/reducers/projects'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { resource } from '../../../api/http'
import AddTag from '../../add-tags/AddTags'
import SettingsAvatar from '../../avatar/SettingsAvatar'
import SharedLinksPopup from '../../popups/shared-links'
import { useTranslation } from 'react-i18next'

const SettingsSection = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { selectedFile, currentFiles } = useSelector(projectsSelector)
    const fileInputRef = useRef(null)
    const isFolder = selectedFile?.type === 'directory'

    useEffect(() => {
        if (!selectedFile?.id) dispatch(setSelectedFile(currentFiles?.find((e) => e.id === +id)))
    }, [])

    const { name, description, tags, preview, thumb } = selectedFile ?? []
    const tags1 = tags?.map((e) => e.name)
    const previousThumb = preview ?? thumb
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            thumb: '',
            tag: '',
            tags: [],
            emails: [],
        },
        validationSchema: Yup.object().shape({
            // name: Yup.string().min(1).required('Email is a required field'),
            // description: Yup.string().required('Password is a required field'),
            // thumb: Yup.mixed().required(),
        }),
        onSubmit: (values) => {
            if (isFolder) {
                let fd = new FormData()
                fd.append('name', values.name)
                fd.append('description', values.description)
                if (values['thumb']) fd.append('thumb', values['thumb'])
                fd.append('tags', values.tags)
                fd.append('_method', 'PUT')
                dispatch(updateProjectById({ fd, id: selectedFile.id }))
            } else
                dispatch(
                    updateFileById({
                        values: { name: values['name'], tags: values['tags'], description: values['description'] },
                        id: selectedFile.id,
                    })
                )
        },
    })
    useEffect(() => {
        // dispatch(setSelectedFile(currentFiles.find((e) => e.id === selectedFile?.id)))
        formik.setValues({ name: name ?? '', description: description ?? '', tags: tags1 ?? [], tag: '' })
    }, [selectedFile])

    const handleClick = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = (event) => {
        formik.setFieldValue('thumb', event.target.files[0])
    }
    // console.log(formik)
    return (
        <div className={styles.info__wrapper}>
            <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                id="upload"
                type="file"
                onChange={handleFileChange}
                multiple
            />
            <div className={styles.info__header}>
                <div>
                    <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6.5L3.5 3.5L0.5 0.5" stroke="black" />
                    </svg>
                    <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6.5L3.5 3.5L0.5 0.5" stroke="black" />
                    </svg>
                </div>

                <span>{t('settings')}</span>
            </div>
            {selectedFile?.id && (
                <>
                    <div className={styles.wrap}>
                        <form onSubmit={formik.handleSubmit} className={styles.form}>
                            <div className={styles.info__column}>
                                <span className={styles.info__label}>{t('name')}</span>
                                <span className={styles.info__item}>
                                    <input
                                        placeholder={selectedFile?.name}
                                        type="text"
                                        name="name"
                                        className={styles.nameInput}
                                        onChange={(e) => formik.setFieldValue('name', e.target.value)}
                                        value={formik.values['name']}
                                    />
                                </span>
                            </div>

                            {!isFolder && (
                                <div className={styles.info__column}>
                                    <span className={styles.info__label}>{t('preview')}</span>
                                    <img
                                        className={styles.info__preview}
                                        src={
                                            formik.values['thumb']
                                                ? URL.createObjectURL(formik.values['thumb'])
                                                : previousThumb
                                        }
                                        alt="preview"
                                    />

                                    {/* {isFolder && (
                                    <div className={styles.reloadCover} onClick={handleClick}>
                                        {t('reload_cover')}
                                    </div>
                                )} */}
                                </div>
                            )}

                            {isFolder && (
                                <div className={styles.info__column}>
                                    <span className={styles.info__label}>{t('description')}</span>
                                    <span className={styles.info__item}>
                                        <input
                                            placeholder={selectedFile?.description}
                                            type="text"
                                            name="description"
                                            className={styles.nameInput}
                                            onChange={formik.handleChange}
                                            value={formik.values['description']}
                                        />
                                    </span>
                                </div>
                            )}

                            <div className={styles.info__column}>
                                <span className={styles.info__label}>{t('tags')}</span>
                                <AddTag
                                    setFieldValue={formik.setFieldValue}
                                    values={formik.values}
                                    handleChange={formik.handleChange}
                                />
                            </div>
                            <div className={styles.info__column}>
                                <span className={styles.info__label}>{t('shared_with')}</span>
                                {/* <span className={styles.info__item}>Here will members</span> */}
                                <div className={styles.members__wrap}>
                                    <div className={styles.members}>
                                        {selectedFile?.sharedWithUsers?.map((e, idx) => {
                                            const margin = idx * 20 + 'px'
                                            return <SettingsAvatar key={e.id} name={e.name} margin={margin} />
                                        })}
                                    </div>
                                    <AddUserSvg />
                                </div>
                                {/* <SearchInput setFieldValue={formik.setFieldValue} /> */}
                            </div>

                            <div className={styles.info__column}>
                                <span className={styles.info__label}>Link</span>
                                <div className={styles.link}>
                                    <div>skicka.co/449030m...</div>
                                    <SharedLinksPopup item={selectedFile} />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={styles.settingBtn}>
                        <button onClick={formik.handleSubmit}>{t('save_changes')}</button>
                    </div>
                </>
            )}
        </div>
    )
}
export default SettingsSection

const AddUserSvg = ({ onClick }) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="5" fill="#F8F8F8" />
            <g opacity="0.3">
                <path
                    d="M17 15C19.21 15 21 13.21 21 11C21 8.79 19.21 7 17 7C14.79 7 13 8.79 13 11C13 13.21 14.79 15 17 15ZM17 17C14.33 17 9 18.34 9 21C9 22.1046 9.89543 23 11 23H23C24.1046 23 25 22.1046 25 21C25 18.34 19.67 17 17 17Z"
                    fill="black"
                />
                <line x1="5" y1="12.5" x2="10" y2="12.5" stroke="black" />
                <line x1="7.5" y1="15" x2="7.5" y2="10" stroke="black" />
            </g>
        </svg>
    )
}
const SearchInput = ({ setFieldValue }) => {
    const [searchInputValue, setSearchInputValue] = useState('')
    const [selectedValues, setSelectedValues] = useState([])
    const [options, setOptions] = useState([])

    React.useEffect(() => {
        setFieldValue('emails', selectedValues)
    }, [selectedValues])

    const handleSearchInputChange = (newValue) => {
        setSearchInputValue(newValue)
        if (newValue) {
            resource
                .post(`${process.env.REACT_APP_BASE_URL}/api/search-user`, { search: newValue })
                .then(({ data: { data } }) => {
                    const options = data.map((result) => ({
                        value: result.email,
                        label: result.name + ' ' + result.email,
                    }))
                    setOptions(options)
                })
        } else {
            setOptions([])
        }
    }

    const handleSelectChange = (selectedOptions) => {
        setSelectedValues(selectedOptions)
    }

    return (
        <div>
            <div>
                <Select
                    isMulti
                    options={options}
                    value={selectedValues}
                    onChange={handleSelectChange}
                    onInputChange={handleSearchInputChange}
                    placeholder="Search by email or name"
                    noOptionsMessage={() => 'No results found'}
                    // classNamePrefix="react-select"
                    styles={{
                        container: (baseStyles, state) => ({
                            ...baseStyles,
                            width: 342,
                            // display: 'none',
                            minHeight: 28,
                            __menu: { display: 'none' },
                        }),

                        menuList: (baseStyles, state) => ({
                            ...baseStyles,
                            height: 100,
                        }),

                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            fontWeight: 400,
                            color: '#fff',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                            borderRadius: 5,
                        }),
                    }}
                />
            </div>
        </div>
    )
}
