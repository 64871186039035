import styles from './styles.module.sass'
import { SharedLogo } from '../deck-carusel-shared/assets/SvgImages'
import { useTranslation } from 'react-i18next'

const SharedHeader = ({ navigate }) => {
    const { t } = useTranslation()
    return (
        <div className={styles.wrapper}>
            <div className={styles.logo}>
                <SharedLogo />
            </div>
            <div className={styles.leftSide}>
                <div className={styles.buttons}>
                    <button onClick={() => navigate('sign-in')}> {t('log_in')}</button>
                </div>
            </div>
        </div>
    )
}
export default SharedHeader
