import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useBatchProgressListener } from '@rpldy/uploady'

function CircularProgressWithLabel(props) {
    const x = props.value ? Math.round(props.value) : 0
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                height: 50,
                span: { margin: '0 0 0 5px' },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="#fff"
                    sx={{ fontFamily: 'Zeitung Micro Pro', fontSize: 12, fontWeight: 400 }}
                >
                    {x + '%'}
                </Typography>
            </Box>
            <CircularProgress {...props} />
        </Box>
    )
}

export default function ProcessLoder() {
    const batch = useBatchProgressListener()
    const [progress, setProgress] = React.useState(batch?.completed)

    React.useEffect(() => {
        setProgress(batch?.completed)
    }, [batch])

    if (!progress || progress === 100) {
        return (
            <CircularProgressWithLabel value={progress ?? 100} variant={'solid'} size="18px" sx={{ color: '#fff' }} />
        )
    } else return <CircularProgressWithLabel value={progress ?? 100} size="18px" sx={{ color: '#fff' }} />
}
