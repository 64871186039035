import * as React from 'react'
import styles from './success-popup.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { popupSelector, setSuccess } from '../../../store/reducers/popups'
import { useTranslation } from 'react-i18next'

const SuccessPopup = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {
        success: { text },
    } = useSelector(popupSelector)

    const handleClose = () => {
        dispatch(setSuccess(false))
    }

    return (
        <section className={styles.overlay} onClick={handleClose}>
            <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
                <div>
                    {/* <IconButton
                    
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton> */}
                    <span>{t(text)}</span>
                    <button onClick={handleClose}>Ok</button>
                </div>
            </div>
        </section>
    )
}
export default SuccessPopup
