import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styles from './add-deck-page.module.sass'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { decksSelector, setCurrentDeck } from '../../../store/reducers/decks'
import { resource } from '../../../api/http'
import { useTranslation } from 'react-i18next'

const AddDeckPagePopup = ({ navigate, plus, button }) => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()

    const { currentDeck } = useSelector(decksSelector)

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object().shape({ name: Yup.string().min(1).required('Name is a required field') }),
        onSubmit: (values) => {
            const payload = {
                deck_id: currentDeck.id,
                name: values['name'],
                dir_id: null,
                // order:0
            }
            resource
                .post(`${process.env.REACT_APP_BASE_URL}/api/deck-directories`, payload)
                .then(({ data: { data, message, success } }) => {
                    if (success) {
                        currentDeck.directories = [...currentDeck.directories, data]
                        dispatch(setCurrentDeck(currentDeck))
                    }
                })
        },
        validateOnMount: true,
    })

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div className={styles.wrapper}>
            {button && (
                <button className={styles.createPage} onClick={handleClickOpen}>
                    {t('create_a_page')}
                </button>
            )}
            {plus && (
                <div onClick={handleClickOpen} className={styles.add}>
                    +
                </div>
            )}

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={styles.wrapper}
            >
                <DialogTitle id="responsive-dialog-title">{'New page'}</DialogTitle>

                <DialogContent sx={{ padding: '0 24px 24px' }}>
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <input type="text" name="name" onChange={formik.handleChange} />
                        <DialogActions>
                            <button
                                className={styles.create}
                                onClick={() => {
                                    handleClose()
                                }}
                                autoFocus
                                type="submit"
                                disabled={formik.errors['name']}
                            >
                                {t('create')}
                            </button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default AddDeckPagePopup
