import { Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './opened-project.module.sass'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedFile } from '../../store/reducers/projects'
import RightBar from '../../components/side-bar-right'
import SharedHeader from '../../components/shared-components/SharedHeader'
import { resource } from '../../api/http'
import FolderFileItem from '../../components/folder-file-item'
import { authSelector } from '../../store/reducers/auth'

// OPEN project FROM SHARED PROJECT

const OpenSharedProject = ({ navigate }) => {
    let { uuid, id } = useParams() // SHARED PROJECT UUID AND FILE ID

    const [currentData, setCurrentData] = useState(false) //current page
    const [currentTree, setCurrentTree] = useState([]) //current page

    const auth = useSelector(authSelector)
    const dispatch = useDispatch()
    
    useEffect(() => {
        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/get-shared-project/${uuid}`, { project_id: id })
            .then(({ data: { data, tree } }) => {
                setCurrentData(data)
                setCurrentTree(tree?.reverse())
            })
    }, [id])

    // if (!currentData) return <h1>Not Found</h1>
    return (
        <>
            {!auth && (
                <div className={styles.wrapper}>
                    <SharedHeader navigate={navigate} />
                </div>
            )}
            <div className={styles.header}>
                <div className={styles.flexCenter}>
                    <svg
                        onClick={() => navigate(-1)}
                        width="12"
                        height="22"
                        style={{ marginRight: '10px' }}
                        viewBox="0 0 12 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </svg>

                    <Breadcrumbs
                        className={styles.breadcrumbs}
                        aria-label="breadcrumb"
                        maxItems={4}
                        separator={<Separator />}
                    >
                        {currentTree?.map((e, idx) => {
                            return (
                                <div to={`/project/${e.id}`} key={e.id}>
                                    {e.name}
                                </div>
                            )
                        })}
                    </Breadcrumbs>
                </div>
                <div className={styles.buttonGroup}></div>
            </div>
            <div className={styles.flexRow}>
                <div className={styles.btn}>
                    {currentData?.map((item, idx) => {
                        return (
                            <FolderFileItem
                                item={item}
                                key={item.id}
                                navigate={navigate}
                                shareFlow={true}
                                uuid={uuid}
                                onClick={() => {
                                    dispatch(setSelectedFile(currentData[idx]))
                                }}
                            />
                        )
                    })}
                </div>
                {auth && <RightBar shareFlow />}
            </div>
        </>
    )
}

export default OpenSharedProject

const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}
