import './App.css'
import { useDispatch, useSelector } from 'react-redux'
// import { setUser } from './store/reducers/user'
import { Routes, Route, useNavigate, useLocation, Outlet } from 'react-router-dom'
import SignIn from './pages/signIn'
import SignUp from './pages/signUp'
import Header from './components/header'
import LeftBar from './components/side-bar-left/LeftBar'
import AllProjects from './pages/projects/AllProject'
import OpenedProject from './pages/open-project'
import Decks from './pages/decks'
import { authSelector } from './store/reducers/auth'
import { useEffect } from 'react'
import RestorePassword from './pages/restore-password'
import NewPassword from './pages/restore-password/NewPassword'
import Uploader from './components/upload'
import { showUploadings } from './store/reducers/uploads'
import OpenFile from './pages/open-file/OpenFile'
import ChunkedUploady from '@rpldy/chunked-uploady'
import CreateDeck from './pages/create-deck'
// import OpenedDeck from './pages/open-deck'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import SharedFile from './pages/shared-file'
import DeckBuilder from './pages/deck-builder'
import Settings from './pages/settings'
import SharedProject from './pages/shared-project'
import OpenSharedFile from './pages/open-shared-file'
import OpenSharedProject from './pages/open-shared-project'
// import RightBar from './components/side-bar-right'
import DeckBar from './components/deck-bar'
import SharedDeck from './pages/shared-deck'
import Castings from './pages/castings'
import CastingsQuiz from './pages/casting-quiz'
import OpenCasting from './pages/open-casting'
import OpenCastingAnswer from './pages/open-casting-answers/OpenCastingAnswers'
import Favorites from './pages/favorites'
import SharedWithMe from './pages/shared-with-me'
import Search from './pages/search'
import CheckoutForm from './pages/checout-form'
// import OpenCastingAnswer from './pages/open-casting-answers'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import './i18n'
import Teams from './pages/teams/Teams'
import Locations from './pages/locations'
import OpenLocation from './pages/open-location'
import LocationQuiz from './pages/location-quiz'
import OpenLocationAnswer from './pages/open-locations-answers '
import { popupSelector } from './store/reducers/popups'
import LoadingPopup from './components/popups/loading'
import SuccessPopup from './components/popups/success/SuccessPopup'
import OpenedProjectDeck from './pages/open-project-in-deck'

function App() {
    let navigate = useNavigate()
    const { loading, success } = useSelector(popupSelector)
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const auth = useSelector(authSelector)
    const unloginRoutes =
        pathname === '/sign-in' ||
        pathname === '/sign-up' ||
        pathname === '/restore-password' ||
        pathname === '/shared' ||
        pathname.includes('/new-password') ||
        pathname.includes('/shared') ||
        pathname.includes('/casting-quiz') ||
        pathname.includes('/location-quiz')

    useEffect(() => {
        if (!auth && !unloginRoutes) navigate('/sign-in')
    }, [])

    useEffect(() => {
        if (!auth && !unloginRoutes) navigate('/sign-in')
    }, [auth])

    const uploadingPopup = useSelector(showUploadings)

    const stripePromise = loadStripe(
        'pk_test_51NDwPqBhhkvyCktEbe405RIAyOVJQrYqbgDYwTjWZnNN6jQZ9f2Gwl5gUKvt8y20ZQeWpRU0F7T6Y1N1w5mqleFY00Mm20IDeJ'
    )
    console.log('loading', loading)
    return (
        <div className="App">
            {loading ? <LoadingPopup /> : null}
            {success?.visible ? <SuccessPopup /> : null} 
            <DndProvider backend={HTML5Backend}>
                {auth ? (
                    <div className="wrapper">
                        {uploadingPopup && <Uploader dispatch={dispatch} />}
                        <ChunkedUploady
                            chunkSize={1024 * 1024}
                            multiple
                            method="POST"
                            // parallel={2}
                        >
                            <Uploader fakeField={true} />
                            <LeftBar navigate={navigate} />
                            <div className="flex-column">
                                <Header navigate={navigate} />
                                <div className="main-container">
                                    <Elements stripe={stripePromise}>
                                        <Routes>
                                            <Route path="/" element={<AllProjects navigate={navigate} />} />
                                            <Route path="/search" element={<Search navigate={navigate} />} />
                                            <Route path="/favorites" element={<Favorites navigate={navigate} />} />

                                            <Route path="/teams" element={<Teams navigate={navigate} />} />
                                            <Route
                                                path="/shared-with-me"
                                                element={<SharedWithMe navigate={navigate} />}
                                            />

                                            <Route
                                                path="/project/:id"
                                                element={<OpenedProject navigate={navigate} />}
                                            />
                                            <Route
                                                path="/file/:project/:id"
                                                element={<OpenFile navigate={navigate} />}
                                            />
                                            <Route path="/decks" element={<Decks navigate={navigate} />} />
                                            {/* BILLING */}
                                            <Route
                                                path="/checkout-form"
                                                element={<CheckoutForm navigate={navigate} />}
                                            />

                                            {/* CASTINGS */}
                                            <Route path="/castings" element={<Castings navigate={navigate} />} />
                                            <Route path="/casting/:id" element={<OpenCasting navigate={navigate} />} />
                                            <Route
                                                path="/casting/:id/:answerId"
                                                element={<OpenCastingAnswer navigate={navigate} />}
                                            />
                                            <Route
                                                path="/casting-quiz/:id"
                                                element={<CastingsQuiz navigate={navigate} />}
                                            />

                                            {/* LOCATIONS */}
                                            <Route path="/locations" element={<Locations navigate={navigate} />} />
                                            <Route
                                                path="/location/:id"
                                                element={<OpenLocation navigate={navigate} />}
                                            />
                                            <Route
                                                path="/location-quiz/:id"
                                                element={<LocationQuiz navigate={navigate} />}
                                            />
                                            <Route
                                                path="/location/:id/:answerId"
                                                element={<OpenLocationAnswer navigate={navigate} />}
                                            />

                                            {/* DECKS */}
                                            <Route path="/create-deck" element={<CreateDeck navigate={navigate} />} />
                                            <Route
                                                path="/deck-builder/"
                                                element={
                                                    <div className="deck-wrapper">
                                                        <Outlet />
                                                        <DeckBar />
                                                    </div>
                                                }
                                            >
                                                <Route path=":deckId" element={<DeckBuilder navigate={navigate} />} />
                                                <Route
                                                    path=":deckId/project/:id"
                                                    element={<OpenedProjectDeck navigate={navigate} deckFlow={true} />}
                                                />
                                                <Route
                                                    path=":deckId/file/:id/:project"
                                                    element={<OpenFile navigate={navigate} deckFlow={true} />}
                                                />
                                            </Route>

                                            {/* SHARE */}
                                            <Route
                                                path="/shared-file/:uuid"
                                                element={<SharedFile navigate={navigate} />}
                                            />
                                            <Route
                                                path="/shared-project/:uuid"
                                                element={<SharedProject navigate={navigate} />}
                                            />
                                            <Route
                                                path="/shared-project/:uuid/file/:id/:projectId"
                                                element={<OpenSharedFile navigate={navigate} />}
                                            />
                                            <Route
                                                path="/shared-project/:uuid/project/:id"
                                                element={<OpenSharedProject navigate={navigate} />}
                                            />
                                            {/* <Route path="/shared-deck/:uuid" element={<SharedDeck navigate={navigate} />} /> */}
                                            <Route path="/settings/:key" element={<Settings navigate={navigate} />} />
                                            <Route
                                                path="/shared-deck/:uuid"
                                                element={<SharedDeck navigate={navigate} />}
                                            />
                                        </Routes>
                                    </Elements>
                                </div>
                            </div>
                        </ChunkedUploady>
                    </div>
                ) : (
                    <ChunkedUploady
                        chunkSize={1024 * 1024}
                        multiple
                        method="POST"
                        // parallel={2}
                    >
                        <Routes>
                            <Route path="/shared-deck/:uuid" element={<SharedDeck navigate={navigate} />} />
                            <Route path="/shared-file/:uuid" element={<SharedFile navigate={navigate} />} />
                            <Route path="/shared-project/:uuid" element={<SharedProject navigate={navigate} />} />
                            <Route
                                path="/shared-project/:uuid/file/:id/:projectId"
                                element={<OpenSharedFile navigate={navigate} />}
                            />
                            <Route
                                path="/shared-project/:uuid/project/:id"
                                element={<OpenSharedProject navigate={navigate} />}
                            />
                            <Route path="/sign-in" element={<SignIn navigate={navigate} />} />
                            <Route path="/sign-up" element={<SignUp navigate={navigate} />} />
                            <Route path="/new-password/:email/:token" element={<NewPassword navigate={navigate} />} />
                            <Route path="/restore-password" element={<RestorePassword navigate={navigate} />} />

                            <Route path="/casting-quiz/:id" element={<CastingsQuiz navigate={navigate} />} />
                            <Route path="/location-quiz/:id" element={<LocationQuiz navigate={navigate} />} />
                        </Routes>
                    </ChunkedUploady>
                )}
            </DndProvider>
            <div></div>
        </div>
    )
}
// const styles = { display: 'flex', 'flex-direction': 'row-reverse' };
export default App
