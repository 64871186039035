import React, { useEffect } from 'react'
import styles from '../left-bar.module.sass'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { decksSelector, deleteDeck, getAllDecks } from '../../../store/reducers/decks'
import { BasketSvg } from '../../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'

const DecksAsside = ({ navigate }) => {
    const { t } = useTranslation()
    const { decks, currentDeck } = useSelector(decksSelector)
    const dispatch = useDispatch()
    let pass = useParams()
    console.log(currentDeck)

    // const [showProjects, setshowProjects] = React.useState(true)

    useEffect(() => {
        if (!decks.length) {
            dispatch(getAllDecks())
        }
    }, [dispatch])

    return (
        <>
            <div className={styles.asside}>
                <div>
                    <span className={styles.asside__label}>{t('decks')}</span>
                    {/* <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M9.5 0.5L6.5 3.5L9.5 6.5" stroke="white" />
                            <path d="M4 0.5L1 3.5L4 6.5" stroke="white" />
                        </g>
                    </svg> */}
                </div>
                <IconButton color="#fff" onClick={() => navigate('/create-deck')}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="4.9541" x2="10" y2="4.9541" stroke="black" />
                        <line x1="4.9541" y1="10" x2="4.9541" y2="2.18557e-08" stroke="black" />
                    </svg>
                </IconButton>
            </div>
            {/* <div className={styles.asside__search}></div> */}
            <div className={styles.decks__itemsWrap}>
                <div className={styles.decks__label}>
                    {/* <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                    </svg> */}
                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                    </svg>

                    <Link to={'/decks'}>{t('all_decks')}</Link>
                </div>

                {decks?.map((e, index) => {
                    const selected = currentDeck.id === e.id
                    return (
                        <Link
                            key={e.id}
                            to={`deck-builder/${e.id}`}
                            className={selected ? styles.decks__card__selected : styles.decks__card}
                        >
                            <img src={e.thumb} alt="preview" />
                            <div className={styles.decks__card__footer}>
                                <span>{e.name}</span>
                                <Dots id={e.id} />
                            </div>
                        </Link>
                    )
                })}
            </div>
        </>
    )
}
const Dots = ({ id }) => {
    const { t } = useTranslation()
    const [anchorElUser, setAnchorElUser] = React.useState(false)
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = (event) => {
        setAnchorElUser(null)
    }
    const dispatch = useDispatch()

    return (
        <>
            <Menu
                sx={{
                    mt: '45px',
                    top: -40,
                    marginLeft: -9.7,
                    ul: {
                        width: 194,
                        background: '#1F1F1F',
                        color: '#fff',
                        borderRadius: '5px',
                        padding: 0,
                    },
                    borderRadius: '5px',
                }}
                id="basic-menu"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {[t('Delete')].map((setting) => (
                    <MenuItem
                        key={setting}
                        onClick={() => {
                            dispatch(deleteDeck(id))
                            handleCloseUserMenu()
                        }}
                        sx={{
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            borderRadius: 5,
                            div: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: 40,
                                opacity: 0.5,
                                '&:hover': { opacity: 1, background: '#292929' },
                            },
                        }}
                    >
                        <div>
                            {setting} <BasketSvg />
                        </div>
                    </MenuItem>
                ))}
            </Menu>
            <svg
                width="18"
                onClick={(e) => {
                    handleOpenUserMenu(e)
                }}
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="3" cy="9" r="2" fill="#3376A3" />
                <circle cx="9" cy="9" r="2" fill="#3376A3" />
                <circle cx="15" cy="9" r="2" fill="#3376A3" />
            </svg>
        </>
    )
}
export default DecksAsside
