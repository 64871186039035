import React from 'react'
import { useDispatch } from 'react-redux'
import { removeFile } from '../../../store/reducers/decks'
import { Menu, MenuItem } from '@mui/material'
import { BasketSvg } from '../../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'
const DeckFileMenu = ({ item }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    return (
        <>
            <Dots onClick={handleOpenUserMenu} />
            <Menu
                sx={{
                    mt: '45px',
                    top: -25,
                    left: 10,
                    ul: {
                        width: 210,
                        background: '#1F1F1F',
                        color: '#fff',
                        borderRadius: '5px',
                        padding: 0,
                    },
                    borderRadius: '5px',
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {[t('Delete')].map((setting) => (
                    <MenuItem
                        key={setting}
                        onClick={() => {
                            dispatch(removeFile(item.id))
                            handleCloseUserMenu()
                        }}
                        sx={{
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            borderRadius: 5,
                            div: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: 40,
                                opacity: 0.5,
                                '&:hover': { opacity: 1, background: '#292929' },
                            },
                        }}
                    >
                        <div>
                            {setting} <BasketSvg />
                        </div>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
export default DeckFileMenu

const Dots = ({ onClick }) => {
    return (
        <svg
            width="24"
            onClick={onClick}
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="8" r="2" fill="#3376A3" />
            <circle cx="12" cy="8" r="2" fill="#3376A3" />
            <circle cx="18" cy="8" r="2" fill="#3376A3" />
        </svg>
    )
}
