import * as React from 'react'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

export default function LinearProgressBar(props) {
    // const [progress, setProgress] = React.useState(50);

    const { value, className } = props
    // React.useEffect(() => {
    //   const timer = setInterval(() => {
    //     setProgress((oldProgress) => {
    //       if (oldProgress === 100) {
    //         return 0;
    //       }
    //       const diff = Math.random() * 10;
    //       return Math.min(oldProgress + diff, 100);
    //     });
    //   }, 500);

    //   return () => {
    //     clearInterval(timer);
    //   };
    // }, []);

    return (
        <Box  className={className}>
            <LinearProgress variant="determinate" value={value} />
        </Box>
    )
}
