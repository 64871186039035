import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take } from 'redux-saga/effects'
import { resource } from '../../api/http'
import { projectsSelector } from './projects'

const namespace = 'comments'

const initialState = {
    comments: [],
}

const GET_COMMENTS = `${namespace}/GET_COMMENTS`
const SET_COMMENTS = `${namespace}/SET_COMMENTS`
const SEND_COMMENT = `${namespace}/SEND_COMMENT`
const RESET_COMMENTS = `${namespace}/RESET_COMMENTS`
const ADD_COMMENT = `${namespace}/ADD_COMMENT`

export const setComments = createAction(SET_COMMENTS)
export const sendComment = createAction(SEND_COMMENT)
export const getComments = createAction(GET_COMMENTS)
export const addComment = createAction(ADD_COMMENT)

export const resetComments = createAction(RESET_COMMENTS)

export default handleActions(
    {
        [SET_COMMENTS]: (state, { payload }) => ({ ...state, comments: payload }),
        [ADD_COMMENT]: (state, { payload }) => ({
            ...state,
            comments: [...state.comments, payload.data],
        }),
        [RESET_COMMENTS]: (state) => ({ ...state, comments: [] }),
    },
    initialState
)

export const commentsSelector = (state) => state[namespace].comments

export function* getCommentsRequest() {
    while (true) {
        const { payload } = yield take(GET_COMMENTS)
        const { selectedFile } = yield select(projectsSelector)
        try {
            const isfolder = selectedFile.type === 'directory'
            yield put(resetComments())
            const {
                data: { data },
            } = yield call(resource.get, `api/${isfolder ? 'project' : 'file'}/comments/${selectedFile.id}`)
            // console.log('response', response)
            yield put(setComments(data))
        } catch (err) {
            console.log(err)
        }
    }
}

function* sendCommentRequest() {
    while (true) {
        const {
            payload: { comment, setFieldValue },
        } = yield take(SEND_COMMENT)

        const { selectedFile } = yield select(projectsSelector)

        try {
            const isfolder = selectedFile.type === 'directory'
            const { data } = yield call(resource.post, `api/${isfolder ? 'project' : 'file'}/comment`, {
                id: selectedFile?.id,
                comment: comment,
            })
            yield put(addComment(data))
            setFieldValue('comment', '')
        } catch (err) {
            console.log(err)
        } finally {
            // yield put(setIsLoad(false))
        }
    }
}
export function* sagas() {
    yield all([getCommentsRequest(), sendCommentRequest()])
}
