import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styles from './create-share-link.module.sass'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { IconButton, Switch } from '@mui/material'
import { useDispatch } from 'react-redux'
import { createShareLink } from '../../../store/reducers/projects'
import { endOfTomorrow } from 'date-fns'
import CloseIcon from '@mui/icons-material/Close'
import { resource } from '../../../api/http'
import { useState } from 'react'
import Select from 'react-select'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'

const CreateShareLinkPopup = (props) => {
    const { t } = useTranslation()
    const { id, file } = props

    const [expiryDate, setExpiryDate] = useState(endOfTomorrow)

    const [open, setOpen] = useState(false)
    const [checked, setChecked] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            link: '',
            emails: [],
            type: 'private',
        },
        validationSchema: Yup.object().shape({
            // type: Yup.object()
            //     .shape({
            //         id: Yup.number().required('id is req'),
            //     })
            //     .required('This field is required.'),
        }),
        onSubmit: (values) => {
            const emails = values['emails']?.map((item) => item.value) ?? []
            const type = checked ? 'public' : 'private'
            const payload = file
                ? { file_id: id, time_from: new Date(), time_to: expiryDate, emails: emails, type: type }
                : { project_id: id, time_from: new Date(), time_to: expiryDate, emails: emails, type: type }
            dispatch(
                createShareLink({
                    payload: payload,
                    setFieldValue: (e) => formik.setFieldValue('link', e),
                    file: file ?? false,
                })
            )
        },
        validateOnMount: true,
    })
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div className={styles.wrapper}>
            <button
                style={
                    file
                        ? {
                              background: '#3376A3',
                              color: '#fff',
                              padding: '10px 20px',
                              display: 'flex',
                              alignItems: 'center',
                          }
                        : {}
                }
                onClick={handleClickOpen}
            >
                {t('share')}
            </button>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={styles.wrapper}
            >
                <DialogTitle id="responsive-dialog-title">
                    {t('share')}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        {/* <div className={styles.flex}>
                            <SearchInput setFieldValue={formik.setFieldValue} />
                        </div> */}
                        <div class="ShareDateExpaire">
                            <span>{t('expiry_date')}</span>
                            <ReactDatePicker
                                minDate={endOfTomorrow()}
                                selected={expiryDate}
                                onChange={(date) => setExpiryDate(date)}
                            />
                        </div>

                        <div className={styles.flex}>
                            <input
                                autoComplete="off"
                                id="link"
                                name="link"
                                value={formik.values.link}
                                onChange={formik.handleChange}
                                type="text"
                            />
                            {!formik.values['link'] && (
                                <button autoFocus type={'submit'} disabled={formik.errors['emails']}>
                                    Generate link
                                </button>
                            )}
                        </div>
                        <div className={styles.footer}>
                            <div>
                                <Switch
                                    checked={checked}
                                    onChange={() => {
                                        setChecked(!checked)
                                        if (formik.values['link']) {
                                            formik.handleSubmit()
                                        }
                                    }}
                                />
                                {t('public_link')}
                            </div>

                            {formik.values['link'] && (
                                <div onClick={() => navigator.clipboard.writeText(formik.values['link'])}>
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10.1785 11.154C8.91926 12.4436 7.63798 13.7123 6.3567 14.9809C5.67578 15.6555 4.76641 16.0011 3.76876 16.0011C1.8209 16.0011 0.194193 14.547 0.0187208 12.6181C-0.0883285 11.4444 0.252684 10.4109 1.09804 9.56403C1.63329 9.02853 2.16743 8.48971 2.70599 7.95752C2.94657 7.72013 3.28979 7.66934 3.58666 7.81067C3.87801 7.94979 4.05569 8.2457 4.03693 8.5659C4.02369 8.79004 3.91664 8.97001 3.75993 9.12569C3.23572 9.64684 2.7082 10.1658 2.19171 10.6947C1.1157 11.7966 1.4843 13.6438 2.89801 14.2544C3.70806 14.6044 4.68254 14.43 5.30828 13.8072C6.53879 12.5838 7.7671 11.3571 8.98878 10.1238C9.87939 9.22507 9.88601 7.94758 9.02079 7.0179C8.68971 6.66127 8.6864 6.18428 9.01306 5.87181C9.34083 5.55824 9.8198 5.58253 10.1575 5.92812C11.5789 7.38447 11.5988 9.69543 10.1774 11.1518L10.1785 11.154Z"
                                            fill="#3376A3"
                                        />
                                        <path
                                            d="M14.9438 6.39183C14.5774 6.76834 14.2033 7.13823 13.8303 7.50701C13.6084 7.72673 13.3414 7.7996 13.0401 7.70906C12.73 7.61521 12.5501 7.3977 12.4916 7.08412C12.4397 6.80257 12.5446 6.5696 12.7421 6.37196C13.1008 6.01311 13.4639 5.65869 13.817 5.29543C14.9857 4.0875 14.4075 2.04044 12.7752 1.64185C11.9806 1.44863 11.2611 1.62308 10.6685 2.21269C9.62886 3.24726 8.59258 4.28515 7.5574 5.32413C6.63811 6.24609 6.61824 7.51253 7.50553 8.46981C7.72294 8.70499 7.8024 8.97109 7.70529 9.27804C7.60707 9.58719 7.38414 9.76275 7.07072 9.81575C6.80585 9.86102 6.57961 9.76496 6.38979 9.57836C4.95842 8.16728 4.90214 5.81547 6.29709 4.37127C7.41283 3.21524 8.55285 2.08129 9.70611 0.962807C10.3992 0.292598 11.2655 0.00552323 11.8383 2.56419e-06C14.1812 -0.0022057 15.7847 1.42213 15.9779 3.37534C16.0927 4.52585 15.7616 5.55048 14.9427 6.39183H14.9438Z"
                                            fill="#3376A3"
                                        />
                                    </svg>
                                    <div>{t('copy_link')}</div>
                                </div>
                            )}
                        </div>
                        <DialogActions></DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default CreateShareLinkPopup

const SearchInput = ({ setFieldValue }) => {
    const [searchInputValue, setSearchInputValue] = useState('')
    const [selectedValues, setSelectedValues] = useState([])
    const [options, setOptions] = useState([])

    React.useEffect(() => {
        setFieldValue('emails', selectedValues)
    }, [selectedValues])

    const handleSearchInputChange = (newValue) => {
        setSearchInputValue(newValue)
        if (newValue) {
            resource
                .post(`${process.env.REACT_APP_BASE_URL}/api/search-user`, { search: newValue })
                .then(({ data: { data } }) => {
                    const options = data.map((result) => ({
                        value: result.email,
                        label: result.name + ' ' + result.email,
                    }))
                    setOptions(options)
                })
        } else {
            setOptions([])
        }
    }

    const handleSelectChange = (selectedOptions) => {
        setSelectedValues(selectedOptions)
    }

    return (
        <div>
            <div>
                <Select
                    isMulti
                    options={options}
                    value={selectedValues}
                    onChange={handleSelectChange}
                    onInputChange={handleSearchInputChange}
                    placeholder="Search by email or name"
                    noOptionsMessage={() => 'No results found'}
                    // classNamePrefix="react-select"
                    styles={{
                        container: (baseStyles, state) => ({
                            ...baseStyles,
                            width: 342,
                            // display: 'none',
                            minHeight: 28,
                            __menu: { display: 'none' },
                        }),

                        menuList: (baseStyles, state) => ({
                            ...baseStyles,
                            height: 100,
                        }),

                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            fontWeight: 400,
                            color: '#fff',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                            borderRadius: 5,
                        }),
                    }}
                />
            </div>
        </div>
    )
}
