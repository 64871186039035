import React, { useEffect, useState } from 'react'
import styles from '../deck-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { decksSelector, updateDeck, updateFiles } from '../../../store/reducers/decks'
import { useFormik } from 'formik'
// import * as Yup from 'yup'
import { useParams } from 'react-router-dom'

import { SketchPicker } from 'react-color'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

const StylesSection = (props) => {
    const { t } = useTranslation()
    // const { selectedIndex, changeIndex } = props // slider curent index index and setter

    const { currentDeck } = useSelector(decksSelector)
    const { files, description, name, thumb, id, font, background_color, font_color } = currentDeck
    const dispatch = useDispatch()

    const [background, setBackground] = useState(background_color ?? '#000000')
    const [backgroundPicker, setBackgroundPicker] = useState(false)
    const [text, setText] = useState(font_color ?? '#FFFFFF')
    const [textPicker, setTextPicker] = useState(false)

    const { deckId } = useParams()

    const [anchorElUser, setAnchorElUser] = React.useState(null)
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const formik = useFormik({
        initialValues: {
            font: '',
            font_color: '',
            background_color: '',
        },
        // validationSchema: Yup.object().shape({
        //     decaType: Yup.object()
        //         .shape({
        //             id: Yup.number().required('id is req'),
        //         })
        //         .required('This field is required.'),
        // }),
        onSubmit: (values) => {
            let fd = new FormData()
            fd.append('font', selectedFont.value)
            fd.append('font_color', text)
            fd.append('background_color', background)

            // if (logo) fd.append('thumb', logo)
            dispatch(updateDeck({ id: deckId, fd }))
        },
        validateOnMount: true,
    })

    useEffect(() => {
        formik.setValues({
            name: name ?? '',
            description: description ?? '',
            font: font ?? '',
            font_color: font_color ?? '',
            background_color: background_color ?? '',
        })
    }, [currentDeck])

    const handleChangeComplete = (color) => {
        setBackground(color.hex)
    }
    const handleChangeCompleteText = (color) => {
        setText(color.hex)
    }

    const fonts = [
        { label: 'Zeitung Micro Pro', value: 'Zeitung Micro Pro' },
        { label: 'Archivo Black', value: 'Archivo Black' },
        { label: 'Arial', value: 'Arial, sans-serif' },
        { label: 'Verdana', value: 'Verdana, sans-serif' },
        { label: 'Tahoma', value: 'Tahoma, sans-serif' },
        { label: 'Trebuchet MS', value: 'Trebuchet MS, sans-serif' },
        { label: 'Times New Roman', value: 'Trebuchet MS, serif' },
        { label: 'Georgia', value: 'Georgia, serif' },
        { label: 'Garamond', value: 'Garamond, serif' },
        { label: 'Courier New', value: 'Courier New, monospace' },
    ]

    const [selectedFont, setSelectedFont] = useState(fonts.find((e) => e.value === font) ?? fonts[0])

    // console.log('selectedFont', selectedFont)
    return (
        <div className={styles.edit}>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
                <div className={styles.edit__header}>
                    <span>{t('styles')}</span>+
                </div>
                <div className={styles.edit__column}>
                    <div className={styles.styles}>
                        <div className={styles.styles__wrap}>
                            <div className={styles.styles__label}>{t('color')}</div>

                            <div className={styles.styles__item}>
                                <div>{t('background')}</div>
                                <div>
                                    <span>{background}</span>
                                    <div
                                        onClick={() => setBackgroundPicker(!backgroundPicker)}
                                        style={{
                                            background: background,
                                            width: 30,
                                            height: 30,
                                            border: '1px solid rgba(0, 0, 0, 0.1)',
                                            borderRadius: 5,
                                            marginLeft: 11,
                                        }}
                                    ></div>
                                </div>
                            </div>
                            {backgroundPicker && (
                                <div style={{ margin: '7px 0 7px 3px' }}>
                                    <SketchPicker
                                        color={background}
                                        onChangeComplete={handleChangeComplete}
                                        width="180px"
                                    />
                                </div>
                            )}

                            <div className={styles.styles__item}>
                                <div>{t('text')}</div>
                                <div>
                                    <span>{text}</span>
                                    <div
                                        onClick={() => setTextPicker(!textPicker)}
                                        style={{
                                            background: text,
                                            width: 30,
                                            height: 30,
                                            border: '1px solid rgba(0, 0, 0, 0.1)',
                                            borderRadius: 5,
                                            marginLeft: 11,
                                        }}
                                    ></div>
                                </div>
                            </div>
                            {textPicker && (
                                <div style={{ margin: '7px 0 7px 3px' }}>
                                    <SketchPicker
                                        color={text}
                                        onChangeComplete={handleChangeCompleteText}
                                        width="180px"
                                    />
                                </div>
                            )}
                        </div>
                        <div className={styles.styles__wrap}>
                            <div className={styles.styles__label}>{t('text')}</div>
                            <div className={styles.styles__flex}>
                                <div className={styles.styles__item}>
                                    <div style={{ fontFamily: selectedFont.value }}>{selectedFont.label}</div>
                                </div>

                                <>
                                    <ChevronSvg onClick={handleOpenUserMenu} />
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {fonts.map((item) => (
                                            <MenuItem
                                                key={item.label}
                                                onClick={() => {
                                                    setSelectedFont(item)
                                                    handleCloseUserMenu()
                                                }}
                                            >
                                                <span style={{ fontFamily: item.value }}>{item.label}</span>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.edit__footer}>
                    <button type="submit">{t('save_changes')}</button>
                    {/* <button>{t('cancel')}</button> */}
                </div>
            </form>
        </div>
    )
}
export default StylesSection

const ChevronSvg = ({ onClick }) => {
    return (
        <svg width="12" height="7" onClick={onClick} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 6L11 1" stroke="black" stroke-opacity="0.3" />
        </svg>
    )
}
