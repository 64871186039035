import React, { useEffect } from 'react'
import styles from '../casting-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar } from '@mui/material'
import { commentsSelector, getComments, sendComment } from '../../../store/reducers/comments'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { deepOrange } from '@mui/material/colors'
import { useParams } from 'react-router-dom'
import { formatDistance } from 'date-fns'
import { projectsSelector } from '../../../store/reducers/projects'
import { useTranslation } from 'react-i18next'
import { pl } from 'date-fns/locale'

const CommentSection = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const comments = useSelector(commentsSelector)
    const { selectedFile, currentFiles } = useSelector(projectsSelector)
    const { id } = useParams()
    useEffect(() => {
        dispatch(getComments(id))
    }, [id, selectedFile])

    const formik = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: Yup.object().shape({
            comment: Yup.string().required('Comment is a required field'),
        }),
        onSubmit: (values, { setSubmitting, setFieldValue }) => {
            dispatch(sendComment({ comment: values['comment'], setFieldValue }))
        },
    })
    return (
        <>
            <div className={styles.commentsWrap}>
                <div className={styles.commentsSection}>
                    <div className={styles.commentsSection__header}>
                        <div>
                            <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 6.5L3.5 3.5L0.5 0.5" stroke="black" />
                            </svg>
                            <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 6.5L3.5 3.5L0.5 0.5" stroke="black" />
                            </svg>
                        </div>
                        <span>{t('comments')}</span>
                    </div>

                    {comments.length &&
                        comments?.map((elem, idx) => {
                            const created_at = formatDistance(new Date(elem.created_at), new Date(), {
                                addSuffix: true,
                                locale: pl,
                            })
                            return (
                                <div className={styles.commentsSection__comment} key={elem.comment + idx}>
                                    <Avatar sx={{ bgcolor: deepOrange[500] }}>N</Avatar>
                                    <div className={styles.commentsSection__comment__name}>{elem.name}</div>
                                    <div className={styles.commentsSection__comment__when}>{created_at ?? ''}</div>
                                    <div className={styles.commentsSection__comment__text}>{elem.comment}</div>
                                </div>
                            )
                        })}
                </div>
                <div>
                    <form onSubmit={formik.handleSubmit} className={styles.commentsSection__form}>
                        <input
                            id="comment"
                            name="comment"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.comment}
                            className={styles.commentsSection__input}
                            placeholder={t('add_your_comment')}
                        />
                        <button
                            className={styles.commentsSection__send}
                            type="submit"
                            disabled={!formik.values.comment}
                        >
                            <ArrowUp />
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}
export default CommentSection
const ArrowUp = () => {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 16.1111C7 16.6634 7.44772 17.1111 8 17.1111C8.55228 17.1111 9 16.6634 9 16.1111L7 16.1111ZM8.70711 1.18176C8.31658 0.79123 7.68342 0.79123 7.29289 1.18176L0.928932 7.54572C0.538408 7.93624 0.538408 8.56941 0.928932 8.95993C1.31946 9.35045 1.95262 9.35045 2.34315 8.95993L8 3.30307L13.6569 8.95993C14.0474 9.35045 14.6805 9.35045 15.0711 8.95993C15.4616 8.5694 15.4616 7.93624 15.0711 7.54572L8.70711 1.18176ZM9 16.1111L9 1.88886L7 1.88886L7 16.1111L9 16.1111Z"
                fill="white"
            />
        </svg>
    )
}
