import React, { useState, useTransition } from 'react'
import styles from '../deck-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { removeFile, setCurrentDeck } from '../../../store/reducers/decks'
import { Menu, MenuItem } from '@mui/material'
import { decksSelector } from '../../../store/reducers/decks'
import { ItemTypes } from '../../../assets/helrers/ItemTypes'
import { useDrag, useDrop } from 'react-dnd'
import { resource } from '../../../api/http'
import { useTranslation } from 'react-i18next'

const FolderFilesMap = ({ item }) => {
    // const [isOpen, setOpen] = React.useState(openedPages.includes(item.id))
    console.log(item)
    const [selectedItem, changeItem] = useState(0)
    const dispatch = useDispatch()
    const { currentDeck } = useSelector(decksSelector)
    const { files, type, directories } = currentDeck
    // const { id } = useParams()

    const moveItemInFolder = (dragIndex, hoverIndex) => {
        // console.log(`dragIndex : ${dragIndex}, hoverIndex : ${hoverIndex}`)
        const draggedItem = item.files[dragIndex]
        const remainingItems = item.files.filter((_, index) => index !== dragIndex)
        const updatedItems = [...remainingItems.slice(0, hoverIndex), draggedItem, ...remainingItems.slice(hoverIndex)]
        const arrayWithIds = updatedItems.map((e, idx) => {
            return { id: e.id, priority: idx }
        })

        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/decks/files/priority/${item.id}`, {
                arrayWithIds: arrayWithIds,
            })
            .then(({ data: { data, success, message } }) => {
                if (success) {
                    const folderIndex = directories.findIndex((e) => e.id === item.id)
                    if (folderIndex !== -1) {
                        currentDeck.directories[folderIndex].files = data
                        dispatch(setCurrentDeck(currentDeck))
                    }
                }
            })
    }
    const moveItemBeatweenFolders = (fileId, dirId, previosFolderId) => {
        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/decks/files/move`, { fileId: fileId, dirId: dirId })
            .then(({ data: { data, success, message } }) => {
                if (success) {
                    // console.log('data', data)
                    const folderIndex = directories.findIndex((e) => e.id === previosFolderId)
                    const newFolderIndex = directories.findIndex((e) => e.id === dirId)
                    console.log('x', newFolderIndex)
                    if (folderIndex !== -1) {
                        currentDeck.directories[folderIndex].files = directories[folderIndex].files.filter(
                            (e) => e.id !== fileId //Remove file from folder
                        )

                        dispatch(setCurrentDeck(currentDeck))

                        const temp = (currentDeck.directories[newFolderIndex].files = [
                            ...currentDeck.directories[newFolderIndex].files,
                            data,
                        ])

                        // dispatch(setCurrentDeck(currentDeck))
                    }
                }
            })
    }
    return (
        <div className={styles.directories__files}>
            {item.files?.map((elem, idx) => (
                <DraggableItem
                    index={idx}
                    moveItemInFolder={moveItemInFolder}
                    moveItemBeatweenFolders={moveItemBeatweenFolders}
                    elem={elem}
                    selectedItem={selectedItem}
                    changeItem={changeItem}
                    dispatch={dispatch}
                    folderId={item.id}
                />
            ))}
        </div>
    )
}
export default FolderFilesMap

const DraggableItem = ({
    index,
    moveItemInFolder,
    moveItemBeatweenFolders,
    elem,
    selectedItem,
    changeItem,
    dispatch,
    folderId,
}) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ItemTypes.DECK_FILE_IN_FOLDER,
        item: () => {
            return { selectedIndex: index, folderId: folderId, fileId: elem.id }
        },

        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [, dropRef] = useDrop({
        accept: ItemTypes.DECK_FILE_IN_FOLDER,
        drop: (item) => {
            const dragIndex = item.selectedIndex
            const hoverIndex = index
            // if (dragIndex === hoverIndex) {
            //     return
            // }
            if (item.folderId === elem.directory_id) {
                console.log('item', item.folderId, 'elem.directory_id', elem.directory_id)
                moveItemInFolder(dragIndex, hoverIndex)
            } else moveItemBeatweenFolders(item.fileId, folderId, item.folderId) // folderId from this component not from UseDrag item

            item.index = hoverIndex
        },
    })

    return (
        <div
            ref={(node) => dragRef(dropRef(node))}
            key={elem.id}
            className={
                selectedItem === elem.id ? styles.directories__files__item__selected : styles.directories__files__item
            }
            onClick={() => changeItem(elem.id)}
            draggable
        >
            <div>
                <div>{index + 1}</div>
                <img src={elem.preview} alt="preview" />
                <div>{elem.name}</div>
            </div>

            {selectedItem === elem.id ? <FileMenu dispatch={dispatch} elem={elem} /> : <div></div>}
        </div>
    )
}

const Dots = ({ onClick }) => {
    return (
        <svg
            width="24"
            onClick={onClick}
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="8" r="2" fill="#3376A3" />
            <circle cx="12" cy="8" r="2" fill="#3376A3" />
            <circle cx="18" cy="8" r="2" fill="#3376A3" />
        </svg>
    )
}
const FileMenu = ({ dispatch, elem }) => {
    const { t } = useTranslation()
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    return (
        <>
            <Dots onClick={handleOpenUserMenu} />
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {[t('Delete')].map((setting) => (
                    <MenuItem
                        key={setting}
                        onClick={() => {
                            dispatch(removeFile(elem.id))
                            handleCloseUserMenu()
                        }}
                    >
                        <span>{setting}</span>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
