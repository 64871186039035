import { all } from 'redux-saga/effects'
import { sagas as user } from '../reducers/user'
import { sagas as auth } from '../reducers/auth'
import { sagas as projects } from '../reducers/projects'
import { sagas as uploads } from '../reducers/uploads'
import { sagas as comments } from '../reducers/comments'
import { sagas as decks } from '../reducers/decks'
import { sagas as search } from '../reducers/search'
import { sagas as casting } from '../reducers/castings'
import { sagas as drag } from '../reducers/drag'
import { sagas as notifications } from '../reducers/notifications'
import { sagas as locations } from '../reducers/locations'
import { sagas as popups } from '../reducers/popups'

export default function* sagas() {
    yield all([
        auth(),
        user(),
        projects(),
        uploads(),
        comments(),
        decks(),
        search(),
        casting(),
        drag(),
        notifications(),
        locations(),
        popups(),
    ])
}
