import { createAction, handleActions } from 'redux-actions'
import { all, call, put, take } from 'redux-saga/effects'
import { resource } from '../../api/http'
import { resetProjects } from './projects'
import { resetDeck } from './decks'
import { getUser, resetUser, setUser } from './user'
// import { dangerMessage } from '../../components/ui/alert/alertReducer'
// import { setCategories } from './timelines'
// import { setUser } from './user'
const namespace = 'auth'

const initialState = {
    auth: false,
    token: null,
}

const SET_AUTH = `${namespace}/SET_AUTH`
const REGISTER = `${namespace}/REGISTER`
const LOGIN = `${namespace}/LOGIN`
const LOGIN_GOOGLE = `${namespace}/LOGIN_GOOGLE`
const SET_TOKEN = `${namespace}/SET_TOKEN`
const LOGOUT = `${namespace}/LOGOUT`
const RESET_PASSWORD = `${namespace}/RESET_PASSWORD`
const SET_PASSWORD = `${namespace}/SET_PASSWORD`

export const setAuth = createAction(SET_AUTH)
export const register = createAction(REGISTER)
export const login = createAction(LOGIN)
export const loginGoogle = createAction(LOGIN_GOOGLE)
export const setToken = createAction(SET_TOKEN)
export const logoutAction = createAction(LOGOUT)
export const resetPassword = createAction(RESET_PASSWORD)
export const setPassword = createAction(SET_PASSWORD)

export default handleActions(
    {
        [SET_AUTH]: (state, { payload }) => ({ ...state, auth: payload }),
        [SET_TOKEN]: (state, { payload }) => ({ ...state, token: payload }),
    },
    initialState
)

export const authSelector = (state) => state[namespace].auth

function* registerRequest() {
    while (true) {
        const {
            payload: { values, navigate, setFieldError },
        } = yield take(REGISTER)

        try {
            const {
                data: { token },
            } = yield call(resource.post, `api/auth/register`, values)

            if (token) {
                localStorage.setItem('token', token)
                yield put(setToken(token))
                yield put(setAuth(!!token))
                yield put(getUser())
                yield put(navigate('/'))
            }
        } catch (err) {
            const message = err?.response?.data?.errors?.email[0]

            if (message?.length) {
                setFieldError('email', message)
            }

            console.log(err)
        }
    }
}
function* loginRequest() {
    while (true) {
        const {
            payload: { values, navigate, setSubmitting, setFieldError },
        } = yield take(LOGIN)

        try {
            const {
                data: { token },
            } = yield call(resource.post, `api/auth/login`, values)

            if (token) {
                localStorage.setItem('token', token)
                yield put(setToken(token))
                yield put(setAuth(!!token))
                yield put(getUser())
                // payload.setSubmitting(false)
                yield put(navigate('/'))
            }
        } catch (err) {
            const message = err?.response?.data?.message
            if (message) {
                // yield put(dangerMessage(message));
                setFieldError('password', message)
            }
            // payload.setSubmitting(false)
            console.log(err)
        }
    }
}
function* resetPasswordRequest() {
    while (true) {
        const {
            payload: { values, navigate, setFieldError },
        } = yield take(RESET_PASSWORD)
        try {
            const res = yield call(resource.post, `/api/forgot-password`, values)

            if (res.status == 200) {
                yield navigate('/sign-in')
                // yield put(dangerMessage(res.data.data))
            } else {
                // payload.setSubmitting()
            }
        } catch (err) {
            const message = err?.response?.data?.message
            if (message) {
                setFieldError('email', message)
                // yield put(dangerMessage(message))
                // payload.setSubmitting()
            }

            console.log(err)
        }
    }
}
function* newPasswordRequest() {
    while (true) {
        const {
            payload: { values, navigate, setSubmitting },
        } = yield take(SET_PASSWORD)

        try {
            const res = yield call(resource.post, `/api/confirm-password`, values)

            if (res.data.data === 'passwords.reset') {
                yield put(navigate('/sign-in'))
            } else {
                // payload.setSubmitting()
            }
        } catch (err) {
            const message = err?.response?.data?.message
            if (message) {
                // yield put(dangerMessage(message))
                // payload.setSubmitting()
            }

            console.log(err)
        }
    }
}

function* init() {
    const token = localStorage.getItem('token')
    if (token) {
        yield put(setToken(token))
        yield put(setAuth(!!token))
        // yield put(getUser());
    }
}
export function* logout() {
    while (true) {
        yield take(LOGOUT)
        yield put(setToken(null))
        yield put(setAuth(false))
        yield put(resetProjects())
        yield put(resetDeck())
        yield put(resetUser())
        localStorage.removeItem('token')
    }
}
export function* sagas() {
    yield all([
        init(),
        registerRequest(),
        newPasswordRequest(),
        resetPasswordRequest(),
        loginRequest(),
        // loginGoogleRequest(),
        logout(),
    ])
}
// function* loginGoogleRequest() {
//     while (true) {
//         const { payload } = yield take(LOGIN_GOOGLE);
//         try {
//             const {
//                 data: { data },
//             } = yield call(resource.post, `/auth/login-google`, payload, {
//                 headers: {
//                     'Access-Control-Allow-Origin': '*',
//                 },
//             });
//             console.log(data);
//             if (data.token) {
//                 localStorage.setItem('token', data.token);
//                 yield put(setToken(data.token));
//                 yield put(setAuth(!!data.token));
//                 yield put(push('/'));
//             }
//         } catch (err) {
//             const message = err?.response?.data?.message;
//             if (message) {
//                 yield put(dangerMessage(message));
//             }
//             console.log(err);
//         }
//     }
// }
