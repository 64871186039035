import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './favorites.module.sass'
import { resource } from '../../api/http'
import FolderFileItem from '../../components/folder-file-item'
import { projectsSelector, setFavorites } from '../../store/reducers/projects'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Favorites = ({ navigate }) => {
    const { favorites, projects } = useSelector(projectsSelector)
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const sortFilters = [t('last_edit'), t('last_added'), t('commented'), 'a-z']
    const filters = [t('all_files'), 'Videos', 'Photos', t('files')]
    const [sort, setSort] = useState(sortFilters[0])
    const [filter, setFilter] = useState(filters[0])

    useEffect(() => {
        try {
            const promise = resource.get(`${process.env.REACT_APP_BASE_URL}/api/project/favorites`).then(({ data }) => {
                // if (data) setItems(data)
                dispatch(setFavorites(data))
            })
        } catch (error) {
            alert(error)
        }
    }, [])

    let filteredArrayFiles
    let filteredArrayDirectories
    if (filter === t('all_files')) {
        filteredArrayFiles = favorites.files?.map((e) => e)
        filteredArrayDirectories = favorites.directories?.map((e) => e)
    }
    if (filter === 'Photos') {
        filteredArrayFiles = favorites.files.filter((e) => e.type === 'image')
        filteredArrayDirectories = favorites.directories.filter((e) => e.type === 'image')
    }
    if (filter === 'Videos') {
        filteredArrayFiles = favorites.files.filter((e) => e.type === 'video')
        filteredArrayDirectories = favorites.directories.filter((e) => e.type === 'video')
    }
    if (filter === t('files')) {
        filteredArrayFiles = favorites.files.filter((e) => e.type !== 'video' && e.type !== 'image')
        filteredArrayDirectories = favorites.directories.filter((e) => e.type !== 'video' && e.type !== 'image')
    }

    if (sort === t('last_edit')) {
        filteredArrayFiles?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        filteredArrayDirectories?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    }
    if (sort === t('last_added')) {
        filteredArrayFiles?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        filteredArrayDirectories?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
    if (sort === 'a-z') {
        filteredArrayFiles.sort((a, b) => a.name.localeCompare(b.name))
        filteredArrayDirectories.sort((a, b) => a.name.localeCompare(b.name))
    }
    // if (sort === 'Liked') {
    //     filteredArrayFiles = favorites.files.filter((e) => e.likesCount > 0)
    //     filteredArrayFiles = favorites.directories.filter((e) => e.likesCount > 0)
    // }
    if (sort === t('commented')) {
        filteredArrayFiles = favorites.files.filter((e) => e.commentsCount > 0)
        filteredArrayFiles = favorites.directories.filter((e) => e.commentsCount > 0)
    }

    const [anchorSort, setAnchorSort] = React.useState(false)

    const handleCloseSortMenu = () => {
        setAnchorSort(null)
    }

    const [anchorFilter, setAnchorFilter] = React.useState(false)

    const handleCloseFilterMenu = () => {
        setAnchorFilter(null)
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.flex}>
                <div className={styles.sort}>
                    <div>{t('sort')} </div>
                    <div className={styles.sort__filter} onClick={(e) => setAnchorSort(e.currentTarget)}>
                        {sort}
                        <svg
                            onClick={(e) => setAnchorSort(e.currentTarget)}
                            width="8"
                            height="5"
                            viewBox="0 0 8 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                        </svg>
                    </div>

                    <Menu
                        // sx={{ mt: '45px' }}
                        id={`Sort`}
                        anchorEl={anchorSort}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            ul: {
                                background: '#1F1F1F',
                                color: 'gray',
                                borderRadius: '5px',
                                padding: 0,
                                width: 145,
                            },
                            top: 15,
                            borderRadius: '5px',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorSort)}
                        onClose={handleCloseSortMenu}
                    >
                        {sortFilters.map((item, idx) => {
                            return (
                                <MenuItem
                                    key={idx}
                                    sx={{
                                        padding: '11px 0px 10px 10px',
                                        background: sort === item ? '#292929' : '#1F1F1F',
                                        color: sort === item ? '#fff' : 'null',
                                        fontFamily: 'Zeitung Micro Pro',
                                        fontSize: 12,
                                        borderRadius: 5,
                                        '&:hover': { color: '#fff', background: '#292929' },
                                    }}
                                    onClick={() => {
                                        setSort(item)
                                        handleCloseSortMenu()
                                    }}
                                >
                                    <div>{item}</div>
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </div>
                <div className={styles.sort}>
                    <div>{`${t("filter")} :`}</div>
                    <div className={styles.sort__filter} onClick={(e) => setAnchorFilter(e.currentTarget)}>
                        {filter}
                        <svg
                            onClick={(e) => setAnchorFilter(e.currentTarget)}
                            width="8"
                            height="5"
                            viewBox="0 0 8 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                        </svg>
                    </div>

                    <Menu
                        // sx={{ mt: '45px' }}
                        id={`Filter`}
                        anchorEl={anchorFilter}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            ul: {
                                background: '#1F1F1F',
                                color: 'gray',
                                borderRadius: '5px',
                                padding: 0,
                                width: 122,
                            },
                            top: 15,
                            borderRadius: '5px',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorFilter)}
                        onClose={handleCloseFilterMenu}
                    >
                        {filters.map((item, idx) => {
                            return (
                                <MenuItem
                                    key={idx}
                                    sx={{
                                        padding: '11px 0px 10px 10px',
                                        background: filter === item ? '#292929' : '#1F1F1F',
                                        color: filter === item ? '#fff' : 'null',
                                        fontFamily: 'Zeitung Micro Pro',
                                        fontSize: 12,
                                        borderRadius: 5,
                                        '&:hover': { color: '#fff', background: '#292929' },
                                    }}
                                    onClick={() => {
                                        setFilter(item)
                                        handleCloseFilterMenu()
                                    }}
                                >
                                    <div>{item}</div>
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </div>
            </div>
            <div className={styles.items}>
                {filteredArrayDirectories?.map((item) => {
                    return <FolderFileItem item={item} key={item.id} navigate={navigate} />
                })}
                {filteredArrayFiles?.map((item) => {
                    return <FolderFileItem item={item} key={item.id} navigate={navigate} />
                })}
            </div>
        </div>
    )
}

export default Favorites
