import React, { useEffect, useState } from 'react'
import styles from './opened-file.module.sass'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedFile } from '../../store/reducers/projects'
import RightBar from '../../components/side-bar-right'
import { getDeckById } from '../../store/reducers/decks'
import FileHeader from '../../components/file-header/FileHeader'
import { resource } from '../../api/http'

const OpenFile = ({ navigate, deckFlow }) => {
    const dispatch = useDispatch()
    let { id, deckId } = useParams() // PROJECT ID AND FILE ID

    useEffect(() => {
        if (deckFlow) {
            dispatch(getDeckById(deckId))
        }
    }, [])

    useEffect(() => {
        resource.get(`${process.env.REACT_APP_BASE_URL}/api/files/${id}`).then(({ data: { data, success } }) => {
            if (success) {
                setFile(data)
                dispatch(setSelectedFile(data))
            }
        })
    }, [id])

    const [file, setFile] = useState([])

    if (!file) return <></>

    return (
        <>
            <div className={styles.wrapper}>
                <FileHeader deckFlow={deckFlow} navigate={navigate} file={file} />
                <div className={styles.flexRow}>
                    {file.type === 'image' && (
                        <div className={styles.imageWrap}>
                            <img src={file.source} alt="Alternative  img" />
                        </div>
                    )}
                    {file.type === 'video' && <ReactPlayer url={file.source} controls width={'100%'} height={'100%'} />}
                    {file.type === 'audio' && (
                        <div className={styles.imageWrap}>
                            <ReactPlayer url={file.source} controls width={'100%'} />
                        </div>
                    )}
                    {file.type === 'file' && (
                        <div className={styles.imageWrap}>
                            <img src={file.preview} alt="Alternative  file" />
                        </div>
                    )}
                    {file.type === 'pdf' && (
                        <div className={styles.imageWrap}>
                            <img src={file.preview} alt="Alternative pdf" />
                        </div>
                    )}
                    {!deckFlow && <RightBar />}
                </div>
            </div>
        </>
    )
}

export default OpenFile
