import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import InputBase from '@mui/material/InputBase'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import AccountCircle from '@mui/icons-material/AccountCircle'
import NotificationsIcon from '@mui/icons-material/Notifications'
// import MoreIcon from '@mui/icons-material/MoreVert'
import ProcessLoder from '../process-loder'
import { Avatar, Tooltip } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setUploadPopup, showUploadings } from '../../store/reducers/uploads'
import { logoutAction } from '../../store/reducers/auth'
import { userSelector } from '../../store/reducers/user'
import SettingsAvatar from '../avatar/SettingsAvatar'
import { resource } from '../../api/http'
import { useState } from 'react'
import styles from './styles.module.sass'
import { startMainSearch } from '../../store/reducers/search'
import { debounce, throttle } from 'lodash'
import { useLocation } from 'react-router-dom'
import Notifications from '../notifications/Notifications'
import { useTranslation } from 'react-i18next'
import { notificationsSelector, setShowNotifications } from '../../store/reducers/notifications'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '200px',
        // [theme.breakpoints.up('md')]: {
        //     width: '20ch',
        // },
    },
}))

export default function Header({ navigate }) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const uploadingPopup = useSelector(showUploadings)
    const { avatar, firstName, lastName, name } = useSelector(userSelector)

    const settings = [
        {
            item: t('logout'),
            action: () => {
                dispatch(logoutAction())
            },
        },
    ]

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        // setAnchorEl(event.currentTarget)
    }
    const handleMobileMenuClose = () => {
        // setMobileMoreAnchorEl(null)
    }

    // const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    //     // setMobileMoreAnchorEl(event.currentTarget)
    // }

    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const mobileMenuId = 'primary-search-account-menu-mobile'

    //TO DO mobile header
    const renderMobileMenu = (
        <Menu
            // anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={false}
            // open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={2} color="error">
                        {/* <MailIcon /> */}
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                    <Badge badgeContent={44} color="error">
                        {/* <NotificationsIcon /> */}
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>{t('profile')}</p>
            </MenuItem>
        </Menu>
    )
    const [searchDirectories, setSearchDirectories] = useState([])
    const [searchFiles, setSearchFiles] = useState([])
    const [searchOnFocus, setSearchOnFocus] = useState(false)

    const hasResults = searchFiles.length || searchDirectories.length
    const temp = Boolean(searchOnFocus || hasResults)

    const { pathname } = useLocation()

    const debouncedSearch = debounce((value) => {
        dispatch(startMainSearch(value))
        console.log('Searching for:', value)
    }, 500)
    const handleSearchChange = (event) => {
        const { value } = event.target
        debouncedSearch(value)
    }

    const [open, setOpen] = React.useState(false)

    const { showNotificationsPopup, notifications } = useSelector(notificationsSelector)
    console.log('notifications', notifications)
    return (
        <AppBar sx={{ flexGrow: 0, minHeight: '50px', width: 'auto', position: 'relative', boxShadow: 'none' }}>
            <Toolbar sx={{ backgroundColor: '#2C2C2C', minHeight: '50px !important' }}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder={t('search')}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => {
                            if (e.target.value.length >= 1) {
                                if (pathname !== '/search') navigate('/search')
                            }
                            handleSearchChange(e)
                        }}
                        // onFocus={() => setSearchOnFocus(true)}
                        // onBlur={() => setSearchOnFocus(false)}
                    />
                    {temp && <ResponseView directories={searchDirectories} files={searchFiles} />}
                </Search>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                    <div
                        onClick={() => {
                            dispatch(setUploadPopup(!uploadingPopup))
                        }}
                    >
                        <ProcessLoder />
                    </div>
                    <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                        onClick={() => dispatch(setShowNotifications(true))}
                    >
                        <Badge badgeContent={notifications.filter((e) => e.read_at == null).length} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Notifications open={showNotificationsPopup} setOpen={setOpen} navigate={navigate} />
                </Box>
                <Box sx={{ flexGrow: 0 }}>
                    {/* <Tooltip title="Open settings"> */}
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        {avatar ? (
                            <Avatar alt="Qemy Sharp" src={avatar} />
                        ) : (
                            <SettingsAvatar
                                name={firstName && lastName ? `${firstName} ${lastName}` : name}
                                sx={{
                                    fontFamily: 'Zeitung Micro Pro;',
                                    fontSize: 14,
                                    fontWeight: 400,
                                }}
                            />
                        )}
                    </IconButton>
                    {/* </Tooltip> */}
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                        {settings.map((setting) => (
                            <MenuItem
                                key={setting.item}
                                onClick={() => {
                                    setting.action()
                                    handleCloseUserMenu()
                                }}
                            >
                                <Typography textAlign="center">{setting.item}</Typography>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

const ResponseView = ({ directories, files }) => {
    return (
        <div className={styles.result}>
            {directories?.map((e) => (
                <div>
                    <a href={`/project/${e.id}`}>{e.name}</a>
                </div>
            ))}
            {files?.map((e) => (
                <a href={`/file/${e.project_id}/${e.id}`}>{e.name}</a>
            ))}
        </div>
    )
}
