import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styles from './open-casting-answers.module.sass'
import { castingsSelector, getAllCastings } from '../../store/reducers/castings'
import CastingBar from '../../components/casting-bar'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'

const OpenCastingAnswer = ({ navigate }) => {
    const { t } = useTranslation()
    const { castings } = useSelector(castingsSelector)
    const [currentIdx, setCurrentIdx] = useState(0)

    const { id, answerId } = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        if (!castings.length) dispatch(getAllCastings())
    }, [])

    const currentCasting = castings?.find((obj) => obj.id === +id)
    const currentAnsvers = currentCasting?.answers.find((obj) => obj.id === +answerId)
    const { photo, video, scene, card } = currentAnsvers ?? []

    const array = [photo, video, scene, card]
    console.log('currentAnsvers', currentAnsvers)

    const items = array?.map((item, idx) => {
        return idx === 1 || idx === 2 ? (
            <div className={styles.item} key={item} data-value={idx + 1} onClick={() => setCurrentIdx(idx)}>
                <ReactPlayer url={item} height={221} width={214} />
            </div>
        ) : (
            <div className={styles.bottomImage} key={item} data-value={idx + 1} onClick={() => setCurrentIdx(idx)}>
                <img src={item} className={styles.media} alt="1" />
            </div>
        )
    })

    const currentItem =
        currentIdx === 1 || currentIdx === 2 ? (
            <div className={styles.itemVideo} key={array[currentIdx]}>
                <ReactPlayer url={array[currentIdx]} controls width={'100%'} height={'100%'} />
            </div>
        ) : (
            <img src={array[currentIdx]} className={styles.media} alt="1" />
        )

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.flexCenter}>
                    <svg
                        onClick={() => navigate(-1)}
                        width="12"
                        height="22"
                        style={{ marginRight: '10px' }}
                        viewBox="0 0 12 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </svg>
                    {/* <ThreeLines /> */}
                    <Link to={`/castings`}> {t('all_castings')}</Link>
                    <Separator />
                    {currentCasting?.title ?? ''}
                </div>
            </div>
            <div className={styles.flexRow}>
                <div className={styles.caruselWrapper}>
                    <div className={styles.currentImage}>{currentItem}</div>

                    <div className={styles.bottomImages}>{items}</div>
                </div>

                <CastingBar currentAnsvers={currentAnsvers} />
            </div>
        </div>
    )
}

export default OpenCastingAnswer

const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}
