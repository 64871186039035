import { Menu, MenuItem } from '@mui/material'
import { formatDistance } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './castings.module.sass'
import { resource } from '../../api/http'
import CreateCasting from '../../components/popups/create-casting'
import { castingsSelector, getAllCastings, removeCasting } from '../../store/reducers/castings'
import { useTranslation } from 'react-i18next'
import { pl } from 'date-fns/locale'

const Castings = ({ navigate }) => {
    const { t } = useTranslation()
    const { castings } = useSelector(castingsSelector)
    const [questions, setQuestions] = useState([])

    const [selectedCusting, setSelectedCasting] = useState(false)

    useEffect(() => {
        try {
            const promise = resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/casting-questions`)
                .then(({ data: { data, success } }) => {
                    if (success) setQuestions(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])
    const dispatch = useDispatch()

    useEffect(() => {
        if (!castings.length) dispatch(getAllCastings())
    }, [])

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.breadcrumbs}>
                    <svg
                        onClick={() => navigate(-1)}
                        width="12"
                        height="22"
                        style={{ marginRight: '10px' }}
                        viewBox="0 0 12 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </svg>
                    <Link to={'/castings'}>{t('all_castings')}</Link>
                </div>
                <CreateCasting />
            </div>
            <div className={styles.flexRow}>
                {castings?.map((item, idx) => {
                    const selected = selectedCusting === item.id
                    return (
                        <div
                            onDoubleClick={() => navigate(`/casting/${item.id}`)}
                            key={item.id}
                            onClick={() => setSelectedCasting(item.id)}
                        >
                            <div
                                style={{
                                    width: 307,
                                    backgroundColor: selected ? '#D7E7FF' : '#F8F8F8',
                                    borderRadius: '10px',
                                    boxShadow: 'none',
                                    border: selected ? '2px solid #3375A3' : '2px solid rgba(0, 0, 0, 0.2)',

                                    marginRight: 10,
                                    marginBottom: 10,
                                }}
                            >
                                <img
                                    style={{ height: 140, width: '100%', objectFit: 'contain', borderRadius: '3px' }}
                                    src={item.thumb}
                                    alt="thumb"
                                />
                                <div
                                    style={{
                                        padding: '5px',
                                        fontFamily: 'Zeitung Micro Pro',
                                        fontSize: 12,
                                        fontWeight: 600,
                                    }}
                                >
                                    <span>{item.title}</span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontFamily: 'Zeitung Micro Pro',
                                                fontSize: 10,
                                                fontWeight: 400,
                                                opacity: 0.5,
                                            }}
                                        >
                                            {`${t('edited')}
                                                ${formatDistance(new Date(), new Date(item.updated_at), {
                                                    includeSeconsds: true,
                                                    locale: pl,
                                                })}
                                                ${t('ago')}`}
                                        </div>
                                        <div>
                                            <FileMenu dispatch={dispatch} elem={item} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {/* </div> */}
                {/* </div> */}
                {/* <RightBar /> */}
            </div>
        </div>
    )
}

export default Castings

const FileMenu = ({ dispatch, elem }) => {
    const { t } = useTranslation()
    const [anchorElUser, setAnchorElUser] = React.useState(null)

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    return (
        <>
            <Dots onClick={handleOpenUserMenu} />
            <Menu
                sx={{
                    mt: '45px',
                    top: -25,
                    left: 10,
                    ul: {
                        width: 210,
                        background: '#1F1F1F',
                        color: '#fff',
                        borderRadius: '5px',
                        padding: 0,
                    },
                    borderRadius: '5px',
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {[t('Delete'), t('copy_share_link_casting')].map((setting, idx) => (
                    <MenuItem
                        sx={{
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            borderRadius: 5,
                            div: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: 40,
                                opacity: 0.5,
                                '&:hover': { opacity: 1, background: '#292929' },
                            },
                        }}
                        key={setting}
                        onClick={() => {
                            // dispatch(removeFile(elem.id))
                            if (idx === 0) dispatch(removeCasting(elem.id))

                            if (idx === 1)
                                navigator.clipboard.writeText(`${window.location.hostname}/casting-quiz/${elem.id}`)

                            handleCloseUserMenu()
                        }}
                    >
                        <span>{setting}</span>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
const Dots = ({ onClick }) => {
    return (
        <svg
            width="24"
            onClick={onClick}
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="6" cy="8" r="2" fill="#3376A3" />
            <circle cx="12" cy="8" r="2" fill="#3376A3" />
            <circle cx="18" cy="8" r="2" fill="#3376A3" />
        </svg>
    )
}
