import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button } from '@mui/material'
import styles from './restore-password.module.sass'
import { useDispatch } from 'react-redux'
import { resetPassword } from '../../store/reducers/auth'
import CustomTextField from '../../components/custom-text-field/CustomTextField'
import { PinkBigLogo } from '../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'

const RestorePassword = ({ navigate }) => {
    // const loading = useSelector(authLoading)
    const { i18n, t } = useTranslation()
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email address').required(t('req_email')),
        }),
        onSubmit: (values, { setFieldError }) => {
            dispatch(resetPassword({ values, navigate, setFieldError }))
        },
    })
    return (
        <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
            {/* <Logo /> */}
            <div>
                <PinkBigLogo />
                <h1 className={styles.title}>{t('restore_pass')}</h1>
                <div className={styles.fields}>
                    <CustomTextField name="email" formik={formik} placeholder="Email" />

                    <Button variant="contained" type="submit" disabled={!formik.isValid}>
                        {t('restore')}
                    </Button>
                </div>
                <div className={styles.links}>
                    <a href="/sign-in"> {t('login')}</a>
                    <a href="/sign-up">{t("sign_up")}</a>
                </div>
            </div>
        </form>
    )
}

export default RestorePassword
// const Logo = () => {
//     return (
//         <section className={styles.header}>
//             <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <g clipPath="url(#clip0_2119_54536)">
//                     <path
//                         d="M20.6949 14.8372C19.8916 16.6859 17.6903 17.5392 15.8265 16.7333C13.9466 15.9433 13.079 13.7943 13.8984 11.9455C13.9306 11.8665 13.9627 11.8033 13.9948 11.7401C14.0752 11.6295 14.1394 11.5031 14.2037 11.3925C14.6697 10.634 15.0874 9.68594 14.8143 8.80106C14.509 7.77398 13.561 7.22093 12.5327 7.52115C11.4723 7.83718 11.4723 8.89587 11.7615 9.78075C11.7775 9.85975 11.8097 9.93876 11.8418 10.0178C11.9704 10.3496 11.9704 10.713 11.8097 11.0607C11.4883 11.7717 10.6528 12.1035 9.91374 11.7875C9.67273 11.6769 9.47992 11.5189 9.31925 11.2977C9.19071 11.1397 9.11037 10.95 9.0943 10.7604C7.87319 8.18481 6.34679 10.2074 6.34679 10.2074C5.92904 11.0449 5.72016 12.0561 6.37892 12.8304C7.26263 13.8575 8.80509 13.7785 9.86554 14.5844C11.1027 15.5324 11.4883 17.4128 10.8778 18.8033C10.0583 20.6521 7.87319 21.4896 6.00938 20.6995C4.1295 19.8936 3.26186 17.7446 4.06523 15.8959C4.1777 15.6431 4.32231 15.406 4.49905 15.1848C4.965 14.3315 5.25421 13.2728 4.72399 12.388C4.29017 11.6927 3.53501 11.3135 2.76378 11.1397C0.755359 10.6656 -0.449691 8.46924 0.144801 6.55727C0.193003 6.41506 0.241205 6.28865 0.289407 6.14644C1.10884 4.29768 3.27793 3.4444 5.15781 4.25027C6.05758 4.62951 6.81274 5.37217 7.15016 6.27285C7.53577 7.28413 7.48757 8.72206 8.66049 9.2277C9.41565 9.57533 10.3797 9.16449 10.8456 8.54824C11.9704 7.06291 9.99407 5.56179 9.84947 4.01325C9.81733 3.74463 9.80127 3.47601 9.8334 3.20738C9.86554 2.87556 9.96194 2.52793 10.1065 2.1961C10.926 0.347339 13.1111 -0.505934 14.991 0.299935C16.8548 1.09 17.7225 3.23899 16.903 5.08775C16.7906 5.32477 16.662 5.56179 16.5013 5.7672C15.1678 8.35863 17.0155 9.43312 18.0277 9.82815C19.1525 10.1916 20.0522 10.6656 20.6146 11.7559C21.0966 12.704 21.1287 13.8575 20.6949 14.8372Z"
//                         fill="url(#paint0_linear_2119_54536)"
//                     />
//                 </g>
//                 <defs>
//                     <linearGradient
//                         id="paint0_linear_2119_54536"
//                         x1="10.4961"
//                         y1="-0.00390625"
//                         x2="10.4961"
//                         y2="20.9966"
//                         gradientUnits="userSpaceOnUse"
//                     >
//                         <stop stop-color="#0085FF" />
//                         <stop offset="1" stop-color="#F3A9FF" />
//                     </linearGradient>
//                     <clipPath id="clip0_2119_54536">
//                         <rect width="21" height="21" fill="white" />
//                     </clipPath>
//                 </defs>
//             </svg>
//             Medialove
//         </section>
//     )
// }
