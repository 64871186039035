import styles from './uploader.module.sass'
import LinearProgressBar from '../progress-bar/LinearProgressBar'
import formatBytes from '../../assets/helrers/byteFormeter'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { addUploadItem, setUploadPopup, uploadsSelector } from '../../store/reducers/uploads'
import { useAbortItem, useBatchAddListener, useBatchProgressListener } from '@rpldy/uploady'
import { useTranslation } from 'react-i18next'

let abort // abort loading HOOK

const Uploader = ({ fakeField, dispatch }) => {
    const { t } = useTranslation()
    const { uploadsItems } = useSelector(uploadsSelector)

    if (fakeField) return LogProgress()

    return (
        <div
            className={styles.overlay}
            onClick={(e) => {
                dispatch(setUploadPopup(false))
            }}
        >
            <div
                className={styles.wrapper}
                onClick={(e) => {
                    e.stopPropagation()

                }}
            >
                <div className={styles.title}>
                    {t('uploadings')}{' '}
                    <CloseIcon
                        onClick={(e) => {
                            e.stopPropagation()
                            dispatch(setUploadPopup(false))
                        }}
                    />
                </div>
                <div className={styles.content}>
                    {uploadsItems?.map((item, idx) => (
                        <div key={idx} className={styles.item}>
                            <MediaSvg />
                            {/* {item.file.type.includes('image') || item.file.type.includes('video') ? (
                            <MediaSvg />
                        ) : (
                            <FolderSvg />
                        )} */}
                            <div className={styles.item__info}>
                                <span>{item?.file?.name}</span>
                                <LinearProgressBar value={item.completed} className={styles.item__info__progress} />
                                <div>
                                    {item.state === 'aborted'
                                        ? 'Canceled'
                                        : `${formatBytes(item.loaded)} of ${formatBytes(item.file.size)} - 1 min`}
                                </div>
                            </div>
                            <div className={styles.item__btns}>
                                {item.completed === 100 && (
                                    <IconButton>
                                        <DoneIcon />
                                    </IconButton>
                                )}
                                {item.state === 'uploading' && item.completed !== 100 && (
                                    <IconButton
                                        onClick={() => {
                                            abort(item.id)
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )}
                                {/* 
                            <IconButton>
                                <FindIcon />
                            </IconButton> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Uploader

function LogProgress() {
    abort = useAbortItem()
    const dispatch = useDispatch()

    useBatchProgressListener((item) => {
        for (let i = 0; i < item.items.length; i++) {
            dispatch(addUploadItem(item.items[i]))
        }
    })
    useBatchAddListener((item) => {
        dispatch(setUploadPopup(true))
    })
}

const FolderSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7" y="9" width="14.7333" height="19.5556" rx="2" fill="#999999" />
            <rect x="7" y="12.6667" width="26" height="18.3333" rx="2" fill="white" />
        </svg>
    )
}

const MediaSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7" y="10" width="26" height="20" rx="2" fill="white" fillOpacity="0.2" />
            <path d="M24 20L17.25 24.3301L17.25 15.6699L24 20Z" fill="#242424" />
        </svg>
    )
}
const CloseIcon = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M11 1.00024L6 6.00009L11 10.9999" stroke="white" />
            <path d="M1 10.9998L6 5.99991L1 1.00006" stroke="white" />
        </svg>
    )
}
const FindIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="20" rx="10" fill="#3E3E3E" />
            <path d="M12.796 13.2144L15.0001 15.4185" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <circle cx="9.5" cy="9.5" r="4" stroke="white" />
        </svg>
    )
}
const DoneIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9.5L9 13.5L15.5 7" stroke="#14AE5C" />
            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#14AE5C" />
        </svg>
    )
}
