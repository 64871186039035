import { CardMedia } from '@mui/material'
import { formatDistance } from 'date-fns'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styles from './open-location.module.sass'
import { castingsSelector, getAllCastings } from '../../store/reducers/castings'
import ShareCastingPopup from '../../components/popups/share-casting/ShareCastingPopup'
import { useTranslation } from 'react-i18next'
import { getAllLocations, locationsSelector } from '../../store/reducers/locations'
import { pl } from 'date-fns/locale'
import ShareLocation from '../../components/popups/share-location/ShareLocation'


const OpenLocation = ({ navigate }) => {
    const { t } = useTranslation()
    const { locations } = useSelector(locationsSelector)
    // const [currentIdx, setCurrentIdx] = useState(0)

    const { id } = useParams()
    // useEffect(() => {
    //     try {
    //         const promise = resource
    //             .get(`${process.env.REACT_APP_BASE_URL}/api/casting-questions`)
    //             .then(({ data: { data, success } }) => {
    //                 if (success) setQuestions(data)
    //             })
    //     } catch (error) {
    //         alert(error)
    //     }
    // }, [])
    const dispatch = useDispatch()

    useEffect(() => {
        if (!locations.length) dispatch(getAllLocations())
    }, [])

    const currentLocation = locations?.find((obj) => obj.id === +id)
    console.log('currentLocation', currentLocation)
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.flexCenter}>
                    <svg
                        onClick={() => navigate(-1)}
                        width="12"
                        height="22"
                        style={{ marginRight: '10px' }}
                        viewBox="0 0 12 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </svg>
                    <Link to={`/locations`} className={styles.header__link}>
                        {t('all_locations')}
                    </Link>
                    <Separator />
                    {currentLocation?.title ?? ''}
                </div>
                <div className={styles.header__btns}>
                    <a href={`/location-quiz/${id}`} target="_blank">
                        {t('preview')}
                    </a>
                    <ShareLocation type={1} item={currentLocation} />
                </div>
            </div>

            {currentLocation?.answers?.length ? (
                <div className={styles.flexRow}>
                    {currentLocation?.answers?.map((item, idx) => {
                        return (
                            <div
                                onDoubleClick={() => navigate(`/location/${id}/${item.id}`)}
                                key={item.id}
                                className={styles.casting_item}
                            >
                                <CardMedia
                                    sx={{ height: 140, borderRadius: '8px 8px 0px 0px' }}
                                    image={item.photo}
                                    title={item.name}
                                />
                                <div className={styles.casting_item__content}>
                                    <div>{item.parcipiant_name}</div>
                                    <div>{item.description}</div>
                                    <div>
                                        <span variant="body2" color="text.secondary">
                                            {`${t('send')} ${formatDistance(new Date(), new Date(item.updated_at), {
                                                includeSeconds: true,
                                                locale: pl,
                                            })} ${t('ago')}`}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            ) : (
                <div className={styles.empty}>
                    <ShareLocation  type={2} item={currentLocation} />
                </div>
            )}
        </div>
    )
}

export default OpenLocation

const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}
