import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, TextField } from '@mui/material'
import styles from './restore-password.module.sass'
import { useDispatch } from 'react-redux'
import { setPassword } from '../../store/reducers/auth'
import { useParams } from 'react-router-dom'
import CustomTextField from '../../components/custom-text-field/CustomTextField'
import { PinkBigLogo } from '../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'

export default function NewPassword({ navigate }) {
    // const loading = useSelector(authLoading)
    const { t } = useTranslation()
    const { token, email } = useParams()
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            token: token,
            email: email,
            password: '',
            confirmed: '',
        },
        validationSchema: Yup.object().shape({
            password: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&)_#^(=+|`:;'",./-])[A-Za-z\d@$!%*?&)_#^(=+|`:;'",./-]{8,}$/,
                    'Password should be a minimum of 8 characters with at least one uppercase, one number, and one special character'
                )
                .required('Password is a required field'),
            confirmed: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required(t('confirm_pass')),
        }),
        onSubmit: (values, { setSubmitting }) => {
            dispatch(setPassword({ values, navigate, setSubmitting, token }))
        },
    })
    return (
        <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
            <div>
                <PinkBigLogo />
                <h1 className={styles.title}>{t("restore_pass")}</h1>
                <div className={styles.fields}>
                    {/* <TextField
                        id="password"
                        name="password"
                        label="Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        type="password"
                        onBlur={() => formik.setFieldTouched('password')}
                    /> */}

                    <CustomTextField name="password" formik={formik} type="password" placeholder={t("password")} />
                    <CustomTextField
                        name="confirmed"
                        formik={formik}
                        type="password"
                        placeholder="Confirmed password"
                    />
                    {/* <TextField
                        id="confirmed"
                        name="confirmed"
                        label="Confirmed password"
                        value={formik.values.confirmed}
                        onChange={formik.handleChange}
                        error={formik.touched.confirmed && Boolean(formik.errors.confirmed)}
                        helperText={formik.touched.confirmed && formik.errors.confirmed}
                        type="password"
                        onBlur={() => formik.setFieldTouched('confirmed')}
                    /> */}

                    <Button variant="contained" type="submit" disabled={!formik.isValid}>
                        {t('restore_pass')}
                    </Button>
                </div>
            </div>
        </form>
    )
}
