import { Menu, MenuItem } from '@mui/material'
import { formatDistance } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { pl } from 'date-fns/locale'
import styles from './shared-with-me.module.sass'
import { resource } from '../../api/http'
import SettingsAvatar from '../../components/avatar/SettingsAvatar'
import { useTranslation } from 'react-i18next'

const SharedWithMe = ({ navigate }) => {
    const { t } = useTranslation()
    const sortFilters = [t('last_edit'), t('last_added'), 'a-z']

    const [items, setItems] = useState([])
    const [sort, setSort] = useState(sortFilters[0])

    useEffect(() => {
        try {
            const promise = resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/project/project-invited-to-me`)
                .then(({ data: { data, success } }) => {
                    if (success) setItems(data)
                })

            //     const promise = resource.get(`${process.env.REACT_APP_BASE_URL}/api/project/shared-with-me`).then(({ data }) => {
            //         if (data) setItems(data)
        } catch (error) {
            alert(error)
        }
    }, [])

    const [anchorSort, setAnchorSort] = React.useState(false)
    const handleCloseSortMenu = () => {
        setAnchorSort(null)
    }

    let filteredArray = items.map((e) => e)

    if (sort === t('last_edit')) {
        filteredArray.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    }
    if (sort === t('last_added')) {
        filteredArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
    if (sort === 'a-z') {
        filteredArray.sort((a, b) => a.name.localeCompare(b.name))
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.sort}>
                <div>{t('sort')} </div>
                <div className={styles.sort__filter} onClick={(e) => setAnchorSort(e.currentTarget)}>
                    {sort}
                    <svg
                        // onClick={(e) => setAnchorSort(e.currentTarget)}
                        width="8"
                        height="5"
                        viewBox="0 0 8 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                    </svg>
                </div>

                <Menu
                    // sx={{ mt: '45px' }}
                    id={`Sort`}
                    anchorEl={anchorSort}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        ul: {
                            background: '#1F1F1F',
                            color: 'gray',
                            borderRadius: '5px',
                            padding: 0,
                            width: 145,
                        },
                        top: 15,
                        borderRadius: '5px',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorSort)}
                    onClose={handleCloseSortMenu}
                >
                    {sortFilters.map((item, idx) => {
                        return (
                            <MenuItem
                                key={idx}
                                sx={{
                                    padding: '11px 0px 10px 10px',
                                    background: sort === item ? '#292929' : '#1F1F1F',
                                    color: sort === item ? '#fff' : 'null',
                                    fontFamily: 'Zeitung Micro Pro',
                                    fontSize: 12,
                                    borderRadius: 5,
                                    '&:hover': { color: '#fff', background: '#292929' },
                                }}
                                onClick={() => {
                                    setSort(item)
                                    handleCloseSortMenu()
                                }}
                            >
                                <div>{item}</div>
                            </MenuItem>
                        )
                    })}
                </Menu>

                <span className={styles.project__countProjects}>
                    {/* {filtredProjectsMemo.length ?? ''} items, 29.3 GB */}
                </span>
            </div>
            <div className={styles.itemsWrapper}>
                {filteredArray?.map((item) => {
                    return <Item item={item} key={item.id} navigate={navigate} />
                })}
            </div>
        </div>
    )
}

export default SharedWithMe

const Item = ({ item, to, selected, navigate, setId, deckFlow, setEditPopup, editPopup }) => {
    const { t } = useTranslation()
    return (
        <>
            <div
                onDoubleClick={() => {
                    navigate(`/project/${item.id}`)
                }}
                // onClick={(e) => setId(item.id)}
                key={item.id}
                className={styles.projectItem}
            >
                <div
                    style={{
                        width: 307,
                        backgroundColor: selected ? '#D7E7FF' : '#F8F8F8',
                        borderRadius: 10,
                        boxShadow: 'none',
                        border: selected ? '2px solid #3375A3' : ' 1px solid rgba(0, 0, 0, 0.2)',
                        img: { display: 'none' },
                    }}
                >
                    <div>
                        <img src={item.thumb} className={selected ? styles.thumb__selected : styles.thumb} />
                    </div>
                    <div className={styles.CardContent}>
                        <div className={styles.row}>
                            <div>
                                <div className={styles.name}>{item.name}</div>
                                <div className={styles.editTime}>
                                    <div>
                                        <div className={styles.subName}>{item.description}</div>
                                        <div className={styles.subName}>
                                            {`${t('edited')}
                                        ${formatDistance(new Date(), new Date(item.updated_at), {
                                            includeSeconds: true,
                                            locale: pl,
                                        })}
                                        ${t('ago')}`}
                                        </div>
                                    </div>
                                    {/* {!deckFlow && selected && <Dots id={item.id} item={item} setEditPopup={setEditPopup} />} */}
                                </div>
                            </div>
                            <div>
                                {!deckFlow && !selected && (
                                    <div className={styles.members__wrap}>
                                        <div className={styles.members}>
                                            {item?.teamMembers?.map((e, idx) => {
                                                const margin = idx * 15 + 'px'
                                                return <SettingsAvatar key={e.id} name={e.name} margin={margin} />
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {selected && <EditProjectPopup openState={editPopup} item={item} close={() => setEditPopup(false)} />} */}
            {/* ^^^^^ for render only one popup */}
        </>
    )
}
