import { useState } from 'react'
import styles from './mansory.module.sass'
import { Masonry } from '@mui/lab'
import ReactPlayer from 'react-player'
import FullScreenPopup from '../deck-carusel-shared/components/FullScreenPopup'

const MansoryView = ({ files }) => {
    const [selectedId, setSelectedId] = useState(0)
    return (
        <div className={styles.wrapper}>
            <Masonry columns={4} spacing={2}>
                {files?.map((item) => (
                    <div key={item.id} className={selectedId === item.id ? styles.selected : styles.relative}>
                        {item.type === 'video' ? (
                            <VideoItem
                                item={item}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setSelectedId(item.id)
                                }}
                            />
                        ) : (
                            <ImageItem
                                item={item}
                                onClick={(e) => {
                                    // e.preventDefault()
                                    setSelectedId(item.id)
                                }}
                            />
                        )}
                    </div>
                ))}
            </Masonry>
        </div>
    )
}

const ImageItem = ({ item, onClick }) => {
    const [fullScreen, setfullScreen] = useState(false)
    return (
        <div onClick={onClick} onDoubleClick={() => setfullScreen(true)}>
            <img
                src={`${item?.preview}`}
                alt={item?.name}
                style={{
                    borderRadius: 5,
                    display: 'block',
                    width: '100%',
                    color: 'black',
                }}
            />
            <ItemButtons item={item} fullScreen={fullScreen} setfullScreen={setfullScreen} />
        </div>
    )
}
const VideoItem = ({ item, onClick }) => {
    const [fullScreen, setfullScreen] = useState(false)
    return (
        <div onClick={onClick}>
            <ReactPlayer url={item?.source} controls width={'100%'} height={''} />

            <ItemButtons item={item} fullScreen={fullScreen} setfullScreen={setfullScreen} video />
        </div>
    )
}
export default MansoryView
const DownloadFileSvg = ({ onClick }) => {
    return (
        <svg
            opacity={0.5}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path d="M15 6L15 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path
                d="M11.1111 13.7142L15 18.4285L18.8889 13.7142M7 14.9999V21.9999H23V14.9999"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export const ItemButtons = ({ item, fullScreen, setfullScreen, onClick, video }) => {
    return (
        <div className={styles.btnGroup}>
            <a href={item.source} download style={{ height: 30 }}>
                <DownloadFileSvg />
            </a>

            {/* {video ? (
                ''
            ) : (
                <FullScreenSvg
                    onClick={() => {
                        setfullScreen(true)
                    }}
                />
            )} */}

            {fullScreen && <FullScreenPopup onClick item={item} setfullScreen={setfullScreen} />}
        </div>
    )
}
const FullScreenSvg = ({ onClick }) => {
    return (
        <svg
            opacity={0.5}
            onClick={onClick}
            style={{ marginLeft: 8 }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.66667 2.49988H4.16667C3.72464 2.49988 3.30072 2.67547 2.98816 2.98803C2.67559 3.30059 2.5 3.72452 2.5 4.16654V6.66655M17.5 6.66655V4.16654C17.5 3.72452 17.3244 3.30059 17.0118 2.98803C16.6993 2.67547 16.2754 2.49988 15.8333 2.49988H13.3333M13.3333 17.4999H15.8333C16.2754 17.4999 16.6993 17.3243 17.0118 17.0117C17.3244 16.6992 17.5 16.2752 17.5 15.8332V13.3332M2.5 13.3332V15.8332C2.5 16.2752 2.67559 16.6992 2.98816 17.0117C3.30072 17.3243 3.72464 17.4999 4.16667 17.4999H6.66667"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
