import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from './location-quiz.module.sass'
import { resource } from '../../api/http'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import General from './components/General'
// import Opportunity from './components/Opportunity'
import { useTranslation } from 'react-i18next'
import { getAllLocations, sendLocationsAnswers } from '../../store/reducers/locations'
import Description from './components/Description'
import Media from './components/Media'

const LocationQuiz = ({ navigate }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [currentQuiz, setCurrentQuiz] = useState([])

    const { title, terms, thumb, video_instructions, description, questions } = currentQuiz

    useEffect(() => {
        if (!currentQuiz) dispatch(getAllLocations())
        try {
            resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/locations/${id}`)
                .then(({ data: { data, success, message } }) => {
                    if (success) setCurrentQuiz(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])
    const dispatch = useDispatch()

    const dropItems = [
        { name: `1. ${t('location_quiz_description')}`, text: description },
        { name: `2. ${t('location_quiz_terms')}`, text: terms },
        { name: `2. ${t('location_quiz_instruction')}`, text: video_instructions },
    ]

    const formik = useFormik({
        initialValues: { photo: false, video: false, others: false, confirm: false },
        validationSchema: Yup.object().shape({
            confirm: Yup.boolean().oneOf([true], 'Please confirm the field'),
        }),

        onSubmit: (values) => {
            // let array = values
            let array = Object.assign({}, values)
            delete array['confirm'] //remove confirm field before request
            delete array['photo']
            delete array['video']
            delete array['others']
            const answers = Object.keys(array).map((value) => {
                return { question_id: value, answer: values[value] }
            })
            const fd = new FormData()
            fd.append('location_id', +id)
            fd.append('answers', JSON.stringify(answers))
            fd.append('photo', values['photo'])
            fd.append('video', values['video'])
            fd.append('others', values['others'])
            dispatch(sendLocationsAnswers({ fd: fd, resetForm: formik.resetForm }))
        },
        validateOnMount: true,
    })

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <img src={thumb} alt="thumb" />
            </div>

            <div className={styles.content}>
                <div className={styles.column}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.info}>
                        {dropItems.map((e) => (
                            <Drop text={e.text} name={e.name} />
                        ))}
                    </div>
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <General formik={formik} />
                        <Description formik={formik} />
                        <Media formik={formik} />
                        <div className={styles.footer}>
                            <div className={styles.footer__terms}>
                                <ul>
                                    <li>
                                        Potwierdzam, że zostałem zapoznany przed poszukiwaniami lokacji z briefem
                                        lokacyjnym, w tym z warunkami finansowymi za wynajem lokacji oraz udzielenie
                                        praw i je akceptuję. Potwierdzam, że wszystkie informacje podane powyżej są
                                        pełne i zgodne ze stanem faktycznym. Zobowiązuję się do zachowania w tajemnicy
                                        wszelkich wrażliwych informacji uzyskanych w związku z udziałem w poszukiwaniu
                                        lokacji. Zapoznałam/em się z informacjami o przetwarzaniu danych osobowych przez
                                        administratora Skicka.co, znajdującymi się pod adresem:{' '}
                                        <a href="http://skicka.co/legal">http://skicka.co/legal</a> w szczególności z
                                        celem przetwarzania w postaci realizacji poszukiwania lokacji i wyboru lokacji
                                        dokonywanego przez administratora, Agencję reklamową i Klienta.
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.footer__check}>
                                <Confirm
                                    checked={formik.values['confirm']}
                                    onClick={() => formik.setFieldValue('confirm', !formik.values['confirm'])}
                                />
                                <span>{t('casting_confirm')}</span>
                            </div>
                            <button type="submit" disabled={formik.isValid ? false : true}>
                                {t('send_castin_location')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default LocationQuiz

const Drop = ({ name, text }) => {
    const [open, setOpen] = useState(false)

    return (
        <div className={styles.info__item} onClick={() => setOpen(!open)}>
            <div className={open ? styles.info__item__active : styles.info__item__noActive}>
                {name} <Chevron isOpen={open} />
            </div>

            {open && <div className={styles.info__drop}>{text}</div>}
        </div>
    )
}

const Chevron = ({ isOpen }) => (
    <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}
    >
        <path d="M1 1L7 7L13 1" stroke="black" />
    </svg>
)
const Confirm = ({ checked, onClick }) => {
    return checked ? (
        <svg
            onClick={onClick}
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13.5" cy="13" r="12.5" fill="#D7E7FF" stroke="#3376A3" />
            <path d="M7.5 11.7222L12.0517 16L19.5 9" stroke="#3376A3" strokeWidth="2" />
        </svg>
    ) : (
        <svg
            onClick={onClick}
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13" cy="13" r="12.5" stroke="#3376A3" />
        </svg>
    )
}