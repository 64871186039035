import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './sign-in.module.sass'
import { useDispatch } from 'react-redux'
import { login } from '../../store/reducers/auth'
import { Link } from 'react-router-dom'
import CustomTextField from '../../components/custom-text-field/CustomTextField'
import { PinkBigLogo } from '../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'
import banner from '../../assets/img/about-banner.jpg'

const SignUp = ({ navigate }) => {
    // const loading = useSelector(authLoading)  //TODO loader and error statgoogle123
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email address').required(t('req_email')),
            password: Yup.string().min(8, 'Minimum of 8 characters').required('Password is a required field'),
        }),
        onSubmit: (values, { setFieldError, setErrors }) => {
            dispatch(login({ values, navigate, setFieldError, setErrors }))
        },
        validateOnMount: true,
    })
    // console.log('formik', formik)
    return (
        <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
            {/* <Logo /> */}
            <div>
                <PinkBigLogo />
                <h1 className={styles.title}> {t('login')}</h1>
                <div className={styles.fields}>
                    <CustomTextField name="email" formik={formik} placeholder="Email" autocomplete={false} />
                    <CustomTextField name="password" formik={formik} type="password" placeholder={t('password')} />
                </div>

                <button type="submit" disabled={!formik.isValid}>
                    {t('log_in')}
                </button>
                {/* <span>Or sign up with:</span> */}

                <div className={styles.links}>
                    <Link to="/sign-up">{t('sign_up')}</Link>
                    <Link to="/restore-password">{t('forgot_pass')}</Link>
                </div>
            </div>
            <img className={styles.banner} src={banner} alt="banner" />
        </form>
    )
}

export default SignUp
