import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../casting-quiz.module.sass'
import { resource } from '../../../api/http'
import { getAllCastings } from '../../../store/reducers/castings'
import { useTranslation } from 'react-i18next'

const Sizes = ({ formik }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [currentQuiz, setCurrentQuiz] = useState([])
    const { questions } = currentQuiz
    const sizesIds = [8, 9, 11, 13, 12, 14, 15]

    useEffect(() => {
        if (!currentQuiz) dispatch(getAllCastings())
        try {
            resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/castings/${id}`)
                .then(({ data: { data, success, message } }) => {
                    if (success) setCurrentQuiz(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])
    const dispatch = useDispatch()

    const inclusesQuestion = (id) => {
        var found = questions?.find((e) => e.id === id)
        return found !== -1 ? found : false
    }
    const showComponent = () => {
        var found = false

        for (let i = 0; i < sizesIds.length; i++) {
            const temp = inclusesQuestion(sizesIds[i])

            if (temp) {
                // console.log('temp', temp)
                found = true
                return true
            }
        }
        return found
    }
    return showComponent() ? (
        <div>
            <div>
                <div className={styles.subTitle}>
                    <div>{t("sizes")}</div>
                </div>

                <div>
                    {sizesIds?.map((e) => {
                        const findItem = inclusesQuestion(e)
                        // console.log(item)
                        return findItem ? (
                            <div className={styles.question} key={findItem?.id}>
                                <span>{t(findItem?.name)}</span>

                                <input
                                    type="text"
                                    name={`${findItem?.id}`}
                                    value={formik.values[`${findItem?.id}`]}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        ) : null
                    })}
                </div>
            </div>
        </div>
    ) : null
}

export default Sizes
