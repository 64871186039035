import React, { useState } from 'react'
import styles from '../deck-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { useDrag, useDrop } from 'react-dnd'
import { decksSelector, setCurrentDeck } from '../../../store/reducers/decks'
import { ItemTypes } from '../../../assets/helrers/ItemTypes'
import { resource } from '../../../api/http'
import { useParams } from 'react-router-dom'
import DeckFileMenu from './Menu'

const FilesMap = ({}) => {
    const dispatch = useDispatch()
    const [selectedItem, changeItem] = useState(0)
    const { currentDeck } = useSelector(decksSelector)
    const { files } = currentDeck
    const { deckId } = useParams()

    const moveItem = (dragIndex, hoverIndex) => {
        // console.log(`dragIndex : ${dragIndex}, hoverIndex : ${hoverIndex}`)
        const draggedItem = files[dragIndex]
        const remainingItems = files.filter((_, index) => index !== dragIndex)
        const updatedItems = [...remainingItems.slice(0, hoverIndex), draggedItem, ...remainingItems.slice(hoverIndex)]

        const arrayWithIds = updatedItems.map((e, idx) => {
            return { id: e.id, priority: idx }
        })
        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/decks/files/priority/${deckId}`, {
                arrayWithIds: arrayWithIds,
            })
            .then(({ data: { data, success, message } }) => {
                if (success) {
                    currentDeck.files = data
                    dispatch(setCurrentDeck(currentDeck))
                }
            })
    }
    return files?.map((item, idx) => {
        return (
            <DraggableItem
                index={idx}
                item={item}
                selectedItem={selectedItem}
                changeItem={changeItem}
                dispatch={dispatch}
                moveItem={moveItem}
                id={item.id}
            />
        )
    })
}
export default FilesMap

const DraggableItem = ({ index, moveItem, item, selectedItem, changeItem }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ItemTypes.DECK_FILE,
        item: () => {
            return { selectedIndex: index }
        },

        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [, dropRef] = useDrop({
        accept: ItemTypes.DECK_FILE,
        drop: (item) => {
            const dragIndex = item.selectedIndex
            const hoverIndex = index
            // if (dragIndex === hoverIndex) {
            //     return
            // }

            moveItem(dragIndex, hoverIndex)

            item.index = hoverIndex
        },
    })

    const opacity = isDragging ? 0.5 : 1

    return (
        <div
            ref={(node) => dragRef(dropRef(node))}
            style={{ opacity }}
            className={selectedItem === item.id ? styles.content__item__selected : styles.content__item}
            key={item.id}
            onClick={() => changeItem(item.id)}
            draggable
        >
            <img src={item.preview} alt="preview" />
            <div className={styles.content__footer}>
                <div>
                    <span>{index + 1}</span>
                    {item.title ?? 'No tittle'}
                </div>
                {selectedItem === item.id && <DeckFileMenu item={item} />}
            </div>
        </div>
    )
}
