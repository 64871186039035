import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../casting-quiz.module.sass'
import { resource } from '../../../api/http'
import { getAllCastings } from '../../../store/reducers/castings'
import { useTranslation } from 'react-i18next'

const Opportunity = ({ formik }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [currentQuiz, setCurrentQuiz] = useState([])
    const { questions } = currentQuiz
    const opportunitesIds = [16, 17, 18, 19]

    useEffect(() => {
        if (!currentQuiz) dispatch(getAllCastings())
        try {
            resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/castings/${id}`)
                .then(({ data: { data, success, message } }) => {
                    if (success) setCurrentQuiz(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])

    const dispatch = useDispatch()
    const inclusesQuestion = (id) => {
        var found = questions?.find((e) => e.id === id)
        return found !== -1 ? found : false
    }
    const showComponent = () => {
        var found = false

        for (let i = 0; i < opportunitesIds.length; i++) {
            const temp = inclusesQuestion(opportunitesIds[i])

            if (temp) {
                found = true
                return true
            }
        }
        return found
    }
    const photoRef = useRef(null)
    const videoInstructionRef = useRef(null)
    const cardRef = useRef(null)
    const sceneRef = useRef(null)
    return (
        <div>
            <div>
                <div className={styles.subTitle}>
                    <div>{t('opportunity')}</div>
                </div>

                <div>
                    {opportunitesIds?.map((e) => {
                        const findItem = inclusesQuestion(e)
                        // console.log(item)
                        return findItem ? (
                            <div className={styles.question} key={findItem?.id}>
                                <span>{t(findItem?.name)}</span>

                                <input
                                    type="text"
                                    name={`${findItem?.id}`}
                                    value={formik.values[`${findItem?.id}`]}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        ) : null
                    })}
                </div>
                <div>
                    {[inclusesQuestion(20)].map((item) => {
                        const findItem = inclusesQuestion(20)
                        return findItem ? (
                            <div className={styles.media} key={item?.id}>
                                <span>{t('add_photo')}</span>
                                <input
                                    ref={photoRef}
                                    accept="image/*"
                                    id="photo"
                                    name="photo"
                                    type="file"
                                    onChange={(event) => {
                                        // setLogo(event.currentTarget.files[0] ?? false)
                                        formik.setFieldValue('photo', event.currentTarget.files[0])
                                    }}
                                    className="form-control"
                                />
                                <div className={styles.media__wrapper}>
                                    {formik.values['photo'] && (
                                        <img src={URL.createObjectURL(formik.values['photo'])} />
                                    )}
                                    <button type="button" onClick={() => photoRef.current.click()}>
                                        {t('upload')}
                                    </button>
                                </div>
                                <div className={styles.media__description}>{t('casting_quiz_portrait')}</div>
                            </div>
                        ) : null
                    })}

                    <div className={styles.media}>
                        <span>{t('add_video_introduction')}</span>

                        <input
                            ref={videoInstructionRef}
                            accept="video/mp4,video/x-m4v,video/*"
                            id="video"
                            name="video"
                            type="file"
                            onChange={(event) => {
                                // setLogo(event.currentTarget.files[0] ?? false)
                                formik.setFieldValue('video', event.currentTarget.files[0])
                            }}
                            className="form-control"
                        />
                        <div className={styles.media__wrapper}>
                            {formik.values['video'] && <video src={URL.createObjectURL(formik.values['video'])} />}
                            <button type="button" onClick={() => videoInstructionRef.current.click()}>
                                {t('upload')}
                            </button>
                        </div>
                        <div className={styles.media__description}>{t('casting_quiz_card')}</div>
                    </div>

                    {[inclusesQuestion(22)].map((item) => {
                        const findItem = inclusesQuestion(22)
                        return findItem ? (
                            <div className={styles.media} key={item?.id}>
                                <span>{t('send_casting_card')} </span>

                                <input
                                    ref={cardRef}
                                    accept="image/*"
                                    id="card"
                                    name="card"
                                    type="file"
                                    onChange={(event) => {
                                        // setLogo(event.currentTarget.files[0] ?? false)
                                        formik.setFieldValue('card', event.currentTarget.files[0])
                                    }}
                                    className="form-control"
                                />
                                <div className={styles.media__wrapper}>
                                    {formik.values['card'] && <img src={URL.createObjectURL(formik.values['card'])} />}
                                    <button type="button" onClick={() => cardRef.current.click()}>
                                        {t('upload')}
                                    </button>
                                </div>
                                <div className={styles.media__description}>{t('complited_casting_card')}</div>
                            </div>
                        ) : null
                    })}

                    {[inclusesQuestion(21)].map((item) => {
                        const findItem = inclusesQuestion(21)
                        return findItem ? (
                            <div className={styles.media} key={item?.id}>
                                <span>{t('send_video_scene')}</span>

                                <input
                                    ref={sceneRef}
                                    accept="video/mp4,video/x-m4v,video/*"
                                    id="scene"
                                    name="scene"
                                    type="file"
                                    onChange={(event) => {
                                        // setLogo(event.currentTarget.files[0] ?? false)
                                        formik.setFieldValue('scene', event.currentTarget.files[0])
                                    }}
                                    className="form-control"
                                />
                                <div className={styles.media__wrapper}>
                                    {formik.values['scene'] && (
                                        <video src={URL.createObjectURL(formik.values['scene'])} />
                                    )}
                                    <button type="button" onClick={() => sceneRef.current.click()}>
                                        {t('upload')}
                                    </button>
                                </div>
                                <div className={styles.media__description}>{t('record_video_scene')}</div>
                            </div>
                        ) : null
                    })}
                </div>
            </div>
        </div>
    )
}

export default Opportunity
