import * as React from 'react'
import styles from './styles.module.sass'
import ReactPlayer from 'react-player'
import { IconButton } from '@mui/material'

const FullScreenPopup = ({ item, setfullScreen }) => {
    const currentItem =
        item?.type === 'video' ? (
            <div className={styles.itemVideo} key={item.id}>
                <div>
                    <ReactPlayer url={item?.source} controls />
                    <section>
                        <div className={styles.btnGroup}>
                            <a href={item.source} download style={{ height: 30 }}>
                                <DownloadFileSvg />
                            </a>
                            {/* <FullScreenSvg onClick={() => setfullScreen(false)} /> */}
                        </div>
                    </section>
                </div>
            </div>
        ) : (
            <div className={styles.itemImage} key={item.id}>
                <div>
                    <img src={item.source} className={styles.media} alt="1" />
                    <div className={styles.btnGroup}>
                        <a href={item.source} download style={{ height: 30 }}>
                            <DownloadFileSvg />
                        </a>
                        {/* <FullScreenSvg onClick={() => setfullScreen(false)} /> */}
                    </div>
                </div>
            </div>
        )

    return (
        <div className={styles.wrapper}>
            {currentItem}

            <IconButton
                aria-label="close"
                onClick={() => setfullScreen(false)}
                sx={{
                    position: 'fixed',
                    right: 30,
                    top: 20,
                }}
            >
                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 1.95703L21 21.4015"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M1 21.9579L21 2.51344"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </IconButton>
        </div>
    )
}
export default FullScreenPopup
const DownloadFileSvg = ({ onClick }) => {
    return (
        <svg
            opacity={0.5}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path d="M15 6L15 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path
                d="M11.1111 13.7142L15 18.4285L18.8889 13.7142M7 14.9999V21.9999H23V14.9999"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
const FullScreenSvg = ({ onClick }) => {
    return (
        <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M11.6667 7.72107H9.16667C8.72464 7.72107 8.30072 7.89666 7.98816 8.20922C7.67559 8.52179 7.5 8.94571 7.5 9.38774V11.8877M22.5 11.8877V9.38774C22.5 8.94571 22.3244 8.52179 22.0118 8.20922C21.6993 7.89666 21.2754 7.72107 20.8333 7.72107H18.3333M18.3333 22.7211H20.8333C21.2754 22.7211 21.6993 22.5455 22.0118 22.2329C22.3244 21.9204 22.5 21.4964 22.5 21.0544V18.5544M7.5 18.5544V21.0544C7.5 21.4964 7.67559 21.9204 7.98816 22.2329C8.30072 22.5455 8.72464 22.7211 9.16667 22.7211H11.6667"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                opacity={0.5}
                style={{ marginLeft: 8 }}
                onClick={onClick}
            />
        </svg>
    )
}
