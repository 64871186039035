import * as React from 'react'
import styles from './notification-styles.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar } from '@mui/material'
import { resource } from '../../api/http'
import { formatDistance } from 'date-fns'
import { notificationsSelector, setNotifications, setShowNotifications } from '../../store/reducers/notifications'
import { useEffect } from 'react'
import { setUploadPopup, showUploadings } from '../../store/reducers/uploads'
import { useTranslation } from 'react-i18next'
import { pl } from 'date-fns/locale'

const Notifications = ({ navigate, plus, button, open, setOpen }) => {
    const { t } = useTranslation()
    useEffect(() => {
        getNotifications()
        const id = setInterval(getNotifications, 10000)
        return () => clearInterval(id)
    }, [])
    const uploadingPopup = useSelector(showUploadings)

    const handleDragOver = (e) => {
        e.preventDefault()
        if (uploadingPopup) dispatch(setUploadPopup(!uploadingPopup))
        if (showNotificationsPopup) dispatch(setShowNotifications(false))
        // window.removeEventListener('dragover', handleDragOver)
    }

    const { showNotificationsPopup, notifications } = useSelector(notificationsSelector)
    window.addEventListener('dragover', handleDragOver)
    useEffect(() => {
        return () => {
            window.removeEventListener('dragover', handleDragOver)
        }
    }, [])

    const getNotifications = () =>
        resource.get(`${process.env.REACT_APP_BASE_URL}/api/user-notifications`).then(({ data: { notifications } }) => {
            // setNotifications(notifications)
            dispatch(setNotifications(notifications))
        })

    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(setShowNotifications(false))
    }

    const ReadNotification = (id) =>
        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/mark-as-read-notification`, { id: id })
            .then(({ data: { notifications } }) => {
                getNotifications()
            })

    return (
        <div className={open ? styles.overview : styles.overviewNone} onClick={handleClose}>
            <div
                className={styles.wrapper}
                onClick={(e) => {
                    // e.preventDefault()\
                    e.stopPropagation()
                }}
            >
                <div className={styles.title}>{t('notifications')}</div>

                <div className={styles.items}>
                    {notifications
                        ?.filter((e) => e.read_at == null)
                        .map((element, idx) => {
                            const {
                                data: { user, message, model, model_id },
                                created_at,
                            } = element
                            const { name } = user

                            const time = formatDistance(new Date(created_at), new Date(), {
                                addSuffix: true,
                                locale: pl,
                            })
                            return (
                                <div
                                    className={styles.item}
                                    key={idx}
                                    onClick={() => {
                                        if (model === 'project') {
                                            ReadNotification(element.id)
                                            handleClose()
                                            navigate(`project/${model_id}`)
                                        }
                                        if (model === 'file') {
                                            ReadNotification(element.id)
                                            handleClose()
                                            navigate(`/file/0/${model_id}`)
                                        }
                                        if (model === 'directory') {
                                            ReadNotification(element.id)
                                            handleClose()
                                            navigate(`/project/${model_id}`)
                                        }
                                    }}
                                >
                                    <div>
                                        <Avatar
                                            onClick={() => console.log()}
                                            sx={{
                                                bgcolor: '#FF7549',
                                                color: 'black',
                                                fontFamily: 'Zeitung Micro Pro',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                            }}
                                        >
                                            {name.slice(0, 1).toUpperCase()}
                                        </Avatar>
                                    </div>
                                    <div className={styles.item__text}>
                                        {message !== 'You have new shared project' ? <span>{`${name} `}</span> : null}
                                        <span>{`${message}`}</span>
                                        <div>{time}</div>
                                    </div>
                                    <div></div>
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
export default Notifications
