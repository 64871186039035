import styles from './project-header.module.sass'
import { Link, useParams } from 'react-router-dom'
import { projectsSelector } from '../../store/reducers/projects'
import { useSelector } from 'react-redux'
import CustomBreadcrumbs from '../breadcrumbs/CustomBreadcrumbs'
import { useRef, useState } from 'react'
import { useUploady } from '@rpldy/uploady'
import CreateFolderPopup from '../popups/create-folder'
import { decksSelector } from '../../store/reducers/decks'
import CreateShareLinkPopup from '../popups/create-share-link'
import { resource } from '../../api/http'
import { useTranslation } from 'react-i18next'

const ProjectHeader = ({ deckFlow, navigate, dowloadIds }) => {
    const { t } = useTranslation()
    let { id, deckId } = useParams()
    const [addFiles, setAddFiles] = useState(false)
    const [addFolderPopup, setFolderPopup] = useState(false)
    const fileInputRef = useRef(null)

    const { selectedFile } = useSelector(projectsSelector)
    const { currentDeck } = useSelector(decksSelector)
    const handleClick = () => {
        fileInputRef.current.click()
    }
    const { setOptions, upload } = useUploady()
    const token = localStorage.getItem('token')

    const handleFileChange = (event) => {
        setOptions({
            destination: {
                url: `${process.env.REACT_APP_BASE_URL}/api/projects/files/upload/${id}`,
                headers: { Authorization: `Bearer ${token}` },
            },
        })
        upload(event.target.files)
    }
    const handleDownload = () => {
        // const url = `${process.env.REACT_APP_BASE_URL}/api/download-files/${id}`
        // const link = document.createElement('a')
        // link.href = url
        // link.setAttribute('download', 'archive.zip')
        // document.body.appendChild(link)
        // link.click()
        // link.parentNode.removeChild(link)

        resource
            .post(
                `${process.env.REACT_APP_BASE_URL}/api/download-files-by-ids-array`,
                {
                    fileIdsArray: dowloadIds,
                },
                { responseType: 'blob' }
            )
            // .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(blob.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'archive.zip')

                // Append to html link element page
                document.body.appendChild(link)

                // Start download
                link.click()

                // Clean up and remove the link
                link.parentNode.removeChild(link)
            })
    }

    return (
        <>
            <div className={styles.header}>
                <div className={styles.flexCenter}>
                    <input
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        id="upload"
                        type="file"
                        onChange={handleFileChange}
                        multiple
                    />
                    <svg
                        onClick={() => navigate(-1)}
                        width="12"
                        height="22"
                        style={{ marginRight: '10px' }}
                        viewBox="0 0 12 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </svg>
                    {deckFlow ? (
                        <div className={styles.deckHeader}>
                            <DeckSvg />
                            {currentDeck?.name}
                            <Separator />
                        </div>
                    ) : (
                        <ThreeLines />
                    )}

                    <Link to={deckFlow ? `/deck-builder/${deckId}` : `/`}>{t('all_projects')}</Link>
                    <Separator />
                    <CustomBreadcrumbs deckFlow={deckFlow} deckId={deckId} />
                </div>

                {!deckFlow && (
                    <div className={styles.buttonGroup}>
                        <div>
                            {selectedFile && (
                                <>
                                    <Like /> {selectedFile.likesCount}
                                </>
                            )}
                        </div>
                        <CreateShareLinkPopup id={id} />
                        <button onClick={() => handleDownload()}>{t('download')}</button>
                        <button
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'baseline',
                                justifyContent: 'initial',
                            }}
                            onClick={() => {
                                setAddFiles(!addFiles)
                            }}
                        >
                            {t('new')}
                            <svg
                                style={{ marginLeft: 3 }}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M3 7.5L8 12.5L13 7.5" stroke="white" />
                            </svg>
                        </button>
                        {addFiles && (
                            <div
                                className={styles.addFiles__wrap}
                                onClick={() => {
                                    setAddFiles(!addFiles)
                                }}
                            >
                                <div className={styles.addFiles}>
                                    <div
                                        onClick={() => {
                                            setFolderPopup(true)
                                            setAddFiles(!addFiles)
                                        }}
                                    >
                                        {t('add_folder')}
                                        <AddFolder />
                                    </div>
                                    <div onClick={handleClick}>
                                        {t('add_file')} <AddFile />
                                    </div>
                                </div>
                            </div>
                        )}
                        <CreateFolderPopup isOpen={addFolderPopup} id={id} close={() => setFolderPopup(false)} />
                    </div>
                )}
            </div>
        </>
    )
}
export default ProjectHeader
const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}

const DeckSvg = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.975433 0.582245C1.03276 0.245079 1.30738 0 1.62786 0H11.3721C11.6926 0 11.9672 0.245079 12.0246 0.582245L12.9891 6.25497C13.0631 6.69024 12.7504 7.09091 12.3367 7.09091H0.663316C0.24959 7.09091 -0.0631222 6.69024 0.0108874 6.25497L0.975433 0.582245Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.18129 1.41818L1.45788 5.67273H11.5421L10.8187 1.41818H2.18129ZM1.62786 0C1.30738 0 1.03276 0.245079 0.975433 0.582245L0.0108874 6.25497C-0.0631222 6.69024 0.24959 7.09091 0.663316 7.09091H12.3367C12.7504 7.09091 13.0631 6.69024 12.9891 6.25497L12.0246 0.582245C11.9672 0.245079 11.6926 0 11.3721 0H1.62786Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.909 13L5.909 5.90906L7.09082 5.90906L7.09082 13L5.909 13Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5459 11.8181L9.45499 11.8181V12.9999H3.5459V11.8181Z"
                fill="black"
            />
        </svg>
    )
}
const ThreeLines = () => {
    return (
        <svg
            width="16"
            height="16"
            style={{ marginRight: '7px' }}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="3" y1="3" x2="13" y2="3" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="8" x2="13" y2="8" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="13" x2="13" y2="13" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}
const Like = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.2474 8.03718C20.508 5.55948 16.7337 7.84924 15.9438 11.4455C15.1543 7.84901 10.0456 5.40792 6.87304 8.57835C2.92446 12.5242 4.88795 20.4757 15.9438 26C27.7073 19.5338 29.3914 11.4455 24.2474 8.03718Z"
                fill="#E59EAD"
            />
        </svg>
    )
}
const AddFolder = () => {
    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="4" width="12.4667" height="16" rx="2" fill="#545454" />
            <rect y="9" width="22" height="13" rx="2" fill="#696969" />
            <circle cx="20" cy="6.125" r="5.5" fill="#696969" stroke="#1F1F1F" />
            <line x1="16.666" y1="6.29199" x2="23.3327" y2="6.29199" stroke="#1F1F1F" />
            <line x1="20.1665" y1="9.4585" x2="20.1665" y2="2.79183" stroke="#1F1F1F" />
        </svg>
    )
}
const AddFile = () => {
    return (
        <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="5" width="22" height="16" rx="2" fill="#696969" />
            <path d="M15.5 13.3301L8 17.6602L8 8.99995L15.5 13.3301Z" fill="#1F1F1F" />
            <circle cx="20" cy="6" r="5.5" fill="#696969" stroke="#1F1F1F" />
            <line x1="16.6665" y1="6.1665" x2="23.3332" y2="6.1665" stroke="#1F1F1F" />
            <line x1="20.1665" y1="9.33301" x2="20.1665" y2="2.66634" stroke="#1F1F1F" />
        </svg>
    )
}
