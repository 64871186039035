import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styles from './casting-quiz.module.sass'
import { resource } from '../../api/http'
import { getAllCastings, sendQuizAnswers } from '../../store/reducers/castings'
import { useFormik } from 'formik'

import * as Yup from 'yup'
import General from './components/General'
import Sizes from './components/Sizes'
import Opportunity from './components/Opportunity'
import { useTranslation } from 'react-i18next'
import { setLoading } from '../../store/reducers/popups'

const CastingQuiz = ({ navigate }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [currentQuiz, setCurrentQuiz] = useState([])

    // const [currentQuiz, setCurrentQuiz] = useState([])

    const { title, terms, thumb, video_instructions, description, questions } = currentQuiz
    // console.log('currentQuiz', currentQuiz)

    const generalIds = [3, 5, 7] //[1, 2, 3, 4, 5, 7]
    const sizesIds = [1, 2, 3, 5, 7]

    useEffect(() => {
        if (!currentQuiz) dispatch(getAllCastings())
        try {
            resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/castings/${id}`)
                .then(({ data: { data, success, message } }) => {
                    if (success) setCurrentQuiz(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])
    const dispatch = useDispatch()

    const dropItems = [
        { name: `1. ${t('character_description')}`, text: description },
        { name: `2. ${t('terms_rates_and_rights')}`, text: terms },
        { name: `2. ${t('video_instructions')}`, text: video_instructions },
    ]

    const formik = useFormik({
        initialValues: { photo: false, video: false, card: false, scene: false, confirm: false },
        validationSchema: Yup.object().shape({
            confirm: Yup.boolean().oneOf([true], 'Please confirm the field'),
        }),

        onSubmit: (values) => {
            // let array = values
            let array = Object.assign({}, values)
            delete array['confirm'] //remove confirm field before request
            delete array['photo']
            delete array['video']
            delete array['card']
            delete array['scene']
            const answers = Object.keys(array).map((value) => {
                return { question_id: value, answer: values[value] }
            })
            const fd = new FormData()
            fd.append('casting_id', +id)
            fd.append('answers', JSON.stringify(answers))
            fd.append('photo', values['photo'])
            fd.append('video', values['video'])
            fd.append('card', values['card'])
            fd.append('scene', values['scene'])
            dispatch(
                sendQuizAnswers({
                    fd: fd,
                    resetForm: () =>
                        formik.setValues({
                            1: '',
                            2: '',
                            3: '',
                            4: '',
                            5: '',
                            6: '',
                            7: '',
                            8: '',
                            9: '',
                            10: '',
                            11: '',
                            12: '',
                            13: '',
                            14: '',
                            15: '',
                            16: '',
                            17: '',
                            18: '',
                            19: '',
                        }),
                })
            )
        },
        validateOnMount: true,
    })
    console.log(123, 'formik.', formik)

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <img src={thumb} alt="thumb" />
            </div>
            <div className={styles.content}>
                <div className={styles.column}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.info}>
                        {dropItems.map((e) => (
                            <Drop text={e.text} name={e.name} />
                        ))}
                    </div>
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <General formik={formik} />
                        <Sizes formik={formik} />
                        <Opportunity formik={formik} />
                        <div className={styles.footer}>
                            <div className={styles.footer__terms}>
                                <ul>
                                    <li>
                                        Potwierdzam, że zostałem zapoznany przed castingiem z briefem castingowym, w tym
                                        ze stawkami za udział w produkcji oraz udzielenie praw i je akceptuję; Wyrażam
                                        chęć udziału w ww. kampanii reklamowej , na przedstawionych warunkach, co
                                        stanowi ofertę w rozumieniu art. 66 kodeksu cywilnego i wiąże mnie, w przypadku
                                        decyzji Producenta o zaangażowaniu mnie do udziału w kampanii; Jestem świadom
                                        przystąpienia do ww. projektu w warunkach epidemii i akceptuję ryzyka z tym
                                        związane; Zgadzam się na udział w scenach aktorskich bez stosowania środków
                                        ochrony osobistej (zwłaszcza maseczki) oraz w dystansie poniżej 1,5 m;
                                        Potwierdzam, że wszystkie informacje podane powyżej są pełne i zgodne ze stanem
                                        faktycznym; Zobowiązuję się do zachowania w tajemnicy wszelkich wrażliwych
                                        informacji uzyskanych w związku z udziałem w castingu ( m.in . informacji
                                        marketingowych, scenariuszy, obsady, reklamowanego produktu i marki);
                                        Zobowiązuję się nie zmieniać swojego wyglądu w stosunku do wyglądu z dnia
                                        castingu (zwłaszcza fryzury, zarostu, koloru włosów itp.) do dnia zakończenia
                                        zdjęć; Przed złożeniem podpisu zapoznałem się z informacjami o przetwarzaniu
                                        danych osobowych przez administratora Skicka.co , znajdującymi się pod adresem:
                                        http://skicka.co/legal , w szczególności z celem przetwarzania w postaci
                                        realizacji castingu i wyboru obsady dokonywanego przez administratora, Agencję
                                        reklamową i Klienta.
                                    </li>
                                    {/* <li>
                                        potwierdzam, że{' '}
                                        <span style={{ fontWeight: 'bold' }}>
                                            zostałem zapoznany przed castingiem z briefem castingowym, w tym ze stawkami
                                        </span>
                                        za udział w produkcji oraz udzielenie praw i je akceptuję;
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 'bold' }}>
                                            wyrażam chęć udziału w ww. kampanii reklamowej,{' '}
                                        </span>{' '}
                                        na przedstawionych warunkach, co stanowi ofertę w rozumieniu art. 66 kodeksu
                                        cywilnego i wiąże mnie, w przypadku decyzji Producenta o zaangażowaniu mnie do
                                        udziału w kampanii;{' '}
                                    </li>
                                    <li>
                                        jestem świadom przystąpienia do ww. projektu w warunkach epidemii i akceptuję
                                        ryzyka z tym związane;{' '}
                                    </li>
                                    <li>
                                        potwierdzam, że wszystkie informacje podane powyżej są pełne i zgodne ze stanem
                                        faktycznym
                                    </li>
                                    <li>
                                        <span style={{ fontWeight: 'bold' }}>
                                            zobowiązuję się do zachowania w tajemnicy wszelkich wrażliwych informacji
                                            uzyskanych w związku z udziałem w castingu
                                        </span>
                                        m.in. informacji marketingowych, scenariuszy, obsady, reklamowanego produktu i
                                        marki;
                                    </li>
                                    <li>
                                        zobowiązuję się{' '}
                                        <span style={{ fontWeight: 'bold' }}>nie zmieniać swojego wyglądu</span>
                                    </li>
                                    w stosunku do wyglądu z dnia castingu (zwłaszcza fryzury, zarostu, koloru włosów
                                    itp.) do dnia zakończenia zdjęć;
                                    <li>
                                        przed złożeniem podpisu
                                        <span style={{ fontWeight: 'bold' }}>
                                            {' '}
                                            zapoznałem się z informacjami o przetwarzaniu danych osobowych
                                        </span>
                                        przez administratora Papaya Films sp. z o.o., znajdującymi się pod adresem:
                                        papaya-films.com/polityka-prywatnosci, w szczególności z celem przetwarzania w
                                        postaci realizacji castingu i wyboru obsady dokonywanego przez administratora,
                                        Agencję reklamową i Klienta
                                    </li> */}
                                </ul>
                            </div>
                            <div className={styles.footer__check}>
                                <Confirm
                                    checked={formik.values['confirm']}
                                    onClick={() => formik.setFieldValue('confirm', !formik.values['confirm'])}
                                />
                                <span>{t('casting_confirm')}</span>
                            </div>
                            <button type="submit" disabled={formik.isValid ? false : true}>
                                {t('send_castin_location')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CastingQuiz

const Drop = ({ name, text }) => {
    const [open, setOpen] = useState(false)

    return (
        <div className={styles.info__item} onClick={() => setOpen(!open)}>
            <div className={open ? styles.info__item__active : styles.info__item__noActive}>
                {name} <Chevron isOpen={open} />
            </div>

            {open && <div className={styles.info__drop}>{text}</div>}
        </div>
    )
}

const Chevron = ({ isOpen }) => (
    <svg
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}
    >
        <path d="M1 1L7 7L13 1" stroke="black" />
    </svg>
)
const Confirm = ({ checked, onClick }) => {
    return checked ? (
        <svg
            onClick={onClick}
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13.5" cy="13" r="12.5" fill="#D7E7FF" stroke="#3376A3" />
            <path d="M7.5 11.7222L12.0517 16L19.5 9" stroke="#3376A3" strokeWidth="2" />
        </svg>
    ) : (
        <svg
            onClick={onClick}
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13" cy="13" r="12.5" stroke="#3376A3" />
        </svg>
    )
}

const Unchecked = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13.332" cy="13.333" r="12.5" stroke="#3376A3" />
        </svg>
    )
}
const Checked = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="27"
            height="27"
            viewBox="0 0 27 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="13.332" cy="13.333" r="12.5" fill="#D7E7FF" stroke="#3376A3" />
            <path d="M7.33203 12.0552L11.8838 16.333L19.332 9.33301" stroke="#3376A3" stroke-width="2" />
        </svg>
    )
}
