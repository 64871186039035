import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styles from './create-project.module.sass'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { createProject } from '../../../store/reducers/projects'
import AddTag from '../../add-tags/AddTags'
import CustomTextField from '../../custom-text-field/CustomTextField'
import { useTranslation } from 'react-i18next'

const CreateProjectPopup = ({ plusButton, emptyProjects, newButton }) => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            tag: '',
            tags: [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().min(1).required('Name is a required field'),
            description: Yup.string().required('Description is a required field'),
            thumb: Yup.mixed().required(),
        }),
        validateOnMount: true,
        onSubmit: (values) => {
            let fd = new FormData()
            fd.append('name', values.name)
            fd.append('description', values.description)
            fd.append('tags', values.tags)
            fd.append('thumb', values.thumb)
            dispatch(createProject({ values: fd, resetForm: formik.resetForm }))
        },
    })

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <div>
            {emptyProjects ? (
                <div className={styles.emptyProjects} onClick={handleClickOpen}>
                    <div>
                        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="0.25" y1="10.7896" x2="20.75" y2="10.7896" stroke="#3376A3" stroke-width="2" />
                            <line x1="10.0396" y1="21.25" x2="10.0395" y2="0.75" stroke="#3376A3" stroke-width="2" />
                        </svg>
                        <span>{t('create_a_project')}</span>
                    </div>
                </div>
            ) : null}

            {plusButton ? (
                <IconButton color="#fff" onClick={handleClickOpen}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="4.9541" x2="10" y2="4.9541" stroke="black" />
                        <line x1="4.9541" y1="10" x2="4.9541" y2="2.18557e-08" stroke="black" />
                    </svg>
                </IconButton>
            ) : null}

            {newButton ? (
                <Button variant="contained" onClick={handleClickOpen}>
                    {t('new')}
                </Button>
            ) : null}

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={styles.wrapper}
                // sx={{background:}}
            >
                <DialogTitle id="responsive-dialog-title">
                    {t('new_project')}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 1.00049L9 9.00049L17 17.0005" stroke="black" />
                            <path d="M1 17L9 9L0.999998 1" stroke="black" />
                        </svg>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <span className={styles.title}>{t('project_name')}</span>
                        <CustomTextField name="name" formik={formik} small placeholder={t('project_name')} />
                        <span className={styles.title}>{t('add_tags')}</span>
                        <AddTag
                            setFieldValue={formik.setFieldValue}
                            values={formik.values}
                            handleChange={formik.handleChange}
                        />
                        <span className={styles.title}>{t('short_description')}</span>

                        <CustomTextField
                            name="description"
                            formik={formik}
                            small
                            placeholder={t('short_description')}
                        />
                        <span className={styles.title}>{t('set_folder_thumbnail')}</span>
                        <p className={styles.subTitle}>{t('new_proj_thumb')}</p>
                        <div className={styles.thumb}>
                            {formik.values.thumb ? (
                                <img src={URL.createObjectURL(formik.values.thumb)} alt="logo" />
                            ) : (
                                <div className={styles.title}>
                                    <div className={styles.thumb__title}>{t('thumbnail')}</div>{' '}
                                    <div className={styles.subTitle}>{t('new_proj_thumb')} </div>
                                    <label for="thumb" className={styles.selectImg}>
                                        <div>{t('select_image')}</div>
                                    </label>
                                    <input
                                        id="thumb"
                                        name="thumb"
                                        type="file"
                                        accept="image/*"
                                        style={{ visibility: 'hidden' }}
                                        onChange={(event) => {
                                            formik.setFieldValue('thumb', event.currentTarget.files[0])
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <DialogActions sx={{ padding: '30px 0 0' }}>
                            <Button
                                className={styles.createBtn}
                                onClick={handleClose}
                                disabled={!formik.isValid}
                                type="submit"
                            >
                                {t('create')}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default CreateProjectPopup
