import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take } from 'redux-saga/effects'
import { resource } from '../../api/http'
import { setLocations } from './locations'
import { setLoading, setSuccess } from './popups'

const namespace = 'castings'

const initialState = {
    castings: [],
}

const SET_CASTINGS = `${namespace}/SET_CASTINGS`
const GET_CASTINGS = `${namespace}/GET_CASTINGS`
const CREATE_CASTING = `${namespace}/CREATE_CASTING`
const SEND_QUIZ_ANSWERS = `${namespace}/SEND_QUIZ_ANSWERS`
const REMOVE_CASTING = `${namespace}/REMOVE_CASTING`

export const setCastings = createAction(SET_CASTINGS)
export const createCasting = createAction(CREATE_CASTING)
export const getAllCastings = createAction(GET_CASTINGS)
export const sendQuizAnswers = createAction(SEND_QUIZ_ANSWERS)
export const removeCasting = createAction(REMOVE_CASTING)

export default handleActions(
    {
        [SET_CASTINGS]: (state, { payload }) => ({ ...state, castings: payload }),
        // [ADD_COMMENT]: (state, { payload }) => ({
        //     ...state,
        //     comments: [...state.comments, payload],
        // }),
        // [RESET_COMMENTS]: (state) => ({ ...state, comments: [] }),
    },
    initialState
)

export const castingsSelector = (state) => state[namespace]

export function* createCastingRequest() {
    while (true) {
        const {
            payload: { values, resetForm, closePopup },
        } = yield take(CREATE_CASTING)
        try {
            // console.log('payload', payload)
            const {
                data: { data, success, message },
            } = yield call(resource.post, `api/castings`, values)
            if (success) {
                const { castings } = yield select(castingsSelector)
                yield put(setCastings([...castings, data]))
                resetForm()
                closePopup()
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* getAllRequest() {
    while (true) {
        const { payload } = yield take(GET_CASTINGS)
        try {
            const {
                data: { message, data, success },
            } = yield call(resource.get, `api/castings`)

            if (success) yield put(setCastings(data))
        } catch (err) {
            console.log(err)
        }
    }
}
export function* removeRequest() {
    while (true) {
        const { payload } = yield take(REMOVE_CASTING)
        try {
            const {
                data: { message, success },
            } = yield call(resource.delete, `api/castings/${payload}`)

            if (success) {
                const { castings } = yield select(castingsSelector)
                yield put(setCastings(castings.filter((obj) => obj.id !== payload)))
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export function* sendQuizRequest() {
    while (true) {
        const {
            payload: { fd, resetForm },
        } = yield take(SEND_QUIZ_ANSWERS)
        try {
            yield put(setLoading(true))
            const {
                data: { success, data, message },
            } = yield call(resource.post, `api/casting-question-answers`, fd)
            if (success) {
                yield put(setSuccess({ visible: true, text: message ?? 'Success' }))
                resetForm()
            }
        } catch (err) {
            console.log(err)
            yield put(setSuccess({ visible: true, text: err.message ?? 'Error' }))
        } finally {
            yield put(setLoading(false))
        }
    }
}
export function* sagas() {
    yield all([createCastingRequest(), getAllRequest(), sendQuizRequest(), removeRequest()])
}
