import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
// import reportWebVitals from './reportWebVitals'
import { configStore } from './store'
import { Provider } from 'react-redux'
import { reducers } from './store/reducers'
import createMiddlewares, { sagaWatch } from './store/middlewares'
import { BrowserRouter } from 'react-router-dom'

export const store = configStore(reducers, createMiddlewares())
sagaWatch()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <Suspense>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Suspense>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
