import React, { useEffect } from 'react'
import styles from '../right-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { projectsSelector, setSelectedFile } from '../../../store/reducers/projects'
import { useParams } from 'react-router-dom'
import SettingsAvatar from '../../avatar/SettingsAvatar'
import { useTranslation } from 'react-i18next'

const InfoSection = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { selectedFile, currentFiles } = useSelector(projectsSelector)

    useEffect(() => {
        if (!selectedFile?.id) dispatch(setSelectedFile(currentFiles?.find((e) => e.id === +id)))
    }, [])

    const created_at = new Date(selectedFile?.created_at)
    return (
        <div className={styles.info__wrapper}>
            <div className={styles.info__header}>
                <div>
                    <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6.5L3.5 3.5L0.5 0.5" stroke="black" />
                    </svg>
                    <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.5 6.5L3.5 3.5L0.5 0.5" stroke="black" />
                    </svg>
                </div>

                <span>{t('info')}</span>
            </div>
            {selectedFile?.id && (
                <div className={styles.wrapInfo}>
                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t('name')}</span>
                        <span className={styles.info__item}>{selectedFile.name}</span>
                    </div>

                    {selectedFile.type !== 'directory' && (
                        <div className={styles.info__column}>
                            <span className={styles.info__label}>{t('preview')}</span>
                            <img
                                className={styles.info__preview}
                                src={selectedFile.preview ?? selectedFile.thumb}
                                alt="preview"
                            />
                        </div>
                    )}

                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t('type')}</span>
                        <div className={styles.info__column__type}>
                            <span>{t(selectedFile.type)}</span>
                        </div>
                    </div>

                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t('tags')}</span>{' '}
                        <div className={styles.tags}>
                            {selectedFile.tags?.map((e, idx) => (
                                <div key={e}>
                                    <div>{e.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t('members')}</span>
                        <div className={styles.members__wrap}>
                            <div className={styles.members}>
                                {selectedFile?.sharedWithUsers?.map((e, idx) => {
                                    const margin = idx * 20 + 'px'
                                    return <SettingsAvatar key={e.id} name={e.name} margin={margin} />
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t('upload_date')}</span>
                        <span className={styles.info__item}>{created_at?.toLocaleDateString() ?? null}</span>
                    </div>
                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t('visibility')}</span>
                        <span className={styles.info__item}>{t('forEveryone')}</span>
                    </div>
                    {/* <div className={styles.info__column}>
                        <span className={styles.info__label}>Link</span>
                        <a href="medialove.com/44903022mm...">medialove.com/44903022mm...</a>
                    </div> */}
                    {/* <div className={styles.info__column}>
                        <span className={styles.info__label}>Views</span>
                        <div className={styles.info__label}>
                            <ViewsSvg />
                            <span>{selectedFile.viewsCount ?? 777}</span>
                        </div>
                    </div> */}
                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t('likes')}</span>
                        <div className={styles.info__label}>
                            <LikeSvg />
                            <span>{selectedFile.likesCount ?? 777}</span>
                        </div>
                    </div>
                    <div className={styles.info__column}>
                        <span className={styles.info__label}>{t('comments')}</span>
                        <div className={styles.info__label}>
                            <CommentSvg />
                            <span>{selectedFile.commentsCount ?? t('no_comments')}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
export default InfoSection

const ViewsSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 9.99994C4.85294 6.85566 11.1471 2.45367 17.5 9.99994C15.1471 13.1442 8.85294 17.5461 2.5 9.99994Z"
                stroke="white"
            />
            <circle cx="10" cy="10" r="2" fill="black" stroke="white" />
        </svg>
    )
}
const LikeSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2572 4.65713C12.8897 3.08732 10.5 4.53806 9.99986 6.81658C9.5 4.53791 6.26554 2.9913 4.25684 5C1.75684 7.5 3 12.5379 9.99986 16.0379C17.4478 11.9411 18.5141 6.81657 15.2572 4.65713Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
const CommentSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_483_16287)">
                <path
                    d="M5.89636 14.6724C5.92905 14.4657 5.82955 14.2605 5.64701 14.1581C3.70612 13.07 2.5 11.3101 2.5 9.37582C2.5 6.23058 5.74606 3.5 10 3.5C14.2539 3.5 17.5 6.23058 17.5 9.37582C17.5 12.5211 14.2539 15.2516 10 15.2516C9.61326 15.2516 9.2336 15.2287 8.86305 15.1844C8.72923 15.1684 8.59461 15.2071 8.48973 15.2917C7.98627 15.698 7.15118 16.2031 6.30095 16.4079C5.653 16.564 5.05471 16.5374 4.5655 16.2186C4.57042 16.2154 4.57536 16.2122 4.58034 16.209C4.71254 16.1228 4.86422 16.024 5.0064 15.9198C5.35641 15.6633 5.8018 15.2704 5.89636 14.6724Z"
                    stroke="black"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_483_16287">
                    <rect width="20" height="20" fill="black" />
                </clipPath>
            </defs>
        </svg>
    )
}
