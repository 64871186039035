import React, { useEffect } from 'react'
import styles from './shared.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector } from '../../store/reducers/auth'
import { useParams } from 'react-router-dom'
import { getSharedProject, projectsSelector, setSelectedFile } from '../../store/reducers/projects'
import RightBar from '../../components/side-bar-right'
import FolderFileItem from '../../components/folder-file-item'
import SharedHeader from '../../components/shared-components/SharedHeader'
import { Breadcrumbs } from '@mui/material'

const SharedProject = ({ navigate }) => {
    // const loading = useSelector(authLoading)
    // const onHandleSubmitForm = () => {}
    const dispatch = useDispatch()
    const { uuid } = useParams()
    const auth = useSelector(authSelector)
    // const [search, setSearch] = useState(null)

    const {
        sharedProject: { data, tree },
    } = useSelector(projectsSelector)

    useEffect(() => {
        dispatch(getSharedProject(uuid))
    }, [])

    return (
        <>
            {!auth && <SharedHeader navigate={navigate} />}
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.flexCenter}>
                        <svg
                            onClick={() => navigate(-1)}
                            width="12"
                            height="22"
                            style={{ marginRight: '10px' }}
                            viewBox="0 0 12 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M11 1L1 11L11 21" stroke="black" />
                        </svg>

                        <Breadcrumbs
                            className={styles.breadcrumbs}
                            aria-label="breadcrumb"
                            maxItems={4}
                            separator={<Separator />}
                        >
                            {tree?.map((e, idx) => {
                                return <div key={e.id}>{e.name}</div>
                            })}
                        </Breadcrumbs>
                    </div>
                    {/* <div>Opened Project -> path -> to -> files</div> */}
                    <div className={styles.buttonGroup}>
                        {/* <Button
                            onClick={() => {
                                dispatch(createShareLink({ id, time_from: new Date(), time_to: endOfTomorrow() }))
                            }}
                        >
                            Share
                        </Button> */}
                        {/* <CreateShareLinkPopup id={id} />
                        <Button>DownLoad</Button>
                        <Button endIcon={<AddIcon />}>New</Button> */}
                    </div>
                </div>
            </div>

            <div className={styles.container}>
                <div className={styles.project}>
                    <div className={styles.project__bar}>
                        <div>
                            <span className={styles.project__countProjects}>
                                {/* {filesArray.length ?? ''} items, 29.3 GB */}
                            </span>
                        </div>

                        <div className={styles.flex}>{/* <ViewSetting /> */}</div>
                    </div>

                    {/* <FinalizeUpload /> */}
                    <div className={styles.btn}>
                        {data?.map((item) => {
                            return (
                                <FolderFileItem
                                    item={item}
                                    key={item.id}
                                    navigate={navigate}
                                    shareFlow={true}
                                    uuid={uuid}
                                    onClick={() => {
                                        dispatch(setSelectedFile(data.find((e) => e.id === item.id)))
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>

                {auth && <RightBar shareFlow />}
            </div>
        </>
    )
}

export default SharedProject
const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}
