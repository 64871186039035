import React, { useState } from 'react'
import styles from './text-field.module.sass'

const CustomTextField = (props) => {
    const { name, formik, type, placeholder, small, onChange, autocomplete } = props
    const { values, errors, setFieldTouched, handleChange, touched } = formik

    const [showPass, setShowPass] = useState(false)
    return (
        <div className={styles.wrap}>
            <input
                className={
                    errors[name] && touched[name]
                        ? small
                            ? styles.errorSmall
                            : styles.error
                        : small
                        ? styles.itemSmall
                        : styles.item
                }
                name={name}
                value={values[name]}
                onChange={onChange ? (e) => onChange(e) : (e) => handleChange(e)}
                type={type}
                placeholder={placeholder}
                onBlur={() => setFieldTouched(name)}
                type={type === 'password' && !showPass ? type : 'text'}
                autoComplete={autocomplete}

                // {...props}
            />
            {errors[name] && touched[name] && (
                <div>
                    <span className={small ? styles.errorTextSmall : styles.errorText}>{formik.errors[name]}</span>
                </div>
            )}
            {type === 'password' && (
                <ShowPassSvg
                    onClick={() => {
                        setShowPass(!showPass)
                    }}
                />
            )}
        </div>
    )
}

export default CustomTextField

const ShowPassSvg = ({ onClick }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <g opacity="0.5">
                <path
                    d="M3 12C5.82353 8.22689 13.3765 2.9445 21 12C18.1765 15.7731 10.6235 21.0555 3 12Z"
                    stroke="black"
                />
                <circle cx="12" cy="12" r="2.5" fill="black" stroke="black" />
                <line x1="5.85355" y1="4.64645" x2="20.8536" y2="19.6464" stroke="#E2E2E2" />
                <line x1="4.85355" y1="4.64645" x2="20.8536" y2="20.6464" stroke="black" />
            </g>
        </svg>
    )
}
