import ClassNames from 'classnames'
import styles from './list.module.sass'
// import 'react-photo-view/dist/react-photo-view.css'
import { useState } from 'react'
import FullScreenPopup from '../deck-carusel-shared/components/FullScreenPopup'

const DeckListView = ({ files, directories }) => {
    const [currentFileId, setCurrentFileId] = useState(false)
    return (
        <div className={styles.wrapper}>
            {files?.map((item, index) => (
                <ListItem
                    item={item}
                    directories={directories}
                    index={index}
                    setCurrentFileId={setCurrentFileId}
                    currentFileId={currentFileId}
                />
            ))}
        </div>
    )
}

const ListItem = ({ item, index, directories, currentFileId, setCurrentFileId }) => {
    const [fullScreen, setfullScreen] = useState(false)
    return (
        <div
            key={index}
            className={ClassNames(
                { [styles.item]: index % 2 !== 0 },
                { [styles.itemGray]: index % 2 === 0 },
                { [styles.itemSelected]: item.id === currentFileId }
            )}
            onClick={() => setCurrentFileId(item.id)}
        >
            <div>
                <img src={item.preview} alt="152" />
                <div className={styles.type}>
                    <span>{item.title}</span>
                </div>
            </div>

            <div className={styles.folder}>
                <div> {directories?.find((e) => e.id === item.directory_id)?.name}</div>
            </div>
            <div className={styles.type}>
                <div>
                    <span>{item.type}</span>
                </div>
            </div>
            <div className={styles.options}>
                <ItemButtons fullScreen={fullScreen} setfullScreen={setfullScreen} item={item} />
            </div>
        </div>
    )
}
export default DeckListView

const DownloadFileSvg = ({}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 1L10 13" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path
                d="M6.11111 8.71423L10 13.4285L13.8889 8.71423M2 9.99995V16.9999H18V9.99995"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
const FullScreenSvg = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            style={{ marginLeft: 8 }}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.66667 2.49988H4.16667C3.72464 2.49988 3.30072 2.67547 2.98816 2.98803C2.67559 3.30059 2.5 3.72452 2.5 4.16654V6.66655M17.5 6.66655V4.16654C17.5 3.72452 17.3244 3.30059 17.0118 2.98803C16.6993 2.67547 16.2754 2.49988 15.8333 2.49988H13.3333M13.3333 17.4999H15.8333C16.2754 17.4999 16.6993 17.3243 17.0118 17.0117C17.3244 16.6992 17.5 16.2752 17.5 15.8332V13.3332M2.5 13.3332V15.8332C2.5 16.2752 2.67559 16.6992 2.98816 17.0117C3.30072 17.3243 3.72464 17.4999 4.16667 17.4999H6.66667"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
export const ItemButtons = ({ item, fullScreen, setfullScreen }) => {
    return (
        <div className={styles.btnGroup}>
            <a href={item.source} download style={{ height: 30 }}>
                <DownloadFileSvg />
            </a>
            <FullScreenSvg
                onClick={() => {
                    setfullScreen(true)
                }}
            />
            {fullScreen && <FullScreenPopup onClick item={item} setfullScreen={setfullScreen} />}
        </div>
    )
}
