import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
// import { createBrowserHistory } from "history";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// export const history = createBrowserHistory();

export const configStore = (reducers, middlewares) => {
  return createStore(
    combineReducers({
      ...reducers,
    }),
    {},
    composeEnhancers(applyMiddleware(...middlewares)),
  )
}
