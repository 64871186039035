import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, TextField } from '@mui/material'
import styles from './shared.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector, register } from '../../store/reducers/auth'
import { Link, useParams } from 'react-router-dom'
import DeckCarusel from '../../components/deck-carusel/DeckCarusel'
import DeckCaruselShared from '../../components/deck-carusel-shared'
import OpenFile from '../open-file/OpenFile'
import { getSharedFile, projectsSelector } from '../../store/reducers/projects'
import ReactPlayer from 'react-player'
import RightBar from '../../components/side-bar-right'
import SharedHeader from '../../components/shared-components/SharedHeader'

const SharedFile = ({ navigate }) => {
    // const loading = useSelector(authLoading)
    // const onHandleSubmitForm = () => {}
    const dispatch = useDispatch()
    const { uuid } = useParams()
    const auth = useSelector(authSelector)

    useEffect(() => {
        dispatch(getSharedFile(uuid))
    }, [])

    const { sharedFile } = useSelector(projectsSelector)
    return auth ? (
        <>
            <div className={styles.wrapper}></div>

            <div className={styles.flexRow}>
                {sharedFile.type === 'image' && (
                    <div className={styles.imageWrap}>
                        <img src={sharedFile.source} alt="Alternative texts" />
                    </div>
                )}
                {sharedFile.type === 'video' && (
                    <ReactPlayer url={sharedFile.source} controls width={'100%'} height={'100%'} />
                )}
                <RightBar />
            </div>
        </>
    ) : (
        <div className={styles.viewPort}>
            {!auth && <SharedHeader navigate={navigate} />}
            {sharedFile.type === 'image' && (
                <div className={styles.imageWrap}>
                    <img src={sharedFile.source} alt="Alternative texts" />
                </div>
            )}
            {sharedFile.type === 'video' && (
                <ReactPlayer url={sharedFile.source} controls width={'100%'} height={'100%'} />
            )}
        </div>
    )
}

export default SharedFile
