import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styles from './open-location-answers.module.sass'
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next'
import { getAllLocations, locationsSelector } from '../../store/reducers/locations'
import LocationBar from '../../components/location-bar'

const OpenLocationAnswer = ({ navigate }) => {
    const { t } = useTranslation()
    const { locations } = useSelector(locationsSelector)
    const [currentIdx, setCurrentIdx] = useState()
    const dispatch = useDispatch()
    const { id, answerId } = useParams()

    const currentCasting = locations?.find((obj) => obj.id === +id)
    const currentAnswers = currentCasting?.answers.find((obj) => obj.id === +answerId)
    const { photo, video, others } = currentAnswers ?? []
    const array = [photo, video, others]

    useEffect(() => {
        if (!locations.length) dispatch(getAllLocations())
    }, [])

    useEffect(() => {
        if (array[0]) {
            setCurrentIdx(0)
            return
        }
        if (array[1]) {
            setCurrentIdx(1)
            return
        }
        if (array[2]) {
            setCurrentIdx(2)
            return
        }
    }, [currentAnswers])

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.flexCenter}>
                    <svg
                        onClick={() => navigate(-1)}
                        width="12"
                        height="22"
                        style={{ marginRight: '10px' }}
                        viewBox="0 0 12 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </svg>
                    {/* <ThreeLines /> */}
                    <Link to={`/locations`}> {t('all_locations')}</Link>
                    <Separator />
                    <Link to={`/location/${currentAnswers?.location_id}`}> {currentCasting?.title ?? ''}</Link>

                    <Separator />
                    <span>{currentAnswers?.parcipiant_name}</span>
                </div>
            </div>
            <div className={styles.flexRow}>
                <div className={styles.caruselWrapper}>
                    <div className={styles.currentImage}>
                        {currentIdx === 0 ? <img src={array[0]} className={styles.media} alt="1" /> : null}
                        {currentIdx === 1 ? (
                            <div className={styles.itemVideo}>
                                <ReactPlayer url={array[currentIdx]} controls width={'100%'} height={'100%'} />
                            </div>
                        ) : null}
                        {currentIdx === 2 ? <img src={array[currentIdx]} className={styles.media} alt="1" /> : null}
                    </div>

                    <div className={styles.bottomImages}>
                        {array[0] ? (
                            <div className={styles.bottomImage} onClick={() => setCurrentIdx(0)}>
                                <img src={array[0]} className={styles.media} alt="1" />
                            </div>
                        ) : null}
                        {array[1] ? (
                            <div className={styles.item} onClick={() => setCurrentIdx(1)}>
                                <ReactPlayer url={array[1]} height={221} width={214} />
                            </div>
                        ) : null}
                        {array[2] ? (
                            <div className={styles.bottomImage} onClick={() => setCurrentIdx(2)}>
                                <img src={array[2]} className={styles.media} alt="1" />
                            </div>
                        ) : null}
                    </div>
                </div>

                <LocationBar currentAnsvers={currentAnswers} />
            </div>
        </div>
    )
}

export default OpenLocationAnswer

const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}
