import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styles from './remove-members.module.sass'
// import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { resource } from '../../../api/http'
// import { useState } from 'react'
import { projectsSelector, setAllProjects } from '../../../store/reducers/projects'
import { useTranslation } from 'react-i18next'

const RemoveMember = ({ id, projectID, isOpen, setIsOpen }) => {
    // const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()
    const { projects } = useSelector(projectsSelector)

    const formSubmit = () => {
        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/project/remove-user-from-project/${projectID}`, {
                removeUserId: id,
            })
            .then(({ data: { data, message, success } }) => {
                if (success) {
                    setIsOpen(false)
                    const currentId = projects.findIndex((e) => e.id === data.id)
                    if (currentId !== -1) {
                        projects[currentId].teamMembers = data.teamMembers

                        dispatch(setAllProjects(projects))
                    }
                }
            })
    }

    const handleClickOpen = () => {
        setIsOpen(true)
    }

    const handleClose = () => {
        setIsOpen(false)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.remove} onClick={handleClickOpen}>
                {t('remove_member')}
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={styles.wrapper}
            >
                <div className={styles.header}>
                    <div>
                        <span>Are you sure?</span>
                    </div>
                    <div>
                        <div>
                            <CloseSvg onClick={handleClose} />
                        </div>
                    </div>
                </div>

                <DialogContent>
                    <form onSubmit={formSubmit} className={styles.removeForm}>
                        <div>
                            <span>
                                Are you sure you want to remove member? They may not be able to access this file
                                anymore.
                            </span>
                        </div>

                        <div className={styles.removeBtns}>
                            <button type="button" onClick={() => handleClose()}>
                                {t('cancel')}
                            </button>
                            <button type="submit">{t("Delete")}</button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default RemoveMember
const CloseSvg = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17 1.00049L9 9.00049L17 17.0005" stroke="black" />
            <path d="M1 17L9 9L0.999998 1" stroke="black" />
        </svg>
    )
}
