import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    login: 'Login',
                    log_in: 'Log in',
                    sign_up: 'Sign-Up',
                    password: 'Password',
                    req_email: 'Email is a required field',
                    forgot_pass: 'Forgot Password?',
                    restore: 'Restore',
                    restore_pass: 'Restore password',
                    username: 'Username',
                    confirm_your_pass: 'Confirm your password ',
                    search: 'Search',
                    all_projects: 'All projects',
                    collaboration: 'Collaboration',
                    liked: 'Liked',
                    sort: 'Sort :',
                    last_edit: 'Last edited',
                    last_added: 'Last added',
                    edited: 'Edited',
                    new: 'New',
                    uploadings: 'Uploadings',
                    all_files: 'All files',
                    comments: 'Comments',
                    no_comments: 'No comments',
                    settings: 'Settings',
                    name: 'Name',
                    preview: 'Preview',
                    type: 'Type',
                    members: 'Members',
                    upload_date: 'Upload date',
                    visibility: 'Visibility',
                    forEveryone: 'For everyone',
                    likes: 'Likes',
                    new_project: 'New Project',
                    project_name: 'Project name',
                    add_tags: 'Add tags',
                    short_description: 'Short description',
                    set_folder_thumbnail: 'Set Folder Thumbnail',
                    '100x100': 'Used as the project preview image. Recomended 100x100.',
                    create: 'Create',
                    folders: 'Folders',
                    files: 'Files',
                    share: 'Share',
                    decks: 'Decks',
                    all_decks: 'All decks',
                    deck_new: 'New',
                    castings: 'Castings',
                    all_castings: 'All Castings',
                    styles: 'Styles',
                    deck_content: 'Deck`s content',
                    background: 'Background',
                    text: 'Text',
                    deck_title: 'Deck title',
                    save_changes: 'Save changess',
                    cancel: 'Cancel',
                    reload: 'Reload',
                    add_your_logo: 'Add your logo',
                    new_deck: 'New Deck',
                    templates: 'Templates',
                    template: 'Template',
                    general: 'General',
                    teams: 'Teams',
                    profile: 'Profile',
                    upload_photo: 'Upload photo',
                    plans_billings: 'Plans & Billings',
                    first_name: 'First name',
                    last_name: 'Last name',
                    organization: 'Organization',
                    change_your_password: 'Change your password',
                    current_password: 'Current Password',
                    new_password: 'New Password',
                    confirm_new_password: 'Confirm New Password',
                    change: 'Change',
                    share_casting: 'Share casting',
                    title: 'Title',
                    character_description: 'Character description',
                    terms_rates_and_rights: 'Terms Rates and Rights',
                    video_instructions: 'Video Instructions',
                    add_photo: 'Add photo',
                    send_casting_card: 'Send casting card',
                    'Send casting card': 'Send casting card',
                    send_video_scene: 'Send video scene',
                    'Send video scene': 'Send video scene',
                    'Send photos': 'Send photos',
                    Age: 'Age',
                    Phone: 'Phone',
                    Email: 'Email',
                    'Place of residence': 'Place of residence',
                    man: 'Man',
                    woman: 'Woman',
                    kid: 'Kid',
                    Height: 'Height',
                    Weight: 'Weight',
                    Waist: 'Waist',
                    Collar: 'Collar',
                    Hips: 'Hips',
                    'Clothing size': 'Clothing size',
                    'Shoe size': 'Shoe size',
                    sizes: 'Sizes',
                    opportunity: 'Opportunity',
                    Agency: 'Agency',
                    'Acting school': 'Acting school',
                    upload: 'Upload',
                    general_casting: 'General',
                    copy_link: 'Copy link',
                    about_project: 'About project',
                    'Agent phone number': 'Agent phone number',
                    'I’m professional actress: Yes / No': 'I’m professional actress: Yes / No',
                    casting_quiz_portrait:
                        'Please add a portrait photo. Photos should be in color and well represent your current image. File types: jpg, jpeg, png ',
                    add_video_introduction: 'Add video introduction',
                    casting_quiz_card:
                        'Please record a business card in which you tell a few sentences about yourself. File types: wma, mpg, flv, avi, mp4, mov, wmv. Maximum size 100 MB.',
                    complited_casting_card:
                        'Please add the completed casting card. File types: doc, pdf, jpg, png, jpeg. / Please add casting card. Files type: doc, pdf, jpg, png, jpeg.',
                    record_video_scene:
                        'Please record a video scene according to the instructions in the brief. File types: wma, mpg, flv, avi, mp4, mov, wmv. Maximum size 100 MB.',
                    casting_confirm: 'I confirm that I accept the terms of the application',
                    locations: 'Locations',
                    all_locations: 'All locations',
                    new_location: 'New location',
                    set_location_thumbnail: 'Set Location Thumbnail',
                    location_use_thumb: 'Used as the project preview image. Recomended 420x560',
                    title_location: 'Title',
                    location_description: 'Location description',
                    terms_rates_and_rights_locations: 'Terms Rates and Rights',
                    video_instruction_locations: 'Photo & Video instruction',

                    Name: 'Name',
                    Surname: 'Surname',
                    'Short location description': 'Short location description',
                    'Location description': 'Location description',
                    'Location address': 'Location address',
                    'Google location pin': 'Google location pin',
                    'Add photo': 'Add photo',
                    'Add video': 'Add video',
                    'Add others': 'Add others',

                    notifications: 'Notifications',
                    logout: 'Logout',
                    directory: 'directory',
                    file: 'file',
                    create_a_project: 'Create a Project',
                    new_proj_thumb:
                        'Zdjęcie zostanie użyte jako zdjęcie tytułowe projektu. Rekomendowana wielkość 420 x560',
                    expiry_date: 'Expiry date',
                    public_link: 'Public link',
                    color: 'Color',
                    all: 'All',
                    ago: 'ago',
                    new_casting: 'New casting',
                    invite_by_email: 'Invite by email',
                    enter_email: 'Enter email',
                    send_invite: 'Send invite',
                    remove_member: 'Remove',
                    copy_invitation_link: 'Copy invitation link',
                    Edit: 'Edit',
                    Delete: 'Delete',
                    edit_project: 'Edit Project',
                    thumbnail: 'Thumbnail',
                    add_your_comment: 'Add your comment',
                    description: 'Description',
                    shared_with: 'Shared with',
                    reload_cover: 'Reload cover',
                    create_pages_and_drag: 'Create pages and drag and drop files into it',
                    create_a_page: 'Create a page',
                    drop_deck: 'Drag and drop content for this deck here',
                    copy_share_link: 'Copy share link',
                    send: 'Send',
                    share_location: 'Share location',
                    preview_deck: 'Preview',
                    Chest: 'Chest',
                    copy_share_link_casting: 'Copy share link',
                    add_folder: 'Add folder ',
                    add_file: 'Add file',
                    download: 'Download',
                    download_all: 'Download reel',
                    new_folder: 'New folder',
                    folder_name: 'Folder name',
                    shared_links: 'Shared links',
                    object: 'Object',
                    link_address: 'Link addressi',
                    creation_date: 'Creation date',
                    valid_until: 'Valid until',
                    status: 'Status',
                    go_to_this_folder: 'Go to this folder',
                    delete_folder: 'Delete folder',
                    delete_file: 'Delete file',
                    req_pass: 'Password is a required field',
                    confirm_pass: 'Confirm password is a required field',
                    commented: 'Commented',
                    tags: 'Tags',
                    copy_share_link_deck: 'Copy share link',
                    create_a_folder: 'Create folder',
                    'Man / Woman / Kid': 'Man / Woman / Kid',
                    location_quiz_description: 'Location description',
                    location_quiz_terms: 'Terms Rates and Rights',
                    location_quiz_instruction: 'Photo & Video instruction',
                    info: 'Info',
                    empty_folder: 'Empty folder',
                    character: 'Character',
                    link_copied: 'Link copied',
                    select_image: 'Select image',
                    filter: 'Filter',
                    'Project updated successfully': 'Project updated successfully',
                    edit_project_2: 'Edit',
                    'Casting Question Answers saved successfully': 'Casting Question Answers saved successfully',
                    send_castin_location: 'Send',
                    upload_deck: 'Upload',
                },
            },
            pl: {
                translation: {
                    login: 'Logowanie',
                    log_in: ' Zaloguj',
                    sign_up: 'Załóż konto',
                    password: 'Hasło',
                    req_email: 'Wymagany adres email',
                    forgot_pass: 'Zapomniałeś hasła?',
                    restore: 'Resetuj',
                    restore_pass: 'Zresetuj hasło',
                    username: 'Nazwa użytkownika',
                    confirm_your_pass: 'Powtórz hasło',
                    search: 'Szukaj',
                    all_projects: 'Projekty',
                    collaboration: 'Zespół',
                    liked: 'Wybrane',
                    sort: 'Sortuj: ',
                    last_edit: 'Ostatnio edytowanie',
                    last_added: 'Ostatnio dodane',
                    edited: 'Edytowany',
                    new: 'Nowy',
                    uploadings: 'Dodane materiały',
                    all_files: 'Wszystkie pliki',
                    comments: 'Komentarze',
                    no_comments: 'Brak',
                    settings: 'Ustawienia',
                    name: 'Nazwa',
                    preview: 'Podglad',
                    type: 'Typ',
                    members: 'Zespół',
                    upload_date: 'Data dodania',
                    visibility: 'Kto ma dostęp',
                    forEveryone: 'Publiczny',
                    likes: 'Polubienia',
                    new_project: 'Nowy projekt',
                    project_name: 'Nazwa projektu',
                    add_tags: 'Dodaj tagi',
                    short_description: 'Krótki opis i data realizacji',
                    set_folder_thumbnail: 'Dodaj zdjęcie folderu',
                    '100x100': 'Podglądowe zdjęcie. Rekomendowana wielkość 100px x 100px',
                    create: 'Dodaj',
                    folders: 'Foldery',
                    files: 'Pliki',
                    share: 'Wyślij',
                    decks: 'Prezentacje',
                    all_decks: 'Prezentacje',
                    deck_new: 'Nowa',
                    castings: 'Castingi',
                    all_castings: 'Lista castingów',
                    styles: 'Wygląd',
                    deck_content: 'Zawartość',
                    background: 'Tło',
                    text: 'Czcionka',
                    deck_title: 'Tytuł prezentacji',
                    save_changes: 'Zapisz zmiany',
                    cancel: 'Anuluj',
                    reload: 'Zmień',
                    add_your_logo: 'Dodaj logo',
                    new_deck: 'Nowa prezentacjak',
                    templates: 'Wzory',
                    template: 'Wzór',
                    general: 'Ogólne',
                    teams: 'Zespół',
                    profile: 'Profil',
                    upload_photo: 'Dodaj zdjęcie',
                    plans_billings: 'Plany subskrybcji',
                    first_name: 'Imie',
                    last_name: 'Nazwisko',
                    organization: 'Organizacja',
                    change_your_password: 'Zmień hasło',
                    current_password: 'Aktualne hasło',
                    new_password: 'Nowe hasło',
                    confirm_new_password: 'Powtórz hasło',
                    change: 'Zmień hasło',
                    share_casting: 'Opublikuj casting',
                    title: 'Tytuł',
                    character_description: 'Opis postaci',
                    terms_rates_and_rights: 'Wynagrodzenie i zakres praw',
                    video_instructions: 'Instrukcje do nagrania wideo',
                    add_photo: 'Wyślij zdjęcia',
                    send_casting_card: 'Wyślij kartę castingową',
                    'Send casting card': 'Wyślij kartę castingową',
                    send_video_scene: 'Wyślij scenkę wideo',
                    'Send video scene': 'Wyślij scenkę wideo',
                    'Send photos': 'Wyślij zdjęcia',
                    Age: 'Wiek',
                    Phone: 'Telefon ',
                    Email: 'Email',
                    'Place of residence': 'Miejsce zamieszkania',
                    man: 'Mężczyzna',
                    woman: 'Kobieta',
                    kid: 'Dziecko',
                    Height: 'Wzrost',
                    Weight: 'Waga',
                    Waist: 'Rozmiar w pasie',
                    Collar: 'Rozmiar w kołnierzu',
                    Hips: 'Rozmiar w biodrach',
                    'Clothing size': 'Rozmiar ubrania',
                    'Shoe size': 'Rozmiar buta',
                    sizes: 'Wymiary',
                    opportunity: 'Dodatkowe informacje',
                    Agency: 'Agencja',
                    'Acting school': 'Wykształcenie aktorskie',
                    upload: 'Wyślij ',
                    general_casting: 'Podstawowe informacje',
                    copy_link: 'Skopiuj link',
                    about_project: 'O projekcie',
                    'Agent phone number': 'Telefon kontaktowy do agencji aktorskiej',
                    'I’m professional actress: Yes / No': 'Jestem aktorką / aktorem zawodowym: Tak / Nie',
                    casting_quiz_portrait:
                        'Dodaj zdjęcie portretowe. Zdjęcia powinny być kolorowe i dobrze odzwierciedlać aktualny wizerunek. Typy plików: jpg, jpeg, png ',
                    add_video_introduction: 'Dodaj wizytówkę',
                    casting_quiz_card:
                        'Proszę nagrać wizytówkę, w której opowiesz kilka zdań o sobie. Typy plików: wma, mpg, flv, avi, mp4, mov, wmv. Maksymalny rozmiar 100 MB',
                    complited_casting_card: 'Dodaj wypełnioną kartę castingu. Typy plików: doc, pdf, jpg, png, jpeg.',
                    record_video_scene:
                        'Proszę nagrać scenkę wideo zgodnie z instrukcjami zawartymi w briefie. Typy plików: wma, mpg, flv, avi, mp4, mov, wmv. Maksymalny rozmiar 100 MB.',
                    casting_confirm: 'Potwierdzam, że akceptuję warunki aplikacji',
                    locations: 'Lokacje',
                    all_locations: 'Lista lokacji',
                    new_location: 'Nowa lokacja',
                    set_location_thumbnail: 'Dodaj zdjęcie tytułowe',
                    location_use_thumb: 'Rekomendowana wielkość 420x560',
                    title_location: 'Nazwa lokacji',
                    location_description: 'Opis poszukiwanej lokacji',
                    terms_rates_and_rights_locations: 'Warunki finansowe, czasowe i prawne',
                    video_instruction_locations: 'Instrukcje dla zdjęć i nagrań wideo',

                    Name: 'Imie',
                    Surname: 'Nazwisko',
                    'Short location description': 'Krótki opis lokacji',
                    'Location description': 'Opis lokacji w tym ilość pomieszczeń i dostęp do lokacji',
                    'Location address': 'Adres lokacji',
                    'Google location pin': 'Link z pinezką google do lokacji',
                    'Add photo': 'Dodaj zdjęcie',
                    'Add video': 'Dodaj nagranie wideo',
                    'Add others': 'Dodaj inne materiały',

                    notifications: 'Powiadomienia',
                    logout: 'Wyloguj',
                    directory: 'folder',
                    file: 'plik',
                    create_a_project: 'Dodaj projekt',
                    new_proj_thumb:
                        'Zdjęcie zostanie użyte jako zdjęcie tytułowe projektu. Rekomendowana wielkość 420 x560',
                    expiry_date: ' Data krańcowa wygaśnięcia ',

                    public_link: 'Link publiczny',
                    color: 'Kolor',
                    all: 'All',
                    ago: 'temu',
                    new_casting: 'Nowy Casting',
                    invite_by_email: 'Dodaj użytkownika do projektu',
                    enter_email: 'Wpisz nazwę lub email',
                    send_invite: 'Dodaj do projektu',
                    copy_invitation_link: 'Kopiuj link z zaproszeniem do projektu',
                    remove_member: 'Usuń z projektu',
                    Edit: 'Edytuj',
                    Delete: 'Usuń',
                    edit_project: 'Edytuj projekt',
                    thumbnail: 'zdjęcie podglądowe',
                    add_your_comment: 'Napisz ...',
                    description: 'Opis',
                    shared_with: 'Członkowie zespołu',
                    reload_cover: 'Zmień zdjęcier',
                    create_pages_and_drag: 'Dodaj sekcje prezentacji - Przeciągnij i dodaj Materiały',
                    create_a_page: 'Dodaj sekcje',
                    drop_deck: 'Przeci ągnij i dodaj do prezentacji',
                    copy_share_link: 'Opublikuj poszukiwania lokacji',
                    copy_share_link_casting: 'Opublikuj casting',
                    send: 'Wysłane',
                    share_location: 'Opublikuj',
                    Chest: 'Rozmiar w klatce piersiowej',
                    add_folder: 'Dodaj folder',
                    add_file: 'Dodaj plik',
                    download: 'Pobierz',
                    download_all: 'Download reel',
                    new_folder: 'Nowy folder',
                    folder_name: 'Nazwa folderu',
                    shared_links: 'Opublikowane materiały',
                    object: 'Materiał',
                    link_address: 'Adres publikacji',
                    creation_date: ' Data utworzenia',
                    valid_until: 'Termin ważności',
                    status: 'Status',
                    go_to_this_folder: 'Idź do folderu',
                    delete_folder: 'Usuń folder',
                    delete_file: 'Usunąć plik',
                    req_pass: 'Brak hasła',
                    confirm_pass: 'Brak hasła potwierdzenia',
                    commented: 'Skomentowane',
                    tags: 'Tagi',
                    preview_deck: 'Podgląd prezentacji',
                    copy_share_link_deck: 'Opublikuj prezentację',
                    create_a_folder: 'Dodaj folder',
                    'Man / Woman / Kid': 'Mężczyzna / Kobieta / Dziecko',
                    location_quiz_description: 'Opis poszukiwanej lokacji',
                    location_quiz_terms: 'Warunki finansowe, czasowe i prawne',
                    location_quiz_instruction: 'Instrukcje dla zdjęć i nagrań wideos',
                    info: 'Informacje',
                    empty_folder: 'Folder pusty',
                    character: 'Typ postaci',
                    link_copied: 'Link skopiowany',
                    select_image: 'Wybierz obraz',
                    filter: 'Filtr',
                    'Project updated successfully': 'Zmiany zapisane',
                    edit_project_2: 'Zapisz',
                    'Casting Question Answers saved successfully':
                        'Twoja aplikacja została pomyślnie wysłana. Miłego dnia! Dziękujemy',
                    send_castin_location: 'Wyślij',
                    upload_deck: 'Dodaj',
                },
            },
        },
    })

export default i18n
