import styles from '../deck-carusel-shared.module.sass'
// import 'react-alice-carousel/lib/scss/alice-carousel.scss'
import { decksSelector } from '../../../store/reducers/decks'
import { useSelector } from 'react-redux'
import { useRef, useState } from 'react'
import { DeckLogo, FacebookLink, InstLink, LinkidInkLink, TwitterLink, VimeoLink } from '../assets/SvgImages'
import FullScreenPopup from '../components/FullScreenPopup'
import useMobile from '../../../hooks/useMobile'
import { useEffect } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const FirstDeckType = (props) => {
    const [selectedIndex, changeIndex] = useState(0)
    const [autoplay, setAutoplay] = useState(false)
    const isMobile = useMobile(428)
    const [fullScreen, setfullScreen] = useState(false)
    const {
        sharedDeck: {
            id,
            files,
            name,
            thumb,
            description,
            font,
            font_color,
            facebook_link,
            linkedin_link,
            twitter_link,
            instagram_link,
            vimeo_link,
        },
    } = useSelector(decksSelector)
    // const carouselRef = useRef(null)

    const handleDownload = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/api/decks/download/${id}`
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'archive.zip')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
    }
    const [isHovered, setIsHovered] = useState(false)
    const handleMouseEnter = () => {
        setIsHovered(true)
    }
    const handleMouseLeave = () => {
        setIsHovered(false)
    }
    const buttonStyle = {
        position:"absolute",
        left:"calc( 50vw - 65px )",
        background: 'transparent',
        color: font_color ?? '#fff',
        border: `2px solid ${font_color ?? '#fff'}`,
        borderRadius: 15,
        padding: 5,
        fontFamily: font ?? 'Zeitung Micro Pro',
        // margin: '30px 0',
    }
    const buttonHoverStyle = {
        cursor: 'pointer',
    }
    const handleOnWheel = (e) => {
        // console.log('onWheel: scrolling the list...', e)
        e.preventDefault()
        var container = document.getElementById('scroll-container')
        var containerScrollPosition = document.getElementById('scroll-container').scrollLeft
        container.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY,
            // behaviour: 'smooth', //if you want smooth scrolling
        })
    }

    var timerID

    const videoRef = useRef(null)

    useEffect(() => {
        StartVideoBlur()

        return () => clearInterval(timerID)
    }, [videoRef, selectedIndex])

    const StartVideoBlur = () => {
        const canvas = document.getElementById('myCanvas')
        const ctx = canvas?.getContext('2d')

        timerID = window.setInterval(function () {
            ctx?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height) //draw blur
        }, 30)
    }
    console.log('selectedIndex', selectedIndex)
    const { t } = useTranslation()
    const items2 = files?.map((item, idx) => {
        return (
            <div
                className={selectedIndex === idx ? styles.downItems__selectedItem : styles.downItems__item}
                onClick={() => {
                    setAutoplay(false)
                    changeIndex(idx)
                }}
                key={item.id}
                data-value={idx + 1}
            >
                <img src={item.preview} className={styles.media} alt="1" />
            </div>
        )
    })
    // console.log('autoplay', autoplay)

    const handleMediaEnd = () => {
        // changeIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1))
    }

    useEffect(() => {
        console.log('Change ref')
        if (files[selectedIndex].type === 'video') {
            // For videos, set up an event listener for the 'ended' event
            videoRef.current.addEventListener('ended', () => {
                changeIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1))
            })

            if (autoplay) videoRef.current.play()
        } else {
            if (autoplay)
                // For images, show image for 3 seconds before moving to the next item
                setTimeout(() => {
                    changeIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1))
                }, 3000)
        }

        // Clean up event listener on unmount or when the current media changes
        return () => {
            videoRef.current.removeEventListener('ended', () =>
                changeIndex((prevIndex) => (prevIndex === files.length - 1 ? 0 : prevIndex + 1))
            )
        }
    }, [selectedIndex])

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.header__title}>
                    <div>{name}</div>
                    <div>{description}</div>
                </div>
                <img src={thumb} alt="src" />
            </div>
            <div className={styles.currentItem}>
                {files[selectedIndex]?.type === 'video' ? (
                    <div className={styles.itemVideo} key={files[selectedIndex].id}>
                        <div>
                            <video
                                autoplay={autoplay}
                                // muted
                                ref={videoRef}
                                // src={files[selectedIndex].source}
                                controls
                                height={'100%'}
                                width={'100%'}
                                id="video"
                                onEnded={handleMediaEnd}
                                onPlay={() => setAutoplay(true)}
                            >
                                <source src={files[selectedIndex].source} />
                            </video>
                            <canvas id="myCanvas"></canvas>
                            <section>
                                <ItemButtons
                                    item={files[selectedIndex]}
                                    fullScreen={fullScreen}
                                    setfullScreen={setfullScreen}
                                    video={true}
                                />
                            </section>
                        </div>
                    </div>
                ) : (
                    <div className={styles.itemImage} key={files[selectedIndex].id}>
                        <div>
                            <img src={files[selectedIndex].source} className={styles.media} alt="1" ref={videoRef} />
                            <canvas id="myCanvas"></canvas>
                            <ItemButtons
                                item={files[selectedIndex]}
                                fullScreen={fullScreen}
                                setfullScreen={setfullScreen}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className={styles.fileName}>{files[selectedIndex]?.title}</div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.downItems} id="scroll-container" onWheel={handleOnWheel}>
                    {items2}
                </div>
            </div>
            {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{ ...buttonStyle, ...(isHovered && buttonHoverStyle) }}
                    onClick={() => handleDownload()}
                >
                    {t("download_all")}
                </button>
            </div> */}
            <div className={styles.footer}>
                {!isMobile && (
                    <div className={styles.footer__links}>
                        {facebook_link ? (
                            <a href={facebook_link} target="_blank" rel="noopener noreferrer">
                                <FacebookLink />
                            </a>
                        ) : null}
                        {twitter_link ? (
                            <a href={twitter_link} target="_blank" rel="noopener noreferrer">
                                <TwitterLink />
                            </a>
                        ) : null}
                        {linkedin_link ? (
                            <a href={linkedin_link} target="_blank" rel="noopener noreferrer">
                                <LinkidInkLink />
                            </a>
                        ) : null}
                        {instagram_link ? (
                            <a href={instagram_link} target="_blank" rel="noopener noreferrer">
                                <InstLink />
                            </a>
                        ) : null}
                        {vimeo_link ? (
                            <a href={vimeo_link} target="_blank" rel="noopener noreferrer">
                                <VimeoLink />
                            </a>
                        ) : null}
                    </div>
                )}
                
                    <button
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ ...buttonStyle, ...(isHovered && buttonHoverStyle) }}
                        onClick={() => handleDownload()}
                    >
                        {t('download_all')}
                    </button>
                

                <div className={styles.footer__rightSide}>
                    <div>Created with</div>
                    <DeckLogo />
                </div>
            </div>
        </div>
    )
}
export default FirstDeckType

export const ItemButtons = ({ item, fullScreen, setfullScreen, video }) => {
    return (
        <div className={styles.btnGroup}>
            <a href={item.source} download style={{ height: 30 }}>
                <DownloadFileSvg />
            </a>

            {video ? (
                ''
            ) : (
                <FullScreenSvg
                    onClick={() => {
                        setfullScreen(true)
                    }}
                />
            )}
            {fullScreen && <FullScreenPopup onClick item={item} setfullScreen={setfullScreen} />}
        </div>
    )
}

const DownloadFileSvg = ({ onClick }) => {
    // const [isHovered, setIsHovered] = useState(false)

    // const handleMouseEnter = () => {
    //     setIsHovered(true)
    // }

    // const handleMouseLeave = () => {
    //     setIsHovered(false)
    // }
    return (
        <svg
            // style={{  cursor: isHovered ? 'pointer' : 'default' }}
            opacity={0.5}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
            onClick={onClick}
        >
            <path d="M15 6L15 18" stroke="white" strokeWidth="2" strokeLinecap="round" />
            <path
                d="M11.1111 13.7142L15 18.4285L18.8889 13.7142M7 14.9999V21.9999H23V14.9999"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
const FullScreenSvg = ({ onClick }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            opacity={0.5}
            style={{ marginLeft: 8 }}
            onClick={onClick}
        >
            <path
                d="M6.66667 2.49988H4.16667C3.72464 2.49988 3.30072 2.67547 2.98816 2.98803C2.67559 3.30059 2.5 3.72452 2.5 4.16654V6.66655M17.5 6.66655V4.16654C17.5 3.72452 17.3244 3.30059 17.0118 2.98803C16.6993 2.67547 16.2754 2.49988 15.8333 2.49988H13.3333M13.3333 17.4999H15.8333C16.2754 17.4999 16.6993 17.3243 17.0118 17.0117C17.3244 16.6992 17.5 16.2752 17.5 15.8332V13.3332M2.5 13.3332V15.8332C2.5 16.2752 2.67559 16.6992 2.98816 17.0117C3.30072 17.3243 3.72464 17.4999 4.16667 17.4999H6.66667"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
