import { Button, Card, CardContent, CardMedia, Menu, MenuItem } from '@mui/material'
import styles from './list-folder-file-item.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import {
    deleteProjectById,
    projectsSelector,
    removeFile,
    removeLike,
    selectFile,
    setLike,
} from '../../store/reducers/projects'
import formatBytes from '../../assets/helrers/byteFormeter'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../../assets/helrers/ItemTypes'
import { decksSelector } from '../../store/reducers/decks'
import { hightlightDeckContent } from '../../store/reducers/drag'
import { format } from 'date-fns'
import { BasketSvg } from '../../assets/img/svg/svgs'
import { pl } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'

const ListFoldeFileItem = ({ item, navigate, shared, uuid, deckFlow, onClick }) => {
    const dispatch = useDispatch()

    const { selectedFile } = useSelector(projectsSelector)
    const {
        currentDeck: { type },
    } = useSelector(decksSelector)

    const isFolder = item?.type === 'directory'
    const isSelected = selectedFile?.id === item.id

    const { deckId } = useParams()

    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.FILE,
            item: () => {
                return type === 1 ? { nodeId: item.id, deck_dir_id: deckId } : { file_id: item.id, deck_dir_id: deckId }
            },

            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    )

    useEffect(() => {
        if (isDragging) {
            dispatch(hightlightDeckContent(true))
        } else dispatch(hightlightDeckContent(false))
    }, [isDragging])

    const handleDownload = (id) => {
        const url = `${process.env.REACT_APP_BASE_URL}/api/download-files/${id}`
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'archive.zip')
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
    }

    return (
        <div
            ref={isFolder ? null : drag}
            style={{
                display: 'flex',
                // border: isSelected ? '2px solid #3376A3' : 'none',
                borderBottom: isSelected ? 'none' : '1px solid rgba(0, 0, 0, 0.1)',
                background: isSelected ? '#D7E7FF' : 'transparent',
                borderRadius: isSelected ? 5 : 0,
                padding: '0 10px',
            }}
            onDoubleClick={() => {
                if (deckFlow) {
                    navigate(
                        isFolder
                            ? `/deck-builder/${deckId}/project/${item.id}`
                            : `/deck-builder/${deckId}/file/${item.id}/${item.project_id}` // IF USE IN DECK PAGE
                    )
                } else
                    shared
                        ? navigate(
                              isFolder
                                  ? `/shared-project/${uuid}/project/${item.id}`
                                  : `/shared-project/${uuid}/file/${item.id}/${item.project_id}` // IF USE IN DECK PAGE
                          )
                        : navigate(isFolder ? `/project/${item.id}` : `/file/${item.project_id}/${item.id}`) // IF USE IN PROJECT PAGE
            }}
            onClick={(e) => dispatch(selectFile(item.id))}
        >
            <div className={styles.list__name}>
                {isFolder ? <FolderSvg /> : <img src={item.thumb ?? item.preview} alt="thumb" />}
                <div>{item.name}</div>
            </div>
            <span>{format(new Date(item.created_at), 'd MMMM yyyy', { locale: pl })}</span>
            <span>{`${formatBytes(item.size)}`}</span>
            <div className={styles.list__svg}>
                <div
                    className={styles.list__like}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        const options = {
                            type: isFolder ? 'project' : 'file',
                            id: item.id,
                            // shared: shared,
                        }
                        dispatch(item.isLiked ? removeLike(options) : setLike(options))
                    }}
                >
                    {item.isLiked ? (
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M20.9981 8.76424C18.2785 6.93856 15.5336 8.62576 14.9591 11.2757C14.3849 8.62559 10.6696 6.82689 8.36221 9.16299C5.49052 12.0705 6.91851 17.9295 14.9591 22C23.5144 17.2354 24.7392 11.2757 20.9981 8.76424Z"
                                fill="#E59EAD"
                                stroke="#E59EAD"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    ) : (
                        <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill={item.isLiked ? 'red' : 'none'}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M20.9981 8.76424C18.2785 6.93856 15.5336 8.62576 14.9591 11.2757C14.3849 8.62559 10.6696 6.82689 8.36221 9.16299C5.49052 12.0705 6.91851 17.9295 14.9591 22C23.5144 17.2354 24.7392 11.2757 20.9981 8.76424Z"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    )}

                    <span>{item.likesCount}</span>
                </div>
                {isFolder ? (
                    <DownloadSvg onClick={() => handleDownload(item.id)} />
                ) : (
                    <a href={item.source} download>
                        <DownloadSvg />
                    </a>
                )}
            </div>
            {/* <TreeDots /> */}
            <Dots id={item.id} isFolder={isFolder} />
        </div>
    )
}
export default ListFoldeFileItem
const DownloadSvg = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M15 6L15 18" stroke="black" strokeWidth="2" strokeLinecap="round" />
                <path
                    d="M11.1111 13.7144L15 18.4286L18.8889 13.7144M7 15.0001V22.0001H23V15.0001"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    )
}

const Dots = ({ id, isFolder }) => {
    const {t}=useTranslation()
    const [anchorElUser, setAnchorElUser] = useState(false)
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = (event) => {
        setAnchorElUser(null)
    }
    const dispatch = useDispatch()
    return (
        <>
            <Menu
                sx={{
                    marginLeft: -6,

                    ul: {
                        width: 150,
                        background: '#1F1F1F',
                        color: '#fff',
                        borderRadius: '5px',
                        padding: 0,
                    },
                    top: 12,
                    borderRadius: '5px',
                }}
                id="basic-menu"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {[t('Delete')].map((setting) => (
                    <MenuItem
                        key={setting}
                        sx={{
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            borderRadius: 5,
                            div: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: 40,
                                opacity: 0.5,
                                borderRadius: 5,
                                '&:hover': { opacity: 1, background: '#292929' },
                            },
                        }}
                        onClick={() => {
                            isFolder ? dispatch(deleteProjectById(id)) : dispatch(removeFile(id))

                            handleCloseUserMenu()
                        }}
                    >
                        <div>
                            {setting} <BasketSvg />
                        </div>
                    </MenuItem>
                ))}
            </Menu>
            <svg
                onClick={(e) => {
                    handleOpenUserMenu(e)
                }}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity="0.5">
                    <circle cx="15" cy="21" r="2" transform="rotate(-90 15 21)" fill="black" />
                    <circle cx="15" cy="15" r="2" transform="rotate(-90 15 15)" fill="black" />
                    <circle cx="15" cy="9" r="2" transform="rotate(-90 15 9)" fill="black" />
                </g>
            </svg>
        </>
    )
}

const FolderSvg = () => {
    return (
        <svg
            width="40"
            style={{ marginRight: 9 }}
            height="30"
            viewBox="0 0 40 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="20" height="24" rx="3" fill="#4C92C0" />
            <rect y="5" width="40" height="25" rx="3" fill="#3376A3" />
            {/* <path
                opacity="0.6"
                d="M15.8749 22V14.572H14.0749L14.3149 13.24H17.3869V22H15.8749ZM23.2625 22.156C21.0185 22.156 19.8425 20.74 19.8425 17.656C19.8425 14.62 21.1025 13.036 23.3585 13.036C25.7225 13.036 26.8385 14.608 26.8385 17.62C26.8385 20.68 25.4345 22.156 23.2625 22.156ZM23.3225 20.872C24.4865 20.872 25.2905 19.924 25.2905 17.632C25.2905 15.328 24.5225 14.308 23.3225 14.308C22.1585 14.308 21.3905 15.268 21.3905 17.632C21.3905 19.9 22.1225 20.872 23.3225 20.872Z"
                fill="white"
            /> */}
        </svg>
    )
}
