import { useState } from 'react'
import { resource } from '../../api/http'
import { searchSelector, setDeckSearch, setDeckSearchInputValue } from '../../store/reducers/search'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const DeckSearch = () => {
    const { deckSearchInput } = useSelector(searchSelector)
    const { t } = useTranslation()
    const [searchInputValue, setSearchInputValue] = useState(deckSearchInput)
    const dispatch = useDispatch()

    const handleSearchInputChange = (e) => {
        setSearchInputValue(e.target.value)
        dispatch(setDeckSearchInputValue(e.target.value))

        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/project/files-search`, { query: e.target.value })
            .then(({ data }) => {
                if (data) dispatch(setDeckSearch(data))
            })
    }

    return (
        <div>
            <div>
                <input value={searchInputValue} onChange={handleSearchInputChange} placeholder={t('search')} />
            </div>
        </div>
    )
}
export default DeckSearch
