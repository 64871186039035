import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, TextField } from '@mui/material'
import styles from './sign-up.module.sass'
import { useDispatch } from 'react-redux'
import { register } from '../../store/reducers/auth'
import { Link } from 'react-router-dom'
import CustomTextField from '../../components/custom-text-field/CustomTextField'
import { PinkBigLogo } from '../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'

const SignUp = ({ navigate }) => {
    // const loading = useSelector(authLoading)
    // const onHandleSubmitForm = () => {}
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password: '',
            passwordConfirm: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(2, 'First name must be at least 2 characters')
                .max(50, 'First name must be at most 50 characters')
                .required('Please enter a valid first name'),
            email: Yup.string().email('Invalid email address').required(t('req_email')),
            password: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&)_#^(=+|`:;'",./-])[A-Za-z\d@$!%*?&)_#^(=+|`:;'",./-]{8,}$/,
                    'Minimum of 8 characters with at least one uppercase, one number, and one special character'
                )
                .required('Password is a required field'),
            passwordConfirm: Yup.string()
                .when('password', {
                    is: (password) => password,
                    then: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Password confirmation does not match')
                        .required(t('confirm_pass')),
                    otherwise: Yup.string(),
                })
                .notRequired(),
            // policy: Yup.bool().oneOf([true]).required(),
        }),
        onSubmit: (values, { setFieldError }) => {
            dispatch(register({ values, navigate, setFieldError }))
        },
        validateOnMount: true,
    })
    return (
        <form onSubmit={formik.handleSubmit} className={styles.wrapper}>
            {/* <Logo /> */}

            <div>
                <PinkBigLogo />
                <h1 className={styles.title}>{t("sign_up")}</h1>
                <div className={styles.fields}>
                    <CustomTextField name="name" formik={formik} placeholder={t("username")} />
                    <CustomTextField name="email" formik={formik} placeholder="Email" />

                    <CustomTextField name="password" formik={formik} placeholder={t('password')} type="password" />
                    <CustomTextField
                        name="passwordConfirm"
                        formik={formik}
                        placeholder={t('confirm_your_pass')}
                        type="password"
                    />

                    <Button variant="contained" type="submit" disabled={!formik.isValid}>
                        {t("sign_up")}
                    </Button>
                </div>

                <div className={styles.links}>
                    <Link to="/sign-in">{t('log_in')}</Link>
                    <Link to="/restore-password">{t('forgot_pass')}</Link>
                </div>
            </div>
        </form>
    )
}

export default SignUp
