import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, TextField } from '@mui/material'
import styles from './teams-page.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../store/reducers/auth'
import { Link } from 'react-router-dom'
import CustomTextField from '../../components/custom-text-field/CustomTextField'
import { PinkBigLogo } from '../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'
import { projectsSelector } from '../../store/reducers/projects'
import SettingsAvatar from '../../components/avatar/SettingsAvatar'
import AddMembers from '../../components/popups/add-members-to-project/AddMembers'

const Teams = ({ navigate }) => {
    // const loading = useSelector(authLoading)
    // const onHandleSubmitForm = () => {}
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { projects } = useSelector(projectsSelector)
    return (
        <div className={styles.wrapper}>
            <div className={styles.members}>
                <div className={styles.members__header}>{t('teams')}</div>

                <div className={styles.members__projects}>
                    {projects?.map((project) => {
                        return (
                            <div className={styles.members__projects__item}>
                                <div>
                                    <div className={styles.members__projects__thumb}>
                                        {project.thumb ? <img src={project.thumb} alt="thumb" /> : <FolderSvg />}
                                    </div>
                                    <span>{project.name}</span>
                                </div>
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: 30 * project?.teamMembers.length - 10 * project?.teamMembers.length,
                                        }}
                                    >
                                        {project?.teamMembers.map((e, idx) => {
                                            const margin = idx * 10 + 'px'
                                            return <SettingsAvatar key={e.id} name={e.name} margin={margin} />
                                        })}
                                    </div>

                                    <AddMembers project={project} />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>{' '}
        </div>
    )
}

export default Teams
const FolderSvg = () => {
    return (
        <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="24" rx="3" fill="#4C92C0" />
            <rect y="5" width="40" height="25" rx="3" fill="#3376A3" />
            {/* <path
                opacity="0.6"
                d="M15.8749 22V14.572H14.0749L14.3149 13.24H17.3869V22H15.8749ZM23.2625 22.156C21.0185 22.156 19.8425 20.74 19.8425 17.656C19.8425 14.62 21.1025 13.036 23.3585 13.036C25.7225 13.036 26.8385 14.608 26.8385 17.62C26.8385 20.68 25.4345 22.156 23.2625 22.156ZM23.3225 20.872C24.4865 20.872 25.2905 19.924 25.2905 17.632C25.2905 15.328 24.5225 14.308 23.3225 14.308C22.1585 14.308 21.3905 15.268 21.3905 17.632C21.3905 19.9 22.1225 20.872 23.3225 20.872Z"
                fill="white"
            /> */}
        </svg>
    )
}
