import { Menu, MenuItem } from '@mui/material'
import styles from './folder-file-item.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import {
    deleteProjectById,
    projectsSelector,
    removeFile,
    removeLike,
    selectFile,
    setLike,
} from '../../store/reducers/projects'
import formatBytes from '../../assets/helrers/byteFormeter'
import { resource } from '../../api/http'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from '../../assets/helrers/ItemTypes'
import { decksSelector } from '../../store/reducers/decks'
import { hightlightDeckContent } from '../../store/reducers/drag'
import { authSelector } from '../../store/reducers/auth'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useUploady } from '@rpldy/uploady'
import { BasketSvg, DropSvg } from '../../assets/img/svg/svgs'
import Folder from './items/Folder'
import File from './items/File'

const FolderFileItem = ({ item, navigate, uuid, deckFlow, onClick, shareFlow }) => {
    const dispatch = useDispatch()
    const auth = useSelector(authSelector)
    const { selectedFile } = useSelector(projectsSelector)
    const {
        currentDeck: { type },
    } = useSelector(decksSelector)
    const [folderPreview, setFolderPreview] = useState()

    const isFolder = item?.type === 'directory'

    const isSelected = selectedFile?.id === item.id

    const { deckId } = useParams()
    const selectedStyle = isSelected ? '#D7E7FF' : '#DEE3EB'
    const selectedStyleFile = isSelected ? '#D7E7FF' : 'transparent'

    const longFileName = isFolder ? item.name : item.name.length > 20 ? item.name.slice(0, 20) + '...' : item.name

    useEffect(() => {
        LoadPreview()
    }, [])

    const LoadPreview = () => {
        if (isFolder) {
            try {
                const promise = resource
                    .get(`${process.env.REACT_APP_BASE_URL}/api/project/project-preview/${item.id}`)
                    .then((preview) => {
                        if (preview.data?.files?.length) setFolderPreview(preview.data)
                    })
            } catch (error) {
                alert(error)
            }
        }
    }

    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: ItemTypes.FILE,
            item: () => {
                return type === 1 ? { nodeId: item.id, deck_dir_id: deckId } : { file_id: item.id, deck_dir_id: deckId }
            },

            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    )
    const token = localStorage.getItem('token')
    const { setOptions, upload } = useUploady()

    const itemId = item.id

    const [, dropRef] = useDrop((e) => ({
        accept: NativeTypes.FILE,
        collect: (monitor) => ({
            isDragging: !!monitor.isOver(),
        }),
        drop: (item) => {
            if (deckFlow) return
            setOptions({
                destination: {
                    url: `${process.env.REACT_APP_BASE_URL}/api/projects/files/upload/${itemId}`,
                    headers: { Authorization: `Bearer ${token}` },
                },
            })
            upload(item.files)
            LoadPreview()
            e.preventDefault()
            e.stopPropagation() //stop propagination like load filein onep dolder
        },
    }))

    // const fileBorderStyle = isSelected ? '2px solid #3376A3' : '1px solid rgba(0, 0, 0, 0.2)'
    useEffect(() => {
        if (isDragging) {
            dispatch(hightlightDeckContent(true))
        } else dispatch(hightlightDeckContent(false))
    }, [isDragging])

    return isFolder ? (
        <Folder
            item={item}
            deckFlow={deckFlow}
            shareFlow={shareFlow}
            navigate={navigate}
            uuid={uuid}
            onClick={onClick}
        />
    ) : (
        <File item={item} navigate={navigate} uuid={uuid} deckFlow={deckFlow} onClick={onClick} shareFlow={shareFlow} />
    )
}
export default FolderFileItem
