import { Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { decksSelector, getAllDecks } from '../../store/reducers/decks'
import styles from './decks.module.sass'
import { useTranslation } from 'react-i18next'

const Decks = ({ navigate }) => {
    const { t } = useTranslation()
    const { decks } = useSelector(decksSelector)
    const dispatch = useDispatch()

    const sortFilters = [t('last_edit'), t('last_added'), 'a-z']

    const [sort, setSort] = useState(sortFilters[0])

    const [anchorSort, setAnchorSort] = React.useState(false)

    const handleCloseSortMenu = () => {
        setAnchorSort(null)
    }
    let filteredArray = decks.map((e) => e)
    console.log('decks', decks)
    // const filesArray = Object.keys(currentFiles).map((e) => {
    //     return currentFiles[e]
    // })

    if (sort === t('last_edit')) {
        filteredArray.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    }
    if (sort === t('last_added')) {
        filteredArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
    if (sort === 'a-z') {
        filteredArray.sort((a, b) => a.name.localeCompare(b.name))
    }

    useEffect(() => {
        if (!decks.length) dispatch(getAllDecks())
    }, [])
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <DecksLogo />
                    <span>{t('decks')}</span>
                </div>
                <button onClick={() => navigate('/create-deck')}>{t('deck_new')}</button>
                {/* <CreateDecaPopup navigate={navigate} /> */}
            </div>
            <div className={styles.flexRow}>
                <div className={styles.decks__container}>
                    <div className={styles.decks__filters}>
                        <div className={styles.sort}>
                            <div>{t('sort')} </div>
                            <div className={styles.sort__filter}>
                                {sort}
                                <svg
                                    onClick={(e) => setAnchorSort(e.currentTarget)}
                                    width="8"
                                    height="5"
                                    viewBox="0 0 8 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                                </svg>
                            </div>

                            <Menu
                                // sx={{ mt: '45px' }}
                                id={`Sort`}
                                anchorEl={anchorSort}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                sx={{
                                    ul: {
                                        background: '#1F1F1F',
                                        color: 'gray',
                                        borderRadius: '5px',
                                        padding: 0,
                                        width: 145,
                                    },
                                    top: 15,
                                    borderRadius: '5px',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorSort)}
                                onClose={handleCloseSortMenu}
                            >
                                {sortFilters.map((item, idx) => {
                                    return (
                                        <MenuItem
                                            key={idx}
                                            sx={{
                                                padding: '11px 0px 10px 10px',
                                                background: sort === item ? '#292929' : '#1F1F1F',
                                                color: sort === item ? '#fff' : 'null',
                                                fontFamily: 'Zeitung Micro Pro',
                                                fontSize: 12,
                                                borderRadius: 5,
                                                '&:hover': { color: '#fff', background: '#292929' },
                                            }}
                                            onClick={() => {
                                                setSort(item)
                                                handleCloseSortMenu()
                                            }}
                                        >
                                            <div>{item}</div>
                                        </MenuItem>
                                    )
                                })}
                            </Menu>

                            <span className={styles.project__countProjects}>
                                {/* {filtredProjectsMemo.length ?? ''} items, 29.3 GB */}
                            </span>
                        </div>

                        <div className={styles.flex}></div>
                    </div>
                    <div className={styles.projWrapper}>
                        {filteredArray?.map((item, idx) => {
                            console.log(item)
                            return (
                                <div
                                    className={styles.decks__item}
                                    onDoubleClick={() => navigate(`/deck-builder/${item.id}`)}
                                    key={item.id}
                                >
                                    <div>
                                        <img src={item.thumb} alt={item.name} />
                                        <div>
                                            <span>{item.name}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {/* <RightBar /> */}
            </div>
        </div>
    )
}

export default Decks

const DecksLogo = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.2">
                <path
                    d="M2.26811 1.89429L13.7319 1.89429L14.7708 6.89429L1.22915 6.89429L2.26811 1.89429Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="2"
                />
                <line x1="8" y1="7.60584" x2="8" y2="15.1058" stroke="black" strokeWidth="2" />
                <path d="M5.03857 14.1058H10.5" stroke="black" strokeWidth="2" strokeLinecap="square" />
            </g>
        </svg>
    )
}
