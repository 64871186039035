import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'
import { resource } from '../../api/http'
import { getComments } from './comments'
import { setLoading, setSuccess } from './popups'

const namespace = 'projects'

const CREATE = `${namespace}/CREATE_PROJECT`
const GET_ALL = `${namespace}/GET_ALL`
const SET_ALL = `${namespace}/SET_ALL`
const GET_BY_ID = `${namespace}/GET_BY_ID`
const SET_BY_ID = `${namespace}/SET_BY_ID`
const UPDATE_BY_ID = `${namespace}/UPDATE_BY_ID`
const DELETE_BY_ID = `${namespace}/DELETE_BY_ID`
const RESET = `${namespace}/RESET`
const SET_LOAD = `${namespace}/SET_LOAD`
const ADD_FOLDER = `${namespace}/ADD_FOLDER`
const SET_BREADCRUMBS = `${namespace}/SET_BREADCRUMBS`
const GET_FOLDER_STRUCTURE_BY_ID = `${namespace}/GET_FOLDER_STRUCTURE_BY_ID`
const ADD_DOWNLOADED_ID = `${namespace}/ADD_DOWNLOADED_ID`
const ADD_DEFAULT_EXPANDED = `${namespace}/ADD_DEFAULT_EXPANDED`
const REMOVE_DEFAULT_EXPANDED = `${namespace}/REMOVE_DEFAULT_EXPANDED`
const SET_LIKE = `${namespace}/SET_LIKE`
const REMOVE_LIKE = `${namespace}/REMOVE_LIKE`
const SELECT_FILE = `${namespace}/SELECT_FILE`
const SET_SELECTED_FILE = `${namespace}/SET_SELECTED_FILE`
const CREATE_SHARE_LINK = `${namespace}/CREATE_SHARE_LINK`
const GET_SHARED_FILE = `${namespace}/GET_SHARED_FILE`
const SET_SHARED_FILE = `${namespace}/SET_SHARED_FILE`
const GET_SHARED_PROJECT = `${namespace}/GET_SHARED_PROJECT`
const SET_SHARED_PROJECT = `${namespace}/SET_SHARED_PROJECT`
const REMOVE_FILE = `${namespace}/REMOVE_FILE`
const UPDATE_FILE_BY_ID = `${namespace}/UPDATE_FILE_BY_ID`
const SET_FAVORITES = `${namespace}/SET_FAVORITES`
const DOWNLOAD_FILES_BY_IDS = `${namespace}/DOWNLOAD_FILES_BY_IDS`
// const RESET_PROJECTS = `${namespace}/RESET_PROJECTS`
// const SET_CURRENT_STRUCTURE = `${namespace}/SET_CURRENT_STRUCTURE`
// const SET_ERROR = `${namespace}/SET_ERROR`;

export const createProject = createAction(CREATE)
export const getAllProjects = createAction(GET_ALL)
export const setAllProjects = createAction(SET_ALL)
export const getProjectById = createAction(GET_BY_ID)
export const setProjectById = createAction(SET_BY_ID)
export const updateProjectById = createAction(UPDATE_BY_ID)
export const deleteProjectById = createAction(DELETE_BY_ID)
export const resetProjects = createAction(RESET)
export const addFolder = createAction(ADD_FOLDER)
export const setBreadcrumbs = createAction(SET_BREADCRUMBS)
export const getFolderStructureById = createAction(GET_FOLDER_STRUCTURE_BY_ID)
export const addDownloadedTreeItem = createAction(ADD_DOWNLOADED_ID)

export const addDefaultExpanded = createAction(ADD_DEFAULT_EXPANDED)
export const removeDefaultExpanded = createAction(REMOVE_DEFAULT_EXPANDED)

export const setLike = createAction(SET_LIKE)
export const removeLike = createAction(REMOVE_LIKE)

export const selectFile = createAction(SELECT_FILE)
export const setSelectedFile = createAction(SET_SELECTED_FILE)
export const createShareLink = createAction(CREATE_SHARE_LINK)
export const getSharedFile = createAction(GET_SHARED_FILE)
export const setSharedFile = createAction(SET_SHARED_FILE)
export const getSharedProject = createAction(GET_SHARED_PROJECT)
export const setSharedProject = createAction(SET_SHARED_PROJECT)
export const removeFile = createAction(REMOVE_FILE)
export const updateFileById = createAction(UPDATE_FILE_BY_ID)
export const setFavorites = createAction(SET_FAVORITES)

export const downloadFilesByIds = createAction(DOWNLOAD_FILES_BY_IDS)

// export const setError = createAction(SET_ERROR);

const initialState = {
    load: false,
    projects: [],
    currentFiles: [],
    breadcrumbs: [],
    downloadedTreeItems: [],
    defaultExpanded: [],
    selectedFile: false,
    sharedFile: false,
    sharedProject: false,
    favorites: [],
    // error: null,
    // payload: null,
}

export default handleActions(
    {
        [RESET]: () => initialState,
        [SET_ALL]: (state, { payload }) => ({ ...state, projects: payload }),
        // [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_BY_ID]: (state, { payload }) => ({ ...state, currentFiles: payload }),
        // [ADD_FOLDER]: (state, { payload }) => ({ ...state, currentFiles: [payload, ...state.currentFiles] }),
        [SET_BREADCRUMBS]: (state, { payload }) => ({ ...state, breadcrumbs: payload.reverse() }),
        [ADD_DOWNLOADED_ID]: (state, { payload }) => ({
            ...state,
            downloadedTreeItems: [...state.downloadedTreeItems, payload],
        }),
        [ADD_DEFAULT_EXPANDED]: (state, { payload }) => ({
            ...state,
            defaultExpanded: [...state.defaultExpanded, payload],
        }),
        [REMOVE_DEFAULT_EXPANDED]: (state, { payload }) => ({
            ...state,
            defaultExpanded: state.defaultExpanded.filter((e) => e !== payload),
        }),
        [SET_SELECTED_FILE]: (state, { payload }) => ({
            ...state,
            selectedFile: payload,
        }),
        [SET_SHARED_FILE]: (state, { payload }) => ({
            ...state,
            sharedFile: payload,
        }),
        [SET_SHARED_PROJECT]: (state, { payload }) => ({
            ...state,
            sharedProject: payload,
        }),
        [SET_FAVORITES]: (state, { payload }) => ({
            ...state,
            favorites: payload,
        }),
    },
    initialState
)

export const projectsSelector = (state) => state[namespace]

export function* addNewfolderIntoTree() {
    while (true) {
        const {
            payload: { data, parentId },
        } = yield take(ADD_FOLDER)

        try {
            const { projects, currentFiles } = yield select(projectsSelector)
            console.log('pay', data)
            let findValue = (arr, val) => {
                for (let obj of arr) {
                    if (obj.id === val) {
                        obj.files = [data, ...(obj.files ?? [])] // add new item
                        return obj
                    }
                    if (obj.files) {
                        let result = findValue(obj.files ?? [], val)
                        if (result) {
                            return result
                        }
                    }
                }
                return undefined
            }
            const x = currentFiles.filter(
                (e) => e.parent_id === data.parent_id || e.parent_id === data.project_id // if add folder in opened folder  (set in current foles)
            )
            const result = findValue(projects, data.parent_id) || x

            if (result) {
                yield put(setAllProjects(projects)) // set new item
            }
            if (data.parent_id === parentId) {
                yield put(setProjectById([data, ...currentFiles]))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* findSelectedFile() {
    while (true) {
        const { payload } = yield take(SELECT_FILE)
        try {
            const { currentFiles } = yield select(projectsSelector)

            let findValue = (arr, val) => {
                for (let obj of arr) {
                    if (obj.id === val) {
                        return obj
                    }
                    if (obj.files) {
                        let result = findValue(obj.files, val)
                        if (result) {
                            return result
                        }
                    }
                }
                return undefined
            }

            const result = findValue(currentFiles, payload)

            if (result) {
                yield put(setSelectedFile(result))
                // yield put(getComments(result.id))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* createRequest() {
    while (true) {
        const {
            payload: { values, parentId, resetForm },
        } = yield take(CREATE)
        try {
            const { projects } = yield select(projectsSelector)

            const {
                data: { data, success },
            } = yield call(resource.post, 'api/projects', values)

            if (parentId && success) {
                // create folder  //if have parrent id its means we create project inside project (folder)
                yield put(addFolder({ data, parentId: parentId }))
                resetForm()
            } else if (success) {
                // create new proj
                resetForm()
                yield put(setAllProjects([data, ...projects]))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* getAllRequest() {
    while (true) {
        yield take(GET_ALL)
        try {
            const {
                data: { data },
            } = yield call(resource.get, 'api/projects')
            if (data) {
                yield put(setAllProjects(data))
            }
        } catch (err) {
            // console.log(err);
        }
    }
}
export function* getFolderStructureByIdRequest() {
    while (true) {
        const { payload } = yield take(GET_FOLDER_STRUCTURE_BY_ID)

        try {
            const {
                data: { data, tree },
            } = yield call(resource.post, `/api/get-projects-as-dir-structure`, { project_id: +payload })
            if (data) {
                const { projects, downloadedTreeItems } = yield select(projectsSelector)

                let findValue = (arr, val) => {
                    for (let obj of arr) {
                        if (obj.id === val) {
                            obj.files = data
                            return obj
                        }
                        if (obj.files) {
                            let result = findValue(obj.files, val)
                            if (result) {
                                result.files = data
                                return result
                            }
                        }
                    }
                    return undefined
                }

                if (!downloadedTreeItems.includes(payload) && findValue(projects, +payload)) {
                    yield put(addDownloadedTreeItem(payload))
                }
                yield put(setAllProjects(projects))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* getByIdRequest() {
    while (true) {
        const { payload } = yield take(GET_BY_ID)

        try {
            const {
                data: { data, tree },
            } = yield call(resource.post, `/api/get-projects-as-dir-structure`, { project_id: +payload })
            if (data) {
                yield put(setProjectById(data))
                yield put(setBreadcrumbs(tree))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* updateByIdRequest() {
    while (true) {
        const {
            payload: { fd, id, updateMainProject },
        } = yield take(UPDATE_BY_ID)
        try {
            yield put(setLoading(true))
            const {
                data: { data, success, message },
            } = yield call(resource.post, `api/projects/${id}`, fd)
            if (success) {
                const { currentFiles, projects } = yield select(projectsSelector)
                yield put(setSuccess({ visible: true, text: message ?? 'Success' }))
                if (updateMainProject) {
                    yield put(
                        setAllProjects(
                            projects.map((obj) => {
                                if (obj.id === id) {
                                    // If the object's ID matches the provided ID, replace it with the updated object
                                    return { ...obj, ...data }
                                }
                                return obj // Return the original object for others
                            })
                        )
                    )
                } else
                    yield put(
                        setProjectById(
                            currentFiles.map((obj) => {
                                if (obj.id === id) {
                                    // If the object's ID matches the provided ID, replace it with the updated object
                                    return { ...obj, ...data }
                                }
                                return obj // Return the original object for others
                            })
                        )
                    )
                yield put(setSelectedFile(data))
            }
        } catch (err) {
            yield put(setSuccess({ visible: true, text: err.message ?? 'Error' }))
        } finally {
            yield put(setLoading(false))
        }
    }
}
export function* deleteByIdRequest() {
    while (true) {
        const { payload } = yield take(DELETE_BY_ID)
        try {
            const { projects, currentFiles } = yield select(projectsSelector)
            const {
                data: { success },
            } = yield call(resource.delete, `api/projects/${payload}`)

            if (success) {
                const removeById = (arr, targetId) =>
                    arr.reduce(
                        (acc, obj) =>
                            obj.id === targetId
                                ? acc
                                : [
                                      ...acc,
                                      {
                                          ...obj,
                                          ...(obj.files && { files: removeById(obj.files, targetId) }),
                                      },
                                  ],
                        []
                    )

                yield put(setAllProjects(removeById(projects, +payload)))
                yield put(
                    setProjectById(
                        currentFiles.filter((e) => {
                            return e.id !== +payload
                        })
                    )
                )
            }
        } catch (err) {
            // console.log(err);
        }
    }
}
export function* setLikeRequest() {
    while (true) {
        const {
            payload: { type, id, shared },
        } = yield take(SET_LIKE)
        //Type can be "project" or "file"
        try {
            const {
                data: { data },
            } = yield call(resource.get, `api/${type}/like/${id}`)
            if (data) {
                const { currentFiles, projects, sharedProject } = yield select(projectsSelector)

                const fileIndex = shared
                    ? sharedProject.data.findIndex((e) => e.id == id)
                    : currentFiles.findIndex((e) => e.id == id)

                if (shared) {
                    if (fileIndex !== -1) {
                        sharedProject.data[fileIndex] = data
                        yield put(setSharedProject(sharedProject))
                    }
                } else {
                    if (fileIndex === -1) {
                        const projectIndex = projects.findIndex((e) => e.id == id)
                        projects[projectIndex] = data
                        yield put(setProjectById(projects))
                    } else {
                        currentFiles[fileIndex] = data
                        yield put(setProjectById(currentFiles))
                    }
                }
            }
        } catch (err) {
            // console.log(err);
        }
    }
}
export function* removeLikeRequest() {
    while (true) {
        const {
            payload: { type, id, shared },
        } = yield take(REMOVE_LIKE)
        //Type can be "project" or "file"
        try {
            const {
                data: { data },
            } = yield call(resource.get, `api/${type}/unlike/${id}`)
            if (data) {
                const { currentFiles, projects, sharedProject, favorites } = yield select(projectsSelector)

                const fileIndex = shared
                    ? sharedProject.data.findIndex((e) => e.id == id)
                    : currentFiles.findIndex((e) => e.id == id)

                if (shared) {
                    if (fileIndex !== -1) {
                        sharedProject.data[fileIndex] = data
                        yield put(setSharedProject(sharedProject))
                    }
                } else {
                    if (fileIndex === -1) {
                        const projectIndex = projects.findIndex((e) => e.id == id)
                        projects[projectIndex] = data
                        yield put(setProjectById(projects))
                    } else {
                        currentFiles[fileIndex] = data
                        yield put(setProjectById(currentFiles))
                    }
                }
                if (favorites.files.length || favorites.directories.length) {
                    const files = favorites.files.filter((e) => e.id !== id)
                    const directories = favorites.directories.filter((e) => e.id !== id)

                    yield put(setFavorites({ files, directories }))
                }
            }
        } catch (err) {
            // console.log(err);
        }
    }
}
export function* createShareLinkRequest() {
    while (true) {
        const {
            payload: { payload, setFieldValue, file },
        } = yield take(CREATE_SHARE_LINK)
        try {
            const {
                data: { projectUrl, fileUrl },
            } = yield call(resource.post, `api/share-${file ? 'file' : 'project'}`, payload)
            if (projectUrl) {
                setFieldValue(projectUrl)
            } else if (fileUrl) {
                setFieldValue(fileUrl)
            } else setFieldValue('ERROR')
        } catch (err) {
            console.log(err)
        }
    }
}
export function* getSharedFileRequest() {
    while (true) {
        const { payload } = yield take(GET_SHARED_FILE)
        try {
            const {
                data: { data },
            } = yield call(resource.get, `api/get-shared-file/${payload}`)
            if (data) {
                yield put(setSharedFile(data))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* getSharedProjectRequest() {
    while (true) {
        const { payload } = yield take(GET_SHARED_PROJECT)
        try {
            const {
                data: { data },
            } = yield call(resource.get, `api/get-shared-project/${payload}`)
            if (data) {
                yield put(setSharedProject(data))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* removeFileRequest() {
    while (true) {
        const { payload } = yield take(REMOVE_FILE)
        try {
            const {
                data: { success },
            } = yield call(resource.delete, `api/files/${payload}`)

            if (success) {
                const { currentFiles, projects } = yield select(projectsSelector)

                yield put(setProjectById(currentFiles.filter((elem) => elem.id !== +payload)))

                const removeById = (arr, targetId) =>
                    arr.reduce(
                        (acc, obj) =>
                            obj.id === targetId
                                ? acc
                                : [
                                      ...acc,
                                      {
                                          ...obj,
                                          ...(obj.files && { files: removeById(obj.files, targetId) }),
                                      },
                                  ],
                        []
                    )

                yield put(setAllProjects(removeById(projects, +payload)))
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* updateFileByIdRequest() {
    while (true) {
        const {
            payload: { values, id },
        } = yield take(UPDATE_FILE_BY_ID)

        try {
            yield put(setLoading(true))
            const {
                data: { data, success, message },
            } = yield call(resource.put, `api/files/${id}`, values)
            if (success) {
                yield put(setSuccess({ visible: true, text: message ?? 'Success' }))
                const { currentFiles } = yield select(projectsSelector)

                yield put(
                    setProjectById(
                        currentFiles.map((obj) => {
                            if (obj.id === id) {
                                // If the object's ID matches the provided ID, replace it with the updated object
                                return { ...obj, ...data }
                            }
                            return obj // Return the original object for others
                        })
                    )
                )
                yield put(setSelectedFile(data))
            }
        } catch (err) {
            // console.log(err);
        } finally {
            yield put(setLoading(false))
        }
    }
}
export function* getComentsWhenSelectFile() {
    while (true) {
        yield take(SET_SELECTED_FILE)

        try {
            yield select(projectsSelector)
            yield put(getComments())
        } catch (err) {
            console.log(err)
        }
    }
}
export function* downloadFilesArray() {
    while (true) {
        const { payload } = yield take(DOWNLOAD_FILES_BY_IDS)
        console.log('payload', payload)
        try {
            const {
                data: { data, success, message },
            } = yield call(resource.put, `api/file/download-files-by-ids-array`, { fileIdsArray: payload })
            if (success) {
                const { currentFiles } = yield select(projectsSelector)
                console.log('data', data)
                // yield put(
                //     setProjectById(
                //         currentFiles.map((obj) => {
                //             if (obj.id === id) {
                //                 // If the object's ID matches the provided ID, replace it with the updated object
                //                 return { ...obj, ...data }
                //             }
                //             return obj // Return the original object for others
                //         })
                //     )
                // )
                // yield put(setSelectedFile(data))
            }
        } catch (err) {
            // console.log(err);
        }
    }
}
export function* sagas() {
    yield all([
        createRequest(),
        getAllRequest(),
        getByIdRequest(),
        updateByIdRequest(),
        deleteByIdRequest(),
        getFolderStructureByIdRequest(),
        setLikeRequest(),
        removeLikeRequest(),
        findSelectedFile(),
        addNewfolderIntoTree(),
        createShareLinkRequest(),
        getSharedFileRequest(),
        getSharedProjectRequest(),
        removeFileRequest(),
        updateFileByIdRequest(),
        getComentsWhenSelectFile(),
        downloadFilesArray(),
    ])
}
