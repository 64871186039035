import { Menu, MenuItem } from '@mui/material'
import styles from '../folder-file-item.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import {
    deleteProjectById,
    projectsSelector,
    removeFile,
    removeLike,
    selectFile,
    setLike,
} from '../../../store/reducers/projects'
import formatBytes from '../../../assets/helrers/byteFormeter'
import { resource } from '../../../api/http'
import { useEffect, useState } from 'react'

import { authSelector } from '../../../store/reducers/auth'

import { BasketSvg, DropSvg } from '../../../assets/img/svg/svgs'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useUploady } from '@rpldy/uploady'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Folder = ({ item, deckFlow, shareFlow, navigate, uuid, onClick }) => {
    const { t } = useTranslation()
    const { selectedFile } = useSelector(projectsSelector)
    const auth = useSelector(authSelector)
    const isSelected = selectedFile?.id === item?.id
    const fileBorderStyle = isSelected ? '2px solid #3376A3' : '2px solid rgba(255, 0, 0, .0)'

    const selectedStyleFile = isSelected ? '#D7E7FF' : 'transparent'
    const selectedStyle = isSelected ? '#D7E7FF' : '#DEE3EB'

    const [folderPreview, setFolderPreview] = useState()
    const dispatch = useDispatch()
    const { setOptions, upload } = useUploady()
    const longFileName = item.name
    const { deckId } = useParams()
    // console.log('folderPreview', folderPreview)
    useEffect(() => {
        LoadPreview()
    }, [item])

    const LoadPreview = () => {
        try {
            const promise = resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/project/project-preview/${item.id}`)
                .then((preview) => {
                    if (preview.data?.files?.length) setFolderPreview(preview.data)
                })
        } catch (error) {
            alert(error)
        }
    }

    const token = localStorage.getItem('token')

    const [, dropRef] = useDrop((e) => ({
        accept: NativeTypes.FILE,
        collect: (monitor) => ({
            isDragging: !!monitor.isOver(),
        }),
        drop: (dropItem) => {
            if (deckFlow) return
            setOptions({
                destination: {
                    url: `${process.env.REACT_APP_BASE_URL}/api/projects/files/upload/${item.id}`,
                    headers: { Authorization: `Bearer ${token}` },
                },
            })
            upload(dropItem.files)
            LoadPreview()
            // e.preventDefault()
            // e.stopPropagation() //stop propagination like load filein onep dolder
        },
    }))

    return (
        <div
            style={{
                border: fileBorderStyle,
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                margin: '0px 20px 20px 0',
                // minHeight: "164px"
            }}
            ref={(node) => dropRef(node)}
            key={item.id}
            onDoubleClick={() => {
                if (deckFlow) {
                    navigate(`/deck-builder/${deckId}/project/${item.id}`)
                    return
                }

                if (shareFlow) {
                    navigate(`/shared-project/${uuid}/project/${item.id}`)
                    return
                }
                navigate(`/project/${item.id}`) // IF USE IN PROJECT PAGE
            }}
            onClick={onClick ? onClick : (e) => dispatch(selectFile(item.id))}
        >
            {!isSelected && (
                <svg width="296" height="20" viewBox="0 0 296 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 10C0 4.47715 4.47715 0 10 0H76.851C81.2375 0 85.5707 0.961937 89.5451 2.81803L113.619 14.0607C114.944 14.6794 116.388 15 117.85 15H290.5C293.261 15 295.5 17.2386 295.5 20V20H0V10Z"
                        fill={selectedStyle}
                    />
                </svg>
            )}

            <div
                style={{
                    width: 296, //like size header svg for folder
                    backgroundColor: selectedStyle,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderRadius: isSelected ? '9px 9px 10px 10px' : '0px 0px 10px 10px',
                    paddingTop: isSelected ? '14px' : 0,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: '164px',
                }}
            >
                {folderPreview?.files?.length ? (
                    <div className={styles.folder__wrapper}>
                        <img src={folderPreview?.files[0].preview} alt="folder preview" />
                        <div>
                            {folderPreview?.files[1]?.preview && (
                                <img src={folderPreview?.files[1]?.preview} alt="folder preview2" />
                            )}
                            <div>
                                <span>{`+${folderPreview?.count}`}</span>
                            </div>
                        </div>
                    </div>
                ) : null}

                {!folderPreview?.files?.length && (shareFlow || deckFlow) ? (
                    <div
                        style={{
                            height: 115,
                            margin: '0 5px',
                            background: isSelected ? 'rgb(215, 231, 255)' : 'transparent',
                            borderRadius: '10px 10px 0px 0px ',
                        }}
                    >
                        <div className={styles.empty}>
                            {/* <DropSvg /> */}
                            <span>{t('empty_folder')}</span>
                        </div>
                    </div>
                ) : null}

                {!folderPreview?.files?.length && !shareFlow && !deckFlow ? (
                    <div
                        style={{
                            height: '100%',
                            margin: '0 5px',
                            background: isSelected ? 'rgb(215, 231, 255)' : 'transparent',
                            borderRadius: '10px 10px 0px 0px ',
                        }}
                    >
                        <div className={styles.empty}>
                            <DropSvg />
                            <span>Drag and drop files here</span>
                        </div>
                    </div>
                ) : null}

                <div
                    style={{
                        background: selectedStyleFile,
                        color: '#fff',
                        // height: 82,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        padding: '7px 10px 12px',
                        borderRadius: '0 0 10px 10px',
                    }}
                >
                    <div className={styles.flexBetween}>
                        <span>{longFileName}</span>

                        <div className={styles.flex}>
                            <span>{item.size ? `${formatBytes(item.size)}  ${t(item?.type)}` : 'Folder pusty'}</span>
                        </div>
                    </div>
                    <div className={styles.card__likeComment}>
                        <div>
                            <div
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    const options = {
                                        type: 'project',
                                        id: item.id,
                                        shared: shareFlow,
                                    }
                                    if (auth) {
                                        dispatch(item.isLiked ? removeLike(options) : setLike(options))
                                    } else navigate('/sign-in')
                                }}
                            >
                                {item.isLiked ? (
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.2572 4.65713C12.8897 3.08732 10.5 4.53806 9.99986 6.81658C9.5 4.53791 6.26554 2.9913 4.25684 5C1.75684 7.5 3 12.5379 9.99986 16.0379C17.4478 11.9411 18.5141 6.81657 15.2572 4.65713Z"
                                            fill="#E59EAD"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15.2572 4.65713C12.8897 3.08732 10.5 4.53806 9.99986 6.81658C9.5 4.53791 6.26554 2.9913 4.25684 5C1.75684 7.5 3 12.5379 9.99986 16.0379C17.4478 11.9411 18.5141 6.81657 15.2572 4.65713Z"
                                            stroke="black"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )}

                                <span>{item.likesCount > 0 ? item.likesCount : ''}</span>
                            </div>
                            <div
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()

                                    if (!auth) {
                                        navigate('/sign-in')
                                    }
                                }}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.89636 14.6724C5.92905 14.4657 5.82955 14.2605 5.64701 14.1581C3.70612 13.07 2.5 11.3101 2.5 9.37582C2.5 6.23058 5.74606 3.5 10 3.5C14.2539 3.5 17.5 6.23058 17.5 9.37582C17.5 12.5211 14.2539 15.2516 10 15.2516C9.61326 15.2516 9.2336 15.2287 8.86305 15.1844C8.72923 15.1684 8.59461 15.2071 8.48973 15.2917C7.98627 15.698 7.15118 16.2031 6.30095 16.4079C5.653 16.564 5.05471 16.5374 4.5655 16.2186C4.57042 16.2154 4.57536 16.2122 4.58034 16.209C4.71254 16.1228 4.86422 16.024 5.0064 15.9198C5.35641 15.6633 5.8018 15.2704 5.89636 14.6724Z"
                                        stroke="black"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span>{item.commentsCount > 0 ? item.commentsCount : ''}</span>
                            </div>
                        </div>
                        {isSelected && !shareFlow && <Dots id={item.id} isFolder={true} />}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Folder
const Dots = ({ id, isFolder }) => {
    const { t } = useTranslation()
    const [anchorElUser, setAnchorElUser] = useState(false)
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseUserMenu = (event) => {
        setAnchorElUser(null)
    }
    const dispatch = useDispatch()
    return (
        <div style={{ position: 'relative' }}>
            <Menu
                sx={{
                    marginLeft: -15.5,
                    ul: {
                        width: 295,
                        background: '#1F1F1F',
                        color: '#fff',
                        borderRadius: '5px',
                        padding: 0,
                    },
                    top: 12,
                    borderRadius: '5px',
                }}
                id="basic-menu"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {[t('Delete')].map((setting) => (
                    <MenuItem
                        key={setting}
                        sx={{
                            fontFamily: 'Zeitung Micro Pro',
                            fontSize: 12,
                            borderRadius: 5,
                            div: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                height: 40,
                                opacity: 0.5,
                                '&:hover': { opacity: 1, background: '#292929' },
                            },
                        }}
                        onClick={() => {
                            isFolder ? dispatch(deleteProjectById(id)) : dispatch(removeFile(id))

                            handleCloseUserMenu()
                        }}
                    >
                        <div>
                            {setting} <BasketSvg />
                        </div>
                    </MenuItem>
                ))}
            </Menu>
            <svg
                width="18"
                onClick={(e) => {
                    handleOpenUserMenu(e)
                }}
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="3" cy="9" r="2" fill="#3376A3" />
                <circle cx="9" cy="9" r="2" fill="#3376A3" />
                <circle cx="15" cy="9" r="2" fill="#3376A3" />
            </svg>
        </div>
    )
}
