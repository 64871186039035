import React, { useEffect, useState } from 'react'
import styles from '../settings.module.sass'
import { resource } from '../../../api/http'
import { useSelector } from 'react-redux'
import { userSelector } from '../../../store/reducers/user'
import { useTranslation } from 'react-i18next'

const Plans = ({ navigate }) => {
    const { t } = useTranslation()
    const [plans, setPlans] = useState([])

    const { subscription } = useSelector(userSelector)

    const hasActivePlan = subscription?.stripe_status === 'active'

    useEffect(() => {
        resource.get(`${process.env.REACT_APP_BASE_URL}/api/user/plans`).then(({ data: { data, success } }) => {
            // if (data) dispatch(setSharedDeck(data))
            setPlans(data)
        })
        // dispatch(getSharedProject(uuid))
    }, [])

    const CustomPlans = plans.map((e, idx) => {
        return {
            ...e,
            activeItems: ['Detail Page', 'Ratings and Reviews', 'E-mail', 'Url', 'Phone', 'Additional Phone'],
            deactivateItems: [],
            recomended: idx === 1 ? true : false,
        }
    })

    return (
        <div className={styles.plans}>
            <div className={styles.plans__title}>{t('plans_billings')}</div>
            <div className={styles.plans__container}>
                {CustomPlans.map((e) => (
                    <div className={styles.plans__item} key={e.name}>
                        {e.recomended ? <div className={styles.plans__recomended}>recomended</div> : null}
                        <div>{e.name}</div>
                        <div className={styles.plans__price}>
                            <span>$</span>
                            {e.price}
                            <span>/Year</span>
                        </div>
                        <div className={styles.plans__active}>
                            {e.activeItems.map((activItem, idx) => (
                                <div className={styles.plans__activeItem} key={idx}>
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect width="16" height="16" fill="#3376A3" />
                                    </svg>

                                    <div> {activItem}</div>
                                </div>
                            ))}
                        </div>

                        <div className={styles.plans__btn}>
                            <button
                                onClick={() => navigate('/checkout-form', { state: { planId: e.id } })}
                                disabled={hasActivePlan}
                            >
                                {e.name === subscription?.name && hasActivePlan ? 'Is active' : 'Start Today'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Plans
