import React, { useRef } from 'react'
import styles from '../deck-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { useDrop } from 'react-dnd'
import { useItemFinalizeListener, useUploady } from '@rpldy/uploady'
import { useParams } from 'react-router-dom'
import { addFiles, decksSelector, addProjectFile, setCurrentDeck } from '../../../store/reducers/decks'
import { ItemTypes } from '../../../assets/helrers/ItemTypes'

import { resource } from '../../../api/http'
import DeckFolder from '../components/DeckFolder'
import { dragSelector } from '../../../store/reducers/drag'
import AddDeckPagePopup from '../../popups/add-deck-page/AddDeckFolder'
import FilesMap from '../components/FilesMap'
import { useTranslation } from 'react-i18next'
import { setLoading } from '../../../store/reducers/popups'

const ContentSection = (props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { currentDeck } = useSelector(decksSelector)
    const { files, type, directories } = currentDeck
    // const { id } = useParams()

    const { setOptions, upload } = useUploady()
    const token = localStorage.getItem('token')
    let { deckId } = useParams()
    const fileInputRef = useRef(null)

    const handleClick = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = (event) => {
        setOptions({
            destination: {
                url: `${process.env.REACT_APP_BASE_URL}/api/decks/files/upload/${deckId}`,
                headers: { Authorization: `Bearer ${token}` },
            },
        })
        upload(event.target.files)
    }

    const { highlightDeckContent } = useSelector(dragSelector)
    function FinalizeUpload() {
        useItemFinalizeListener((item) => {
            const {
                uploadResponse: { results },
            } = item
            if (results[results.length - 1].data) dispatch(addFiles(results[results.length - 1].data))
        })
        //fake component
        return null
    }
    function FinalizeUpload2() {
        useItemFinalizeListener((item) => {
            const {
                uploadResponse: { results },
            } = item
            if (results[results.length - 1].data) {
                const data = results[results.length - 1].data
                const folder = directories.findIndex((e) => e.id === data.directory_id)
                currentDeck.directories[folder].files.unshift(results[results.length - 1].data)
                dispatch(setCurrentDeck(currentDeck))
            }
        })
        //fake component
        return null
    }

    return (
        <div className={styles.content}>
            <div className={styles.content__header}>
                <span>{t('deck_content')}</span>

                {type === 2 ? (
                    <AddDeckPagePopup plus />
                ) : (
                    <>
                        {/* <div onClick={handleClick}>+</div>
                        <input
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            id="upload"
                            type="file"
                            onChange={handleFileChange}
                            multiple
                        /> */}
                    </>
                )}
            </div>

            {type === 1 && <FinalizeUpload /> && (
                <DropZone>
                    {(highlightDeckContent || !files.length) && <DropWrap isEmpty={!files.length} type={type} />}
                    {!highlightDeckContent && <FilesMap />}
                </DropZone>
            )}
            {type === 2 && <FinalizeUpload2 /> && (
                <div className={styles.dropZoneFolder}>
                    {!directories.length && <DropWrap isEmpty={!directories.length} type={type} />}
                    {directories?.map((e, idx) => (
                        <DropZoneFolder folderId={e.id} directories={directories} key={e.id}>
                            <DeckFolder item={e} index={idx} />
                            {[]}
                        </DropZoneFolder>
                    ))}
                </div>
            )}
        </div>
    )
}
export default ContentSection

const DropZone = ({ children }) => {
    const dispatch = useDispatch()
    const { setOptions, upload } = useUploady()
    const token = localStorage.getItem('token')
    let { deckId } = useParams()

    const [{ isDragging }, dropRef, type] = useDrop({
        accept: [ItemTypes.FILE],
        collect: (monitor) => ({
            isDragging: !!monitor.isOver(),
        }),
        drop: (item) => {
            console.log('drop', item)
            if (item.nodeId) {
                dispatch(addProjectFile({ fileIds: [item.nodeId], deckId: deckId }))
            } else {
                setOptions({
                    destination: {
                        url: `${process.env.REACT_APP_BASE_URL}/api/decks/files/upload/${deckId}`,
                        headers: { Authorization: `Bearer ${token}` },
                    },
                })
                upload(item.files)
            }
        },
    })
    return (
        <div ref={dropRef} className={styles.dropZone}>
            {children?.length &&
                children?.map((e) => {
                    return e
                })}
        </div>
    )
}
const DropZoneFolder = ({ children, folderId, directories }) => {
    const { setOptions, upload } = useUploady()
    const token = localStorage.getItem('token')
    const dispatch = useDispatch()
    const { currentDeck } = useSelector(decksSelector)
    let { deckId } = useParams()

    const [{ isDragging }, dropRef, type] = useDrop({
        accept: [ItemTypes.FILE],
        collect: (monitor) => ({
            isDragging: !!monitor.isOver(),
        }),
        drop: (item) => {
            const { file_id, nodeId } = item

            const id = file_id ?? nodeId

            if (id) {
                dispatch(setLoading(true))
                const payload = { file_id: +id, deck_dir_id: folderId }
                resource
                    .post(`${process.env.REACT_APP_BASE_URL}/api/decks/copy/file-from-project-to-deck-dir`, payload)
                    .then(({ data: { data, success } }) => {
                        if (success) {
                            const folder = directories.findIndex((e) => e.id === data.directory_id)
                            currentDeck.directories[folder].files.unshift(data)
                            dispatch(setCurrentDeck(currentDeck))
                        }
                    })
                    .then(() => dispatch(setLoading(false)))
                    .catch((err) => {
                        console.log(123, err)
                        dispatch(setLoading(false))
                    })
            } else {
                setOptions({
                    destination: {
                        url: `${process.env.REACT_APP_BASE_URL}/api/decks/files/upload/${deckId}`,
                        headers: { Authorization: `Bearer ${token}` },
                        params: { directory_id: folderId },
                    },
                })
                upload(item.files)
            }
        },
    })
    return (
        <div ref={dropRef}>
            {/* <FinalizeUpload /> */}
            {children?.map((e) => {
                return e
            })}
        </div>
    )
}

const DropWrap = ({ isEmpty, type }) => {
    const { setOptions, upload } = useUploady()
    const token = localStorage.getItem('token')
    let { deckId } = useParams()
    const fileInputRef = useRef(null)

    const handleClick = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = (event) => {
        setOptions({
            destination: {
                url: `${process.env.REACT_APP_BASE_URL}/api/decks/files/upload/${deckId}`,
                headers: { Authorization: `Bearer ${token}` },
            },
        })
        upload(event.target.files)
    }
    const { t } = useTranslation()
    return (
        <div className={styles.DropWrap}>
            <input
                ref={fileInputRef}
                style={{ display: 'none' }}
                id="upload"
                type="file"
                onChange={handleFileChange}
                multiple
            />
            {type === 1 ? (
                <svg width="100" height="99" viewBox="0 0 100 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1413_56484)">
                        <path
                            d="M85.8029 76.5117C89.1841 82.4225 92.5205 88.2564 95.8928 94.149C93.1972 95.7803 90.5375 97.3891 87.8778 99H87.4319C87.3669 98.7944 87.3333 98.5685 87.2302 98.3855C84.0753 92.8589 80.916 87.339 77.7543 81.8169C77.6804 81.6881 77.5863 81.5729 77.4518 81.3809C73.981 84.761 70.5326 88.1185 66.8489 91.7065V52.9095C77.8081 59.3512 88.7651 65.7952 100 72.3973C95.1287 73.8094 90.5241 75.1448 85.8029 76.5117Z"
                            fill="#3376A3"
                        />
                        <path
                            d="M95.3505 52.3582C95.3505 52.4667 95.3147 52.5774 95.2766 52.7898H85.8007V38.5394H38.2151V86.4736H57.1646V96.0921C56.7703 96.1215 56.3804 96.1757 55.9905 96.1757C48.6522 96.1802 41.3117 96.187 33.9734 96.1757C30.4107 96.1712 28.533 94.2755 28.5263 90.6785C28.5173 83.3918 28.524 76.1028 28.524 68.8161V67.37H19.1086V57.6453H28.524V56.2264C28.524 48.9013 28.5375 41.5761 28.5151 34.251C28.5106 32.1836 29.0999 30.4009 31 29.4542C31.9232 28.9933 33.0659 28.817 34.1123 28.808C41.3027 28.7538 48.4931 28.7809 55.6835 28.7809H57.1444V19.2754H66.757V28.7809H68.1687C75.433 28.7809 82.6996 28.7899 89.9639 28.7741C91.7789 28.7696 93.4303 29.2034 94.4095 30.828C94.9248 31.6843 95.308 32.7733 95.3214 33.763C95.3998 39.9606 95.3573 46.1606 95.3505 52.3582Z"
                            fill="#3376A3"
                        />
                        <path d="M28.5442 0.0203857H19.0571V9.53718H28.5442V0.0203857Z" fill="#3376A3" />
                        <path d="M47.5678 0H38.1121V9.51906H47.5678V0Z" fill="#3376A3" />
                        <path d="M9.46017 19.2889H0.00219727V28.7831H9.46017V19.2889Z" fill="#3376A3" />
                        <path d="M9.44005 38.4468H0V47.9816H9.44005V38.4468Z" fill="#3376A3" />
                        <path d="M9.45789 0.0316162H0.00439453V9.52582H9.45789V0.0316162Z" fill="#3376A3" />
                        <path d="M66.6898 0.0294189H57.2363V9.52814H66.6898V0.0294189Z" fill="#3376A3" />
                        <path d="M9.45569 57.7402H0.00219727V67.239H9.45569V57.7402Z" fill="#3376A3" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1413_56484">
                            <rect width="100" height="99" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ) : (
                <svg width="84" height="56" viewBox="0 0 84 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g opacity="0.5">
                        <path
                            d="M0 2C0 0.895429 0.89543 0 2 0H73.6C74.7046 0 75.6 0.89543 75.6 2V30.8L56 50.4H2C0.89543 50.4 0 49.5046 0 48.4V2Z"
                            fill="#3376A3"
                        />
                        <path d="M75.6 30.8H56V50.4L75.6 30.8Z" fill="#12A0FF" />
                    </g>
                    <path
                        d="M8.3999 7.6001C8.3999 6.49553 9.29533 5.6001 10.3999 5.6001H81.9999C83.1045 5.6001 83.9999 6.49553 83.9999 7.6001V36.4001L64.3999 56.0001H10.3999C9.29533 56.0001 8.3999 55.1047 8.3999 54.0001V7.6001Z"
                        fill="#3376A3"
                    />
                    <path
                        d="M83.9999 36.3999H66.3999C65.2953 36.3999 64.3999 37.2953 64.3999 38.3999V55.9999L83.9999 36.3999Z"
                        fill="#85AED1"
                    />
                </svg>
            )}

            <span>{type === 1 ? t('drop_deck') : t('create_pages_and_drag')}</span>
            {isEmpty && type === 2 ? <AddDeckPagePopup button /> : null}
        </div>
    )
}
