import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'
import { resource } from '../../api/http'

const namespace = 'user'




const GET_USER = `${namespace}/GET_USER`
const SET_USER = `${namespace}/SET_USER`
const RESET_USER = `${namespace}/RESET_USER`
const SET_ERROR = `${namespace}/SET_ERROR`

const UPDATE_USER = `${namespace}/UPDATE_USER`
const DELETE_AVATAR = `${namespace}/DELETE_AVATAR`

const SUBMIT_SETTINGS = `${namespace}/SUBMIT_SETTINGS`
const SET_LOAD = `${namespace}/SET_LOAD`
const SET_CUSTOM_AMENITIES = `${namespace}/SET_CUSTOM_AMENITIES`

export const setUser = createAction(SET_USER)
export const resetUser = createAction(RESET_USER)
export const getUser = createAction(GET_USER)
export const updateUser = createAction(UPDATE_USER)

// export const updateAvatar = createAction(UPDATE_AVATAR)
export const deleteAvatar = createAction(DELETE_AVATAR)
export const submitSettings = createAction(SUBMIT_SETTINGS)
export const setLoad = createAction(SET_LOAD)
export const setCustomAmenities = createAction(SET_CUSTOM_AMENITIES)

const initialState = {
    load: false,
    error: null,
    // payload: null,
}

export default handleActions(
    {
        [RESET_USER]: () => initialState,
        [SET_USER]: (state, { payload }) => ({ ...state, ...payload }),
        [SET_ERROR]: (state, { payload }) => ({ ...state, error: payload }),
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_CUSTOM_AMENITIES]: (state, { payload }) => ({ ...state, custom_amenities: payload }),
    },
    initialState
)

export const userSelector = (state) => state[namespace]

export function* getUserRequest() {
    while (true) {
        yield take(GET_USER)
        try {
            const {
                data: { data, message, success },
            } = yield call(resource.get, 'api/user/info')

            if (success) yield put(setUser(data))
        } catch (err) {
            // console.log(err);
        }
    }
}
export function* updateUserRequest() {
    while (true) {
        const { payload } = yield take(UPDATE_USER)
        try {
            const {
                data: { data, message, success },
            } = yield call(resource.post, 'api/user/update', payload)
            yield put(getUser())
        } catch (err) {
            // console.log(err);
        }
    }
}

function* init() {
    yield put(getUser())
    // yield put(setAuth(!!token))
    // yield put(getUser());
}
export function* sagas() {
    yield all([init(), getUserRequest(), updateUserRequest()])
}
