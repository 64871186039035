import React, { useState } from 'react'
import styles from '../deck-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { decksSelector, setCurrentDeck } from '../../../store/reducers/decks'
import { addOpenedPages } from '../../../store/reducers/decks'
import { removeOpenedPages } from '../../../store/reducers/decks'
import { dragSelector } from '../../../store/reducers/drag'
import FolderFilesMap from './FolderFilesMap'
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from '../../../assets/helrers/ItemTypes'
import { resource } from '../../../api/http'
import { useParams } from 'react-router-dom'

const DeckFolder = ({ item, index }) => {
    const { openedPages } = useSelector(decksSelector)

    const { deckId } = useParams()
    const dispatch = useDispatch()
    const { currentDeck } = useSelector(decksSelector)
    const { directories } = currentDeck
    const openAll = directories.map((e) => e.id)

    const { highlightDeckContent } = useSelector(dragSelector)

    const [isOpen, setOpen] = React.useState(openedPages.includes(item.id))

    const moveItem = (dragIndex, hoverIndex) => {
        const draggedItem = directories[dragIndex]
        const remainingItems = directories.filter((_, index) => index !== dragIndex)
        const updatedItems = [...remainingItems.slice(0, hoverIndex), draggedItem, ...remainingItems.slice(hoverIndex)]

        const arrayWithIds = updatedItems.map((e, idx) => {
            return { id: e.id, priority: idx }
        })
        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/decks/directory/priority/${deckId}`, {
                arrayWithIds: arrayWithIds,
            })
            .then(({ data: { data, success, message } }) => {
                if (success) {
                    currentDeck.directories = data
                    dispatch(setCurrentDeck(currentDeck))
                }
            })
    }

    const [{ isDragging }, dragRef] = useDrag({
        type: ItemTypes.DECK_FOLDER,
        item: () => {
            return { selectedIndex: index }
        },

        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [, dropRef] = useDrop({
        accept: ItemTypes.DECK_FOLDER,
        drop: (item) => {
            const dragIndex = item.selectedIndex
            const hoverIndex = index
            if (dragIndex === hoverIndex) {
                return
            }
            moveItem(dragIndex, hoverIndex)
            // TO DO
            item.index = hoverIndex
        },
    })

    return (
        <div
            ref={(node) => dragRef(dropRef(node))}
            className={highlightDeckContent ? styles.hightlight : styles.folder}
        >
            <div
                className={highlightDeckContent ? styles.directories__selectItem : styles.directories__item}
                onClick={() => {
                    !isOpen ? dispatch(addOpenedPages(item.id)) : dispatch(removeOpenedPages(item.id))
                    setOpen(!isOpen)
                }}
            >
                <div>
                    <Chevron isOpen={isOpen} />
                    <PageLogo />
                    {item.name}
                </div>
            </div>
            {highlightDeckContent ? (
                <div className={styles.hightlight__item}>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2598_66873)">
                            <path
                                d="M34.3211 30.9139C35.6736 33.3021 37.0082 35.6592 38.3571 38.0401C37.2788 38.6992 36.215 39.3492 35.1511 40.0001H34.9727C34.9467 39.917 34.9333 39.8257 34.892 39.7518C33.6301 37.5188 32.3663 35.2886 31.1017 33.0574C31.0721 33.0054 31.0345 32.9588 30.9807 32.8812C29.5924 34.247 28.213 35.6035 26.7395 37.0532V21.3777C31.1232 23.9804 35.506 26.584 39.9999 29.2515C38.0514 29.8221 36.2096 30.3616 34.3211 30.9139Z"
                                fill="#3376A3"
                            />
                            <path
                                d="M38.1402 21.1549C38.1402 21.1987 38.1259 21.2434 38.1106 21.3293H34.3203V15.5715H15.286V34.9389H22.8658V38.8251C22.7081 38.837 22.5521 38.8589 22.3962 38.8589C19.4609 38.8608 16.5247 38.8635 13.5893 38.8589C12.1643 38.8571 11.4132 38.0912 11.4105 36.6378C11.4069 33.6937 11.4096 30.7486 11.4096 27.8045V27.2203H7.64343V23.2911H11.4096V22.7178C11.4096 19.7581 11.415 16.7985 11.406 13.8388C11.4042 13.0035 11.6399 12.2832 12.4 11.9007C12.7692 11.7145 13.2263 11.6433 13.6449 11.6396C16.5211 11.6177 19.3972 11.6287 22.2734 11.6287H22.8578V7.78809H26.7028V11.6287H27.2674C30.1732 11.6287 33.0798 11.6323 35.9855 11.626C36.7115 11.6241 37.3721 11.7994 37.7638 12.4558C37.9699 12.8018 38.1232 13.2418 38.1285 13.6417C38.1599 16.1458 38.1429 18.6508 38.1402 21.1549Z"
                                fill="#3376A3"
                            />
                            <path d="M11.4176 0.00830078H7.6228V3.85347H11.4176V0.00830078Z" fill="#3376A3" />
                            <path d="M19.027 0H15.2448V3.84608H19.027V0Z" fill="#3376A3" />
                            <path d="M3.78404 7.79346H0.000854492V11.6295H3.78404V7.79346Z" fill="#3376A3" />
                            <path d="M3.77602 15.5339H0V19.3864H3.77602V15.5339Z" fill="#3376A3" />
                            <path d="M3.78323 0.0126953H0.00183105V3.84874H3.78323V0.0126953Z" fill="#3376A3" />
                            <path d="M26.6759 0.0119629H22.8945V3.84983H26.6759V0.0119629Z" fill="#3376A3" />
                            <path d="M3.78225 23.3293H0.000854492V27.1672H3.78225V23.3293Z" fill="#3376A3" />
                        </g>
                        <defs>
                            <clipPath id="clip0_2598_66873">
                                <rect width="40" height="40" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span>Drag and drop your content here</span>
                </div>
            ) : (
                <div className={styles.directories__files}>{isOpen && <FolderFilesMap item={item} />}</div>
            )}
        </div>
    )
}
export default DeckFolder

const PageLogo = () => {
    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 2C0 0.89543 0.895431 0 2 0H18C19.1045 0 20 0.895431 20 2V7L12.6316 14H2C0.895432 14 0 13.1046 0 12V2Z"
                fill="#3376A3"
            />
            <path d="M19.9998 7H14.6313C13.5268 7 12.6313 7.89543 12.6313 9V14L19.9998 7Z" fill="#99BAD1" />
        </svg>
    )
}

const Chevron = ({ isOpen }) => {
    return (
        <svg
            style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M0.5 7L3.5 4L0.5 1" stroke="black" />
        </svg>
    )
}
