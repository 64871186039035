import * as React from 'react'
import styles from './loading-popup.module.sass'
import { CircularProgress } from '@mui/material'

const LoadingPopup = (props) => {
    return (
        <section className={styles.overlay}>
            <div className={styles.wrapper}>
                <CircularProgress color="primary" size={70} />
            </div>
        </section>
    )
}
export default LoadingPopup
