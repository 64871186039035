import React, { useEffect } from 'react'
import styles from '../left-bar.module.sass'
import { Link } from 'react-router-dom'
import { TreeItem, TreeView } from '@mui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProjects, getFolderStructureById, projectsSelector, removeFile } from '../../../store/reducers/projects'
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import CustomTreeItem from '../components/CustomTreeItem'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../../../assets/helrers/ItemTypes'
import CreateProjectPopup from '../../popups/create-project'
import { hightlightDeckContent } from '../../../store/reducers/drag'
import { BasketSvg } from '../../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'

const ProjectsAsside = () => {
    const { t } = useTranslation()
    const { projects, defaultExpanded } = useSelector(projectsSelector)
    const dispatch = useDispatch()

    const [showProjects, setshowProjects] = React.useState(true)

    const [selectedItems, setSelectedItems] = React.useState([])

    useEffect(() => {
        if (!projects.length) dispatch(getAllProjects())
    }, [])

    return (
        <>
            <div className={styles.asside}>
                <div>
                    <span className={styles.asside__label}>{t('all_projects')}</span>
                    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M9.5 0.5L6.5 3.5L9.5 6.5" stroke="white" />
                            <path d="M4 0.5L1 3.5L4 6.5" stroke="white" />
                        </g>
                    </svg>
                </div>
                <CreateProjectPopup plusButton={true} />
            </div>
            {/* <div className={styles.asside__search}></div> */}

            <div className={styles.asside__tree__wrapper}>
                <Link to="/shared-with-me" className={styles.link}>
                    <SharedImg />
                    <span>{t('collaboration')}</span>
                </Link>
                <Link to="/favorites" className={styles.link}>
                    <HeartImg />
                    <span>{t('liked')}</span>
                </Link>
                <Link
                    to="/"
                    className={styles.link}
                    // onClick={() => {
                    //     setshowProjects(!showProjects)
                    // }}
                >
                    <AllProjectsImg />
                    <span>{t('all_projects')}</span>
                </Link>

                {showProjects && (
                    <TreeView
                        // onNodeSelect={(e) => {
                        //     console.log('onNodeSelect', e)
                        // }}
                        // multiSelect
                        // defaultSelected={['1784', '285']}
                        selected={selectedItems}
                        draggable
                        aria-label="file system navigator"
                        defaultCollapseIcon={<ExpandMoreImg />}
                        defaultExpandIcon={<CollapseMoreImg />}
                        sx={{ flexGrow: 1, color: 'red', fontSize: '12px !important', padding: '0 11px' }}
                        defaultExpanded={defaultExpanded}
                        onNodeToggle={(e, id) => {
                            if (e) dispatch(getFolderStructureById(e))
                        }}
                        onFocusCapture={(e) => e.stopPropagation()}
                        disabled={false}
                    >
                        {projects?.map((e, index) => {
                            return (
                                <div
                                    key={e.id}
                                    className={styles.asside__tree__link}
                                    // onClick={() => {
                                    //     setSelectedItems([e.id.toString()])
                                    // }}
                                >
                                    {/* //the same id will selected in different TreeItems */}
                                    <CustomTreeItem
                                        nodeId={e.id.toString()}
                                        label={e.name}
                                        className={styles.treeViewFont}
                                        thumb={e.thumb}
                                        setSelectedItems={setSelectedItems}
                                        firstChild={true}
                                        disabled={false}
                                    >
                                        {e.files?.length ? (
                                            <MapStructure
                                                files={e.files}
                                                value={1}
                                                setSelectedItems={setSelectedItems}
                                                selectedItems={selectedItems}
                                            />
                                        ) : null}
                                        {['']}
                                    </CustomTreeItem>
                                </div>
                            )
                        })}
                    </TreeView>
                )}
            </div>
        </>
    )
}
export default ProjectsAsside

const HeartImg = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.1864 1.63809C10.8189 0.068279 8.4292 1.51902 7.92906 3.79754C7.4292 1.51887 4.19474 -0.0277438 2.18604 1.98096C-0.313965 4.48096 0.929199 9.51881 7.92906 13.0189C15.377 8.92206 16.4433 3.79753 13.1864 1.63809Z"
                fill="#CACACA"
                stroke="#CACACA"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
const AllProjectsImg = () => {
    return (
        <svg
            width="16"
            height="16"
            style={{ marginRight: '7px' }}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="3" y1="3" x2="13" y2="3" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="8" x2="13" y2="8" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="13" x2="13" y2="13" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}

const MapStructure = ({ files, idx, previosIdx, value, setSelectedItems, selectedItems }) => {
    const dispatch = useDispatch()
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.FILE,
            item: { file_id: 825 },

            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    )
    useEffect(() => {
        if (isDragging) {
            dispatch(hightlightDeckContent(true))
        } else dispatch(hightlightDeckContent(false))
    }, [isDragging])

    let x = value
    if (previosIdx) x = x + 1
    if (!previosIdx && value !== 1) x = x - 1

    return files.map((file, index) => {
        return file.type === 'directory' ? (
            <CustomTreeItem
                key={file.id}
                nodeId={file.id.toString()}
                label={file.name}
                padding={x * 17}
                thumb={file.thumb}
                setSelectedItems={setSelectedItems}
            >
                {file?.files?.length ? (
                    <MapStructure
                        files={file.files}
                        previosIdx={true}
                        value={x}
                        setSelectedItems={setSelectedItems}
                        selectedItems={selectedItems}
                    />
                ) : null}
                {/* above put previosIdx  and value to components when in recursions. This values using for create paddings in tree */}
                {['']}
            </CustomTreeItem>
        ) : (
            <Link
                ref={drag}
                to={`/file/${file.project_id}/${file.id}`}
                className={
                    selectedItems?.includes(file.id.toString())
                        ? styles.asside__tree__fileSelected
                        : styles.asside__tree__file
                }
                key={file.id}
            >
                <FileBox
                    nodeId={file.id.toString()}
                    label={file.name && file.name.length ? file.name : 'NoName file'}
                    key={file.id}
                    // component={{ SharedImg }}
                    thumb={file.leftSidebarPreview ?? file.preview}
                    index={previosIdx ? x : x}
                    setSelectedItems={setSelectedItems}
                    selected={selectedItems?.includes(file.id.toString())}
                ></FileBox>
            </Link>
        )
    })
}
const SharedImg = () => {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.31288 6.13546C-1.17938 8.84573 -0.76409 14.6065 3.88053 17.3474C3.96392 17.3967 4.05881 17.3096 4.01708 17.2222C2.83428 14.7456 1.83494 12.8781 3.53677 11.4835C5.93843 9.51541 8.92919 9.47867 10.6706 9.47799C10.7259 9.47797 10.7697 9.52275 10.7697 9.57797V12.5849C10.7697 12.6731 10.8757 12.7181 10.9392 12.6568L16.9272 6.87404C16.9673 6.83536 16.968 6.77142 16.9289 6.73181L10.9408 0.673142C10.878 0.609576 10.7697 0.654064 10.7697 0.743437V4.02987C10.7697 4.08509 10.7259 4.12986 10.6707 4.12911C9.4214 4.11208 5.2779 3.83437 2.31288 6.13546Z"
                fill="#CACACA"
            />
        </svg>
    )
}
const ExpandMoreImg = () => {
    return (
        <svg width="8" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 7L3.5 4L0.5 1" stroke="black" />
        </svg>
    )
}
const CollapseMoreImg = () => {
    return (
        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
        </svg>
    )
}

export const FileBox = React.forwardRef(function CustomContent(props, ref) {
    const { label, nodeId, key, thumb, index, setSelectedItems, selected } = props
    const [anchorElProject, setAnchorElProject] = React.useState(false)
    const handleOpenprojectMenu = (event) => {
        setAnchorElProject(event.currentTarget)
    }
    const handleCloseUserMenu = () => {
        setAnchorElProject(null)
    }
    const { t } = useTranslation()
    const menuItems = [
        { label: t('go_to_this_folder'), link: `/project/${+nodeId}`, action: () => {} },
        {
            label: t('delete_file'),
            link: ``,
            svg: <BasketSvg />,
            action: () => {
                dispatch(removeFile(+nodeId))
            },
        },
        // { label: 'Remove folder', action: () => removeProject() },
        // { label: 'Add folder', action: () => setFolderPopup(true) },
        // { label: 'Create Deck' },
        // { label: 'Comment' },
        // { label: 'Share' },
        // { label: 'Copy' },
        // { label: 'Download' },
    ]

    const dispatch = useDispatch()
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: ItemTypes.FILE,
            item: { label: label, nodeId: nodeId, key: key },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        []
    )
    useEffect(() => {
        if (isDragging) {
            dispatch(hightlightDeckContent(true))
        } else dispatch(hightlightDeckContent(false))
    }, [isDragging])

    return (
        <TreeItem
            ref={drag}
            draggable={true}
            sx={{ fontSize: 12, img: { width: 12, height: 12 } }}
            disabled={false}
            key={nodeId}
            nodeId={nodeId}
            collapseIcon={false}
            label={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: index * 17,
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <img src={thumb} alt="thumb" className={styles.filePreview} />
                        <span>{label}</span>
                    </div>
                    {selected ? (
                        <Box sx={{ marginLeft: '15px' }}>
                            <Tooltip title="Open menu">
                                <div>
                                    <IconButton
                                        sx={{
                                            background: 'transparent',
                                            height: 30,
                                            width: 30,
                                            borderRadius: '5px',
                                            // marginLeft: '10px',
                                            padding: 0,
                                        }}
                                        onClick={handleOpenprojectMenu}
                                    >
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle cx="6" cy="12" r="2" fill="#3376A3" />
                                            <circle cx="12" cy="12" r="2" fill="#3376A3" />
                                            <circle cx="18" cy="12" r="2" fill="#3376A3" />
                                        </svg>
                                    </IconButton>
                                </div>
                            </Tooltip>
                            <Menu
                                sx={{
                                    // marginLeft: -15.5,
                                    ul: {
                                        width: 250,
                                        background: '#1F1F1F',
                                        color: '#fff',
                                        borderRadius: '5px',
                                        padding: 0,
                                    },
                                    // top: 30,
                                    borderRadius: '5px',
                                }}
                                id={`project${nodeId}_menu`}
                                anchorEl={anchorElProject}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElProject)}
                                onClose={handleCloseUserMenu}
                            >
                                {menuItems.map((item, idx) => {
                                    return (
                                        <div key={idx}>
                                            {item.link ? (
                                                <Link to={`${item.link}`} className={styles.menuLink}>
                                                    <MenuItem
                                                        sx={{
                                                            fontFamily: 'Zeitung Micro Pro',
                                                            fontSize: 12,
                                                            borderRadius: 5,
                                                            div: {
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                height: 40,
                                                                opacity: 0.5,
                                                                '&:hover': { opacity: 1, background: '#292929' },
                                                            },
                                                        }}
                                                        onClick={handleCloseUserMenu}
                                                    >
                                                        <div>
                                                            {item.label} {item.svg ?? ''}
                                                        </div>
                                                    </MenuItem>
                                                </Link>
                                            ) : (
                                                <MenuItem
                                                    sx={{
                                                        fontFamily: 'Zeitung Micro Pro',
                                                        fontSize: 12,
                                                        borderRadius: 5,
                                                        div: {
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            height: 40,
                                                            opacity: 0.5,
                                                            '&:hover': { opacity: 1, background: '#292929' },
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        item.action()

                                                        handleCloseUserMenu()
                                                    }}
                                                >
                                                    <div>
                                                        {item.label} {item.svg ?? ''}
                                                    </div>
                                                </MenuItem>
                                            )}
                                        </div>
                                    )
                                })}
                            </Menu>
                        </Box>
                    ) : (
                        <div style={{ width: '30px', marginLeft: '15px' }}></div> // For markups dont jump
                    )}
                </div>
            }
            onClick={() => setSelectedItems(nodeId)}
        />
    )
})
