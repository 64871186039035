import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styles from './share-location.module.sass'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import { IconButton, Switch } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import CloseIcon from '@mui/icons-material/Close'
import { resource } from '../../../api/http'
import { useState } from 'react'
import { createCasting, castingsSelector } from '../../../store/reducers/castings'
import { useTranslation } from 'react-i18next'

const ShareLocation = ({ type, item }) => {
    const { t } = useTranslation()
    // const { id, file } = props
    const [questions, setQuestions] = useState([])
    const [logo, setLogo] = useState(false)
    React.useEffect(() => {
        try {
            const promise = resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/location-questions`)
                .then(({ data: { data, success } }) => {
                    if (success) setQuestions(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])
    const [open, setOpen] = useState(false)
    // const [checked, setChecked] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()
    // const { castings } = useSelector(castingsSelector)

    const formik = useFormik({
        initialValues: {
            title: '',
            terms: '',
            video_instructions: '',
            description: '',
            question_ids: [],
            thumb: '',
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required('Title is a required field'),
            terms: Yup.string().required('Is a required field'),
            description: Yup.string().required('Is a required field'),
            question_ids: Yup.array().min(1, 'Is a required field').required('Is a required field'),
            // terms: Yup.string().required('Is a required field'),
            thumb: Yup.mixed().required(),
            video_instructions: Yup.string().required('Is a required field'),
        }),
        onSubmit: (values) => {
            let fd = new FormData()
            fd.append('title', formik.values['title'])
            fd.append('terms', formik.values['terms'])
            fd.append('video_instructions', formik.values['video_instructions'])
            fd.append('description', formik.values['description'])
            fd.append('question_ids', formik.values['question_ids'])

            if (logo) fd.append('thumb', logo)

            dispatch(createCasting({ values: fd, resetForm: formik.resetForm, closePopup: handleClose }))
        },
        validateOnMount: true,
    })
    console.log('item', item)
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    // console.log('selectedQuestions', selectedQuestions)
    const AddRemoveQuestion = (id) => {
        const { setFieldValue, values } = formik
        const hasId = values['question_ids'].includes(id)
        if (hasId) {
            setFieldValue(
                'question_ids',
                values['question_ids'].filter((e) => e !== id)
            )
        } else setFieldValue('question_ids', [id, ...values['question_ids']])
    }
    return (
        <div className={styles.wrapper}>
            {type === 1 ? (
                <button className={styles.type1} onClick={handleClickOpen}>
                    {t('share')}
                </button>
            ) : (
                <section className={styles.empty}>
                    <button className={styles.type2} onClick={handleClickOpen}>
                        {t('share_location')}
                    </button>
                </section>
            )}

            <form onSubmit={formik.handleSubmit} className={styles.form}>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    className={styles.wrapper}
                >
                    <DialogTitle id="responsive-dialog-title">
                        {t('share_location')}
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ padding: 0 }}>
                        <div className={styles.thumb}>
                            <img src={item?.thumb} alt="logo" />
                        </div>
                        <div className={styles.inputs}>
                            <div>
                                <div className={styles.title}>{t('title')}</div>
                                <input type="text" name="title" value={item?.title} readOnly />
                            </div>
                            <div>
                                <div className={styles.title}>1.{t('character_description')} </div>
                                <input type="text" name="description" value={item?.description} readOnly />
                            </div>
                            <div>
                                <div className={styles.title}>2.{t('terms_rates_and_rights')}</div>
                                <input type="text" name="terms" value={item?.terms} readOnly />
                            </div>
                            <div>
                                <div className={styles.title}>3.{t('video_instructions')}</div>
                                <input
                                    type="text"
                                    name="video_instructions"
                                    value={item?.video_instructions}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className={styles.questions}>
                            {item?.questions?.map((e) => {
                                return (
                                    <div key={e.id} onClick={() => AddRemoveQuestion(e.id)}>
                                        <SelectedImg select={true} />
                                        {t(e.name)}
                                    </div>
                                )
                            })}
                        </div>
                    </DialogContent>{' '}
                    <DialogActions>
                        <button
                            className={styles.createBtn}
                            onClick={() => {
                                navigator.clipboard.writeText(`${window.location.host}/location-quiz/${item.id}`)
                                handleClose()
                            }}
                            autoFocus
                            type="button"
                        >
                            {t('copy_link')}
                        </button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    )
}
export default ShareLocation
const SelectedImg = ({ select }) => {
    return select ? (
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13" r="12.5" fill="#D7E7FF" stroke="#3376A3" />
            <path d="M7.5 11.7222L12.0517 16L19.5 9" stroke="#3376A3" strokeWidth="2" />
        </svg>
    ) : (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13" cy="13" r="12.5" stroke="#3376A3" />
        </svg>
    )
}
