import user from './user'
import auth from './auth'
import projects from './projects'
import uploads from './uploads'
import comments from './comments'
import decks from './decks'
import search from './search'
import castings from './castings'
import drag from './drag'
import notifications from './notifications'
import locations from './locations'
import popups from './popups'

export const reducers = {
    user,
    auth,
    projects,
    uploads,
    comments,
    decks,
    search,
    castings,
    drag,
    notifications,
    locations,
    popups,
}
