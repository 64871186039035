import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take } from 'redux-saga/effects'

const namespace = 'popups'

const initialState = {
    loading: false,
    success: { visible: false, text: '' },
}

const SET_LOADING = `${namespace}/SET_LOADING`
const SET_SUCCESS = `${namespace}/SET_SUCCESS`

export const setLoading = createAction(SET_LOADING)
export const setSuccess = createAction(SET_SUCCESS)

export default handleActions(
    {
        [SET_LOADING]: (state, { payload }) => ({ ...state, loading: payload }),
        [SET_SUCCESS]: (state, { payload }) => ({ ...state, success: payload }),
    },
    initialState
)

export const popupSelector = (state) => state[namespace]

export function* sagas() {
    yield all([])
}
