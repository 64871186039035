import React, { useEffect, useState } from 'react'
import styles from '../deck-bar.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { decksSelector, updateDeck } from '../../../store/reducers/decks'
import { useFormik } from 'formik'
// import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setSuccess } from '../../../store/reducers/popups'

const Editor = (props) => {
    const { t } = useTranslation()
    const { currentDeck } = useSelector(decksSelector)
    const {
        files,
        description,
        name,
        thumb,
        id,
        linkedin_link,
        twitter_link,
        facebook_link,
        shareLink,
        vimeo_link,
        instagram_link,
    } = currentDeck
    const dispatch = useDispatch()

    const [logo, setLogo] = useState(false)
    const { deckId } = useParams()

    const url = new URL(shareLink) ?? ''
    const formik = useFormik({
        initialValues: {
            name: '',
            link: '',
            description: '',
            linkedin_link: '',
            twitter_link: '',
            facebook_link: '',
            vimeo_link: '',
            instagram_link: '',
        },
        // validationSchema: Yup.object().shape({
        //     decaType: Yup.object()
        //         .shape({
        //             id: Yup.number().required('id is req'),
        //         })
        //         .required('This field is required.'),
        // }),
        onSubmit: (values) => {
            let fd = new FormData()
            fd.append('name', formik.values['name'])
            fd.append('description', formik.values['description'])
            fd.append('linkedin_link', formik.values['linkedin_link'])
            fd.append('twitter_link', formik.values['twitter_link'])
            fd.append('facebook_link', formik.values['facebook_link'])

            fd.append('vimeo_link', formik.values['vimeo_link'])
            fd.append('instagram_link', formik.values['instagram_link'])
            if (logo) fd.append('thumb', logo)
            dispatch(updateDeck({ id: deckId, fd }))
        },
        validateOnMount: true,
    })

    useEffect(() => {
        formik.setValues({
            name: name ?? '',
            description: description ?? '',
            linkedin_link: linkedin_link ?? '',
            twitter_link: twitter_link ?? '',
            facebook_link: facebook_link ?? '',

            instagram_link: instagram_link ?? '',
            vimeo_link: vimeo_link ?? '',

            link: shareLink ?? '',
        })
    }, [currentDeck])

    // const GenerateLink = () => {
    //     resource
    //         .post(`${process.env.REACT_APP_BASE_URL}/api/share-deck`, {
    //             deck_id: id,
    //             time_from: new Date(),
    //             time_to: endOfTomorrow(),
    //         })
    //         .then(({ data: { deckUrl } }) => {
    //             if (deckUrl) formik.setFieldValue('link', deckUrl)
    //         })
    // } First variant link generation
    return (
        <div className={styles.edit}>
            <form onSubmit={formik.handleSubmit} className={styles.form}>
                <div className={styles.edit__header}>
                    <span>{t('settings')}</span>+
                </div>
                <div className={styles.edit__column}>
                    <div className={styles.edit__flex}>
                        <div className={styles.edit__logo}>
                            {<img src={logo ? URL.createObjectURL(logo) : thumb} alt="logo" />}
                            {/* <DeckLogo /> */}
                        </div>

                        <label for="thumb">{t('reload')}</label>
                        <input
                            id="thumb"
                            name="thumb"
                            type="file"
                            onChange={(event) => {
                                setLogo(event.currentTarget.files[0] ?? false)
                                // formik.setFieldValue('thumb', event.currentTarget.files[0])
                            }}
                            className="form-control"
                        />
                    </div>
                    <div className={styles.edit__field}>
                        <span>{t('deck_title')}</span>
                        <input type="text" name="name" value={formik.values['name']} onChange={formik.handleChange} />
                    </div>
                    <div className={styles.edit__field}>
                        <span>{t('short_description')}</span>
                        <input
                            type="text"
                            name="description"
                            value={formik.values['description']}
                            onChange={formik.handleChange}
                        />
                    </div>
                    {/* <div className={styles.edit__field}>
                        <span>Twitter link</span>
                        <input
                            type="text"
                            name="twitter_link"
                            value={formik.values['twitter_link']}
                            onChange={formik.handleChange}
                        />
                    </div> */}
                    <div className={styles.edit__field}>
                        <span>Linkedin link</span>
                        <input
                            type="text"
                            name="linkedin_link"
                            value={formik.values['linkedin_link']}
                            onChange={formik.handleChange}
                        />
                    </div>{' '}
                    <div className={styles.edit__field}>
                        <span>Facebook link</span>
                        <input
                            type="text"
                            name="facebook_link"
                            value={formik.values['facebook_link']}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className={styles.edit__field}>
                        <span>Instagram link</span>
                        <input
                            type="text"
                            name="instagram_link"
                            value={formik.values['instagram_link']}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className={styles.edit__field}>
                        <span>Vimeo link</span>
                        <input
                            type="text"
                            name="vimeo_link"
                            value={formik.values['vimeo_link']}
                            onChange={formik.handleChange}
                        />
                    </div>
                    {formik.values['link'] && (
                        <div className={styles.edit__shareBtns}>
                            <button
                                onClick={() => {
                                    dispatch(setSuccess({ visible: 'true', text: t('link_copied') }))
                                    navigator.clipboard.writeText(formik.values['link'])
                                }}
                                type="button"
                            >
                                {t('copy_share_link_deck')}
                                <CopyShareLink />
                            </button>
                            <a href={url} target="_blank">
                                {t('preview_deck')}
                                <PreviewSvg />
                            </a>
                        </div>
                    )}
                </div>
                <div className={styles.edit__footer}>
                    <button type="submit">{t('save_changes')}</button>
                    {/* <button type="button">{t('cancel')}</button> */}
                </div>
            </form>
        </div>
    )
}
export default Editor
const CopyShareLink = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3371_23459)">
                <path
                    d="M9.79475 7.64226C9.44279 7.99353 8.87158 8.03569 8.47679 7.73523C8.33609 7.62805 8.2096 7.50113 8.06425 7.40149C7.26823 6.85606 6.19459 6.94859 5.50865 7.62805C4.53554 8.59266 3.56532 9.56037 2.60176 10.5343C1.82039 11.3238 1.84036 12.5962 2.63371 13.3873C3.4102 14.1617 4.68933 14.1671 5.47248 13.3948C5.95115 12.9231 6.42339 12.4447 6.89985 11.9704C7.28998 11.5821 7.82879 11.5428 8.26797 11.8686C8.6845 12.1773 8.77993 12.8143 8.47102 13.2377C8.42109 13.3059 8.36539 13.3706 8.30569 13.4306C7.85742 13.8808 7.40804 14.3295 6.95843 14.7784C5.54016 16.1941 3.39444 16.4091 1.74649 15.267C0.595625 14.4695 0.04283 13.3329 0 12.0443C0.0184191 10.8374 0.402336 9.88146 1.17416 9.09571C2.15215 8.09982 3.13147 7.10392 4.13764 6.13665C5.43919 4.88535 7.41713 4.65302 9.0034 5.5961C9.33694 5.79448 9.6545 6.01904 9.89218 6.3337C10.1889 6.72691 10.1436 7.29387 9.79475 7.64226Z"
                    fill="#3376A3"
                />
                <path
                    d="M15.1921 6.48441C15.0732 6.64374 14.9416 6.79552 14.8013 6.9362C13.8402 7.90214 12.876 8.8652 11.9111 9.82758C10.5458 11.1892 8.29226 11.368 6.73617 10.2308C6.51182 10.067 6.29456 9.88062 6.11702 9.66848C5.80434 9.29502 5.85894 8.71519 6.20535 8.36325C6.54954 8.01331 7.12164 7.96583 7.51377 8.25874C7.62562 8.34262 7.7257 8.44225 7.83622 8.52813C8.63689 9.151 9.7791 9.08577 10.4992 8.37035C11.4659 7.40974 12.4303 6.44691 13.3906 5.48008C14.1722 4.69322 14.1675 3.41706 13.3884 2.63375C12.6019 1.84312 11.327 1.82758 10.5332 2.60557C10.0505 3.07844 9.57738 3.56108 9.09782 4.03728C8.75917 4.37368 8.27828 4.44713 7.87505 4.22878C7.29474 3.91457 7.16003 3.13214 7.62739 2.64773C8.17065 2.08477 8.72566 1.53134 9.29976 0.999889C9.87253 0.469322 10.5693 0.173749 11.3387 0.054144C11.5387 0.0230778 11.7424 0.0146455 11.8995 0C13.1629 0.0366138 14.1935 0.479751 15.0015 1.40197C16.2575 2.83524 16.3361 4.95174 15.1921 6.48441Z"
                    fill="#3376A3"
                />
            </g>
            <defs>
                <clipPath id="clip0_3371_23459">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
const PreviewSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.4738 11.3973C13.4402 3.63798 6.55825 7.79061 3.49487 11.3818C3.1931 11.7356 3.21395 12.2582 3.52623 12.6027C10.5598 20.362 17.4417 16.2094 20.5051 12.6182C20.8069 12.2645 20.7861 11.7418 20.4738 11.3973Z"
                stroke="#3376A3"
                stroke-width="2"
            />
            <circle cx="12" cy="12" r="2.5" fill="#3376A3" stroke="#3376A3" />
        </svg>
    )
}
