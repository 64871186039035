import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styles from './edit-project.module.sass'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateProjectById } from '../../../store/reducers/projects'
import AddTag from '../../add-tags/AddTags'
import CustomTextField from '../../custom-text-field/CustomTextField'
import { useTranslation } from 'react-i18next'

const EditProjectPopup = ({ openState, item, close }) => {
    const { t } = useTranslation()

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()

    const tags1 = item.tags?.map((e) => e.name)
    React.useEffect(() => {
        formik.setValues({
            name: item.name ?? '',
            description: item.description ?? '',
            tag: '',
            tags: tags1 ?? [],
        })
    }, [item])

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            tag: '',
            tags: [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().min(1).required('Name is a required field'),
            description: Yup.string().required('Description is a required field'),
            // thumb: Yup.mixed().required(),
        }),
        validateOnMount: true,
        onSubmit: (values) => {
            let fd = new FormData()
            fd.append('name', values.name)
            fd.append('description', values.description)
            if (values['thumb']) fd.append('thumb', values['thumb'])
            fd.append('tags', values.tags)
            fd.append('_method', 'PUT')
            dispatch(updateProjectById({ fd, id: item.id, updateMainProject: true }))
        },
    })

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={openState}
                onClose={close}
                aria-labelledby="responsive-dialog-title"
                className={styles.wrapper}
            >
                <DialogTitle id="responsive-dialog-title">
                    {t('edit_project')}
                    <IconButton
                        aria-label="close"
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 1.00049L9 9.00049L17 17.0005" stroke="black" />
                            <path d="M1 17L9 9L0.999998 1" stroke="black" />
                        </svg>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <span className={styles.title}>{t('project_name')}</span>
                        <CustomTextField name="name" formik={formik} small placeholder={t('project_name')} />
                        <span className={styles.title}>{t('add_tags')}</span>
                        <AddTag
                            setFieldValue={formik.setFieldValue}
                            values={formik.values}
                            handleChange={formik.handleChange}
                        />
                        <span className={styles.title}>{t('short_description')}</span>

                        <CustomTextField
                            name="description"
                            formik={formik}
                            small
                            placeholder={t('short_description')}
                        />
                        <span className={styles.title}>{t('set_folder_thumbnail')}</span>
                        <p className={styles.subTitle}>{t('location_use_thumb')}</p>
                        <div className={styles.thumb}>
                            {formik.values.thumb ? (
                                <img src={URL.createObjectURL(formik.values.thumb)} alt="logo" />
                            ) : (
                                <div className={styles.title}>
                                    <div className={styles.thumb__title}>{t("thumbnail")}</div>{' '}
                                    <div className={styles.subTitle}>{t('location_use_thumb')}</div>

                                    <label for="thumb" className={styles.selectImg}>
                                        <div>{t('select_image')}</div>
                                    </label>
                                    <input
                                        id="thumb"
                                        name="thumb"
                                        type="file"
                                        accept="image/*"
                                        style={{ visibility: 'hidden' }}
                                        onChange={(event) => {
                                            formik.setFieldValue('thumb', event.currentTarget.files[0])
                                        }}
                                    />
                                    {/* <input
                                        id="thumb"
                                        name="thumb"
                                        type="file"
                                        onChange={(event) => {
                                            formik.setFieldValue('thumb', event.currentTarget.files[0])
                                        }}
                                        className="form-control"
                                    /> */}
                                </div>
                            )}
                        </div>
                        <DialogActions sx={{ padding: '30px 0 0' }}>
                            <Button
                                className={styles.createBtn}
                                onClick={close}
                                disabled={!formik.isValid}
                                type="submit"
                            >
                                {t('edit_project_2')}
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default EditProjectPopup
