import { Avatar } from '@mui/material'
import React from 'react'

const SettingsAvatar = ({ name, margin, sx }) => {
    if (sx) sx.bgcolor = stringToColor(name) // add color in sx style
    function stringToColor(string) {
        let hash = 0
        let i

        for (i = 0; i < string?.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash)
        }

        let color = '#'

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff
            color += `00${value.toString(16)}`.slice(-2)
        }
        return color
    }

    function stringAvatar(name, left) {
        const nameLength = name.split(' ')?.length
        const shortString =
            nameLength === 1 ? `${name.split(' ')[0][0]}` : `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`

        return {
            sx: sx ?? {
                bgcolor: stringToColor(name),
                // position: 'absolute',
                right: left,
                width: 30,
                height: 30,
                fontFamily: 'Zeitung Micro Pro',
                fontSize: 14,
            },
            children: shortString,
        }
    }

    return <Avatar {...stringAvatar(`${name}`, margin)} />
}
export default SettingsAvatar
