import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take } from 'redux-saga/effects'
import { resource } from '../../api/http'
import { setLoading, setSuccess } from './popups'

const namespace = 'locations'

const initialState = {
    locations: [],
}

const SET_LOCATIONS = `${namespace}/SET_LOCATIONS`
const GET_LOCATIONS = `${namespace}/GET_LOCATIONS`

const CREATE_LOCATION = `${namespace}/CREATE_LOCATION`

const SEND_LOCATION_ANSWERS = `${namespace}/SEND_LOCATION_ANSWERS`
const REMOVE_LOCATION = `${namespace}/REMOVE_LOCATION`

export const setLocations = createAction(SET_LOCATIONS)
export const createLocations = createAction(CREATE_LOCATION)
export const getAllLocations = createAction(GET_LOCATIONS)
export const sendLocationsAnswers = createAction(SEND_LOCATION_ANSWERS)
export const removeLocation = createAction(REMOVE_LOCATION)

export default handleActions(
    {
        [SET_LOCATIONS]: (state, { payload }) => ({ ...state, locations: payload }),
        // [ADD_COMMENT]: (state, { payload }) => ({
        //     ...state,
        //     comments: [...state.comments, payload],
        // }),
        // [RESET_COMMENTS]: (state) => ({ ...state, comments: [] }),
    },
    initialState
)

export const locationsSelector = (state) => state[namespace]

export function* createLocationsRequest() {
    while (true) {
        const {
            payload: { values, resetForm, closePopup },
        } = yield take(CREATE_LOCATION)
        try {
            console.log('closePopup', closePopup)
            const {
                data: { data, success, message },
            } = yield call(resource.post, `api/locations`, values)
            if (success) {
                const { locations } = yield select(locationsSelector)
                yield put(setLocations([...locations, data]))
                resetForm()
                closePopup()
            }
        } catch (err) {
            console.log(err)
        }
    }
}
export function* getAllRequest() {
    while (true) {
        const { payload } = yield take(getAllLocations)
        try {
            const {
                data: { message, data, success },
            } = yield call(resource.get, `api/locations`)

            if (success) yield put(setLocations(data))
        } catch (err) {
            console.log(err)
        }
    }
}
export function* removeRequest() {
    while (true) {
        const { payload } = yield take(REMOVE_LOCATION)
        try {
            const {
                data: { message, success },
            } = yield call(resource.delete, `api/locations/${payload}`)

            if (success) {
                const { locations } = yield select(locationsSelector)
                yield put(setLocations(locations.filter((obj) => obj.id !== payload)))
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export function* sendQuizRequest() {
    while (true) {
        const {
            payload: { fd, resetForm },
        } = yield take(SEND_LOCATION_ANSWERS)
        try {
            yield put(setLoading(true))
            const {
                data: { success, data, message },
            } = yield call(resource.post, `api/location-question-answers`, fd)
            if (success) {
                yield put(setSuccess({ visible: true, text: message ?? 'Success' }))
                resetForm()
            }
        } catch (err) {
            console.log(err)
            yield put(setSuccess({ visible: true, text: err.message ?? 'Error' }))
        } finally {
            yield put(setLoading(false))
        }
    }
}
export function* sagas() {
    yield all([createLocationsRequest(), getAllRequest(), sendQuizRequest(), removeRequest()])
}
