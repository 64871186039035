import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
// import * as Yup from 'yup'
// import { Button, TextField } from '@mui/material'
import styles from './shared-deck.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector, register } from '../../store/reducers/auth'
import { Link, useParams } from 'react-router-dom'
// import DeckCarusel from '../../components/deck-carusel/DeckCarusel'
// import DeckCaruselShared from '../../components/deck-carusel-shared'
// import OpenFile from '../open-file/OpenFile'
import { getSharedFile, getSharedProject, projectsSelector } from '../../store/reducers/projects'
// import ReactPlayer from 'react-player'
import RightBar from '../../components/side-bar-right'
import FolderFileItem from '../../components/folder-file-item'
import { resource } from '../../api/http'
import { throttle } from 'lodash'
import SharedHeader from '../../components/shared-components/SharedHeader'
import { decksSelector, setSharedDeck } from '../../store/reducers/decks'
import DeckCaruselShared from '../../components/deck-carusel-shared'

const ShareDeck = ({ navigate }) => {
    // const loading = useSelector(authLoading)
    // const onHandleSubmitForm = () => {}
    const dispatch = useDispatch()
    const { uuid } = useParams()
    const auth = useSelector(authSelector)
    const [search, setSearch] = useState(null)

    const {
        sharedDeck: {
            files,
            name,
            thumb,
            description,
            type,
            font,
            background_color,
            font_color,
            twitter_link,
            facebook_link,
            linkedin_link,
            instagram_link,
        },
    } = useSelector(decksSelector)
    useEffect(() => {
        resource.get(`${process.env.REACT_APP_BASE_URL}/api/get-shared-deck/${uuid}`).then(({ data: { data } }) => {
            if (data) dispatch(setSharedDeck(data))
        })
        // dispatch(getSharedProject(uuid))
    }, [])

    // const { sharedProject } = useSelector(projectsSelector)
    // console.log('sharedProject', sharedProject)
    return (
        <div
            className={styles.wrapper}
            style={{
                position: 'absolute',
                fontFamily: font ?? 'Archivo Black',
                background: background_color,
                color: font_color,
                height: '100vh',
                width: '100vw',
                left: 0,
                top: 0,
                zIndex: 1200,
                overflow: 'hidden',
            }}
        >
            <DeckCaruselShared />
        </div>
    )
}

export default ShareDeck
