import { createAction, handleActions } from 'redux-actions'
import { all, call, put, select, take } from 'redux-saga/effects'
import { resource } from '../../api/http'

//states for different searches
const namespace = 'search'

const initialState = {
    deckSearch: [],
    deckSearchInput: '',
    mainSearch: [],
    load: false,
}

const SET_LOAD = `${namespace}/SET_LOAD`
const SET_DECK_SEARCH = `${namespace}/SET_DECK_SEARCH`
const SET_DECK_SEARCH_INPUT_VALUE = `${namespace}/SET_DECK_SEARCH_INPUT_VALUE`
const GET_MAIN_SEARCH_RESULT = `${namespace}/GET_MAIN_SEARCH_RESULT`
const SET_MAIN_RESULT = `${namespace}/SET_MAIN_RESULT`

export const setLoad = createAction(SET_LOAD)

export const setDeckSearch = createAction(SET_DECK_SEARCH)
export const setDeckSearchInputValue = createAction(SET_DECK_SEARCH_INPUT_VALUE)

export const startMainSearch = createAction(GET_MAIN_SEARCH_RESULT)
export const setMainResult = createAction(SET_MAIN_RESULT)

export default handleActions(
    {
        [SET_LOAD]: (state, { payload }) => ({ ...state, load: payload }),
        [SET_DECK_SEARCH]: (state, { payload }) => ({ ...state, deckSearch: payload }),
        [SET_DECK_SEARCH_INPUT_VALUE]: (state, { payload }) => ({ ...state, deckSearchInput: payload }),

        [SET_MAIN_RESULT]: (state, { payload }) => ({ ...state, mainSearch: payload }),
    },
    initialState
)

export const searchSelector = (state) => state[namespace]

function* addItem() {
    while (true) {
        const { payload } = yield take(SET_DECK_SEARCH)
        try {
            // const items = yield select(itemsColection)
            // const hasId = items.filter((element, idx) => {
            //     return element.id === payload.id
            // })
            // if (!hasId.length) {
            //     yield put(setItem(payload))
            // } else {
            //     const { completed, loaded, state, id } = payload
            //     yield put(updateItem({ id: id, completed: completed, loaded: loaded, itemState: state })) // Updates only fields wich need update
            // }
        } catch (err) {
            console.log(err)
        }
    }
}
function* mainSearchRequest() {
    while (true) {
        const { payload } = yield take(GET_MAIN_SEARCH_RESULT)
        try {
            const { data } = yield call(resource.post, `api/project/global-search`, { query: payload })
            if (data) {
                yield put(setMainResult(data))
            }
        } catch (err) {
            // console.log(err);
        }
    }
}
export function* sagas() {
    yield all([addItem(), mainSearchRequest()])
}
