import React, { useEffect } from 'react'
import styles from './deck-builder.module.sass'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDeckById } from '../../store/reducers/decks'

import AllProjects from '../projects/AllProject'

const DeckBuilder = ({ navigate }) => {
    let { deckId } = useParams()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDeckById(deckId))
    }, [dispatch, deckId])

    return (
        <>
            <div className={styles.container}>
                <AllProjects deckFlow={true} navigate={navigate} />
            </div>
        </>
    )
}
export default DeckBuilder
