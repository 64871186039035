import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styles from './add-members.module.sass'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { resource } from '../../../api/http'
import CustomTextField from '../../custom-text-field/CustomTextField'
import SettingsAvatar from '../../avatar/SettingsAvatar'
import { useState } from 'react'
import { projectsSelector, setAllProjects } from '../../../store/reducers/projects'
import RemoveMember from '../remove-member-from-project/RemoveMember'
import { useTranslation } from 'react-i18next'

const AddMembers = ({ project }) => {
    const [open, setOpen] = useState(false)
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()
    const { projects } = useSelector(projectsSelector)
    const { i18n, t } = useTranslation()
    const formik = useFormik({
        initialValues: {
            email: '',
            userId: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid email address').required(t('req_email')),
            userId: Yup.number().required('Id is a required field'),
        }),
        onSubmit: (values, { resetForm }) => {
            resource
                .post(`${process.env.REACT_APP_BASE_URL}/api/project/add-user-to-project/${project.id}`, {
                    inviteUserId: values['userId'],
                })
                .then(({ data: { data, message, success } }) => {
                    if (success) {
                        const currentId = projects.findIndex((e) => e.id === data.id)
                        if (currentId !== -1) {
                            projects[currentId].teamMembers = data.teamMembers

                            dispatch(setAllProjects(projects))
                        }
                    }
                })
                .then(() => resetForm())
        },
        validateOnMount: true,
    })

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const [options, setOptions] = useState([])
    // console.log('formik', formik)
    const handleSearchInputChange = (event) => {
        formik.setFieldValue('email', event.target.value)
        if (event) {
            resource
                .post(`${process.env.REACT_APP_BASE_URL}/api/search-user`, { search: formik.values['email'] })
                .then(({ data: { data } }) => {
                    const options = data.map((result) => ({
                        value: result.email,
                        id: result.id,
                        name: result.name,
                    }))
                    setOptions(options)
                })
        } else {
            setOptions([])
        }
    }

    return (
        <div className={styles.wrapper}>
            <div style={{ display: 'flex' }}>
                <AddMemberSvg onClick={handleClickOpen} />
            </div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={styles.wrapper}
            >
                <div className={styles.header}>
                    <div>
                        {project.thumb ? <img src={project.thumb} alt="thumb" /> : <FolderSvg />}
                        <span>{t('members')}</span>
                    </div>
                    <div>
                        <div>
                            <CloseSvg onClick={handleClose} />
                        </div>
                    </div>
                </div>

                <DialogContent>
                    <form onSubmit={formik.handleSubmit} className={styles.form}>
                        <span>{t('invite_by_email')}</span>
                        <div className={styles.searchUser}>
                            <CustomTextField
                                name="email"
                                formik={formik}
                                placeholder={t('enter_email')}
                                type="text"
                                small={true}
                                onChange={handleSearchInputChange}
                                autocomplete="off"
                            />
                            <button
                                onClick={() => {
                                    // handleClose()
                                }}
                                autoFocus
                                type="submit"
                                disabled={formik.errors['email']}
                            >
                                {t('send_invite')}
                            </button>
                        </div>
                        <SearchResult response={options} formik={formik} setOptions={setOptions} />
                        <div className={styles.users}>
                            {project?.teamMembers.map((e, idx) => {
                                return <MemberItem project={project} item={e} key={idx} />
                            })}
                        </div>
                        <div className={styles.footer}>
                            <div>
                                {/* <CopyLinkSvg />
                                <div>{t('copy_invitation_link')}</div> */}
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default AddMembers

const MemberItem = ({ project, item, key }) => {
    // const { setFieldValue, validateField } = formik
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className={styles.userItem} key={key}>
            <div className="">
                <SettingsAvatar key={item.id} name={item.name} margin={0} />
                <div className={styles.userItem__name}>{item.name}</div>
            </div>
            {<RemoveMember id={item.id} projectID={project.id} isOpen={isOpen} setIsOpen={setIsOpen} />}
        </div>
    )
}

const SearchResult = ({ response, formik, setOptions }) => {
    const { setFieldValue, validateField } = formik

    return response.length ? (
        <>
            <div className={styles.search__overload} onClick={() => setOptions([])}>
                {' '}
            </div>
            <div className={styles.search}>
                {response?.map((item, index) => (
                    <div
                        key={item.id}
                        onClick={() => {
                            setFieldValue('email', item.value).then(() => validateField('email'))
                            setFieldValue('userId', item.id)
                            setOptions([])
                        }}
                    >
                        {item.name + ' ' + item.value}
                    </div>
                ))}
            </div>
        </>
    ) : null
}

const AddMemberSvg = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            style={{ marginLeft: 25 }}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="30" height="30" rx="5" fill="#F8F8F8" />
            <g opacity="0.3">
                <path
                    d="M18 15C20.21 15 22 13.21 22 11C22 8.79 20.21 7 18 7C15.79 7 14 8.79 14 11C14 13.21 15.79 15 18 15ZM18 17C15.33 17 10 18.34 10 21V21C10 22.1046 10.8954 23 12 23H24C25.1046 23 26 22.1046 26 21V21C26 18.34 20.67 17 18 17Z"
                    fill="black"
                />
                <path d="M3 14.5H12" stroke="black" strokeWidth="2" strokeLinecap="round" />
                <path d="M7.5 19L7.5 10" stroke="black" strokeWidth="2" strokeLinecap="round" />
            </g>
        </svg>
    )
}
const FolderSvg = () => {
    return (
        <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="20" height="24" rx="3" fill="#4C92C0" />
            <rect y="5" width="40" height="25" rx="3" fill="#3376A3" />
            {/* <path
                opacity="0.6"
                d="M15.8749 22V14.572H14.0749L14.3149 13.24H17.3869V22H15.8749ZM23.2625 22.156C21.0185 22.156 19.8425 20.74 19.8425 17.656C19.8425 14.62 21.1025 13.036 23.3585 13.036C25.7225 13.036 26.8385 14.608 26.8385 17.62C26.8385 20.68 25.4345 22.156 23.2625 22.156ZM23.3225 20.872C24.4865 20.872 25.2905 19.924 25.2905 17.632C25.2905 15.328 24.5225 14.308 23.3225 14.308C22.1585 14.308 21.3905 15.268 21.3905 17.632C21.3905 19.9 22.1225 20.872 23.3225 20.872Z"
                fill="white"
            /> */}
        </svg>
    )
}
const CloseSvg = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17 1.00049L9 9.00049L17 17.0005" stroke="black" />
            <path d="M1 17L9 9L0.999998 1" stroke="black" />
        </svg>
    )
}
const CopyLinkSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2598_56855)">
                <path
                    d="M10.1785 11.154C8.91926 12.4436 7.63798 13.7123 6.3567 14.9809C5.67578 15.6555 4.76641 16.0011 3.76876 16.0011C1.8209 16.0011 0.194193 14.547 0.0187208 12.6181C-0.0883285 11.4444 0.252684 10.4109 1.09804 9.56403C1.63329 9.02853 2.16743 8.48971 2.70599 7.95752C2.94657 7.72013 3.28979 7.66934 3.58666 7.81067C3.87801 7.94979 4.05569 8.2457 4.03693 8.5659C4.02369 8.79004 3.91664 8.97001 3.75993 9.12569C3.23572 9.64684 2.7082 10.1658 2.19171 10.6947C1.1157 11.7966 1.4843 13.6438 2.89801 14.2544C3.70806 14.6044 4.68254 14.43 5.30828 13.8072C6.53879 12.5838 7.7671 11.3571 8.98878 10.1238C9.87939 9.22507 9.88601 7.94758 9.02079 7.0179C8.68971 6.66127 8.6864 6.18428 9.01306 5.87181C9.34083 5.55824 9.8198 5.58253 10.1575 5.92812C11.5789 7.38447 11.5988 9.69543 10.1774 11.1518L10.1785 11.154Z"
                    fill="#3376A3"
                />
                <path
                    d="M14.9438 6.39183C14.5774 6.76834 14.2033 7.13823 13.8303 7.50701C13.6084 7.72673 13.3414 7.7996 13.0401 7.70906C12.73 7.61521 12.5501 7.3977 12.4916 7.08412C12.4397 6.80257 12.5446 6.5696 12.7421 6.37196C13.1008 6.01311 13.4639 5.65869 13.817 5.29543C14.9857 4.0875 14.4075 2.04044 12.7752 1.64185C11.9806 1.44863 11.2611 1.62308 10.6685 2.21269C9.62886 3.24726 8.59258 4.28515 7.5574 5.32413C6.63811 6.24609 6.61824 7.51253 7.50553 8.46981C7.72294 8.70499 7.8024 8.97109 7.70529 9.27804C7.60707 9.58719 7.38414 9.76275 7.07072 9.81575C6.80585 9.86102 6.57961 9.76496 6.38979 9.57836C4.95842 8.16728 4.90214 5.81547 6.29709 4.37127C7.41283 3.21524 8.55285 2.08129 9.70611 0.962807C10.3992 0.292598 11.2655 0.00552323 11.8383 2.56419e-06C14.1812 -0.0022057 15.7847 1.42213 15.9779 3.37534C16.0927 4.52585 15.7616 5.55048 14.9427 6.39183H14.9438Z"
                    fill="#3376A3"
                />
            </g>
            <defs>
                <clipPath id="clip0_2598_56855">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
