import React, { useEffect } from 'react'
// import styles from './projects.module.sass'

import { useDispatch, useSelector } from 'react-redux'

import { searchSelector } from '../../store/reducers/search'
import FolderFileItem from '../folder-file-item'

const DeckSearchResult = ({ navigate }) => {
    const {
        deckSearch: { directories, files },
    } = useSelector(searchSelector)

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', ">div": { display: 'none' } }}>
            {directories?.map((e) => (
                <FolderFileItem item={e} key={e.id} navigate={navigate} deckFlow={true} />
            ))}
            {files?.map((e) => (
                <FolderFileItem item={e} key={e.id} navigate={navigate} deckFlow={true} />
            ))}
        </div>
    )
}

export default DeckSearchResult
