import { Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './opened-project.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectById, projectsSelector } from '../../store/reducers/projects'
import { getDeckById } from '../../store/reducers/decks'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useUploady, useItemFinalizeListener } from '@rpldy/uploady'
import { useParams } from 'react-router-dom'
import FolderFileItem from '../../components/folder-file-item'
import RightBar from '../../components/side-bar-right'
import ProjectHeader from '../../components/project-header/ProjectHeader'
import ListFoldeFileItem from '../../components/list-folder-file-item'
import { useTranslation } from 'react-i18next'
import CreateFolderPopup from '../../components/popups/create-folder'

const OpenedProject = ({ navigate, deckFlow, shared }) => {
    const { t } = useTranslation()
    // const loading = useSelector(authLoading)
    const { currentFiles } = useSelector(projectsSelector)

    const token = localStorage.getItem('token')
    console.log('deckFlow', deckFlow)
    //TO do for shared page ???

    let { id, deckId } = useParams()

    const sortFilters = [t('last_edit'), t('last_added'), t('liked'), t('commented'), 'a-z']
    const filters = [t('all_files'), 'Videos', 'Photos', t('files')]

    const [sort, setSort] = useState(sortFilters[0])
    const [filter, setFilter] = useState(filters[0])
    const [view, setView] = useState('grid')

    function FinalizeUpload() {
        useItemFinalizeListener((item) => {
            dispatch(getProjectById(id))
        })

        return null
    }

    let filteredArray

    const filesArray = Object.keys(currentFiles).map((e) => {
        return currentFiles[e]
    })
    if (filter === t('all_files')) {
        filteredArray = filesArray
    }
    if (filter === 'Photos') {
        filteredArray = filesArray.filter((e) => e.type === 'image')
    }
    if (filter === 'Videos') {
        filteredArray = filesArray.filter((e) => e.type === 'video')
    }
    if (filter === t('files')) {
        filteredArray = filesArray.filter((e) => e.type !== 'video' && e.type !== 'image')
    }

    if (sort === t('last_edit')) {
        filteredArray.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    }
    if (sort === t('last_added')) {
        filteredArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
    if (sort === t('liked')) {
        filteredArray = filesArray.filter((e) => e.likesCount > 0)
    }
    if (sort === t('commented')) {
        filteredArray = filesArray.filter((e) => e.commentsCount > 0)
    }
    if (sort === 'a-z') {
        filteredArray.sort((a, b) => a.name.localeCompare(b.name))
    }
    const dispatch = useDispatch()
    useEffect(() => {
        if (deckFlow) {
            dispatch(getDeckById(deckId))
        }
    }, [])

    useEffect(() => {
        if (currentFiles.length && currentFiles[0].project_id === +id) {
            return
        }
        dispatch(getProjectById(id))
    }, [id])

    console.log(filteredArray)

    return (
        <>
            <div className={styles.wrapper}>
                <ProjectHeader
                    deckFlow={deckFlow}
                    navigate={navigate}
                    dowloadIds={filteredArray
                        ?.filter((e) => {
                            return e.type !== 'directory' ? e.id : null
                        })
                        .map((e) => e.id)}
                />
                <div className={styles.container}>
                    {currentFiles?.length ? (
                        <div className={styles.project}>
                            <FinalizeUpload />
                            <ProjectSubHeader
                                sort={sort}
                                sortFilters={sortFilters}
                                setSort={setSort}
                                view={view}
                                setView={setView}
                                filter={filter}
                                setFilter={setFilter}
                                filters={filters}
                            />
                            <DropZone deckFlow={deckFlow} token={token} view={view}>
                                {view === 'grid' &&
                                    filteredArray?.map((item) => {
                                        return (
                                            <FolderFileItem
                                                item={item}
                                                key={item.id}
                                                navigate={navigate}
                                                deckFlow={deckFlow}
                                            />
                                        )
                                    })}
                                {view === 'list' && (
                                    <div className={styles.list}>
                                        {filteredArray?.map((item) => {
                                            return (
                                                <ListFoldeFileItem
                                                    item={item}
                                                    key={item.id}
                                                    navigate={navigate}
                                                    deckFlow={deckFlow}
                                                />
                                            )
                                        })}
                                    </div>
                                )}
                            </DropZone>
                        </div>
                    ) : (
                        <>
                            <FinalizeUpload /> <DropZone isEmpty={true} token={token} deckFlow={deckFlow} view={view} />
                        </>
                    )}

                    {!deckFlow && <RightBar />}
                </div>
            </div>
        </>
    )
}
export default OpenedProject

const DropZone = ({ children, token, isEmpty, deckFlow, view }) => {
    const { setOptions, upload } = useUploady()
    let { id } = useParams()
    const [addFolderPopup, setFolderPopup] = useState(false)

    const [{ isDragging }, dropRef] = useDrop({
        accept: NativeTypes.FILE,
        collect: (monitor) => ({
            isDragging: !!monitor.isOver(),
        }),
        drop: (item) => {
            if (deckFlow) return
            setOptions({
                destination: {
                    url: `${process.env.REACT_APP_BASE_URL}/api/projects/files/upload/${id}`,
                    headers: { Authorization: `Bearer ${token}` },
                },
            })
            upload(item.files)
        },
    })

    if (deckFlow || view === 'list') {
        return (
            <div ref={dropRef} className={styles.DropZone__wrapper}>
                {children?.length &&
                    children?.map((e) => {
                        return e
                    })}
            </div>
        )
    } else
        return (
            <div ref={dropRef} className={isEmpty ? styles.DropZone__wrapper__empty : styles.DropZone__wrapper}>
                {children?.length &&
                    children?.map((e) => {
                        return e
                    })}
                <CreateFolderPopup
                    isOpen={addFolderPopup}
                    id={id}
                    close={() => setFolderPopup(false)}
                    setFolderPopup={setFolderPopup}
                    emptyProjects
                />
            </div>
        )

    // return isEmpty ? (
    //     <div ref={dropRef} className={styles.emptyDropzone}>
    //         <div>
    //             <DropSvg />
    //         </div>
    //     </div>
    // ) : (
    //     <div ref={dropRef} className={styles.DropZone__wrapper}>
    //         {children?.length &&
    //             children?.map((e) => {
    //                 return e
    //             })}
    //     </div>
    // )
}

const ViewSetting = ({ view, setView }) => {
    return (
        <>
            <div
                className={view === 'grid' ? styles.project__view__active : styles.project__view}
                onClick={() => setView('grid')}
            >
                <svg
                    opacity={view === 'grid' ? 0.5 : 1}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect x="0.5" y="0.5" width="6.46667" height="6.46667" rx="0.5" stroke="#3376A3" />
                    <rect x="0.5" y="9.03345" width="6.46667" height="6.46667" rx="0.5" stroke="#3376A3" />
                    <rect x="9.03333" y="0.5" width="6.46667" height="6.46667" rx="0.5" stroke="#3376A3" />
                    <rect x="9.03333" y="9.03345" width="6.46667" height="6.46667" rx="0.5" stroke="#3376A3" />
                </svg>
            </div>

            <div
                className={view === 'list' ? styles.project__view__active : styles.project__view}
                onClick={() => setView('list')}
            >
                <svg
                    opacity={view === 'list' ? 0.5 : 1}
                    width="16"
                    height="16"
                    viewBox="0 0 18 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="4" y1="1" x2="17" y2="1" stroke="#3376A3" strokeWidth="2" strokeLinecap="round" />
                        <line x1="4" y1="7" x2="17" y2="7" stroke="#3376A3" strokeWidth="2" strokeLinecap="round" />
                        <line x1="4" y1="13" x2="17" y2="13" stroke="#3376A3" strokeWidth="2" strokeLinecap="round" />
                        <circle cx="1" cy="13" r="1" fill="#3376A3" />
                        <circle cx="1" cy="7" r="1" fill="#3376A3" />
                        <circle cx="1" cy="1" r="1" fill="#3376A3" />
                    </svg>
                </svg>
            </div>
        </>
    )
}

const ProjectSubHeader = ({ sort, sortFilters, setSort, view, setView, filter, setFilter, filters }) => {
    const [anchorSort, setAnchorSort] = React.useState(false)
    const { t } = useTranslation()
    const handleCloseSortMenu = () => {
        setAnchorSort(null)
    }

    const [anchorFilter, setAnchorFilter] = React.useState(false)

    const handleCloseFilterMenu = () => {
        setAnchorFilter(null)
    }
    return (
        <div className={styles.project__bar}>
            <div className={styles.sort}>
                <div>{t('sort')}</div>
                <div className={styles.sort__filter} onClick={(e) => setAnchorSort(e.currentTarget)}>
                    {sort}
                    <svg
                        onClick={(e) => setAnchorSort(e.currentTarget)}
                        width="8"
                        height="5"
                        viewBox="0 0 8 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                    </svg>
                </div>

                <Menu
                    // sx={{ mt: '45px' }}
                    id={`Sort`}
                    anchorEl={anchorSort}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        ul: {
                            background: '#1F1F1F',
                            color: 'gray',
                            borderRadius: '5px',
                            padding: 0,
                            width: 145,
                        },
                        top: 15,
                        borderRadius: '5px',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorSort)}
                    onClose={handleCloseSortMenu}
                >
                    {sortFilters.map((item, idx) => {
                        return (
                            <MenuItem
                                key={idx}
                                sx={{
                                    padding: '11px 0px 10px 10px',
                                    background: sort === item ? '#292929' : '#1F1F1F',
                                    color: sort === item ? '#fff' : 'null',
                                    fontFamily: 'Zeitung Micro Pro',
                                    fontSize: 12,
                                    borderRadius: 5,
                                    '&:hover': { color: '#fff', background: '#292929' },
                                }}
                                onClick={() => {
                                    setSort(item)
                                    handleCloseSortMenu()
                                }}
                            >
                                <div>{item}</div>
                            </MenuItem>
                        )
                    })}
                </Menu>

                <span className={styles.project__countProjects}>
                    {/* {filtredProjectsMemo.length ?? ''} items, 29.3 GB */}
                </span>
            </div>

            <div className={styles.flex}>
                <div className={styles.sort}>
                    <div>{`${t("filter")} :`}</div>
                    <div className={styles.sort__filter} onClick={(e) => setAnchorFilter(e.currentTarget)}>
                        {filter}
                        <svg
                            onClick={(e) => setAnchorFilter(e.currentTarget)}
                            width="8"
                            height="5"
                            viewBox="0 0 8 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                        </svg>
                    </div>

                    <Menu
                        // sx={{ mt: '45px' }}
                        id={`Filter`}
                        anchorEl={anchorFilter}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        sx={{
                            ul: {
                                background: '#1F1F1F',
                                color: 'gray',
                                borderRadius: '5px',
                                padding: 0,
                                width: 122,
                            },
                            top: 15,
                            borderRadius: '5px',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorFilter)}
                        onClose={handleCloseFilterMenu}
                    >
                        {filters.map((item, idx) => {
                            return (
                                <MenuItem
                                    key={idx}
                                    sx={{
                                        padding: '11px 0px 10px 10px',
                                        background: filter === item ? '#292929' : '#1F1F1F',
                                        color: filter === item ? '#fff' : 'null',
                                        fontFamily: 'Zeitung Micro Pro',
                                        fontSize: 12,
                                        borderRadius: 5,
                                        '&:hover': { color: '#fff', background: '#292929' },
                                    }}
                                    onClick={() => {
                                        setFilter(item)
                                        handleCloseFilterMenu()
                                    }}
                                >
                                    <div>{item}</div>
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </div>
                <ViewSetting view={view} setView={setView} />
            </div>
        </div>
    )
}
