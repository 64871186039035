import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import styles from './change-password.module.sass'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { decksSelector } from '../../../store/reducers/decks'
import { resource } from '../../../api/http'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import { setUser } from '../../../store/reducers/user'
import CustomTextField from '../../custom-text-field/CustomTextField'
import { useTranslation } from 'react-i18next'

const ChangePasswordPopup = ({ navigate }) => {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()

    const [oldPass, setOldPass] = useState(false)
    const [newPass, setNewPass] = useState(false)
    const [confirmPass, setConfirmPass] = useState(false)
    const [success, setSuccess] = useState(false)

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()

    const { currentDeck } = useSelector(decksSelector)

    const formik = useFormik({
        initialValues: {
            new: '',
            old: '',
            confirm: '',
        },
        validationSchema: Yup.object().shape({
            old: Yup.string().min(1).required(t('req_pass')),
            new: Yup.string()
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&)_#^(=+|`:;'",./-])[A-Za-z\d@$!%*?&)_#^(=+|`:;'",./-]{8,}$/,
                    'Password should be a minimum of 8 characters with at least one uppercase, one number, and one special character'
                )
                .required(t('req_pass')),
            confirm: Yup.string()
                .oneOf([Yup.ref('new'), null], 'Passwords must match')
                .required(t('confirm_pass')),
        }),
        onSubmit: (values, { setFieldError }) => {
            const payload = {
                old_password: values['old'],
                new_password: values['new'],
            }

            resource
                .post(`${process.env.REACT_APP_BASE_URL}/api/set-new-password`, payload)
                .then(({ data: { data, message, success } }) => {
                    if (success) {
                        setSuccess(true)
                        dispatch(setUser(data))
                    } else {
                        setFieldError('old', message)
                    }
                })
                .catch(
                    ({
                        response: {
                            data: { message, success },
                        },
                    }) => {
                        if (!success && message) formik.setFieldError('old', message)
                        console.log('catch', message)
                    }
                )
        },
        validateOnMount: true,
    })

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setSuccess(false)
        formik.resetForm()
    }
    return (
        <div className={styles.wrapper}>
            <div onClick={handleClickOpen} className={styles.password}>
                {t('change_your_password')}
            </div>

            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={styles.wrapper}
            >
                <DialogTitle
                    sx={{
                        fontFamily: 'Zeitung Micro Pro',
                        fontSize: 20,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                    id="responsive-dialog-title"
                >
                    <div>{!success && `${t('change_your_password')}`}</div>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 1.00049L9 9.00049L17 17.0005" stroke="black" />
                            <path d="M1 17L9 9L0.999998 1" stroke="black" />
                        </svg>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    {!success ? (
                        <form onSubmit={formik.handleSubmit} className={styles.form}>
                            <CustomTextField
                                name="old"
                                formik={formik}
                                type="password"
                                placeholder={t('current_password')}
                            />

                            <CustomTextField
                                name="new"
                                formik={formik}
                                type="password"
                                placeholder={t('new_password')}
                            />
                            {/* <div class={styles.field}>
                                <input
                                    type={newPass ? 'text' : 'password'}
                                    name="new"
                                    onChange={formik.handleChange}
                                    placeholder={t('new_password')}
                                    autocomplete
                                />{' '}
                                <ShowPassSvg onClick={() => setNewPass(!newPass)} />
                            </div> */}
                            <CustomTextField
                                name="confirm"
                                formik={formik}
                                type="password"
                                placeholder={t('confirm_new_password')}
                            />

                            <button
                                className={styles.changeBtn}
                                // onClick={() => {}}
                                autoFocus
                                type="submit"
                                disabled={!formik.isValid}
                            >
                                {t('change')}
                            </button>
                            <a href={'/'} className={styles.forgot}>
                                {t('forgot_pass')}
                            </a>
                        </form>
                    ) : (
                        <div className={styles.success}>
                            <SuccessSvg />
                            Password changed successfully
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default ChangePasswordPopup

const ShowPassSvg = ({ onClick }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <g opacity="0.5">
                <path
                    d="M3 12C5.82353 8.22689 13.3765 2.9445 21 12C18.1765 15.7731 10.6235 21.0555 3 12Z"
                    stroke="black"
                />
                <circle cx="12" cy="12" r="2.5" fill="black" stroke="black" />
                <line x1="5.85355" y1="4.64645" x2="20.8536" y2="19.6464" stroke="#E2E2E2" />
                <line x1="4.85355" y1="4.64645" x2="20.8536" y2="20.6464" stroke="black" />
            </g>
        </svg>
    )
}
const SuccessSvg = ({ onClick }) => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="77" height="77" rx="38.5" fill="#D7E7FF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M56.8135 23.6918C58.1333 24.827 58.3912 26.9598 57.3896 28.4556L39.1754 55.6554C38.6082 56.5025 37.724 57 36.7857 57C35.8475 57 34.9633 56.5025 34.396 55.6554L22.6104 38.0555C21.6088 36.5598 21.8667 34.427 23.1865 33.2918C24.5063 32.1566 26.3882 32.4489 27.3898 33.9447L36.7857 47.976L52.6102 24.3447C53.6118 22.849 55.4937 22.5566 56.8135 23.6918Z"
                fill="#3376A3"
            />
            <rect x="1.5" y="1.5" width="77" height="77" rx="38.5" stroke="#3376A3" strokeWidth="3" />
        </svg>
    )
}
