import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../location-quiz.module.sass'
import { resource } from '../../../api/http'
import { getAllCastings } from '../../../store/reducers/castings'
import { useTranslation } from 'react-i18next'

const General = ({ formik }) => {
    const { t } = useTranslation()
    const { id } = useParams()
    const [currentQuiz, setCurrentQuiz] = useState([])
    const { questions } = currentQuiz
    const generalIds = [3, 4] //[1, 2, 3, 4, 5,6, 7]


    useEffect(() => {
        if (!currentQuiz) dispatch(getAllCastings())
        try {
            resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/locations/${id}`)
                .then(({ data: { data, success, message } }) => {
                    if (success) setCurrentQuiz(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])
    const dispatch = useDispatch()

    const inclusesQuestion = (id) => {
        var found = questions?.find((e) => e.id === id)
        return found !== -1 ? found : false
    }

    return (
        <div>
            <div>
                <div className={styles.subTitle}>
                    <div>{t('general_casting')}</div>
                </div>

                <div>
                    <div className={styles.question}>
                        <span>{t('first_name')}</span>

                        <input type="text" name={'1'} value={formik.values[`1`]} onChange={formik.handleChange} />
                    </div>
                    <div className={styles.question}>
                        <span>{t('last_name')}</span>

                        <input type="text" name={'2'} value={formik.values[`2`]} onChange={formik.handleChange} />
                    </div>
                    {generalIds?.map((e) => {
                        const findItem = inclusesQuestion(e)
                        // console.log(item)
                        return findItem ? (
                            <div className={styles.question} key={findItem?.id}>
                                <span>{t(findItem?.name)}</span>

                                <input
                                    type="text"
                                    name={`${findItem?.id}`}
                                    value={formik.values[`${findItem?.id}`]}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        ) : null
                    })}
                </div>
            </div>
        </div>
    )
}

export default General
