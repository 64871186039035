import React, { useEffect, useState } from 'react'
import styles from '../settings.module.sass'
import banner from '../../../assets/img/about-banner.jpg'

const About = ({ navigate }) => {
    return (
        <div className={styles.about}>
            <img src={banner} alt="banner" />

            <span style={{ marginBottom: '15px' }}>Sfinansowano w ramach reakcil Unii na pandemie COVID-19</span>
            <span style={{ marginBottom: '15px' }}>
                PELICULA LOCA TOMASZ KULESZA jest inwestorem i właścicielem platformy elektronicznej produkcji
                filmowo-telewizyjnej SKICKA stworzonej przez Pelicula Loca Tomasz Kulesza. Projekt został wdrożony w
                życie, przeszedł szereg scenariuszy testowych i pomyślcie został umieszczony w środowisku sieciowym.
                Platforma zawiera rozwiązania informatyczne pozwalające na wdrożenie nowoczesnych rozwiązań pracy
                zdalnej i szeregu innowacji procesowych, które pozwolą na sprawną pracę nad projektami jak i
                przygotowywanie ofert projektowych dla kontrahentów.
            </span>
            <span>Wartość projektu: 270000,00 PLN</span>
            <span>Wkład Funduszy Europejskich: 229500,00 PLN</span>
            <span style={{ marginBottom: '15px' }}>Inwestor: Pelicula Loca Tomasz Kulesza. ul. Woronicza 31/122 02-640</span>
        </div>
    )
}

export default About
