import React, { useEffect, useMemo, useRef } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './settings.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateUser, userSelector } from '../../store/reducers/user'
import Members from './tabs/Members'
import General from './tabs/General'
import Plans from './tabs/Plans'
import { useTranslation } from 'react-i18next'
import About from './tabs/About'

const Settings = ({ navigate }) => {
    // const loading = useSelector(authLoading)

    const dispatch = useDispatch()
    const user = useSelector(userSelector)
    const { key } = useParams()
    const { i18n, t } = useTranslation()
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            firstName: '',
            lastName: '',
            organization: '',
            avatar: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .min(2, 'First name must be at least 2 characters')
                .max(50, 'First name must be at most 50 characters')
                .required('Please enter a valid first name'),
            email: Yup.string().email('Invalid email address').required(t('req_email')),
            // firstName: Yup.string()
            //     .min(2, 'First name must be at least 2 characters')
            //     .max(50, 'First name must be at most 50 characters')
            //     .required('Please enter a valid first name'),
            // lastName: Yup.string()
            //     .min(2, 'First name must be at least 2 characters')
            //     .max(50, 'First name must be at most 50 characters')
            //     .required('Please enter a valid first name'),
            // organization: Yup.string()
            //     .min(2, 'First name must be at least 2 characters')
            //     .max(50, 'First name must be at most 50 characters')
            //     .required('Please enter a valid first name'),
        }),
        onSubmit: (values) => {
            let fd = new FormData()
            if (values.name) fd.append('name', values.name)
            if (values.email) fd.append('email', values.email)
            if (values.firstName) fd.append('firstName', values.firstName)
            if (values.lastName) fd.append('lastName', values.lastName)
            if (values.organization) fd.append('organization', values.organization)

            dispatch(updateUser(fd))
        },
    })

    useEffect(() => {
        formik.setValues(user) // sets values from server
    }, [user])

    switch (key) {
        case 'general':
            return <General navigate={navigate} />
            break
        case 'members':
            return <Members navigate={navigate} />
            break
        case 'plans':
            return <Plans navigate={navigate} />
            break
        case 'about':
            return <About navigate={navigate} />
            break
        default:
            return null
    }
}

export default Settings
