import React, { useEffect, useState } from 'react'
import styles from './projects.module.sass'
import CreateProjectPopup from '../../components/popups/create-project'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProjects, projectsSelector } from '../../store/reducers/projects'

import { useParams } from 'react-router-dom'
import DeckSearch from '../../components/deck-file-search/DeckSearch'
import { searchSelector } from '../../store/reducers/search'
import ProjectsMap from './projectsMap'
import DeckSearchResult from '../../components/deck-search-result/DeckSeachResult'
import { decksSelector } from '../../store/reducers/decks'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

const AllProjects = ({ deckFlow, navigate }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projects } = useSelector(projectsSelector)
    const { currentDeck } = useSelector(decksSelector)
    const {
        deckSearch: { directories, files },
    } = useSelector(searchSelector)

    const sortFilters = [t('last_edit'), t('last_added'), 'a-z']

    const [sort, setSort] = useState(sortFilters[0])
    const [anchorSort, setAnchorSort] = React.useState(false)
    const handleCloseSortMenu = () => {
        setAnchorSort(null)
    }

    let filteredArray = projects.map((e) => e)

    if (sort === t('last_edit')) {
        filteredArray.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    }
    if (sort === t('last_added')) {
        filteredArray.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    }
    if (sort === 'a-z') {
        filteredArray.sort((a, b) => a.name.localeCompare(b.name))
    }

    const { deckId } = useParams()
    const hasResult = Boolean(deckFlow && (directories?.length || files?.length))
    return (
        <div style={{ width: '100%' }}>
            <div>
                <div className={styles.header}>
                    <div>
                        {deckFlow ? (
                            <div className={styles.deckHeader}>
                                <DeckSvg />
                                {currentDeck?.name}
                            </div>
                        ) : (
                            <>
                                <ThreeLines /> <div className={styles.header__label}>{t('all_projects')}</div>
                            </>
                        )}
                    </div>

                    {!deckFlow ? <CreateProjectPopup newButton /> : <DeckSearch />}
                </div>
            </div>

            <div className={styles.flexRow}>
                <div className={styles.project__container}>
                    <div className={styles.sort}>
                        <div>{t('sort')} </div>
                        <div className={styles.sort__filter} onClick={(e) => setAnchorSort(e.currentTarget)}>
                            {sort}
                            <svg
                                // onClick={(e) => setAnchorSort(e.currentTarget)}
                                width="8"
                                height="5"
                                viewBox="0 0 8 5"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 0.5L4 3.5L7 0.5" stroke="black" />
                            </svg>
                        </div>

                        <Menu
                            // sx={{ mt: '45px' }}
                            id={`Sort`}
                            anchorEl={anchorSort}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{
                                ul: {
                                    background: '#1F1F1F',
                                    color: 'gray',
                                    borderRadius: '5px',
                                    padding: 0,
                                    width: 145,
                                },
                                top: 15,
                                borderRadius: '5px',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorSort)}
                            onClose={handleCloseSortMenu}
                        >
                            {sortFilters.map((item, idx) => {
                                return (
                                    <MenuItem
                                        key={idx}
                                        sx={{
                                            padding: '11px 0px 10px 10px',
                                            background: sort === item ? '#292929' : '#1F1F1F',
                                            color: sort === item ? '#fff' : 'null',
                                            fontFamily: 'Zeitung Micro Pro',
                                            fontSize: 12,
                                            borderRadius: 5,
                                            '&:hover': { color: '#fff', background: '#292929' },
                                        }}
                                        onClick={() => {
                                            setSort(item)
                                            handleCloseSortMenu()
                                        }}
                                    >
                                        <div>{item}</div>
                                    </MenuItem>
                                )
                            })}
                        </Menu>

                        <span className={styles.project__countProjects}>
                            {/* {filtredProjectsMemo.length ?? ''} items, 29.3 GB */}
                        </span>
                    </div>
                    <div
                        className={deckFlow ? styles.projWrapperDeck : styles.projWrapper}
                        style={{ width: '100%', color: 'black' }}
                    >
                        {/* {hasResult && directories.map((e) => e.name)}
                        {hasResult && files.map((e) => e.name)} */}
                        <DeckSearchResult navigate={navigate} />
                        {!deckFlow && <CreateProjectPopup emptyProjects={true} />}
                        {!hasResult && (
                            <ProjectsMap
                                projects={filteredArray}
                                deckFlow={deckFlow}
                                deckId={deckId}
                                navigate={navigate}
                            />
                        )}
                    </div>
                </div>
                {/* <RightBar /> */}
            </div>
        </div>
    )
}

export default AllProjects

const ThreeLines = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="3" y1="3" x2="13" y2="3" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="8" x2="13" y2="8" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
            <line x1="3" y1="13" x2="13" y2="13" stroke="#CACACA" strokeWidth="2" strokeLinecap="round" />
        </svg>
    )
}
const DeckSvg = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.975433 0.582245C1.03276 0.245079 1.30738 0 1.62786 0H11.3721C11.6926 0 11.9672 0.245079 12.0246 0.582245L12.9891 6.25497C13.0631 6.69024 12.7504 7.09091 12.3367 7.09091H0.663316C0.24959 7.09091 -0.0631222 6.69024 0.0108874 6.25497L0.975433 0.582245Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.18129 1.41818L1.45788 5.67273H11.5421L10.8187 1.41818H2.18129ZM1.62786 0C1.30738 0 1.03276 0.245079 0.975433 0.582245L0.0108874 6.25497C-0.0631222 6.69024 0.24959 7.09091 0.663316 7.09091H12.3367C12.7504 7.09091 13.0631 6.69024 12.9891 6.25497L12.0246 0.582245C11.9672 0.245079 11.6926 0 11.3721 0H1.62786Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.909 13L5.909 5.90906L7.09082 5.90906L7.09082 13L5.909 13Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5459 11.8181L9.45499 11.8181V12.9999H3.5459V11.8181Z"
                fill="black"
            />
        </svg>
    )
}
