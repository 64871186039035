import { Breadcrumbs } from '@mui/material'
import styles from './breadcrumbs.module.sass'
import { Link } from 'react-router-dom'
import { projectsSelector } from '../../store/reducers/projects'
import { useSelector } from 'react-redux'

const CustomBreadcrumbs = ({ deckFlow, deckId, file }) => {
    const { projects, breadcrumbs } = useSelector(projectsSelector)
    const mainProj = projects?.find((e) => e.id === breadcrumbs[0]?.id)

    const thumb = mainProj?.thumb ? (
        <img src={`${mainProj.thumb}`} className={styles.breadcrumbs__thumb} alt="123" />
    ) : (
        <FoldeSvg />
    )
    const fullBreadcrumbs = file ? [...breadcrumbs, { id: false, name: file.fileName }] : breadcrumbs

    return (
        <>
            <Breadcrumbs
                className={styles.breadcrumbs}
                aria-label="breadcrumb"
                maxItems={4}
                separator={<Separator />}
                sx={{
                    li: {
                        fontFamily: 'Zeitung Micro Pro',
                        fontSize: 12,
                        fontWeight: 400,

                        margin: 0,
                        div: {
                            display: 'flex',
                            alignItems: 'center',
                            img: { marginRight: '5px', borderRadius: '3px' },
                            svg: { marginRight: '5px' },
                        },
                    },
                }}
            >
                {fullBreadcrumbs.map((e, idx, array) => {
                    const to = deckFlow ? `/deck-builder/${deckId}/project/${e.id}` : `/project/${e.id}`
                    const lastElement = idx === array.length - 1
                    return lastElement ? (
                        <div style={{ color: '#000', img: {} }} key={e.id}>
                            {e.id === false ? (
                                <img
                                    src={file?.leftSidebarPreview}
                                    className={styles.breadcrumbs__thumbFile}
                                    alt="123"
                                />
                            ) : idx === 0 ? (
                                thumb
                            ) : (
                                <FoldeSvg />
                            )}
                            {/* {idx === 0 ? thumb : <FoldeSvg />} */}
                            {e.name}
                        </div>
                    ) : (
                        <Link to={to} key={e.id}>
                            {idx === 0 ? thumb : <FoldeSvg />}
                            {e.name}
                        </Link>
                    )
                })}
            </Breadcrumbs>
        </>
    )
}
export default CustomBreadcrumbs
const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}
const FoldeSvg = () => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <rect x="5" y="6" width="11" height="15" rx="1" fill="black" />
                <rect x="5" y="9" width="20" height="15" rx="1" fill="#4B4B4B" />
            </g>
        </svg>
    )
}
