import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styles from '../location-quiz.module.sass'
import { resource } from '../../../api/http'
import { useTranslation } from 'react-i18next'
import { getAllLocations } from '../../../store/reducers/locations'

import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'

const Description = (props) => {
    const { formik, google } = props
    const { t } = useTranslation()
    const { id } = useParams()
    const dispatch = useDispatch()
    const [currentQuiz, setCurrentQuiz] = useState([])
    const { questions } = currentQuiz
    const ids = [5, 6, 7, 8]
    const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 }) // Default center for the map

    useEffect(() => {
        if (!currentQuiz) dispatch(getAllLocations())
        try {
            resource
                .get(`${process.env.REACT_APP_BASE_URL}/api/locations/${id}`)
                .then(({ data: { data, success, message } }) => {
                    if (success) setCurrentQuiz(data)
                })
        } catch (error) {
            alert(error)
        }
    }, [])

    useEffect(() => {
        // Parse the URL to extract latitude and longitude
        const latLngRegex = /@(-?\d+\.\d+),(-?\d+\.\d+)/
        const matches = formik.values['8']?.match(latLngRegex)

        if (matches && matches.length === 3) {
            const latitude = parseFloat(matches[1])
            const longitude = parseFloat(matches[2])
            setMapCenter({ lat: latitude, lng: longitude })
        }
    }, [formik.values['8']])

    const inclusesQuestion = (id) => {
        var found = questions?.find((e) => e.id === id)
        return found !== -1 ? found : false
    }
    const showComponent = () => {
        var found = false

        for (let i = 0; i < ids.length; i++) {
            const temp = inclusesQuestion(ids[i])
            if (temp) {
                found = true
                return true
            }
        }
        return found
    }

    return showComponent() ? (
        <div>
            <div>
                <div className={styles.subTitle}>
                    <div>{t('description')}</div>
                </div>

                <div>
                    {ids?.map((e) => {
                        const findItem = inclusesQuestion(e)
                        return findItem ? (
                            <div className={styles.question} key={findItem?.id}>
                                <span>{t(findItem?.name)}</span>

                                <input
                                    type="text"
                                    name={`${findItem?.id}`}
                                    value={formik.values[`${findItem?.id}`]}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        ) : null
                    })}
                    {inclusesQuestion(8) && (
                        <div className={styles.map}>
                            <div className={styles.mapWrapper}>
                                <Map google={props.google} center={mapCenter} zoom={14}>
                                    {mapCenter && <Marker position={mapCenter} />}
                                </Map>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : null
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_MAPBOX_TOKEN,
})(Description)
