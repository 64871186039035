import React, { useEffect, useState } from 'react'
import styles from '../left-bar.module.sass'
import { Link } from 'react-router-dom'
import { TreeItem, useTreeItem } from '@mui/lab'
import { useDispatch, useSelector } from 'react-redux'
import {
    deleteProjectById,
    projectsSelector,
    addDefaultExpanded,
    removeDefaultExpanded,
} from '../../../store/reducers/projects'
import { Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import CreateFolderPopup from '../../popups/create-folder'
import { BasketSvg } from '../../../assets/img/svg/svgs'
import { useTranslation } from 'react-i18next'

export default function CustomTreeItem(props) {
    // console.log(111, props)
    return (
        <TreeItem
            draggable={true}
            ContentComponent={CustomContent}
            // onDragStart={() => {
            //     console.log('ondragStart')
            // }}
            ContentProps={props}
            {...props}
        />
    )
}

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        thumb,
        padding,
        setSelectedItems,
        firstChild,
        // selected,
    } = props
    // console.log("CUSTOM ", setSelectedItems)
    const { t } = useTranslation()
    const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
        useTreeItem(nodeId)

    const icon = iconProp || expansionIcon || displayIcon

    const { defaultExpanded } = useSelector(projectsSelector)

    useEffect(() => {
        return () => {
            if (expanded && !defaultExpanded.includes(nodeId)) dispatch(addDefaultExpanded(nodeId))
            if (!expanded && defaultExpanded.includes(nodeId)) dispatch(removeDefaultExpanded(nodeId))
        }
    }, [expanded])

    const handleMouseDown = (event) => {
        preventSelection(event)
    }

    const handleExpansionClick = (event) => {
        handleExpansion(event)
    }
    // const handleSelectionClick = (event) => {
    //     handleSelection(event)
    // }

    const [anchorElProject, setAnchorElProject] = React.useState(false)
    const handleOpenprojectMenu = (event) => {
        setAnchorElProject(event.currentTarget)
    }
    const handleCloseUserMenu = () => {
        setAnchorElProject(null)
    }

    const dispatch = useDispatch()

    function removeProject() {
        dispatch(deleteProjectById(nodeId))
    }
    const menuItems = [
        { label: t('go_to_this_folder'), link: `/project/${+nodeId}` },
        { label: t('delete_folder'), svg: <BasketSvg />, action: () => removeProject() },
        { label: t('add_folder'), svg: <AddFolderSvg />, action: () => setFolderPopup(true) },
        // { label: 'Create Deck' },
        // { label: 'Comment' },
        // { label: 'Share' },
        // { label: 'Copy' },
        // { label: 'Download' },
    ]

    const [addFolderPopup, setFolderPopup] = React.useState(false)
    const selectedStyle = selected
        ? {
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: padding,
              border: '2px solid #3376A3',
              borderRadius: 5,
              background: '#D7E7FF',
              height: 34,
          }
        : {
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: padding,
              height: 34,
              border: '2px solid rgba(255, 0, 0, .0)',
              background: firstChild ? 'transparent' : '#F7F7F7',
              borderRadius: 5,
          }
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            onClick={() => {
                setSelectedItems(nodeId)
            }}
            style={selectedStyle}
            // className={clsx(className, classes.root, {
            //   [classes.expanded]: expanded,
            //   [classes.selected]: selected,
            //   [classes.focused]: focused,
            //   [classes.disabled]: disabled,
            // })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    img: {
                        height: 16,
                        width: 24,
                    },
                }}
            >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={() => handleExpansionClick(nodeId)} className={classes.iconContainer}>
                    {icon}
                </div>
                {thumb && <img src={thumb} alt="thumb" />}
                {!thumb && <FolderSvg />}
                <Link to={`/project/${nodeId}`} className={styles.asside__tree__link}>
                    {label}
                </Link>
                {/* <Typography onClick={handleSelectionClick} component="div" className={classes.label}></Typography> */}
            </Box>
            {selected && (
                <Box sx={{ marginLeft: '15px' }}>
                    <Tooltip title="Open menu">
                        <div>
                            <IconButton
                                sx={{
                                    background: 'transparent',
                                    height: 30,
                                    width: 30,
                                    borderRadius: '5px',
                                    // marginLeft: '10px',
                                    padding: 0,
                                }}
                                onClick={handleOpenprojectMenu}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="6" cy="12" r="2" fill="#3376A3" />
                                    <circle cx="12" cy="12" r="2" fill="#3376A3" />
                                    <circle cx="18" cy="12" r="2" fill="#3376A3" />
                                </svg>
                            </IconButton>
                        </div>
                    </Tooltip>
                    <Menu
                        sx={{
                            ul: {
                                width: 250,
                                background: '#1F1F1F',
                                color: '#fff',
                                borderRadius: '5px',
                                padding: 0,
                            },
                            // marginTop: '30px',
                            borderRadius: '5px',
                        }}
                        id={`project${nodeId}_menu`}
                        anchorEl={anchorElProject}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElProject)}
                        onClose={handleCloseUserMenu}
                    >
                        {menuItems.map((item, idx) => {
                            return (
                                <div key={idx}>
                                    {item.link ? (
                                        <Link to={`${item.link}`} className={styles.menuLink}>
                                            <MenuItem
                                                sx={{
                                                    fontFamily: 'Zeitung Micro Pro',
                                                    fontSize: 12,
                                                    borderRadius: 5,
                                                    div: {
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        height: 40,
                                                        opacity: 0.5,
                                                        '&:hover': { opacity: 1, background: '#292929' },
                                                    },
                                                }}
                                                onClick={handleCloseUserMenu}
                                            >
                                                <div>
                                                    {item.label} {item.svg ?? ''}
                                                </div>
                                            </MenuItem>
                                        </Link>
                                    ) : (
                                        <MenuItem
                                            sx={{
                                                fontFamily: 'Zeitung Micro Pro',
                                                fontSize: 12,
                                                borderRadius: 5,
                                                div: {
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    height: 40,
                                                    opacity: 0.5,
                                                    '&:hover': { opacity: 1, background: '#292929' },
                                                },
                                            }}
                                            onClick={() => {
                                                item.action()

                                                handleCloseUserMenu()
                                            }}
                                        >
                                            <div>
                                                {item.label} {item.svg ?? ''}
                                            </div>
                                        </MenuItem>
                                    )}
                                </div>
                            )
                        })}
                    </Menu>
                </Box>
            )}
            <CreateFolderPopup isOpen={addFolderPopup} id={nodeId} close={() => setFolderPopup(false)} />
        </div>
    )
})
const FolderSvg = () => {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="6.8" height="8.88889" rx="1" fill="#7C7C7C" />
            <rect y="1.66675" width="12" height="8.33333" rx="1" fill="#A1A1A1" />
        </svg>
    )
}

const AddFolderSvg = () => {
    return (
        <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="4" width="12.4667" height="16" rx="2" fill="#545454" />
            <rect y="9" width="22" height="13" rx="2" fill="#696969" />
            <circle cx="20" cy="6.125" r="5.5" fill="#696969" stroke="#1F1F1F" />
            <line x1="16.666" y1="6.2915" x2="23.3327" y2="6.2915" stroke="#1F1F1F" />
            <line x1="20.1665" y1="9.4585" x2="20.1665" y2="2.79183" stroke="#1F1F1F" />
        </svg>
    )
}
