import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import styles from './checout-form.module.sass'
import { useDispatch } from 'react-redux'
import { getUser } from '../../store/reducers/user'

function CheckoutForm({ navigate }) {
    // collect data from the user
    const dispatch=useDispatch()
    const [name, setName] = useState('')
    const location = useLocation()

    let planId = location.state?.planId


    // stripe items
    const stripe = useStripe()
    const elements = useElements()
    const token = localStorage.getItem('token')
    // main function


    const createSubscription = async () => {
        try {
            // create a payment method
            const paymentMethod = await stripe?.createPaymentMethod({
                type: 'card',
                card: elements?.getElement(CardElement),
                billing_details: {
                    name,
                    // email,
                },
            })

            // call the backend to create subscription
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/user/subscription`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    paymentMethod: paymentMethod?.paymentMethod?.id,
                    name,
                    // email,
                    planId,
                }),
            })
                .then((res) => res.json())
                .then((json) => {
                    const { data, success, message } = json
                    if(success){
                        dispatch(getUser())
                        navigate('/settings/plans')
                        
                    }
                })

            const confirmPayment = await stripe?.confirmCardPayment(response.clientSecret)

            if (confirmPayment?.error) {
                alert(confirmPayment.error.message)
            } else {
                alert('Success! Check your email for the invoice.')
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        // <Elements stripe={stripePromise}>
        <div className={styles.wrapper}>
            <input placeholder="Name" type="text" value={name} onChange={(e) => setName(e.target.value)} />
            <br />
            {/* <input placeholder="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} /> */}

            <CardElement />
            <button onClick={createSubscription} disabled={!stripe}>
                Subscribe
            </button>
        </div>
        // </Elements>
    )
}

export default CheckoutForm
