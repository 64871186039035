import { Tab, Tabs } from '@mui/material'
import styles from './casting-bar.module.sass'
import { TabContext, TabPanel } from '@mui/lab'
import { useState } from 'react'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CommentSection from './assides/CommentSection'
import InfoSection from './assides/AnswersSection'

const CastingBar = ({ currentAnsvers }) => {
    const [value, setValue] = useState('1')
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const [menuIsOpen, setMenuIsOpen] = useState(true)
    const handleTabClick = (prevValue, newValue) => {
        if (!menuIsOpen) {
            setMenuIsOpen(!menuIsOpen)
        } else {
            if (prevValue === newValue) setMenuIsOpen(!menuIsOpen)
        }
    }

    return (
        <>
            <div className={styles.wrapper}>
                <TabContext value={value} sx={{ display: 'flex' }}>
                    {menuIsOpen && (
                        <div className={styles.tabPanelWrapper}>
                            <TabPanel value="1">
                                <InfoSection currentAnsvers={currentAnsvers ?? []} />
                            </TabPanel>

                            <TabPanel value="2" sx={{ height: '100%' }}>
                                <CommentSection />
                            </TabPanel>
                            {/* <TabPanel value="3">
                                <SettingsSection />
                            </TabPanel> */}
                        </div>
                    )}
                    <Tabs
                        value={value}
                        // aria-label="wrapped label tabs example"
                        orientation="vertical"
                        sx={{
                            borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
                            button: { minWidth: 50, img: { width: 20, height: 20 } },
                        }}
                        onChange={handleChange}
                    >
                        <Tab value="1" icon={<InfoOutlinedIcon />} onClick={() => handleTabClick(value, '1')} />
                        {/* <Tab value="2" icon={<FolderIcon />} onClick={() => handleTabClick(value, '2')} /> */}
                        {/* <Tab value="2" icon={<ChatIcon />} onClick={() => handleTabClick(value, '2')} />     */}
                        {/* <Tab value="3" icon={<SettingsOutlinedIcon />} onClick={() => handleTabClick(value, '3')} /> */}
                    </Tabs>
                </TabContext>
            </div>
        </>
    )
}
export default CastingBar
