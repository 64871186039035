import React, { useEffect } from 'react'
import styles from './add-tags.module.sass'

const AddTag = ({ setFieldValue, values, handleChange }) => {
    const addTag = () => {
        setFieldValue('tags', [...values['tags'], values['tag']]).then(() => setFieldValue('tag', ''))
    }
    const removeTag = (index) => {
        setFieldValue(
            'tags',
            values['tags'].filter((e, idx) => idx !== index)
        )
    }

    return (
        <>
            <div className={styles.tags}>
                <input
                    type="text"
                    name="tag"
                    // className={styles.nameInput}
                    onChange={handleChange}
                    value={values['tag']}
                />
                <button onClick={addTag} disabled={!values['tag'] || values['tags'].includes(values['tag'])}>
                    <PlusSvg />
                </button>
            </div>
            <div className={styles.tags__items}>
                {values['tags']?.map((e, idx) => (
                    <div key={e}>
                        <div>{e}</div>
                        <RemoveTagSvg onClick={() => removeTag(idx)} />
                    </div>
                ))}
            </div>
        </>
    )
}
export default AddTag

const PlusSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="16" y1="8.13892" x2="-9.02429e-08" y2="8.13892" stroke="white" strokeWidth="2" />
            <line x1="8.11084" y1="0.25" x2="8.11084" y2="15.75" stroke="white" strokeWidth="2" />
        </svg>
    )
}
const RemoveTagSvg = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line x1="3.46481" y1="10.4646" x2="10.4648" y2="3.46456" stroke="#3376A3" />
            <line x1="10.4648" y1="10.5354" x2="3.46481" y2="3.53544" stroke="#3376A3" />
        </svg>
    )
}
