import React, { useState } from 'react'

import styles from './search.module.sass'
import { useSelector } from 'react-redux'
import { searchSelector } from '../../store/reducers/search'
import FolderFileItem from '../../components/folder-file-item'
import { useTranslation } from 'react-i18next'

const Search = ({ navigate }) => {
    // const loading = useSelector(authLoading)
    // const onHandleSubmitForm = () => {}
    // const dispatch = useDispatch()
    const { t } = useTranslation()
    const {
        mainSearch: { files, directories },
    } = useSelector(searchSelector)

    const [hideFolders, setHideFolders] = useState(false)
    const [hideFiles, setHideFiles] = useState(false)
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <BackSvg
                    onClick={() => {
                        navigate(-1)
                    }}
                />
                <div>
                    <SearchSvg />
                    <span>{t('search')}</span>
                </div>
            </div>
            <div className={styles.content}>
                <div className={styles.title} onClick={() => setHideFolders(!hideFolders)}>
                    <ChevronSvg hide={hideFolders} /> <span>{t("folders")}</span>
                </div>
                {!hideFolders && (
                    <div className={styles.items}>
                        {directories?.map((item) => (
                            <div>
                                <FolderFileItem item={item} key={item.id} navigate={navigate} />
                            </div>
                        ))}
                    </div>
                )}
                <div className={styles.title} onClick={() => setHideFiles(!hideFiles)}>
                    <ChevronSvg hide={hideFiles} /> <span>{t("files")}</span>
                </div>
                {!hideFiles && (
                    <div className={styles.items}>
                        {files?.map((item) => (
                            <FolderFileItem item={item} key={item.id} navigate={navigate} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Search

const BackSvg = ({ onClick }) => {
    return (
        <svg
            onClick={onClick}
            width="12"
            height="22"
            viewBox="0 0 12 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M11 1L1 11L11 21" stroke="black" />
            </g>
        </svg>
    )
}

const SearchSvg = () => {
    return (
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.94151 8.33304L9.99863 10.3902" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <circle cx="4.99935" cy="4.99984" r="3.83333" stroke="black" />
        </svg>
    )
}
const ChevronSvg = ({ hide }) => {
    return (
        <svg
            style={{ transform: hide ? 'rotate(-90deg)' : 'none' }}
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M1 0.5L6 5.5L11 0.5" stroke="black" />
        </svg>
    )
}
