import { Breadcrumbs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from './opened-share-file.module.sass'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import RightBar from '../../components/side-bar-right'
import SharedHeader from '../../components/shared-components/SharedHeader'
import { resource } from '../../api/http'
import { authSelector } from '../../store/reducers/auth'

const OpenSharedFile = ({ navigate }) => {
    // OPEN FILE FROM SHARED PROJECT

    let { uuid, id, projectId } = useParams() // SHARED PROJECT UUID AND FILE ID and current open project id

    const auth = useSelector(authSelector)
    const [currentData, setCurrentData] = useState(false) //current page
    const [currentTree, setCurrentTree] = useState([]) //current page

    useEffect(() => {
        resource
            .post(`${process.env.REACT_APP_BASE_URL}/api/get-shared-project/${uuid}`, { project_id: +projectId })
            .then(({ data: { data, tree } }) => {
                setCurrentData(data)
                setCurrentTree(tree)
            })
    }, [id])

    const filesArray = currentData?.length
        ? Object.keys(currentData)?.map((e) => {
              return currentData[e]
          })
        : []

    const file = filesArray?.find((element) => element.id == id)

    return (
        <>
            {!auth && (
                <div className={styles.wrapper}>
                    <SharedHeader navigate={navigate} />
                </div>
            )}
            <div className={styles.header}>
                <div className={styles.flexCenter}>
                    <svg
                        onClick={() => navigate(-1)}
                        width="12"
                        height="22"
                        style={{ marginRight: '10px' }}
                        viewBox="0 0 12 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M11 1L1 11L11 21" stroke="black" />
                    </svg>

                    <Breadcrumbs
                        className={styles.breadcrumbs}
                        aria-label="breadcrumb"
                        maxItems={4}
                        separator={<Separator />}
                    >
                        {currentTree?.reverse().map((e, idx) => {
                            return <div key={e.id}>{e.name}</div>
                        })}
                    </Breadcrumbs>
                </div>

                <div className={styles.buttonGroup}></div>
            </div>
            <div className={styles.flexRow}>
                {file?.type === 'image' && (
                    <div className={styles.imageWrap}>
                        <img src={file.source} alt="Alternative texts" />
                    </div>
                )}
                {file?.type === 'video' && <ReactPlayer url={file.source} controls width={'100%'} height={'100%'} />}
                {auth && <RightBar shareFlow />}
            </div>
        </>
    )
}

export default OpenSharedFile

const Separator = () => {
    return (
        <svg
            style={{ margin: ' 0 7px' }}
            width="5"
            height="8"
            viewBox="0 0 5 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.5">
                <path d="M1 7L4 4L1 1" stroke="black" />
            </g>
        </svg>
    )
}
